import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

type Args = { integrationId: number; version: string };

const QUERY_KEY_PREFIX = 'query-implementation';
const getQueryKey = (args: Args) =>
  `${QUERY_KEY_PREFIX}-${args.integrationId}-${args.version}`;

// The types defined below should be used by consumers using this
// `useQueryImplementation` hook. One of their goals is to replace
// developer-v3/platformSchema ultimately.
//
// developer-v3/platformSchema has schemas similar to the types below. But they
// aren't completely maintained and accurate, and even ignored sometimes,
// because of incomplete FlowJS => Typescript migration, along with other nuance
// type bugs. It's challenging to fix them all. Therefore the goal is to have
// this query hook replace Entity and developer-v3/platformSchema.
type Display = {
  description: string;
  hidden?: boolean;
  important?: boolean;
  label: string;
};
type FunctionSchema = string;
type AuthType = 'basic' | 'custom' | 'digest' | 'oauth1' | 'oauth2' | 'session';
type Request = {
  auth?: string[] | Record<string, null | string | number | boolean>;
  body?: null | string | Record<string, any> | any[];
  headers?: Record<string, null | string | number | boolean>;
  method?: 'DELETE' | 'GET' | 'HEAD' | 'PATCH' | 'POST' | 'PUT';
  params?: Record<string, null | string | number | boolean>;
  removeMissingValuesFrom?: Record<string, any>;
  serializeValueForCurlies?: FunctionSchema;
  skipEncodingChars?: boolean;
  skipThrowForStatus?: boolean;
  url?: string;
};
type InputField = {
  choices?: Record<string, string>;
  key: string;
  default?: string;
  dynamic?: string;
  helpText?: string;
  label: string;
  required: boolean;
};
type OutputField = {
  key: string;
  label?: string;
  list?: boolean;
  type?: string;
};
type Sample = Record<string, any>;
type Resource = string;
type ChoiceField =
  | Record<string, any>
  | string[]
  | { label: string; sample: string; value: string }[];
type Field = {
  altersDynamicFields: boolean;
  computed?: boolean;
  dynamic: Resource;
  search: Resource;
  choices: ChoiceField[];
  dict: boolean;
  children: Field[];
  list?: boolean;
  key: string;
  default: string;
  helpText: string;
  label: string;
  required: boolean;
  type: 'string';
  placeholder: string;
  inputFormat: string;
};
type Definition = {
  authentication: {
    type: AuthType;
    connectionLabel?: string; // | FunctionSchema | Request;
    fields: Field[];
    oauth2Config: {
      authorizeUrl: FunctionSchema;
      autoRefresh: true;
      getAccessToken: FunctionSchema;
      refreshAccessToken: FunctionSchema;
    };
    test: FunctionSchema; // | Request;
  };
  creates: Record<
    string,
    {
      display: Display;
      key: string;
      noun: string;
      operation: {
        inputFields?: InputField[];
        outputFields?: OutputField[];
        perform: FunctionSchema | Request;
        performGet?: FunctionSchema | Request;
        performResume?: FunctionSchema;
        sample: Sample;
      };
    }
  >;
  platformVersion: string;
  searchOrCreates: Record<
    string,
    {
      create: string;
      display: Display;
      key: string;
      search: string;
      searchUniqueInputToOutputConstraint: Record<string, string>;
      update?: string;
      updateInputFromSearchOutput: Record<
        string,
        Record<string, null | string | number | boolean>
      >;
    }
  >;
  searches: Record<
    string,
    {
      display: Display;
      key: string;
      noun: string;
      operation: {
        inputFields?: InputField[];
        outputFields?: OutputField[];
        perform: FunctionSchema | Request;
        performGet?: FunctionSchema | Request;
        performResume?: FunctionSchema;
        sample: Sample;
      };
    }
  >;
  triggers: Record<
    string,
    {
      display: Display;
      key: string;
      noun: string;
      operation:
        | {
            canPaginate?: boolean;
            inputFields?: InputField[];
            outputFields?: OutputField[];
            perform: FunctionSchema;
            resource?: string;
            sample: Sample;
            type?: 'polling';
          }
        | {
            canPaginate?: boolean;
            inputFields?: InputField[];
            outputFields?: OutputField[];
            perform: FunctionSchema;
            performList: FunctionSchema | Request;
            performSubscribe: FunctionSchema | Request;
            performUnsubscribe: FunctionSchema | Request;
            resource?: string;
            sample: Sample;
            type?: 'hook';
          };
    }
  >;
  version: string;
};

export type Implementation = {
  appId: string;
  // changelog: string;
  // coreNpmVersion: string;
  // date: string;
  deployment: string;
  definition: Definition;
  // deprecationDate?: string;
  // environment: Record<string, string>;
  // etag?: string;
  id: number;
  // inviteUrl: string;
  isUi: boolean;
  // oauthRedirectUri?: string;
  // platformVersion: string;
  selectedApi: string;
  // null (private) -> submitted (pending) [-> // pushed-back (pushed back)] -> submitted (resubmitted)-> submitted (approved) // rejected?
  submitStatus: 'submitted' | 'pushed-back';
  // userCount?: number;
  // userCountUpdatedAt?: string;
  version: string;
};

export function formatResponseBody(body: any): Implementation {
  return {
    appId: body.app_id.toString(),
    // changelog: body.changelog,
    // coreNpmVersion: body.core_npm_version,
    // date: body.date, // Creation date.
    definition: body.definition || body.definition_override,
    deployment: body.deployment,
    // deprecateionDate: body.deprecation_date,
    // environment: body.environment,
    // etag: body.etag,
    id: body.id,
    // inviteUrl: body.invite_url,
    isUi: body.is_ui,
    // oauthRedirectUri: body.oauth_redirect_uri,
    // platformVersion: body.platform_version,
    selectedApi: body.selected_api,
    submitStatus: body.submit_status,
    // userCount: body.user_count,
    version: body.version,
  };
}

function useQueryImplementation(
  args: Args,
  options?: UseQueryOptions<Implementation, Error>
) {
  const { integrationId, version } = args;
  const notify = useNotify();
  const url = new URL(
    `api/platform/cli/apps/${encodeURIComponent(
      integrationId
    )}/versions/${encodeURIComponent(version)}`,
    ZAPIER_APP_BASE_URL
  );

  const queryKey = getQueryKey({ integrationId, version });

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryImplementation;
export { getQueryKey, QUERY_KEY_PREFIX };
