import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

export default {
  routes: {
    '/logout': {
      onEnter() {
        window.location = `${ZAPIER_APP_BASE_URL}/logout`;
      },
    },
  },
};
