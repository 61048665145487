// @flow

import _ from 'lodash';
import { routeTo } from 'redux-router-kit';

export const routeBackTo = (url: string) => {
  return (dispatch: Function, getState: Function) => {
    const { router } = getState();
    const { previous } = router;
    if (previous && previous.url === url) {
      return window.history.back();
    }
    dispatch(
      routeTo(url, {
        replace: true,
      })
    );
    return undefined;
  };
};

// Something like Backbone's `loadUrl`, which forces the route to load again.
// Used for legacy Backbone; should be removed when not needed.
export const forceRouteStateChange = () => {
  return (dispatch: Function, getState: Function) => {
    const { router } = getState();
    const { current } = router;
    const newState = { ...current.state };
    if (!newState._force || _.isNaN(newState._force)) {
      newState._force = 0;
    }
    newState._force++;
    dispatch(
      routeTo(current.url, {
        replace: true,
        state: newState,
      })
    );
  };
};

window.forceRouteStateChange = forceRouteStateChange;

window.routeBackTo = routeBackTo;
