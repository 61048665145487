import {
  APP_STATUSES_NAME,
  APP_STATUSES_DISPLAY_NAME,
} from 'app/developer-v3/constants';

export type AppStatusName = APP_STATUSES_NAME;

export type AppStatusDisplayName = APP_STATUSES_DISPLAY_NAME;

export type AppStatus = {
  name: AppStatusName | 'inviteOnly';
  display: AppStatusDisplayName;
  lifecycle?: 'build' | 'review' | 'beta' | 'partnered';
  published: boolean;
};

export enum SubmitStatus {
  // notSubmitted = null,
  submitted = 'submitted',
  pushedBack = 'pushed-back',
}

export enum ReviewStatus {
  notSubmitted = 'not-submitted',
  rejected = 'rejected',
  inReview = 'in-review',
  pushedBack = 'pushed-back',
  approved = 'approved',
}
