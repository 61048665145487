import { useContext } from 'react';

import AvroEventContext from './AvroEventContext';
import emitPageViewEvent from './emitPageViewEvent';
import emitPartnerUserInteractionEvent from './emitPartnerUserInteractionEvent';
import emitUserInteractionEvent from './emitUserInteractionEvent';
import useActiveProfile from '../useActiveProfile';
import useRouter from '../useRouter';

type ArgsEmitPageViewEvent = Parameters<typeof emitPageViewEvent>[0];
type ArgsEmitPartnerUserInteractionEvent = Parameters<
  typeof emitPartnerUserInteractionEvent
>[0];
type ArgsEmitUserInteractionEvent = Parameters<
  typeof emitUserInteractionEvent
>[0];

function useAvroEvent() {
  const { correlationIds, updateCorrelationIds } = useContext(AvroEventContext);

  const { params } = useRouter();
  const { id } = params;
  const integrationId = Number(id);

  const {
    activeProfile,
    isLoading: isLoadingActiveProfile,
    error: errorActiveProfile,
  } = useActiveProfile();

  const activeProfileId = activeProfile?.id;

  const isLoading = isLoadingActiveProfile;
  const error = errorActiveProfile;

  return {
    correlationIds,
    emitPageViewEvent: (eventProperties: {
      pagePath: ArgsEmitPageViewEvent['pagePath'];
      urlAddress: ArgsEmitPageViewEvent['urlAddress'];
    }) => {
      if (!isLoading && !error) {
        emitPageViewEvent({ ...eventProperties, userId: activeProfileId });
      }
    },
    emitPartnerUserInteractionEvent: (
      eventProperties: Omit<
        ArgsEmitPartnerUserInteractionEvent,
        'accountId' | 'customuserId' | 'integrationId'
      >
    ) => {
      if (!isLoading && !error) {
        const {
          correlation_id: correlationId,
        } = emitPartnerUserInteractionEvent({
          ...eventProperties,
          accountId: activeProfileId,
          customuserId: activeProfileId,
          integrationId,
        });

        updateCorrelationIds({
          [eventProperties.interactionName]: correlationId,
        });
      }
    },
    emitUserInteractionEvent: (
      eventProperties: ArgsEmitUserInteractionEvent
    ) => {
      if (!isLoading && !error) {
        emitUserInteractionEvent(eventProperties);
      }
    },
    error,
    isLoading,
    updateCorrelationIds,
  };
}

export default useAvroEvent;
