import { createPrefixedActions } from '@zapier/toolbox-redux-framework';

export default createPrefixedActions(
  'auth',
  `
    LOAD_AUTHS
    UPDATE_AUTHS

    CONNECT_AUTH
    CONNECT_AUTH_DONE
    CONNECT_AUTH_FAIL

    RECONNECT_AUTH
    RECONNECT_AUTH_DONE
    RECONNECT_AUTH_FAIL

    UPDATE_AUTH

    SAVE_AUTH
    SAVE_AUTH_DONE
    SAVE_AUTH_FAIL

    DELETE_AUTH
    DELETE_AUTH_DONE

    UPDATE_AUTH_STATE
    RESET_AUTH_STATES

    TEST_AUTH
    TEST_AUTH_DONE
    TEST_AUTH_FAIL

    LOAD_AUTH_COUNTS
    LOAD_AUTH_COUNTS_DONE
  `
);
