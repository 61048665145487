import ServiceActionTypes from 'app/service/ActionTypes';
import reducer from 'app/service/reducer';

export default {
  reducers: {
    service: reducer,
  },
  actionTypes: ServiceActionTypes,
  run: () => {},
};
