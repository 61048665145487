// @flow

import { frame } from 'app/perf-utils/rules/Constants';

import type {
  PerformanceLintRule,
  PerformanceMeasurementItem,
  PerformanceStats,
} from 'app/perf-utils/types';

const threshold = {
  failure: 20 * frame,
  warning: 10 * frame,
};

const rule: PerformanceLintRule = {
  id: 'slow-component-operation',

  description: `
    A slow React component lifecycle method can be due to a number of factors.
    Whenever this occurs, the best approach is to use a divide and conquer approach to finding the poorly performing parts.
    Once identified, memoization and algorithmic optimizations are two common strategies for improving performance.
    Greater than ${threshold.warning} ms is a warning.
    Greater than ${threshold.failure} ms is a failure.`,

  check(item: PerformanceMeasurementItem, stats: PerformanceStats) {
    if (item.phase === 'other' || item.phase === 'react') {
      return 'success';
    }

    if (stats.max > threshold.failure) {
      return 'failure';
    }
    if (stats.max > threshold.warning) {
      return 'warning';
    }
    return 'success';
  },
};

export default rule;
