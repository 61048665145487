// @flow

import PropTypes from 'prop-types';

export type KindType = Array<any> | Object | string;

const KindPropType = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.object,
  PropTypes.string,
]);

export default KindPropType;
