import React from 'react';

import { useLink } from '@zapier/common-hooks';

import { isClientRoute } from './utils';

const AppLink = React.forwardRef((props: any, ref) => {
  const { anchor, children, href, onClick, target } = props;
  const { routeTo } = useLink();

  const isBlockLink = typeof children !== 'string';

  const hrefComplete = href ? `${href}${anchor || ''}` : undefined;

  return (
    // This will receive all a11y props from the Link props.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <a
      {...props}
      onClick={event => {
        // eslint-disable-next-line no-unused-expressions
        onClick?.(event);

        if (isClientRoute(href)) {
          if (target) {
            if (isBlockLink) {
              window.open(hrefComplete, target).focus();
            }
          } else {
            routeTo(hrefComplete);
            // Stop browser from handling routing since routeTo is taking over
            event.preventDefault();
          }
        }
      }}
      ref={ref}
    >
      {props.children}
    </a>
  );
});

export default AppLink;
