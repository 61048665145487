import { partial } from 'lodash';

import { createKeyFromService } from 'app/developer-v3/utils';
import {
  BASE_APP_URL,
  LEGACY_BUILDER_BASE_URL,
  SERVICE_TYPE,
} from 'app/developer-v3/constants';

import type { SemVer } from 'app/developer-v3/types/version';
import type { ActionType } from 'app/developer-v3/types';
import type { ServiceType } from 'app/developer-v3/types/service';
/**
 * Builds the app URL based on ID and legacy check
 *
 * @param {number|string} appId
 * @param {boolean} isLegacy
 */
const getAppUrl = (appId: number | string, isLegacy: boolean = false): string =>
  `${isLegacy ? LEGACY_BUILDER_BASE_URL : BASE_APP_URL}/app/${appId}`;

/**
 * Given the app ID and the version provided
 * returns the base app url
 *
 * @param {number|string} appId
 * @param {string} version
 */
const getAppVersionUrl = (appId: number | string, version: string): string =>
  `${BASE_APP_URL}/app/${appId}/version/${version}`;

const getBaseAuthUrl = (
  appId: number | string,
  appVersionNumber: string
): string => `${getAppVersionUrl(appId, appVersionNumber)}/authentication`;

const getSettingsUrl = (appId: number | string): string =>
  `${getAppUrl(appId)}/settings`;

const getAuthUrlForFieldKey = (
  appId: number | string,
  appVersionNumber: string,
  fieldKey: string
): string => `${getBaseAuthUrl(appId, appVersionNumber)}/fields/${fieldKey}`;

const getAdvancedUrl = (appId: number | string, appVersionNumber: string) =>
  `${getAppVersionUrl(appId, appVersionNumber)}/advanced`;

const getServiceUrl = (
  type: ServiceType,
  serviceOptions: {
    id: string;
    version: SemVer;
    [serviceKey: string]: string;
    actionType?: ActionType;
  }
) => {
  const { id: appId, version, actionType, actionKey } = serviceOptions;
  const serviceKey = serviceOptions[createKeyFromService(type)];
  const baseUrl = `${getAppVersionUrl(appId, version)}`;

  return actionType
    ? `${baseUrl}/actions/${actionType}/${actionKey}`
    : `${baseUrl}/${type}/${serviceKey}`;
};

const getActionUrl = partial(getServiceUrl, SERVICE_TYPE.actions);
const getTriggerUrl = partial(getServiceUrl, SERVICE_TYPE.triggers);

const getTeamUrl = (appId: number | string) => `${getAppUrl(appId)}/team`;
const getEmbedUrl = (appId: number | string) => `${getAppUrl(appId)}/embed`;

/**
 * Given the versionUrl and the current url,
 * returns the part of the URL after the version number.
 *
 * It will strip `/app/:appId/version/:version` from the URL.
 *
 * This is currently being used to redirect the user to
 * a new cloned version but in the same route the user was
 * before cloning.
 *
 * @param {string} versionUrl
 * @param {string} currentUrl
 * @returns string
 */
const getCurrentBuildUrl = (versionUrl: string, currentUrl: string = '') =>
  currentUrl.replace(versionUrl, '');

// TODO: Use from url-utils once it's converted to TS
const deduplicateSlashes = (url: string) => url.replace(/\/{2,}/g, '/');

export {
  deduplicateSlashes,
  getActionUrl,
  getAdvancedUrl,
  getAppUrl,
  getAppVersionUrl,
  getAuthUrlForFieldKey,
  getBaseAuthUrl,
  getCurrentBuildUrl,
  getEmbedUrl,
  getServiceUrl,
  getSettingsUrl,
  getTeamUrl,
  getTriggerUrl,
};
