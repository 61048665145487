/** @jsx jsx */

import { ChangeEvent, FormEvent, Fragment, useState } from 'react';

import {
  Field,
  FormLabel,
  LabeledCheckbox,
  TextInput,
} from '@zapier/design-system';
import { jsx, css } from '@emotion/core';

import AsyncButton from 'app/developer-v3/components/AsyncButton';
import Link from 'app/common/components/Link';

import Description from '../Description';
import { DropdownAndTagsContainer } from './DropdownAndTagsContainer';
import { FormatItems } from './TriggerActionAndOthersDropdown';

const stylesForm = css`
  display: grid;
  gap: 24px;
  justify-items: flex-start;

  & > [class*='Field'] {
    width: 100% !important;
  }
`;

enum Reason {
  hasNewFeatures = 'hasNewFeatures',
  hasBugFixes = 'hasBugFixes',
  hasOtherChanges = 'hasOtherChanges',
}

type ChangeType = 'BUGFIX' | 'FEATURE_UPDATE';

export type AppMetadata = {
  app_change_type: ChangeType;
  action_key: string;
  action_type: 'read' | 'search' | 'write';
};

export type IssueMetadata = {
  app_change_type: ChangeType;
  issue_id: number;
};

export type VersionChanges = {
  app_metadata: AppMetadata[];
  issue_metadata: IssueMetadata[];
  is_bugfix: boolean;
  is_feature_update: boolean;
  is_other: boolean;
  changelog: string;
};

type Props = {
  isLoading: boolean;
  onSubmit: (
    version: string,
    changelog?: string,
    changes?: VersionChanges
  ) => void;
  version: string;
};

function Start({ isLoading, onSubmit, version }: Props) {
  const [reasons, setReasons] = useState({
    hasNewFeatures: false,
    hasBugFixes: false,
    hasOtherChanges: false,
  });
  const [bugfixMetadata, setBugfixMetadata] = useState<FormatItems[]>([]);
  const [featureUpdateMetadata, setFeatureUpdateMetadata] = useState<
    FormatItems[]
  >([]);
  const [description, setDescription] = useState('');

  const transformAppMetadataItems = (
    item: FormatItems,
    changeType: ChangeType
  ): AppMetadata => ({
    app_change_type: changeType,
    action_key: item.key,
    action_type: item.type,
  });

  const transformIssueMetadataItems = (
    item: FormatItems,
    changeType: ChangeType
  ): IssueMetadata => ({
    app_change_type: changeType,
    issue_id: item.issue,
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const appMetadata = [
      ...bugfixMetadata
        .filter(item => item.key && item.type)
        .map(item => transformAppMetadataItems(item, 'BUGFIX')),
      ...featureUpdateMetadata
        .filter(item => item.key && item.type)
        .map(item => transformAppMetadataItems(item, 'FEATURE_UPDATE')),
    ];

    const issueMetadata = [
      ...bugfixMetadata
        .filter(item => item.issue)
        .map(item => transformIssueMetadataItems(item, 'BUGFIX')),
      ...featureUpdateMetadata
        .filter(item => item.issue)
        .map(item => transformIssueMetadataItems(item, 'FEATURE_UPDATE')),
    ];

    const changes: VersionChanges = {
      app_metadata: appMetadata,
      issue_metadata: issueMetadata,
      is_bugfix: reasons.hasBugFixes,
      is_feature_update: reasons.hasNewFeatures,
      is_other: reasons.hasOtherChanges,
      changelog: description,
    };

    // Pass changelog as empty string because we are sending it on the changes object
    onSubmit(version, '', changes);
  };

  const handleSelection = (
    item: FormatItems,
    metadataType: 'bug' | 'feature'
  ) => {
    if (!item) return;

    if (metadataType === 'bug') {
      setBugfixMetadata([item, ...bugfixMetadata]);
    } else {
      setFeatureUpdateMetadata([item, ...featureUpdateMetadata]);
    }
  };

  const setReason = (reason: Reason, isChecked: boolean) => {
    setReasons(prevReasons => ({ ...prevReasons, [reason]: isChecked }));
  };

  // When the feature flag is enabled, we want to disable the promote button
  // if there are no reasons selected or if the description is empty.
  // (Old logic) When the feature flag is disabled, we want to disable
  // the promote button if there are no reasons selected
  const isPromoteButtonDisabled =
    !Object.values(reasons).some(value => value) || !description.trim();

  return (
    <Fragment>
      <Description>
        When you make a version public in your integration, it becomes the
        default for users creating Zaps. The earlier public version turns
        private but remains usable in the Zap editor. Zap templates using your
        integration will update to the new version unless there are significant
        changes. If there are, they'll stay on the current version.{' '}
        <Link
          href="https://platform.zapier.com/manage/versions-ui#promoting-a-version"
          target="_blank"
        >
          Learn more
        </Link>
        .
      </Description>
      <Description>
        Please provide the Zapier team and your end users with the reason for
        your integration updates.
      </Description>
      <form css={stylesForm} noValidate={true} onSubmit={handleSubmit}>
        <Field
          helpTextLineClamp={3}
          isRequired={true}
          label="What changed?"
          renderHelpText={() => (
            <p>
              Relating features or bugs to the promotion will queue them to be
              reviewed for closure by the Zapier Support team.{' '}
              <Link
                href="https://platform.zapier.com/manage/user-feedback#3-close-resolved-issues"
                target="_blank"
              >
                Learn more
              </Link>
              .
            </p>
          )}
          renderInput={() => (
            <Fragment>
              <FormLabel isSelected={reasons.hasNewFeatures}>
                <LabeledCheckbox
                  ariaLabel="Has new features"
                  checked={reasons.hasNewFeatures}
                  name="checkbox"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setReason(
                      Reason.hasNewFeatures,
                      event.currentTarget.checked
                    );
                  }}
                  tabIndex={0}
                  value={Reason.hasNewFeatures}
                >
                  New Features
                </LabeledCheckbox>
              </FormLabel>

              {reasons.hasNewFeatures && (
                <DropdownAndTagsContainer
                  label="Related trigger / action / feature request"
                  onRemoveTag={tag =>
                    setFeatureUpdateMetadata(
                      featureUpdateMetadata.filter(item => item.value !== tag)
                    )
                  }
                  onSelect={(item: FormatItems) =>
                    handleSelection(item, 'feature')
                  }
                  selectedItems={featureUpdateMetadata}
                />
              )}

              <FormLabel isSelected={reasons.hasBugFixes}>
                <LabeledCheckbox
                  ariaLabel="Has bug fixes"
                  checked={reasons.hasBugFixes}
                  name="checkbox"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setReason(Reason.hasBugFixes, event.currentTarget.checked);
                  }}
                  tabIndex={0}
                  value={Reason.hasBugFixes}
                >
                  Bug Fixes
                </LabeledCheckbox>
              </FormLabel>

              {reasons.hasBugFixes && (
                <DropdownAndTagsContainer
                  label="Related trigger / action / bug"
                  onRemoveTag={tag =>
                    setBugfixMetadata(
                      bugfixMetadata.filter(item => item.value !== tag)
                    )
                  }
                  onSelect={(item: FormatItems) => handleSelection(item, 'bug')}
                  selectedItems={bugfixMetadata}
                />
              )}

              <FormLabel isSelected={reasons.hasOtherChanges}>
                <LabeledCheckbox
                  ariaLabel="Has other changes"
                  checked={reasons.hasOtherChanges}
                  name="checkbox"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setReason(
                      Reason.hasOtherChanges,
                      event.currentTarget.checked
                    );
                  }}
                  tabIndex={0}
                  value={Reason.hasOtherChanges}
                >
                  Other
                </LabeledCheckbox>
              </FormLabel>
            </Fragment>
          )}
        />
        <Field
          helpTextLineClamp={2}
          isRequired={true}
          label="User-facing description"
          renderHelpText={() => (
            <p>
              The clearer your description, the more likely Zapier’s marketing
              team is to highlight your update! (max 255 characters)
            </p>
          )}
          renderInput={descriptionProps => (
            <TextInput
              ariaLabel="User-facing description"
              placeholder="How does this update help your users?"
              {...descriptionProps}
              isMultiline={true}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setDescription(event.currentTarget.value);
              }}
              rows={5}
              value={description}
            />
          )}
        />
        <AsyncButton
          ariaLabel={`Promote version ${version}`}
          color="primary"
          disabled={isPromoteButtonDisabled}
          isWorking={isLoading}
          type="submit"
        >
          Promote
        </AsyncButton>
      </form>
    </Fragment>
  );
}

export default Start;
