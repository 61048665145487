import ActionTypes from 'app/refresh/ActionTypes';

const actions = {
  updateResourceVersion(newResourceVersion) {
    return {
      type: ActionTypes.UPDATE_RESOURCE_VERSION,
      newResourceVersion,
    };
  },
};

export default actions;
