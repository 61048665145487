// @flow weak

import _ from 'lodash';

import { RouterSelectors } from '@zapier/toolbox-redux-framework';

import * as CliAppDefinition from 'app/entities/CliAppDefinition';
import {
  selectAppId,
  selectAppVersionEntity,
  selectVersionNumber,
} from 'app/developer-v3/store/shared/selectors';
import { getAppVersionUrl, getServiceUrl} from 'app/developer-v3/utils';

import {
  SERVICE_TYPE,
} from 'app/developer-v3/constants';

import type { ServiceType } from 'app/developer-v3/types/service';

const { searchOrCreates: SEARCH_OR_CREATES, actions: ACTIONS } = SERVICE_TYPE;

export const selectServiceUrl = (type: ServiceType, state) => {
  const id = selectAppId(state);
  const version = selectVersionNumber(state);
  const params = RouterSelectors.getParams(state);

  return getServiceUrl(type, { id, version, ...params });
};

export const selectServicesListUrl = (type: ServiceType, state) => {
  const appId = selectAppId(state);
  const versionNumber = selectVersionNumber(state);
  const { actionType } = RouterSelectors.getParams(state);
  const serviceRoute = actionType ? ACTIONS : type;

  return `${getAppVersionUrl(appId, versionNumber)}/${serviceRoute}`;
};

export const selectDefinitionOverride = state => {
  const appVersion = CliAppDefinition.selectors.all.findByAppIdAndVersion(
    selectAppId(state),
    selectVersionNumber(state),
    state
  );
  return CliAppDefinition.selectors.definitionOverride(appVersion);
};

export const selectAppDefinitionEntityId = state => {
  const appDefinition = CliAppDefinition.selectors.all.findByAppIdAndVersion(
    selectAppId(state),
    selectVersionNumber(state),
    state
  );
  return CliAppDefinition.selectors.id(appDefinition);
};

export const selectServiceType = state => {
  // Service type is assigned in routing.
  const { actionType = '', serviceType } = RouterSelectors.getParams(state);

  return serviceType || actionType;
};

export const selectServiceKey = state => {
  const { triggerKey, actionKey } = RouterSelectors.getParams(state);

  return triggerKey || actionKey;
};

const getCurrentService = (type, state) => {
  const serviceType = type || selectServiceType(state);
  const override = selectDefinitionOverride(state);
  const name = selectServiceKey(state);

  return _.get(override, [serviceType, name], {});
};

export const selectCurrentService = _.partial(getCurrentService, undefined);
selectCurrentService.searchOrCreates = _.partial(
  getCurrentService,
  SEARCH_OR_CREATES
);

export const selectServicesByType = (serviceType: ServiceType, state) => {
  return _.get(selectDefinitionOverride(state), serviceType, {});
};

export const selectIsAppVersionSaving = state => {
  const entity = selectAppVersionEntity(state);
  return CliAppDefinition.selectors.isSaving(entity);
};

export const selectIsAppVersionSaveFailed = state => {
  const entity = selectAppVersionEntity(state);
  return CliAppDefinition.selectors.isFailure(entity);
};
