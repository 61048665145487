import _ from 'lodash';
import { ROUTE_TO } from 'redux-router-kit';

import { isInIframe } from '@zapier/common-utils';

import FlowActionTypes from 'app/redux-boneyard/flow/ActionTypes';
import TrackActions from 'app/track/TrackActions';

const IS_IN_IFRAME = isInIframe();

function postToParent(data) {
  if (IS_IN_IFRAME) {
    window.parent.postMessage(data, '*');
  }
}

function postEventToParent(event) {
  postToParent({ event });
}

export default function trackMiddleware({ dispatch }) {
  const types = {
    // TODO: Replace this with calling
    // developer-v3/hooks/userArvoEvent/emitPageViewEvent in
    // common/components/Root which is the React tree crown.
    [ROUTE_TO]: action => {
      // The window.location is still the old route up to this point. We grab
      // the new route from "action" and overrite them in the page view event to
      // sent.
      // Ideally we track the page view when window.location changed just now
      // and window.location is the new route. This way the underlying tracking
      // packages can get default properties correctly. Unfortunately there is
      // no Redux Action available to track it exactly.
      const path = (action?.meta?.location ?? (window.location || {}))
        ?.pathname;
      const url = (action?.meta?.location ?? (window.location || {}))?.href;
      dispatch(TrackActions.trackPageView(path, url));
    },
    [FlowActionTypes.UNPAUSE_FLOW]: _.partial(postEventToParent, 'zap:unpause'),
    [FlowActionTypes.UNPAUSE_FLOW_DONE]: _.partial(
      postEventToParent,
      'zap:unpause:done'
    ),
    [FlowActionTypes.UNPAUSE_FLOW_FAIL]: _.partial(
      postEventToParent,
      'zap:unpause:fail'
    ),
    [FlowActionTypes.PAUSE_FLOW]: _.partial(postEventToParent, 'zap:pause'),
    [FlowActionTypes.PAUSE_FLOW_DONE]: _.partial(
      postEventToParent,
      'zap:pause:done'
    ),
    [FlowActionTypes.PAUSE_FLOW_FAIL]: _.partial(
      postEventToParent,
      'zap:pause:fail'
    ),
  };

  return (next: any) => (action: any) => {
    if (action && types[action.type]) {
      types[action.type](action);
    }

    return next(action);
  };
}
