import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import {
  Issue,
  formatResponseBody as formatIssueResponseBody,
} from './useIssue';

type Args = {
  actions: string[];
  integrationId: number;
  orderBy: 'created_at' | 'priority';
  orderDirection: 'ASC' | 'DESC';
  page: number;
  typeOf?: 'bug' | 'feature-request';
};

export type IssueItem = Omit<Issue, 'comments' | 'events' | 'stream'>;
type Data = {
  count: number;
  currentPageIndex: number;
  issues: IssueItem[];
  pages: number[];
  totalPages: number;
};

const QUERY_KEY_PREFIX = 'query-integration-issues';
const getQueryKey = (args: Args) =>
  `${QUERY_KEY_PREFIX}-${args.actions.join(',')}-${args.integrationId}-${
    args.orderBy
  }-${args.orderDirection}-${args.page}-${args.typeOf}`;

function formatResponseBody(body: any): Data {
  return {
    count: body.count,
    currentPageIndex: body.current_page_index,
    issues: body.results.map((issue: any) => formatIssueResponseBody(issue)),
    pages: body.pages,
    totalPages: body.total_pages,
  };
}

function useQueryIntegrationIssues(
  args: Args,
  options?: UseQueryOptions<Data, Error, Data>
) {
  const {
    actions,
    integrationId,
    orderBy,
    orderDirection,
    page,
    typeOf,
  } = args;
  const notify = useNotify();

  const queryKey = getQueryKey(args);
  const url = new URL(
    `api/platform/cli/apps/${encodeURIComponent(integrationId)}/issues`,
    ZAPIER_APP_BASE_URL
  );
  const params = new URLSearchParams({ page: page.toString() });

  if (actions.length) {
    params.append('action', actions.join(','));
  }
  if (orderBy) {
    params.append(
      'ordering',
      `${orderDirection === 'DESC' ? '-' : ''}${orderBy}`
    );
  }
  // https://gitlab.com/zapier/team-platform-services/loki/-/blob/355603032f7fc7a3c9582b37edcab0775e31703e/backend/loki/issues/filters.py#L136-152
  if (typeOf) {
    params.append('type', typeOf);
  }
  url.search = params.toString();

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryIntegrationIssues;
export { getQueryKey, QUERY_KEY_PREFIX };
