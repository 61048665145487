import React from 'react';

import { Header } from '@zapier/product-dev-platform';
import { getGravatarUrl } from '@zapier/common-utils';

import {
  useAvroEvent,
  useActiveProfile,
  useRouter,
} from 'app/developer-v3/hooks';

import { emitUserEventOptionClicked } from './utils';
import { optionSelectedMap } from './constants';

function PlatformHeader() {
  const {
    emitPartnerUserInteractionEvent,
    emitUserInteractionEvent,
  } = useAvroEvent();
  const { params, url } = useRouter();
  const {
    activeProfile,
    isLoading: isLoadingActiveProfile,
  } = useActiveProfile();
  const user =
    !isLoadingActiveProfile && activeProfile
      ? {
          avatarUrl: getGravatarUrl(activeProfile.emailHash),
          name:
            `${activeProfile.firstName ?? ''} ${
              activeProfile.lastName ?? ''
            }`.trim() || activeProfile.email,
        }
      : null;

  const emitEvent = params.id
    ? emitPartnerUserInteractionEvent
    : emitUserInteractionEvent;

  return (
    <Header
      currentUrl={url}
      isLoadingUser={isLoadingActiveProfile}
      onClickLogOut={() =>
        emitUserEventOptionClicked(emitEvent, 'Log out', 'Log Out', 'log out')
      }
      onClickNavLink={({ label }) => {
        emitEvent({
          eventAction: 'click' as const,
          interactionGoal: 'Navigate using the top menu',
          interactionName: 'menu_item_clicked',
          interactionRawText: label,
          optionSelected: optionSelectedMap[label],
        });
      }}
      onClickSettings={() =>
        emitUserEventOptionClicked(
          emitEvent,
          'Navigate to settings',
          'Settings',
          'settings'
        )
      }
      onClickZapier={() =>
        emitUserEventOptionClicked(
          emitEvent,
          'Navigate to zapier.com',
          'zapier.com',
          'zapier'
        )
      }
      onToggleUserDropdown={isOpen => {
        if (isOpen) {
          emitEvent({
            eventAction: 'open',
            interactionGoal: 'Open the profile menu',
            interactionName: 'profile_picture_clicked',
            interactionRawText: '',
            optionSelected: '',
          });
        }
      }}
      user={user}
    />
  );
}

export default PlatformHeader;
