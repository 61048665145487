// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StoreContext } from 'redux-react-hook';
import { enableBatching } from 'redux-batched-actions';
import { $each, $eachPair, select } from 'qim';
import { applyMiddleware, compose, createStore } from 'redux';
import { ExceptionTrackingProvider } from '@zapier/toolbox-error-tracking';
import { MegaphoneOverlay } from '@zapier/perf-utils';
import { trackException } from '@zapier/toolbox-redux-framework';
import { DesignSystemProvider, ZinniaFonts } from '@zapier/design-system';
import {
  TrackingCookies,
  TrackingProvider,
  createEventTracker,
} from '@zapier/toolbox-tracking';
import { SplitFactory } from '@splitsoftware/splitio-react';

import FullStory from 'app/common/components/FullStory';
import AccountAndProfileProvider from 'app/common/components/contexts/AccountAndProfileProvider';
import DateProvider from 'app/common/components/DateProvider';
import LinkProvider from 'app/common/components/LinkProvider';
import Root from 'app/common/components/Root';
import AppLink from 'app/common/components/Link/AppLink';
import StaticAssetProvider from 'app/common/components/StaticAssetProvider';
import createPerformanceLinterMiddleware from 'app/common/middlewares/performanceLinterMiddleware';
import createReducer from 'app/common/createReducer';
import getAllReducersAndMiddleware from 'app/common/getAllReducersAndMiddleware';
import { reset as resetPerformanceLinter } from 'app/perf-utils/PerformanceMonitoring';
import { trackTimeToMount } from 'app/perf-utils/PerfActions';
import { AvroEventProvider } from 'app/developer-v3/hooks';

import type { Plugin } from 'app/common/types';

/*
  Build an app, given some plugins. Each plugin can have these properties:

    actionTypes:
      an object mapping action type keys to action type strings
    reducers:
      an object with any number of reducers, with a unique key across all reducers
    run:
      a function that accepts a redux store object and then does whatever it needs to do to get going
    routes:
      some redux-router-kit routes
*/

type CreateAppType = {
  splitConfig: {},
  initialReduxState: {},
  plugins: {
    routes: [],
    all: Array<Plugin>,
  },
};

const createApp = ({
  initialReduxState,
  plugins,
  splitConfig,
}: CreateAppType) => {
  const routes = plugins.routes;
  const { reducers, rootReducer, ...middleware } = getAllReducersAndMiddleware(
    plugins
  );

  const {
    allMiddleware,
    authMiddleware,
    scrollToTopMiddleware,
    routerMiddleware,
  } = middleware;

  const createStoreWithMiddleware = compose(
    applyMiddleware(
      ...allMiddleware,
      createPerformanceLinterMiddleware(resetPerformanceLinter)
    ),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 50 })
      : f => f
  )(createStore);

  const store = createStoreWithMiddleware(rootReducer, initialReduxState);

  store.dispatch(
    trackTimeToMount({
      href: window.location.pathname,
      source: 'initial-page-load',
      time: performance.now(),
      timeToMountType: 'initial-page-load',
    })
  );

  const trackingFunctions = createEventTracker();

  const run = () => {
    plugins.all.forEach(plugin => {
      if (typeof plugin.run === 'function') {
        plugin.run({ history, store });
      }
    });

    const containerElement = document.getElementById('react-root-app');

    // Store any UTM tracking information in a cookie, just in case we need
    // to send it up later, or append it to a URL for conversion tracking.
    TrackingCookies.storeUtms();

    // Store any Click ID tracking information in a cookie, for the same reasons
    // as storing the UTMs above.
    TrackingCookies.storeAllClickIds();

    if (containerElement) {
      ReactDOM.render(
        <ExceptionTrackingProvider trackException={trackException}>
          <Provider store={store}>
            <SplitFactory config={splitConfig} updateOnSdkUpdate={true}>
              <React.StrictMode>
                <StoreContext.Provider value={store}>
                  <DesignSystemProvider LinkComponent={AppLink}>
                    <AvroEventProvider>
                      <LinkProvider>
                        <DateProvider>
                          <StaticAssetProvider>
                            <AccountAndProfileProvider>
                              <TrackingProvider
                                trackingFunctions={trackingFunctions}
                              >
                                <FullStory />
                                <ZinniaFonts />
                                <Root routes={routes} />
                                <MegaphoneOverlay />
                              </TrackingProvider>
                            </AccountAndProfileProvider>
                          </StaticAssetProvider>
                        </DateProvider>
                      </LinkProvider>
                    </AvroEventProvider>
                  </DesignSystemProvider>
                </StoreContext.Provider>
              </React.StrictMode>
            </SplitFactory>
          </Provider>
        </ExceptionTrackingProvider>,
        containerElement
      );
    }

    // Dynamic routing needs to kick off a re-render, and we need to update
    // any other middleware that might have a handle on our routes.
    routerMiddleware.onRoutesChanged(newRoutes => {
      // Load in new reducers.
      const reducerPairs = select(
        [$each, 'app', 'reducers', $eachPair],
        newRoutes
      );
      reducerPairs.forEach(([reducerKey, routeReducer]) => {
        reducers[reducerKey] = routeReducer;
      });
      const newReducer = enableBatching(createReducer(reducers));
      store.replaceReducer(newReducer);
      authMiddleware.setRoutes(newRoutes);
      scrollToTopMiddleware.setRoutes(newRoutes);

      if (containerElement) {
        ReactDOM.render(
          <ExceptionTrackingProvider trackException={trackException}>
            <Provider store={store}>
              <SplitFactory config={splitConfig} updateOnSdkUpdate={true}>
                <React.StrictMode>
                  <StoreContext.Provider value={store}>
                    <DesignSystemProvider LinkComponent={AppLink}>
                      <AvroEventProvider>
                        <LinkProvider>
                          <DateProvider>
                            <StaticAssetProvider>
                              <AccountAndProfileProvider>
                                <TrackingProvider
                                  trackingFunctions={trackingFunctions}
                                >
                                  <FullStory />
                                  <ZinniaFonts />
                                  <Root routes={newRoutes} />
                                  <MegaphoneOverlay />
                                </TrackingProvider>
                              </AccountAndProfileProvider>
                            </StaticAssetProvider>
                          </DateProvider>
                        </LinkProvider>
                      </AvroEventProvider>
                    </DesignSystemProvider>
                  </StoreContext.Provider>
                </React.StrictMode>
              </SplitFactory>
            </Provider>
          </ExceptionTrackingProvider>,
          containerElement
        );
      }
    });
  };

  return {
    history,
    store,
    run,
  };
};

export default createApp;
