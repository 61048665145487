// @flow weak

import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';
import createReactClass from 'create-react-class'; // eslint-disable-line no-restricted-syntax
import bemify, { toModifiers } from 'bemify-js';
import { PortalWithState } from 'react-portal';
import { isFunction, isString } from 'lodash';

import { createKeyHandler } from '@zapier/common-utils';

import ButtonGroup from 'app/common/components/ButtonGroup';
import Icon from 'app/common/components/Icon';
import KindPropType from 'app/common/KindPropType';
import kindQuery from 'app/common/components/kindQuery';
import 'app/common/components/Modal.scss';

const bem = bemify('react-portal-modal');

const InnerModal = createReactClass({
  displayName: 'InnerModal',
  mixins: [PureRenderMixin],

  propTypes: {
    contentClassName: PropTypes.string,
    hideCloseButton: PropTypes.bool,
    isScrollable: PropTypes.bool,
    closePortal: PropTypes.func,
    kind: KindPropType,
    className: PropTypes.string,
    children: PropTypes.any,
    style: PropTypes.object,
  },

  closeModal(e) {
    if (this.props.closePortal) {
      this.props.closePortal(e);
    }
  },

  childProps() {
    // Don't pass these props to HTML tags.
    if (this.props.children && !isString(this.props.children.type)) {
      return {
        closeModal: this.closeModal,
      };
    }
    return {};
  },

  render() {
    const children = React.cloneElement(
      React.Children.only(this.props.children),
      this.childProps()
    );

    const closeButton = this.props.hideCloseButton ? null : (
      <Icon
        className={bem('__close')}
        onClick={this.closeModal}
        type="delete"
      />
    );

    const className = bem(
      '__inner',
      toModifiers(this.props.kind),
      {
        '--scrollable': this.props.isScrollable,
      },
      this.props.className
    );

    // Prevent clicks on the content of the modal from closing it
    const onClick = e => e.stopPropagation();

    return (
      <div
        className={className}
        onClick={onClick}
        onKeyDown={createKeyHandler(['enter'], onClick)}
        role="button"
        style={this.props.style}
        tabIndex="0"
      >
        {closeButton}
        <div className={this.props.contentClassName}>{children}</div>
      </div>
    );
  },
});

export const ModalActions = props => (
  <ButtonGroup
    className={bem('__actions', props.className)}
    kindQueries={{
      '(max-width: 699px)': 'reversed',
      '(min-width: 700px)': `horizontal ${
        props.alignRight ? 'justify-end' : 'justify-center'
      }`,
    }}
  >
    {React.Children.map(props.children, child =>
      child
        ? {
            ...child,
            props: {
              ...child.props,
              className: `${child.props.className || ''} ${bem(
                '__actions-button'
              )}`,
            },
          }
        : null
    ).filter(Boolean)}
  </ButtonGroup>
);

// react-portal no longer supports declarative opening, so we have to use this
// component to immediately open the portal when `isOpened` is passed in.
const OpenPortal = createReactClass({
  togglePortal(shouldOpen) {
    const { props } = this;
    const toggle = shouldOpen ? props.openPortal : props.closePortal;
    requestAnimationFrame(() => {
      toggle({
        nativeEvent: {
          stopImmediatePropagation: () => {},
        },
      });
    });
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shouldOpen !== this.props.shouldOpen) {
      this.togglePortal(nextProps.shouldOpen);
    }
  },

  render() {
    return null;
  },
});

const Modal = createReactClass({
  displayName: 'Modal',
  mixins: [PureRenderMixin],

  propTypes: {
    isOpened: PropTypes.bool.isRequired,
    isSelfClosing: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
      .isRequired,
    contentClassName: PropTypes.string,
    onClose: PropTypes.func,
    closeOnOutsideClick: PropTypes.bool,
    closeOnEsc: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    isScrollable: PropTypes.bool,
    kind: KindPropType,
    className: PropTypes.string,
    style: PropTypes.object,
    zIndex: PropTypes.number,
  },

  getDefaultProps() {
    return {
      contentClassName: bem('__content'),
      className: 'react-portal',
      closeOnOutsideClick: true,
      hideCloseButton: false,
    };
  },

  render() {
    const { props } = this;
    const { zIndex } = props;
    return (
      <PortalWithState
        closeOnEsc={props.closeOnEsc}
        closeOnOutsideClick={props.closeOnOutsideClick}
        defaultOpen={props.isOpened}
        onClose={props.onClose}
      >
        {({ openPortal, closePortal, portal }) => {
          const onClose = props.isSelfClosing ? props.onClose : closePortal;
          return [
            <OpenPortal
              closePortal={onClose}
              key="open-portal"
              openPortal={openPortal}
              shouldOpen={props.isOpened}
            />,
            portal(
              <div
                className={bem()}
                key="modal-wrapper"
                onClick={props.closeOnOutsideClick ? onClose : undefined}
                role="presentation"
                style={{ zIndex }}
              >
                <InnerModal
                  className={props.className}
                  closePortal={onClose}
                  contentClassName={props.contentClassName}
                  hideCloseButton={props.hideCloseButton}
                  isScrollable={props.isScrollable}
                  kind={props.kind}
                  style={props.style}
                >
                  {isFunction(props.children)
                    ? props.children({ closeModal: onClose })
                    : props.children}
                </InnerModal>
              </div>
            ),
          ];
        }}
      </PortalWithState>
    );
  },
});

export default kindQuery(Modal);
