import { partial, mapKeys, camelCase } from 'lodash';

import {
  EntityActions,
  buildEntityActions,
  buildEntityApi,
  buildEntitySelectors,
} from '@zapier/toolbox-redux-framework';
import { stripTrailingSlash } from '@zapier/url-utils';
import { postJson, getJson } from '@zapier/toolbox-browser-fetch';

import {
  IApi,
  ISelectors,
  IActions,
  ConversionStage,
  WbConversionEntity,
} from './types';

export const type = 'wbConversion';
const API_BASE = '/api/platform/cli/apps';

export const api: IApi = buildEntityApi(
  type,
  API_BASE,
  (wbConversionApi: IApi) => {
    const endpoint = (appId: ID, action: 'start' | 'progress') =>
      stripTrailingSlash(`${API_BASE}/${appId}/wb-migration/${action}`);

    wbConversionApi.migrate = appId => postJson(endpoint(appId, 'start'), {});
    wbConversionApi.loadProgress = appId =>
      getJson(endpoint(appId, 'progress'));
  }
);

export const selectors: ISelectors = buildEntitySelectors(
  type,
  (sel: ISelectors) => {
    sel.cliAppId = partial(sel.dataItem, 'cli_app_id');
    sel.stage = partial(sel.dataItem, 'stage');
    sel.progress = entity =>
      sel.dataItem(['migration_progress', 'overall_progress'], entity) || 0;
    sel.currentStep = (entity: WbConversionEntity) => {
      const step = sel.dataItem(['migration_progress', 'current_step'], entity);
      return mapKeys(step, (_, key) => camelCase(key));
    };
    sel.estimatedPoints = partial(sel.dataItem, [
      'migration_progress',
      'current_step',
      'estimated_points',
    ]);
    sel.finishedPoints = partial(sel.dataItem, [
      'migration_progress',
      'current_step',
      'finished_points',
    ]);
    sel.timestamp = partial(sel.dataItem, 'timestamp_ms');
    sel.rankInQueue = partial(sel.dataItem, 'rank_in_queue');
    sel.steps = partial(sel.dataItem, ['migration_progress', 'steps']);
  }
);

export const actions: IActions = buildEntityActions(
  type,
  selectors,
  api,
  (wbConversionActions: IActions) => {
    wbConversionActions.startMigration = (appId: ID) => {
      return EntityActions.saveEntity(
        type,
        () => {},
        async () => {
          const result = await api.migrate(appId);
          return {
            ...result,
            stage: ConversionStage.migrationEstimating,
            // Ensure we have a number here so the entity framework doesn't get
            // confused and key the same entity by it's string vs. number ID.
            id: Number(appId),
          };
        },
        {},
        appId
      );
    };
    wbConversionActions.getMigrationProgress = (appId: ID) => {
      return EntityActions.loadEntity(
        type,
        async () => {
          const result = await api.loadProgress(appId);
          return { ...result, id: result.cli_app_id };
        },
        {},
        appId
      );
    };
    wbConversionActions.finishTesting = (appId: ID) => {
      return dispatch =>
        // There's no endpoint to finalize testing. So we just keep it in-memory
        dispatch(
          wbConversionActions.updateEntityDataItem(
            'stage',
            ConversionStage.tested,
            Number(appId)
          )
        );
    };
  }
);
