// @flow

import _ from 'lodash';
import React, { type ComponentType } from 'react';
import { connect } from 'react-redux';

import { Account, Profile } from '@zapier/shared-entities';

import DowngradedModalContainer from 'app/team/components/DowngradedModalContainer';
import { isProfileAdminOfAccount } from 'app/account/Selectors';

const modals = [
  {
    Modal: DowngradedModalContainer,
    shouldRender: state => {
      const currentAccount = Account.selectors.all.currentAccount(state);
      const currentProfile = Profile.selectors.all.currentProfile(state);
      const helpKey = Profile.selectors.all.currentAccountDowngradedTrackingKey(
        state
      );
      return (
        currentProfile &&
        Account.selectors.all.hasLoaded(state) &&
        Profile.selectors.all.hasLoaded(state) &&
        Account.selectors.wasTeam(currentAccount) &&
        !isProfileAdminOfAccount(currentProfile, currentAccount) &&
        !Profile.selectors.hasViewedHelp(helpKey, currentProfile)
      );
    },
  },
];

const findModal = (state): ?ComponentType<{}> => {
  const modalConfig = _.find(modals, config => config.shouldRender(state));
  return modalConfig ? modalConfig.Modal : null;
};

const mapStateToProps = state => ({
  Modal: findModal(state),
});

type GlobalModalHolderProps = {
  Modal: ?ComponentType<{}>,
};

const GlobalModalHolder = ({ Modal }: GlobalModalHolderProps) =>
  Modal && <Modal />;

export default connect(mapStateToProps)(GlobalModalHolder);
