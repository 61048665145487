import PropTypes from 'prop-types';
import createReactClass from 'create-react-class'; // eslint-disable-line no-restricted-syntax

const BrowserTabFocus = createReactClass({
  displayName: 'BrowserTabFocus',

  propTypes: {
    onChangeFocus: PropTypes.func.isRequired,
  },

  UNSAFE_componentWillMount() {
    this.bindTabFocus(true);
  },

  componentWillUnmount() {
    this.bindTabFocus(false);
  },

  onChangeFocus(e) {
    this.props.onChangeFocus(
      (e.type || '').toLowerCase() === 'focus',
      this.props
    );
  },

  bindTabFocus(shouldBind) {
    const method = shouldBind ? 'addEventListener' : 'removeEventListener';
    window[method]('focus', this.onChangeFocus);
    window[method]('blur', this.onChangeFocus);
  },

  render() {
    return null;
  },
});

export default BrowserTabFocus;
