import React, { Children, Fragment, ComponentType, cloneElement } from 'react';

import useAvroEvent from './useAvroEvent';

function Wrapper({ children }: { children: React.ReactElement }) {
  const avroEvent = useAvroEvent();

  return (
    <Fragment>
      {Children.map(children, child => cloneElement(child, avroEvent))}
    </Fragment>
  );
}

// Example usage:
// function MyDiv({ a }: { a: number } & ReturnType<typeof useAvroEvent>) {
//   return <div>{ a }</div>
// };
// const MyAvroDiv = withAvroEvent(MyDiv);
// TODO: This HOC is interim for class components that can’t use the
// `useAvroEvent` hook directlly. Should gets removed when all of its consumers
// are functional.
function withAvroEvent<T extends ReturnType<typeof useAvroEvent>>(
  Component: ComponentType<T>
) {
  return (props: T) => (
    <Wrapper>
      <Component {...props} />
    </Wrapper>
  );
}

export default withAvroEvent;
