import { createPrefixedActions } from '@zapier/toolbox-redux-framework';

export default createPrefixedActions(
  'developer-v3',
  `
    SET_CURRENT_APP_ID
    SET_CURRENT_APP_VERSION
    SET_MODIFIED_APP_DEFINITION
    SET_CLEAN_DEFINITION
    RESET_TEST_REQUEST
    RESET_TEST_LOGS
    SET_UNSAVED_CHANGES

    GET_APP_FORM
    GET_APP_FORM_DONE
    GET_APP_FORM_FAIL

    GET_REQUEST_TEST_INFO
    GET_REQUEST_TEST_INFO_DONE
    GET_REQUEST_TEST_INFO_FAIL

    EXECUTE_REQUEST
    EXECUTE_REQUEST_DONE
    EXECUTE_REQUEST_FAIL

    GET_TEST_LOGS
    GET_TEST_LOGS_DONE
    GET_TEST_LOGS_FAIL

    LIFECYCLE_REQUEST
    LIFECYCLE_REQUEST_DONE
    LIFECYCLE_REQUEST_FAIL
    LIFECYCLE_REQUEST_RESET

    UPDATE_NEW_ADMIN

    LOAD_TASKS
    LOAD_TASKS_DONE
    LOAD_TASKS_FAIL

    LOAD_ZAP_DETAILS
    LOAD_ZAP_DETAILS_DONE
    LOAD_ZAP_DETAILS_FAIL
    LOAD_ZAP_DETAILS_RESET
  `
);
