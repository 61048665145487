// @flow weak

import React from 'react';
import bemify, { toModifiers } from 'bemify-js';

import kindQuery from 'app/common/components/kindQuery';
import 'app/common/components/ButtonGroup.scss';

const ButtonGroup = props => {
  const bem = bemify('button-group');
  const className = bem(toModifiers(props.kind), props.className);
  return <div className={className}>{props.children}</div>;
};

export default kindQuery(ButtonGroup);
