import React, { PropsWithChildren, useReducer } from 'react';

import AvroEventContext from './AvroEventContext';

function Provider({ children }: PropsWithChildren<{}>) {
  const [correlationIds, updateCorrelationIds] = useReducer(
    (
      current: Record<string, string>,
      next: Partial<Record<string, string>>
    ) => ({ ...current, ...next }),
    {}
  );

  return (
    <AvroEventContext.Provider value={{ correlationIds, updateCorrelationIds }}>
      {children}
    </AvroEventContext.Provider>
  );
}

export default Provider;
