import { useQuery } from 'react-query';
import useNotify from './useNotify';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

const QUERY_KEY_PREFIX = 'montly-active-users-data';
const getQueryKey = integrationId => `${QUERY_KEY_PREFIX}-${integrationId}`;

export type MonthlyActiveUsers = {
  outgest_id: number;
  outgest_run_date_time: Date;
  app_name: string;
  app_integration_id: number;
  activity_month_end_date: string;
  users_active_monthly: number;
};

type Result = { integration_activity: MonthlyActiveUsers[] };

export default function useQueryInsightsMonthlyActiveUsers(
  integrationId: string
) {
  const notify = useNotify();
  const queryKey = getQueryKey(integrationId);

  const { data, error, ...rest } = useQuery(
    queryKey,
    (): Promise<Result> =>
      fetch(
        `${ZAPIER_APP_BASE_URL}/developer/insights/v1/${integrationId}/activation-monthly`,
        {
          credentials: 'include',
        }
      ).then(res => res.json())
  );

  if (error) {
    console.error(error);
    notify.failure('Failed to fetch insights activity monthly data.');
  }

  return {
    data: data?.integration_activity ?? [],
    error,
    ...rest,
  };
}
