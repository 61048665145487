export enum Status {
  open = 1,
  closed = 2,
  resolved = 3,
}

// TODO: hit Loki API /work-status-options to get these dynamically
export enum WorkStatus {
  notStarted = 1,
  planned = 2,
  inProgress = 3,
}

export enum ResolutionStatus {
  resolved = 1,
  wontDo = 2,
  duplicate = 3,
  cannotReproduce = 4,
}
