import { addMonths, endOfMonth } from 'date-fns';
import Prando from 'prando';

export const TimePeriodDays = {
  Last_Seven_days: 7,
  Last_Thirty_days: 30,
  Last_Ninety_days: 90,
} as const;

type ValuesOf<T> = T[keyof T];

export type TimePeriodDays = ValuesOf<typeof TimePeriodDays>;

export type ParamsDailyInsights = {
  appIntegrationId: number;
  timePeriodDays: TimePeriodDays;
};

type ParamsInsightsAppId = {
  appIntegrationId: number;
};

type ResultSummaryIntegrationActivity = {
  outgest_id: string;
  outgest_run_date_time: string; // ISOString
  app_integration_name: string;
  app_integration_id: number;
  new_user_last_7days: number;
  new_user_last_30days: number;
  new_user_last_90days: number;
  active_zaps_last_7days: number;
  active_zaps_last_30days: number;
  active_zaps_last_90days: number;
};

type ResultIntegrationActivityDaily = {
  outgest_id: number;
  outgest_run_timestamp_ms: string;
  app_name: string;
  app_integration_id: number;
  date: string; // ex.'2022-06-23',
  users_active_daily: number;
  zaps_active_daily: number;
};

type ResultIntegrationActivityMonthly = {
  outgest_id: number;
  outgest_run_timestamp_ms: string;
  month_end_date: string; // ex.'2021-11-30',
  app_integration_id: number;
  app_name: string;
  users_active_monthly: number;
};

type ResultIntegrationActivationRetention = {
  outgest_id: string;
  outgest_run_timestamp_ms: string;
  first_activation_month_end_date: string; // ex.'2021-11-30',
  app_integration_id: number;
  app_name: string;
  users_active: number;
  month1_user_retention?: number;
  month2_user_retention?: number;
  month3_user_retention?: number;
  month4_user_retention?: number;
  month5_user_retention?: number;
  month6_user_retention?: number;
  month7_user_retention?: number;
  month8_user_retention?: number;
  month9_user_retention?: number;
  month10_user_retention?: number;
  month11_user_retention?: number;
  month12_user_retention?: number;
  month1_retention_rate?: number;
  month2_retention_rate?: number;
  month3_retention_rate?: number;
  month4_retention_rate?: number;
  month5_retention_rate?: number;
  month6_retention_rate?: number;
  month7_retention_rate?: number;
  month8_retention_rate?: number;
  month9_retention_rate?: number;
  month10_retention_rate?: number;
  month11_retention_rate?: number;
  month12_retention_rate?: number;
};

type getNewUsersFunctionResult = { users: number };
type getNewUsersFunction = (
  params: ParamsDailyInsights
) => Promise<getNewUsersFunctionResult>;

type getDailyActiveUsersFunctionResult = {
  date: Date;
  usersActiveDaily: number;
};
type getDailyActiveUsersFunction = (
  params: ParamsDailyInsights
) => Promise<getDailyActiveUsersFunctionResult[]>;

type getMonthlyActiveUsersFunctionResult = {
  monthEndDate: Date;
  usersActiveMonthly: number;
};
type getMonthlyActiveUsersFunction = (
  params: ParamsInsightsAppId
) => Promise<getMonthlyActiveUsersFunctionResult[]>;

type getActiveZapsFunctionResult = { zapsActive: number };
type getActiveZapsFunction = (
  params: ParamsDailyInsights
) => Promise<getActiveZapsFunctionResult>;

type getDailyActiveZapsFunctionResult = {
  date: Date;
  zapsActiveDaily: number;
};
type getDailyActiveZapsFunction = (
  params: ParamsDailyInsights
) => Promise<getDailyActiveZapsFunctionResult[]>;

type getIntegrationActivationRetentionFunction = (
  params: ParamsInsightsAppId
) => Promise<ResultIntegrationActivationRetention[]>;

type useIntegrationInsightsReturns = {
  getNewUsers: getNewUsersFunction;
  getDailyActiveUsers: getDailyActiveUsersFunction;
  getMonthlyActiveUsers: getMonthlyActiveUsersFunction;
  getActiveZaps: getActiveZapsFunction;
  getDailyActiveZaps: getDailyActiveZapsFunction;
  getIntegrationActivationRetention: getIntegrationActivationRetentionFunction;
};

function useIntegrationInsights(): useIntegrationInsightsReturns {
  /**
   * Fetches summary insight data about an integration by id
   *
   * TODO: This is a temporary implementation of this function with dummy data for development purposes.
   * TPS-1984 will cover re-implementing this function with a real API call and real data.
   *
   * See these pages for details about the summary integration activity data
   * - https://coda.io/d/Partner-Sharing_dH4ks8hdPC0/Data-Outgest_su3IC#Table-33_tuS3C/r1
   * - https://docs.google.com/spreadsheets/d/1NeyRZcIaiHBCIzPClOf9NrVbZg00yv2fIwhcbjsPLJg/edit#gid=1714602712
   *
   * @param {number} appIntegrationId the id of the integration for which to fetch information
   * @returns Promise<ResultSummaryIntegrationActivity>
   */
  const summaryIntegrationActivity = async (
    appIntegrationId: number
  ): Promise<ResultSummaryIntegrationActivity> => {
    return {
      outgest_id: '1111',
      outgest_run_date_time: Date.now().toString(),
      app_integration_name: 'Mock Integration Name',
      app_integration_id: appIntegrationId,
      new_user_last_7days: 6,
      new_user_last_30days: 24,
      new_user_last_90days: 61,
      active_zaps_last_7days: 1824,
      active_zaps_last_30days: 3228,
      active_zaps_last_90days: 4452,
    };
  };

  /**
   * Fetches daily insight data about an integration by id
   *
   * TODO: This is a temporary implementation of this function with dummy data for development purposes.
   * TPS-1984 will cover re-implementing this function with a real API call and real data.
   *
   * See these pages for details about the integration activity daily data
   * - https://coda.io/d/Partner-Sharing_dH4ks8hdPC0/Data-Outgest_su3IC#Table-33_tuS3C/r2
   * - https://docs.google.com/spreadsheets/d/1B5nCu0uK0sbBi1rHKDeUlMAXyMxxWglNQBh0ab9EEJ0/edit#gid=1023122832
   *
   * @param {number} appIntegrationId the id of the integration for which to fetch information
   * @returns Promise<ResultSummaryIntegrationActivity>
   */
  const integrationActivityDaily = async (
    appIntegrationId: number
  ): Promise<ResultIntegrationActivityDaily[]> => {
    // You can change this string to change the numbers generated. We're using a
    // seeded random number generator so the data doesn't shift around during
    // re-renders.
    const rng = new Prando('just a seed value');

    // generate mock data for today and the previous 90 days
    const todayDate = new Date();
    const mockResults = [];
    const msInOneDay = 1000 * 60 * 60 * 24;
    for (let i = 0; i <= 90; i += 1) {
      const date = new Date(todayDate.getTime() - i * msInOneDay);
      const dateYYYYMMDD = date.toISOString().split('T')[0];
      mockResults.push({
        outgest_id: 1111,
        outgest_run_timestamp_ms: todayDate.getTime(),
        app_name: 'Mock Integration Name',
        app_integration_id: appIntegrationId,
        date: dateYYYYMMDD,
        users_active_daily: rng.nextInt(0, 99),
        zaps_active_daily: rng.nextInt(0, 99),
      });
    }
    return mockResults;
  };

  /**
   * Fetches monthly insight data about an integration by id
   *
   * TODO: This is a temporary implementation of this function with dummy data for development purposes.
   * TPS-1984 will cover re-implementing this function with a real API call and real data.
   *
   * See these pages for details about the integration activity monthly data
   * - https://coda.io/d/Partner-Sharing_dH4ks8hdPC0/Data-Outgest_su3IC#Table-33_tuS3C/r9
   * - https://docs.google.com/spreadsheets/d/17ZlKBm3OaUovVnlNL68ykduBEsmKfF4j-jGx4FLiw5U/edit#gid=720391403
   *
   * @param {number} appIntegrationId the id of the integration for which to fetch information
   * @returns Promise<ResultSummaryIntegrationActivity>
   */
  const integrationActivityMonthly = async (
    appIntegrationId: number
  ): Promise<ResultIntegrationActivityMonthly[]> => {
    // You can change this string to change the numbers generated. We're using a
    // seeded random number generator so the data doesn't shift around during
    // re-renders.
    const rng = new Prando('just a seed value');

    // generate mock data for the previous 36 months (including this month)
    const todayDate = new Date();
    const mockResults = [];
    for (let i = 0; i < 36; i += 1) {
      const endOfMonthDate = new Date(
        todayDate.getFullYear(),
        todayDate.getMonth() - i + 1,
        0
      );
      const dateYYYYMMDD = endOfMonthDate.toISOString().split('T')[0];
      mockResults.push({
        outgest_id: 1111,
        outgest_run_timestamp_ms: Date.now(),
        month_end_date: dateYYYYMMDD,
        app_integration_id: appIntegrationId,
        app_name: 'Mock Integration Name',
        users_active_monthly: rng.nextInt(0, 999),
      });
    }
    return mockResults;
  };

  /**
   * Fetches activation retention data about an integration by id
   *
   * TODO: This is a temporary implementation of this function with dummy data for development purposes.
   * TPS-2179 will cover re-implementing this function with a real API call and real data.
   *
   * See these pages for details about the integration activity daily data
   * - https://coda.io/d/Partner-Sharing_dH4ks8hdPC0/Data-Outgest_su3IC#Table-33_tuS3C/r2
   *
   * @param {number} appIntegrationId the id of the integration for which to fetch information
   * @returns Promise<ResultIntegrationActivationRetention>
   */
  const integrationActivationRetention = async (
    appIntegrationId: number
  ): Promise<ResultIntegrationActivationRetention[]> => {
    // You can change this string to change the numbers generated. We're using a
    // seeded random number generator so the data doesn't shift around during
    // re-renders.
    const rng = new Prando('just a seed value');

    // generate mock data for the previous 12 months
    const todayDate = new Date();
    const mockResults = [];
    for (let i = 1; i <= 12; i += 1) {
      const endOfMonthXLocal = endOfMonth(addMonths(todayDate, -i));
      const endOfMonthXUtc = new Date(
        Date.UTC(
          endOfMonthXLocal.getFullYear(),
          endOfMonthXLocal.getMonth(),
          endOfMonthXLocal.getDate(),
          endOfMonthXLocal.getHours(),
          endOfMonthXLocal.getMinutes(),
          endOfMonthXLocal.getSeconds()
        )
      );
      const mockActiveUsers = rng.nextInt(0, 9999);

      const mockResult = {
        outgest_id: 1111,
        outgest_run_timestamp_ms: todayDate.toISOString(),
        first_activation_month_end_date: endOfMonthXUtc.toISOString(),
        app_name: 'Mock Integration Name',
        app_integration_id: appIntegrationId,
        users_active: mockActiveUsers,
      };

      for (let month = 1; month <= i; month += 1) {
        const mockUserRetention = rng.nextInt(0, mockActiveUsers);
        mockResult[`month${month}_user_retention`] = mockUserRetention;
        mockResult[`month${month}_retention_rate`] =
          mockUserRetention / mockActiveUsers;
      }

      mockResults.push(mockResult);
    }
    return mockResults;
  };

  return {
    getNewUsers: ({
      appIntegrationId,
      timePeriodDays,
    }: ParamsDailyInsights) => {
      return summaryIntegrationActivity(appIntegrationId).then(results => {
        if (timePeriodDays === TimePeriodDays.Last_Ninety_days) {
          return {
            users: results.new_user_last_90days,
          };
        } else if (timePeriodDays === TimePeriodDays.Last_Thirty_days) {
          return {
            users: results.new_user_last_30days,
          };
        } else {
          return {
            users: results.new_user_last_7days,
          };
        }
      });
    },
    getDailyActiveUsers: ({
      appIntegrationId,
      timePeriodDays,
    }: ParamsDailyInsights) => {
      return integrationActivityDaily(appIntegrationId).then(results => {
        return results
          .map(dailyActivity => ({
            date: new Date(dailyActivity.date),
            usersActiveDaily: dailyActivity.users_active_daily,
          }))
          .sort((a, b) => b.date.getTime() - a.date.getTime())
          .splice(0, timePeriodDays + 1);
      });
    },
    getMonthlyActiveUsers: ({ appIntegrationId }: ParamsInsightsAppId) => {
      return integrationActivityMonthly(appIntegrationId).then(results => {
        return results
          .map(monthlyActivity => ({
            monthEndDate: new Date(monthlyActivity.month_end_date),
            usersActiveMonthly: monthlyActivity.users_active_monthly,
          }))
          .sort((a, b) => b.monthEndDate.getTime() - a.monthEndDate.getTime());
      });
    },
    getActiveZaps: ({
      appIntegrationId,
      timePeriodDays,
    }: ParamsDailyInsights) => {
      return summaryIntegrationActivity(appIntegrationId).then(results => {
        if (timePeriodDays === TimePeriodDays.Last_Ninety_days) {
          return {
            zapsActive: results.active_zaps_last_90days,
          };
        } else if (timePeriodDays === TimePeriodDays.Last_Thirty_days) {
          return {
            zapsActive: results.active_zaps_last_30days,
          };
        } else {
          return {
            zapsActive: results.active_zaps_last_7days,
          };
        }
      });
    },
    getDailyActiveZaps: ({
      appIntegrationId,
      timePeriodDays,
    }: ParamsDailyInsights) => {
      return integrationActivityDaily(appIntegrationId).then(results => {
        return results
          .map(dailyActivity => ({
            date: new Date(dailyActivity.date),
            zapsActiveDaily: dailyActivity.zaps_active_daily,
          }))
          .sort((a, b) => b.date.getTime() - a.date.getTime())
          .splice(0, timePeriodDays + 1);
      });
    },
    getIntegrationActivationRetention: ({
      appIntegrationId,
    }: ParamsInsightsAppId) => integrationActivationRetention(appIntegrationId),
  };
}

export default useIntegrationInsights;
