// @flow

import { frame } from 'app/perf-utils/rules/Constants';

import type {
  PerformanceLintRule,
  PerformanceMeasurementItem,
  PerformanceStats,
} from 'app/perf-utils/types';

const threshold = {
  failure: 20 * frame,
  warning: 10 * frame,
};

const rule: PerformanceLintRule = {
  id: 'slow-function',

  description: `
    Slow functions will degrade overall frontend performance and should be avoided whenever possible.
    Memoization and algorithmic changes are typical solutions to poor function performance.
    Greater than ${threshold.warning} ms is a warning.
    Greater than ${threshold.failure} ms is a failure.`,

  check(item: PerformanceMeasurementItem, stats: PerformanceStats) {
    if (item.phase !== 'other') {
      return 'success';
    }

    if (stats.max > threshold.failure) {
      return 'failure';
    }
    if (stats.max > threshold.warning) {
      return 'warning';
    }
    return 'success';
  },
};

export default rule;
