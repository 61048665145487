import _ from 'lodash';
import { routeTo } from 'redux-router-kit';
import { $apply, $none, $set, update } from 'qim';
import { addSeconds } from 'date-fns';

import { Profile } from '@zapier/shared-entities';
import { RouterSelectors } from '@zapier/toolbox-redux-framework';
import { deduplicateSlashes } from '@zapier/url-utils';
import { readCookie } from '@zapier/cookies';
import { snakeCaseKeysDeep } from '@zapier/common-utils';

import * as CliApp from 'app/entities/CliApp';
import * as CliAppDefinition from 'app/entities/CliAppDefinition';
import * as EnvironmentVariable from 'app/entities/EnvironmentVariable';
import * as LegacyApp from 'app/entities/LegacyApp';
import {
  currentRequestTemplate,
  currentTestRequest,
} from 'app/developer-v3/selectors';

import ActionTypes from 'app/developer-v3/ActionTypes';
import Data from 'app/developer-v3/Data';
import TrackActions from 'app/track/TrackActions';
import { cleanObjectOfEmptyValues } from 'app/common/CommonUtils';
import { notify } from 'app/developer-v3/store/shared/actions';
import {
  NEW_APP_DEFINITION,
  NEW_SERVICE_FIELD,
  SERVICE_TYPE,
  TRACKING_EVENTS,
} from 'app/developer-v3/constants';
import {
  getAppUrl,
  getAppVersionUrl,
  includeInputFieldsInOperation,
  mergeDefinitions,
  titleize,
} from 'app/developer-v3/utils';
import {
  findZapsOfImplementation,
  parseZapDetails,
} from 'app/developer-v3/utils/zaps';
import { BaseSelectors, IEntity } from 'app/entities/types';
import {
  cleanDefinition,
  currentAppDefinitionEntity,
  currentAppDefinitionEntityId,
  currentAppId,
  currentAppVersion,
  currentAuthFields,
  currentDefinitionOverride,
  currentLegacyTrigger,
  currentServiceInputFields,
  currentServiceUrl,
  currentTrigger,
  lifecycleErrorMessage,
  lifecycleHasFinished,
} from './selectors';

import type {
  ActionType,
  AppForm,
  AppFormType,
  AppService,
  ObjectType,
  OperationMethodType,
  PartialDefinition,
} from 'app/developer-v3/types';
import type {
  ServiceSettings,
  ServiceType,
} from 'app/developer-v3/types/service';
import type { Dispatch } from 'app/common/types';
import type { FieldSchema } from 'app/developer-v3/platformSchema/field';
import type { LegacyTriggerOperationSchema } from 'app/developer-v3/platformSchema/legacy';
import type { TestData } from 'app/developer-v3/components/RequestTester';
import type {
  TriggerOperationSchema,
  TriggerSchema,
} from 'app/developer-v3/platformSchema/trigger';
import type { SemVer } from 'app/developer-v3/types/version';
import { TaskData } from 'app/developer-v3/types/task';
import { ZapData } from 'app/entities/Zap/types';
import { getAppsPendingDeletion } from './utils/appsPendingDeletion';
import { RequestSchema } from './platformSchema/request';
import {
  mutateFieldsInAuthentication,
  mutateRequestTemplate,
  removeAuthFieldsInTestRequest,
} from './utils/request';
import { AuthenticationType } from './platformSchema/authentication';

type GetState = () => any;

const {
  searchOrCreates: SEARCH_OR_CREATES,
  actions: ACTIONS,
  triggers: TRIGGERS,
} = SERVICE_TYPE;

export const trackEvent = (message: string, data?: {}, wait?: number) =>
  TrackActions.track(message, snakeCaseKeysDeep(data), wait);

export const updateOverrideWithService = ({
  serviceType,
  service,
  serviceLegacy,
  state,
}: {
  serviceType: ServiceType;
  service: ServiceSettings;
  serviceLegacy?: ServiceSettings;
  state: any;
}) =>
  update(
    [
      [serviceType, service.key, $set(service)],
      ...(serviceLegacy
        ? [['legacy', serviceType, service.key, $set(serviceLegacy)]]
        : []),
    ],
    currentDefinitionOverride(state)
  );

const getServiceType = (type: SERVICE_TYPE | ServiceType, state): ServiceType =>
  type === SERVICE_TYPE.actions
    ? RouterSelectors.getParam('actionType', state)
    : type;

export const setCurrentAppId = (appId: string | number) => {
  return {
    type: ActionTypes.SET_CURRENT_APP_ID,
    appId,
  };
};

export const setCurrentAppVersion = appVersion => ({
  type: ActionTypes.SET_CURRENT_APP_VERSION,
  appVersion,
});

export const setCleanDefinition = (definition: {}) => ({
  type: ActionTypes.SET_CLEAN_DEFINITION,
  definition,
});

export const setCurrentAppDefinition = (appDefinition: {}) => ({
  type: ActionTypes.SET_CURRENT_APP_DEFINITION,
  appDefinition,
});

const reportFailedEntityAction = (
  dispatch: Dispatch,
  getState: GetState,
  entityId: string | number,
  selectors: BaseSelectors<IEntity>
) => {
  const state = getState();
  const fetchedEntity = selectors.all.entity(entityId, state);
  const isFailure = selectors.isFailure(fetchedEntity);

  if (isFailure) {
    const statusMessage = selectors.statusMessage(fetchedEntity);
    dispatch(notify.failure(statusMessage));
  }

  return isFailure;
};

export const loadAppWithLatestVersion = (
  appId: string,
  versionNumber?: string
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setCurrentAppId(appId));
    await dispatch(CliApp.actions.loadEntity(null, appId));
    const state = getState();
    const currentApp = CliApp.selectors.all.entity(appId, state);

    if (CliApp.selectors.isFailure(currentApp)) {
      return;
    }

    if (versionNumber) {
      dispatch(setCurrentAppVersion(versionNumber));
    } else {
      const latestVersion = CliApp.selectors.latestVersion(currentApp);
      dispatch(setCurrentAppVersion(latestVersion));
    }

    await dispatch(
      CliAppDefinition.actions.loadEntity(null, currentAppVersion(getState()))
    );

    dispatch(EnvironmentVariable.actions.resetAndLoadCollection());

    // save fresh version for use later
    const freshDefinition = currentDefinitionOverride(getState());
    await dispatch(setCleanDefinition(freshDefinition));
  };
};

export const loadEnvironmentForVersion = () => {
  // should this take a version?
  return async (dispatch: Dispatch) => {
    dispatch(EnvironmentVariable.actions.loadCollection());
  };
};

export const loadAppAndAllVersions = (appId: number) => {
  return (dispatch: Dispatch) =>
    Promise.all([
      dispatch(CliApp.actions.loadEntity(null, appId)),
      dispatch(CliAppDefinition.actions.loadCollection()),
    ]);
};

export const getAppFormFields = (formId: AppForm) => ({
  type: ActionTypes.GET_APP_FORM,
  promise: Data.getAppFormFields(formId),
  formId,
  resultKey: 'fields',
});

const startLifecycleRequest = () => ({ type: ActionTypes.LIFECYCLE_REQUEST });
const completeLifecycleRequest = () => ({
  type: ActionTypes.LIFECYCLE_REQUEST_DONE,
});
const failLifecycleRequest = (errorMessage: string = 'Unknown Error') => ({
  type: ActionTypes.LIFECYCLE_REQUEST_FAIL,
  errorMessage,
});

const pullErrorMessage = (
  responseText: string,
  errorMessagePath: Array<string> = ['errors', '0']
) => {
  const errorResponse = JSON.parse(responseText);
  return _.get(errorResponse, errorMessagePath);
};

export const resetLifecycle = () => async (dispatch: Dispatch) =>
  dispatch({ type: ActionTypes.LIFECYCLE_REQUEST_RESET });

export const updateAppDefinition = (definition: PartialDefinition) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    // First attempt to update.
    await dispatch(CliAppDefinition.actions.updateAppDefinition(definition));

    let isFailure = CliAppDefinition.selectors.isFailure(
      currentAppDefinitionEntity(getState())
    );

    if (isFailure) {
      const [statusMessage] = CliAppDefinition.selectors.statusMessages(
        currentAppDefinitionEntity(getState())
      );
      if (_.isPlainObject(statusMessage) && statusMessage.code === 409) {
        // Stumbled upon a conflict, try to merge.
        const newServerDefinition = statusMessage.definition;
        const { mergedDefinition, success, paths } = mergeDefinitions(
          definition,
          cleanDefinition(getState()),
          newServerDefinition.definition_override
        );

        if (success) {
          dispatch(trackEvent(TRACKING_EVENTS.SUCCESSFUL_MERGE));

          // Second attempt to update.
          await dispatch(
            CliAppDefinition.actions.updateAppDefinition(
              mergedDefinition,
              newServerDefinition.etag
            )
          );
          await dispatch(
            setCleanDefinition(currentDefinitionOverride(getState()))
          );

          isFailure = CliAppDefinition.selectors.isFailure(
            currentAppDefinitionEntity(getState())
          );
        } else {
          dispatch(trackEvent(TRACKING_EVENTS.FAILED_MERGE, paths));
          dispatch(
            notify.failure(
              'Sorry! There was a conflict updating this integration.'
            )
          );
        }
      } else {
        dispatch(
          notify.failure(
            CliAppDefinition.selectors.statusMessage(
              currentAppDefinitionEntity(getState())
            )
          )
        );
      }
    }

    return !isFailure;
  };
};

// TODO: Make this a definite type after upgrading TS
const saveAppImage = (entityId: number, imageFile: any) => {
  return async (dispatch: Dispatch) => {
    try {
      return await Data.saveAppImage(entityId, imageFile);
    } catch (err) {
      return dispatch(
        notify.failure(
          'Please upload a valid image. The uploaded image was either in the incorrect format or corrupted.'
        )
      );
    }
  };
};

export const createNewAppVersion = (appId: number, versionNumber: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    // createEntity below uses the "current" state, so we need to set before, and revert after
    const state = getState();
    const currentAppIdToRevertTo = currentAppId(state);
    const currentAppVersionNumberToRevertTo = currentAppVersion(state);
    await dispatch(setCurrentAppId(appId));
    await dispatch(setCurrentAppVersion(versionNumber));

    const appDefEntityId = 'new-cli-app-definition';
    await dispatch(
      CliAppDefinition.actions.createCollectionUiEntity({
        id: appDefEntityId,
        definition_override: NEW_APP_DEFINITION as any,
        is_ui: true,
      })
    );
    await dispatch(CliAppDefinition.actions.createEntity(null, appDefEntityId));

    await dispatch(setCurrentAppId(currentAppIdToRevertTo));
    await dispatch(setCurrentAppVersion(currentAppVersionNumberToRevertTo));
  };
};

export const createNewApp = (newApp: AppFormType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    // Create CliApp entity
    const entityId = 'new-cli-app';
    await dispatch(
      CliApp.actions.createCollectionUiEntity({
        id: entityId,
        version: NEW_APP_DEFINITION.version,
        is_ui: true,
        ...newApp,
      })
    );
    // 'create' is the form id in `developer_cli.api.resources.AppBaseResource.post`
    const response = await dispatch(
      CliApp.actions.createEntityWithParams(
        { formId: 'create' },
        null,
        entityId
      )
    );

    const shouldBail = reportFailedEntityAction(
      dispatch,
      getState,
      entityId,
      CliApp.selectors
    );
    if (shouldBail) {
      return;
    }

    // TODO: This is ugly and needs to change.
    const { entity } = response as any;

    if (entity) {
      const versionNumber = '1.0.0';
      dispatch(setCurrentAppId(entity.id));
      dispatch(setCurrentAppVersion(versionNumber));

      await dispatch(createNewAppVersion(entity.id, versionNumber));

      const baseUrl = getAppVersionUrl(entity.id, versionNumber);

      dispatch(
        notify.success(`Alright! ${newApp.title} created successfully!`)
      );

      const routeOptions = { exit: false };

      if (newApp.imageFile) {
        const { image } = (await dispatch(
          saveAppImage(entity.id, newApp.imageFile)
        )) as any;
        if (image) {
          routeOptions.exit = true; // We need exit to get new custom CSS with images from backend (private-logos.css)
        }
      }

      await dispatch(routeTo(`${baseUrl}`, routeOptions));
      return;
    }

    return;
  };
};

export const updateApp = (appId: number, updatedApp: AppFormType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    if (updatedApp.imageFile) {
      // TODO: This shouldn't be. We shouldn't depend on the repsonse of thunk actions.
      const { image } = (await dispatch(
        saveAppImage(appId, updatedApp.imageFile)
      )) as any;
      if (!image) {
        return;
      }
      dispatch(
        notify.success(
          'Image uploaded successfully! Refresh your browser to see the changes.'
        )
      );
    }

    await dispatch(CliApp.actions.updateEntityData(updatedApp, appId));
    // 'meta' is the form id in `developer_cli.api.resources.AppResource.put`
    // 'isV3' is to distinguish between the old and new UI (error formatting is different)
    const response = await dispatch(
      CliApp.actions.saveEntityWithParams(
        { formId: 'meta', isV3: true },
        null,
        appId
      )
    );

    const shouldBail = reportFailedEntityAction(
      dispatch,
      getState,
      appId,
      CliApp.selectors
    );
    if (shouldBail) {
      return;
    }

    // TODO: Shouldn't work this way
    const { entity } = response as any;

    if (entity) {
      dispatch(
        notify.success(`Alright! ${updatedApp.title} updated successfully!`)
      );
    }

    return;
  };
};

export const saveServiceUpdates = (
  type: SERVICE_TYPE,
  serviceUpdate: ServiceSettings,
  serviceUpdateLegacy?: ServiceSettings
) => async (dispatch: Dispatch, getState: GetState) => {
  const isSuccess = await dispatch(
    updateAppDefinition(
      updateOverrideWithService({
        serviceType: getServiceType(type, getState()),
        service: serviceUpdate,
        serviceLegacy: serviceUpdateLegacy,
        state: getState(),
      })
    )
  );

  return isSuccess;
};
export const saveAction = (
  serviceUpdate: ServiceSettings,
  serviceUpdateLegacy?: ServiceSettings
) => async (dispatch: Dispatch) => {
  const isSuccess = await dispatch(
    saveServiceUpdates(ACTIONS, serviceUpdate, serviceUpdateLegacy)
  );

  if (isSuccess) {
    dispatch(notify.success('Action has been updated!'));
  }
};
export const saveSearchOrCreate = _.partial(
  saveServiceUpdates,
  SEARCH_OR_CREATES
);

export const saveInputField = (
  serviceRoute: SERVICE_TYPE,
  serviceUpdate: AppService,
  shouldRedirect: boolean = true
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const serviceType = getServiceType(serviceRoute, state);
    const oldInputFields = currentServiceInputFields(serviceType, state);
    const parsedServiceUpdate = includeInputFieldsInOperation(
      serviceUpdate,
      oldInputFields,
      serviceType
    );

    const isSuccess = await dispatch(
      saveServiceUpdates(serviceType, parsedServiceUpdate)
    );

    if (isSuccess) {
      dispatch(
        notify.success(
          RouterSelectors.getParam('inputKey', state) === NEW_SERVICE_FIELD
            ? `Congratulations! You created a new input field for ${
                parsedServiceUpdate.display.label
              } ${titleize(serviceRoute)}`
            : `All right! You successfully updated ${
                parsedServiceUpdate.display.label
              } ${titleize(serviceRoute)}`
        )
      );
    }

    return shouldRedirect
      ? dispatch(routeTo(`${currentServiceUrl(serviceType, state)}/input`))
      : undefined;
  };
};

export const saveTriggerInputField = _.partial(saveInputField, TRIGGERS);
export const saveActionInputField = _.partial(saveInputField, ACTIONS);

const deleteService = (serviceType: SERVICE_TYPE, serviceKey: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const state = getState();
  const definition = currentDefinitionOverride(state);
  const service = definition[serviceType];

  const newService = update([serviceKey, $none], service);
  let newDefinition = update([serviceType, $set(newService)], definition);

  const serviceLegacy = definition?.legacy?.[serviceType];
  if (serviceLegacy) {
    const newServiceLegacy = update([serviceKey, $none], serviceLegacy);
    newDefinition = update(
      ['legacy', serviceType, $set(newServiceLegacy)],
      newDefinition
    );
  }

  const isSuccess = await dispatch(updateAppDefinition(newDefinition));
  if (isSuccess) {
    dispatch(
      notify.success(
        `${titleize(serviceType)}: ${serviceKey} has been deleted!`
      )
    );
  }
};

export const deleteAction = (
  actionType: ActionType,
  actionKey: string
) => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const definition = currentDefinitionOverride(state);
  const definitionUpdates = [];

  if (actionType === SERVICE_TYPE.creates) {
    _.forEach(definition[SERVICE_TYPE.searchOrCreates], searchOrCreate => {
      // @ts-ignore todo: handle action types
      if (searchOrCreate.create === actionKey) {
        definitionUpdates.push([
          SERVICE_TYPE.searchOrCreates,
          // @ts-ignore todo: handle action types
          searchOrCreate.key,
          $none,
        ]);
      }
    });
  } else {
    const searchOrCreate = _.get(definition, [
      SERVICE_TYPE.searchOrCreates,
      actionKey,
    ]);
    if (searchOrCreate) {
      definitionUpdates.push([
        SERVICE_TYPE.searchOrCreates,
        searchOrCreate.key,
        $none,
      ]);
    }
  }

  if (!_.isEmpty(definitionUpdates)) {
    const newDefinition = update(definitionUpdates, definition);
    const isSuccess = await dispatch(updateAppDefinition(newDefinition));
    if (isSuccess) {
      dispatch(notify.success(`Paired action: ${actionKey} has been deleted!`));
    }
  }

  await dispatch(deleteService(actionType, actionKey));
};
export const deleteTrigger = _.partial(deleteService, TRIGGERS);
export const deleteSearchOrCreate = _.partial(deleteService, SEARCH_OR_CREATES);

export const saveCurrentAppVersion = (
  message: string = 'Changes have been saved!'
) => (dispatch: Dispatch, getState: GetState) => {
  const entityId = currentAppDefinitionEntityId(getState());

  dispatch(
    CliAppDefinition.actions.saveEntity(
      {
        notificationDuration: Infinity,
        shouldNotify: true,
        success: message,
      },
      entityId
    )
  );
};

export const saveTriggerOperation = (
  operation: TriggerOperationSchema,
  operationLegacy?: LegacyTriggerOperationSchema
) => {
  function updateOperation(savedOperation) {
    const updated = { ...savedOperation, ...operation };

    return _.omit(updated, [
      updated.type === 'polling' ||
      (_.isEmpty(updated?.performSubscribe?.url) &&
        _.isEmpty(updated?.performSubscribe?.source))
        ? 'performSubscribe'
        : '',
      updated.type === 'polling' ||
      (_.isEmpty(updated?.performUnsubscribe?.url) &&
        _.isEmpty(updated?.performUnsubscribe?.source))
        ? 'performUnsubscribe'
        : '',
      updated.type === 'polling' ||
      (_.isEmpty(updated?.performList?.url) &&
        _.isEmpty(updated?.performList?.source))
        ? 'performList'
        : '',
    ]);
  }

  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const trigger = currentTrigger(state);
    const updatedTrigger: TriggerSchema = {
      ...trigger,
      operation: updateOperation(trigger.operation),
    };
    const updatedTriggerLegacy = operationLegacy
      ? {
          ...currentLegacyTrigger(state),
          operation: {
            ...currentLegacyTrigger(state).operation,
            ...operationLegacy,
          },
        }
      : undefined;

    const definition = updateOverrideWithService({
      serviceType: SERVICE_TYPE.triggers,
      service: updatedTrigger,
      serviceLegacy: updatedTriggerLegacy,
      state,
    });
    const isSuccess = await dispatch(updateAppDefinition(definition));

    if (isSuccess) {
      dispatch(notify.success('Trigger has been updated!'));
    }
  };
};

export const upsertAuthField = (field: FieldSchema, key?: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const fieldClean = cleanObjectOfEmptyValues(field);
    const state = getState();
    const id = currentAppId(state);
    const version = currentAppVersion(state);
    const definitionOverride = currentDefinitionOverride(state);
    const { authentication, requestTemplate } = definitionOverride;
    const fields = _.get(authentication, ['fields'], []);
    const newFields = [
      ...(key ? _.reject(fields, ['key', key]) : fields),
      fieldClean,
    ];

    const newAuthentication = mutateFieldsInAuthentication(
      authentication,
      newFields
    );
    const newRequestTemplate = mutateRequestTemplate(
      requestTemplate,
      authentication.type,
      fields,
      newFields
    );

    const newDefinition = {
      ...definitionOverride,
      authentication: newAuthentication,
      requestTemplate: newRequestTemplate,
    };
    const isSuccess = await dispatch(updateAppDefinition(newDefinition));

    if (isSuccess) {
      dispatch(
        notify.success(
          `Successfully ${key ? 'updated' : 'created'} field: ${
            fieldClean.label || fieldClean.key
          }!`
        )
      );
      const appVersionUrl = getAppVersionUrl(id, version);
      dispatch(routeTo(`${appVersionUrl}/authentication/edit`));
    }
  };
};

export const deleteAuthField = (key: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const definitionOverride = currentDefinitionOverride(state);
    const { authentication, requestTemplate } = definitionOverride;
    const fields = currentAuthFields(state);
    const newFields = fields.filter(field => field.key !== key);

    const newAuthentication = mutateFieldsInAuthentication(
      authentication,
      newFields
    );
    const newRequestTemplate = mutateRequestTemplate(
      requestTemplate,
      authentication.type,
      fields,
      newFields
    );

    // In case user deletes an auth field that was set before the request template was created
    // we should try to remove it from the auth test
    const newAuthTest = removeAuthFieldsInTestRequest(
      newAuthentication?.test,
      newRequestTemplate,
      fields,
      newFields
    );

    if (Object.keys(newAuthTest).length > 0) {
      newAuthentication.test = newAuthTest;
    }

    const newDefinition = {
      ...definitionOverride,
      authentication: newAuthentication,
      requestTemplate: newRequestTemplate,
    };
    const isSuccess = await dispatch(updateAppDefinition(newDefinition));

    if (isSuccess) {
      dispatch(notify.success(`Deleted authentication field: ${key}`));
    }
  };
};

export const saveAuthenticationSchemeType = (type: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    if (type === 'none') {
      return;
    }

    const state = getState();
    const appId = currentAppId(state);
    const appVersion = currentAppVersion(state);
    const appDefinition = currentDefinitionOverride(state);

    const requestTemplate = currentRequestTemplate(state);
    const newRequestTemplate = mutateRequestTemplate(
      requestTemplate,
      type as AuthenticationType,
      [],
      []
    );

    const definition = {
      ...appDefinition,
      authentication: {
        type,
        test: {},
      },
      requestTemplate: newRequestTemplate,
    };
    const isSuccess = await dispatch(updateAppDefinition(definition));
    if (isSuccess) {
      dispatch(
        trackEvent(TRACKING_EVENTS.AUTH_CONFIG_STARTED, {
          auth_type: type,
          app_id: appId,
          app_version: appVersion,
          app_version_id: currentAppDefinitionEntityId(state),
          customuser_id: Profile.selectors.all.currentProfileId(state),
          is_staff: Profile.selectors.isStaff(
            Profile.selectors.all.currentProfile(state)
          ),
        })
      );

      const appVersionUrl = getAppVersionUrl(appId, appVersion);
      dispatch(routeTo(`${appVersionUrl}/authentication/edit`));
    }
  };
};

export const deleteAuthentication = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const appId = currentAppId(state);
    const version = currentAppVersion(state);
    const appDefinition = currentDefinitionOverride(state);
    const isLegacyAuth = !_.isEmpty(appDefinition.legacy?.authentication);

    // Remove fields from RequestTemplate
    const requestTemplate = currentRequestTemplate(state);
    const authFields = currentAuthFields(state);

    const newRequestTemplate = mutateRequestTemplate(
      requestTemplate,
      appDefinition.authentication.type,
      authFields,
      [],
      true
    );

    // Remove fields from Authentication
    const definition = update(
      isLegacyAuth
        ? [
            ['authentication', $none],
            [
              'legacy',
              $apply(legacy => {
                const { authentication, ...rest } = legacy;
                return Object.keys(rest).length === 0 ? $none : rest;
              }),
            ],
            ['beforeRequest', $set([])],
            ['afterResponse', $none],
          ]
        : ['authentication', $none],
      appDefinition
    );
    const isSuccess = await dispatch(
      updateAppDefinition({
        ...definition,
        ...(Object.keys(newRequestTemplate).length > 0
          ? { requestTemplate: newRequestTemplate }
          : {}),
      })
    );

    if (isSuccess) {
      dispatch(notify.success('Authentication deleted'));
    }

    const appVersionUrl = getAppVersionUrl(appId, version);
    dispatch(routeTo(`${appVersionUrl}/authentication`));
  };
};

export const deleteConnectionLabelCode = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const appDefinition = currentDefinitionOverride(state);
    const definition = _.omit(appDefinition, 'authentication[connectionLabel]');
    dispatch(updateAppDefinition(definition));
  };
};

export const deleteAuthenticationTestCode = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const appDefinition = currentDefinitionOverride(state);
    const definition = _.omit(appDefinition, 'authentication[test]');
    dispatch(updateAppDefinition(definition));
  };
};

export const createOrUpdateEnvironmentVariable = (
  key: string,
  value: string
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const envVarEntity = EnvironmentVariable.selectors.all.entityForKeyAndVersion(
      currentAppId(state),
      currentAppVersion(state),
      key,
      state
    );

    if (!envVarEntity) {
      const newEnvVarEntityId = key;
      await dispatch(
        EnvironmentVariable.actions.createCollectionUiEntity({
          id: newEnvVarEntityId,
          key,
          value,
        })
      );
      await dispatch(
        EnvironmentVariable.actions.createEntity(null, newEnvVarEntityId)
      );
    } else {
      const envEntityId = EnvironmentVariable.selectors.id(envVarEntity);
      if (value !== EnvironmentVariable.selectors.value(envVarEntity)) {
        await dispatch(
          EnvironmentVariable.actions.updateValue(envEntityId, value)
        );

        const shouldBail = reportFailedEntityAction(
          dispatch,
          getState,
          envEntityId,
          EnvironmentVariable.selectors
        );
        if (shouldBail) {
          return;
        }
      }
    }
  };
};

// TODO: Need to convert schema type to TS for this
export const saveAuthenticationSettings = (authSettings: any) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const appDefinition = currentDefinitionOverride(state);
    const { envVariables, ...authConfig } = authSettings;

    for (const envKey of Object.keys(envVariables)) {
      await dispatch(
        createOrUpdateEnvironmentVariable(envKey, envVariables[envKey])
      );
    }

    const isLegacyAuth = !_.isEmpty(appDefinition.legacy?.authentication);
    const isOAuth2 = appDefinition.authentication.type === 'oauth2';
    const {
      connectionLabel,
      oauth2Config = {},
      test,
      testTriggerKeyLegacy,
    } = authConfig;

    const {
      authorizeUrl,
      autoRefresh,
      accessTokenUrl,
      refreshTokenUrl,
      scope,
    } = oauth2Config;

    let appDefinitionNew;

    if (isLegacyAuth) {
      appDefinitionNew = update(
        [
          ['authentication', 'test', $set(test)],
          ['authentication', 'connectionLabel', $set(connectionLabel)],
          [
            'legacy',
            'authentication',
            'testTrigger',
            testTriggerKeyLegacy === '' ? $none : $set(testTriggerKeyLegacy),
          ],
        ],
        appDefinition
      );

      if (isOAuth2) {
        appDefinitionNew = update(
          [
            [
              'authentication',
              'oauth2Config',
              'autoRefresh',
              autoRefresh === undefined ? $none : $set(autoRefresh),
            ],
            [
              'authentication',
              'oauth2Config',
              'scope',
              scope === undefined ? $none : $set(scope),
            ],
            [
              'legacy',
              'authentication',
              'oauth2Config',
              'authorizeUrl',
              authorizeUrl === undefined ? $none : $set(authorizeUrl),
            ],
            [
              'legacy',
              'authentication',
              'oauth2Config',
              'accessTokenUrl',
              accessTokenUrl === undefined ? $none : $set(accessTokenUrl),
            ],
            [
              'legacy',
              'authentication',
              'oauth2Config',
              'refreshTokenUrl',
              refreshTokenUrl === undefined ? $none : $set(refreshTokenUrl),
            ],
          ],
          appDefinitionNew
        );
      }
    } else {
      appDefinitionNew = {
        ...appDefinition,
        authentication: {
          ...appDefinition.authentication,
          ...authConfig,
        },
      };
    }

    const isSuccess = await dispatch(updateAppDefinition(appDefinitionNew));
    if (isSuccess) {
      dispatch(notify.success('Authentication settings updated successfully!'));
    }
  };
};

export const saveRequestTemplate = (requestTemplate: RequestSchema) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const appDefinition = currentDefinitionOverride(state);
    const newDefinition = { ...appDefinition, requestTemplate };
    const isSuccess = await dispatch(updateAppDefinition(newDefinition));
    if (isSuccess) {
      dispatch(
        notify.success('Request Template settings updated successfully!')
      );
    }
  };
};

export const deleteApp = (appId: number, isLegacy: boolean = false) => {
  return async (dispatch: Dispatch) => {
    const deleteEntity = (isLegacy ? LegacyApp : CliApp).actions.deleteEntity;
    const response = await dispatch(deleteEntity(null, appId));
    if (response && response.status === 'success') {
      const appsPendingDeletion = getAppsPendingDeletion();
      if (appsPendingDeletion[appId]) {
        dispatch(notify.success('App is scheduled to be deleted!'));
      } else {
        dispatch(notify.success('App deleted successfully!'));
      }
    } else {
      // Unfortunately we can't know much more because response above is undefined
      dispatch(notify.failure('Unable to delete app!'));
    }
  };
};

export const deleteAppVersion = (appId: number, versionNumber: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    // deleteEntity below uses the "current" state, so we need to set before, and revert after
    const state = getState();
    const currentAppIdToRevertTo = currentAppId(state);
    const currentAppVersionNumberToRevertTo = currentAppVersion(state);
    await dispatch(setCurrentAppId(appId));
    await dispatch(setCurrentAppVersion(versionNumber));

    // TODO: This shouldn't be the way we do this
    const response = await dispatch(
      CliAppDefinition.actions.deleteEntity(null, versionNumber)
    );

    if (response && response.status === 'success') {
      dispatch(notify.success(`${versionNumber} deleted successfully!`));
    } else {
      // Unfortunately we can't know much more because response above is undefined
      dispatch(notify.failure('Unable to delete version!'));
    }

    await dispatch(setCurrentAppId(currentAppIdToRevertTo));
    await dispatch(setCurrentAppVersion(currentAppVersionNumberToRevertTo));
  };
};

type LifecycleActionOptions = {
  errorMessagePath?: Array<string>;
  onError?: (
    response: XMLHttpRequest,
    dispatch: Dispatch,
    getState: GetState
  ) => void;
};

const handleLifecycleAction = (
  actionToCall: () => Promise<Object>,
  options: LifecycleActionOptions = {}
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { errorMessagePath, onError } = options;

    try {
      dispatch(startLifecycleRequest());
      await dispatch(actionToCall);
      await dispatch(completeLifecycleRequest());
    } catch (response) {
      try {
        const errorMessage = pullErrorMessage(
          response.responseText,
          errorMessagePath
        );
        dispatch(failLifecycleRequest(errorMessage));
        if (typeof onError === 'function') {
          onError(response, dispatch, getState);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };
};

export const batchUpdateEnvironmentVariables = (
  environmentVariables: Record<string, string>
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    await dispatch(
      handleLifecycleAction(
        Data.batchUpdateEnvironment.bind(
          null,
          currentAppId(state),
          currentAppVersion(state),
          environmentVariables
        )
      )
    );

    const newState = getState();
    if (lifecycleHasFinished(newState)) {
      dispatch(notify.success('Environment was Updated!'));
    } else {
      dispatch(notify.failure(lifecycleErrorMessage(newState)));
    }
    dispatch(resetLifecycle());
  };
};

export const promoteAppVersion = (
  appId: number,
  version: SemVer,
  changelog?: string
) =>
  handleLifecycleAction(
    Data.promoteAppVersion.bind(null, appId, version, changelog),
    {
      errorMessagePath: ['errors'],
      onError: (response, dispatch, getState) => {
        const state = getState();
        const app = CliApp.selectors.all.entity(appId, state);
        const isReadyToActivate =
          response.status === 403 &&
          JSON.parse(response.responseText).activationInfo;

        if (CliApp.selectors.isPrivate(app) && isReadyToActivate) {
          dispatch(routeTo(`${getAppUrl(appId)}/publishing`));
        }
      },
    }
  );

export const deprecateAppVersion = (
  appId: number,
  versionNumber: string,
  date: string
) =>
  handleLifecycleAction(
    Data.deprecateAppVersion.bind(null, appId, versionNumber, date)
  );

export const migrateAppVersion = (
  appId: number,
  fromVersionNumber: string,
  toVersionNumber: string,
  options: { percentage?: number; email?: string }
) =>
  handleLifecycleAction(
    Data.migrateAppVersion.bind(
      null,
      appId,
      fromVersionNumber,
      toVersionNumber,
      options
    )
  );

export const cloneAppVersion = (
  appId: number,
  fromVersionNumber: string,
  toVersionNumber: string
) =>
  handleLifecycleAction(
    Data.cloneAppVersion.bind(null, appId, fromVersionNumber, toVersionNumber)
  );

export const executeRequest = (
  appId: number,
  versionNumber: string,
  objectType: ObjectType,
  objectKey: string,
  partialDefinition: PartialDefinition,
  authenticationId: string,
  operationMethod: OperationMethodType,
  testData: TestData
) => ({
  type: ActionTypes.EXECUTE_REQUEST,
  promise: Data.executeTestRequest(
    appId,
    versionNumber,
    objectType,
    objectKey,
    partialDefinition,
    authenticationId,
    operationMethod,
    testData
  ),
  appId,
  versionNumber,
  objectKey,
  objectType,
  operationMethod,
  testData,
});

export const resetTestRequest = () => ({
  type: ActionTypes.RESET_TEST_REQUEST,
});

export const getTestLogs = (
  logsType: 'http' | 'console' | 'bundle',
  appId: number,
  versionNumber: string,
  startDate: Date
) => {
  const filters = {
    app_version: versionNumber,
    category: logsType,
    limit: 10,
    start_date: addSeconds(startDate, -5).toISOString(),
    personal: false,
  };

  return {
    type: ActionTypes.GET_TEST_LOGS,
    promise: Data.loadAppEvents(appId, filters),
    resultKey: 'events',
    logsType,
  };
};

export const getAllTestLogs = (appId: number, versionNumber: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const testRequest = currentTestRequest(state);
    const startDate = new Date(testRequest.timestampMs);
    await Promise.all([
      dispatch(getTestLogs('http', appId, versionNumber, startDate)),
      dispatch(getTestLogs('bundle', appId, versionNumber, startDate)),
      dispatch(getTestLogs('console', appId, versionNumber, startDate)),
    ]);
  };
};

export const resetTestLogs = () => ({
  type: ActionTypes.RESET_TEST_LOGS,
});

export const setUnsavedChanges = (hasUnsavedChanges: boolean) => ({
  type: ActionTypes.SET_UNSAVED_CHANGES,
  hasUnsavedChanges,
});

export const viewAppVersion = (appVersion: SemVer, route: string = '') => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const appVersionUrl = getAppVersionUrl(currentAppId(getState()), appVersion);
  dispatch([
    setCurrentAppVersion(appVersion),
    CliAppDefinition.actions.loadEntity(null, appVersion),
    routeTo(
      deduplicateSlashes(route ? `${appVersionUrl}/${route}` : appVersionUrl)
    ),
  ]);
};

// Maximum number of pages we can request (10K pages x 500 count = 5M Zaps)
const MAX_PAGE_COUNT = 10000;
const PAGE_SIZE = 500;

const fetchTasks = async (zaps: ZapData[], accountId: string) => {
  let offset = 0;
  const tasks: TaskData[] = [];
  const offsetLimit = Math.min(MAX_PAGE_COUNT, zaps.length);
  const zapIds = zaps.map(zap => String(zap.id));

  while (offset < offsetLimit) {
    tasks.push(
      ...(await Data.loadTasks({
        account_id: accountId,
        statuses: 'success',
        zap_ids: zapIds.slice(offset, offset + PAGE_SIZE).join(','),
      }))
    );

    offset += PAGE_SIZE;
  }

  return tasks;
};

// Implementing this by hand via Data for a good reason.
// If the dev has an account like a Zapier user,
// the UI will tend to freeze—rare, but bad.
// This is becuase entities stores the entire payload (which could be
// thousands of Zaps and Tasks) into state. This becomes an updating
// bottle neck. Entities uses qim to effeciently update state in an
// immutable way. But in this case, we don't want to keep all of that
// state. So instead, let's make the requests, parse them, and then
// save them all within a Promise (the thunk). This way, fetching,
// parsing, and saving to memory won't block the event loop—blocking
// the UI.
export const loadZapDetails = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const implementation = CliAppDefinition.selectors.data(
      currentAppDefinitionEntity(state)
    );
    dispatch({ type: ActionTypes.LOAD_ZAP_DETAILS });

    try {
      const currentAccountId = readCookie('currentAccountId');
      const selectedApi = implementation.selected_api;

      const zaps = await Data.loadZaps({ selected_apis: selectedApi });
      const zapsOfImplementation = findZapsOfImplementation(
        implementation,
        zaps
      );

      const tasks = await fetchTasks(zapsOfImplementation, currentAccountId);
      const zapIdsOfTasks = [
        // Remove duplicates as tasks could pertain to the same zap.
        ...new Set(tasks.map(task => Number(task.zap_id))),
      ];

      const details = parseZapDetails(implementation, zaps, zapIdsOfTasks);
      dispatch({ type: ActionTypes.LOAD_ZAP_DETAILS_DONE, details });
    } catch (error) {
      dispatch({ type: ActionTypes.LOAD_ZAP_DETAILS_FAIL, error });
    }
  };
};
