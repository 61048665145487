import { isAtVB, isStaging } from './env';

function getZapierBaseUrl(host: string) {
  if (isAtVB()) {
    return process.env.ZAPIER_APP_BASE_URL as string;
  }

  if (
    ['zapier.com', 'zapier-staging.com'].includes(host) ||
    /zapier-worker-lab-\d+.k8s.zapier-staging.com/.test(host)
  ) {
    return '';
  }

  return isStaging(host) ? 'https://zapier-staging.com' : 'https://zapier.com';
}

function getVBBaseUrl(host: string) {
  if (isAtVB()) {
    return '';
  }

  return isStaging(host)
    ? 'https://developer.zapier-staging.com'
    : 'https://developer.zapier.com';
}

export function getUrls(host: string) {
  const zapierBaseUrl = getZapierBaseUrl(host);
  const vbBaseUrl = getVBBaseUrl(host);
  const platformRoot = `${zapierBaseUrl}/platform`;

  return {
    docs: 'https://platform.zapier.com/docs',
    // Monolith URLs.
    baseApp: `${zapierBaseUrl}/app/developer`,
    buildIntegration: `${zapierBaseUrl}/developer-platform/integrations`,
    developerPlatform: `${zapierBaseUrl}/developer-platform`,
    legacyBuilderBase: `${zapierBaseUrl}/developer/builder`,
    logOut: `${zapierBaseUrl}/logout`,
    partnerProgram: `${zapierBaseUrl}/developer-platform/partner-program`,
    platformRoot,
    platformTos: `${zapierBaseUrl}/platform/tos`,
    privacyPolicy: `${zapierBaseUrl}/privacy`,
    resources: `${zapierBaseUrl}/developer-platform/resources`,
    tools: `${zapierBaseUrl}/developer-platform/embed-tools`,
    userTos: `${zapierBaseUrl}/terms`,
    zapier: `${zapierBaseUrl}/`,
    // Platform URLs in the monollith.
    logIn: `${platformRoot}/login`,
    signUp: `${platformRoot}/sign-up`,
    // Visual Builder URLs.
    joinIntegration: `${vbBaseUrl}/join-integration`,
    settings: `${vbBaseUrl}/partner-settings/profile`,
    vbBase: `${vbBaseUrl}/`,
    zapTemplates: `${vbBaseUrl}/zap-templates`,
  };
}
