/* Handler called by the fetch middleware to allow
 * refresh to check HTTP Repsonse Headers and update
 * state.
 */

import RefreshActions from 'app/refresh/RefreshActions';

export const RESOURCE_VERSION_HEADER_KEY = 'X-Zapier-Revision';

export const refreshFetchResponseHandler = (dispatch, response) => {
  if (response.headers.has(RESOURCE_VERSION_HEADER_KEY)) {
    dispatch(
      RefreshActions.updateResourceVersion(
        response.headers.get(RESOURCE_VERSION_HEADER_KEY)
      )
    );
  }
};
