import _ from 'lodash';
import { $set, update } from 'qim';

import { createReducer } from '@zapier/toolbox-redux-framework';

import ActionTypes from 'app/developer-v3/ActionTypes';

const initialState = {
  currentApp: {
    id: null as ID,
    version: '',
    zapDetails: { triggers: [], actions: [] },
  },
  modifiedAppDefinition: {},
  cleanDefinition: {},
  newAdmin: {},
  formFields: {},
  tasks: [],
  testRequest: {
    isExecuting: false,
    output: {},
    status: '',
    errorMessage: '',
  },
  testLogs: {
    http: {
      isLoading: false,
      logs: [],
    },
    console: {
      isLoading: false,
      logs: [],
    },
    bundle: {
      isLoading: false,
      logs: [],
    },
  },
  versions: {
    lifecycle: {
      started: false,
      success: false,
      // error: false,
      errorMessage: '',
    },
  },
  hasUnsavedChanges: false,
  fieldErrors: {},
};

/** State specific to visual-builder's custom redux reducer */
type State = typeof initialState;

export type VbState = {
  developerV3: State;
};

const handlers = {
  [ActionTypes.SET_CURRENT_APP_ID](state: State, { appId }) {
    return update(['currentApp', 'id', $set(appId)], state);
  },
  [ActionTypes.SET_CURRENT_APP_VERSION](state: State, { appVersion }) {
    return update(['currentApp', 'version', $set(appVersion)], state);
  },
  [ActionTypes.SET_MODIFIED_APP_DEFINITION](
    state: State,
    { modifiedAppDefinition }
  ) {
    return update(
      ['modifiedAppDefinition', $set(modifiedAppDefinition)],
      state
    );
  },
  [ActionTypes.SET_CLEAN_DEFINITION](state: State, { definition }) {
    return update(['cleanDefinition', $set(definition)], state);
  },
  [ActionTypes.GET_APP_FORM_DONE](state: State, { formId, fields }) {
    return update(['formFields', formId, $set(fields)], state);
  },
  [ActionTypes.GET_APP_FORM_FAIL](state: State, { formId }) {
    return update(['formFields', formId, $set(undefined)], state);
  },
  [ActionTypes.EXECUTE_REQUEST](state) {
    return update(['testRequest', 'isExecuting', $set(true)], state);
  },
  [ActionTypes.EXECUTE_REQUEST_DONE](
    state,
    { result: { errorMessage, output, status, timestamp_ms } }
  ) {
    const testRequest = {
      errorMessage,
      isExecuting: false,
      output,
      status,
      timestampMs: timestamp_ms,
    };

    return update(['testRequest', $set(testRequest)], state);
  },
  [ActionTypes.EXECUTE_REQUEST_FAIL](state: State, { error }) {
    let errorMessage = '';
    try {
      errorMessage = JSON.parse(error.responseText).errors[0];
    } catch (e) {
      // Do nothing
    }
    const testRequest = {
      ...initialState.testRequest,
      errorMessage,
      status: 'error',
    };
    return update(['testRequest', $set(testRequest)], state);
  },
  [ActionTypes.RESET_TEST_REQUEST](state) {
    return update(['testRequest', $set(initialState.testRequest)], state);
  },
  [ActionTypes.GET_TEST_LOGS](state: State, { logsType }) {
    return update(['testLogs', logsType, 'isLoading', $set(true)], state);
  },
  [ActionTypes.GET_TEST_LOGS_DONE](state: State, { events: logs, logsType }) {
    return update(
      ['testLogs', logsType, $set({ isLoading: false, logs })],
      state
    );
  },
  [ActionTypes.GET_TEST_LOGS_FAIL](state: State, { logsType }) {
    return update(
      ['testLogs', logsType, $set(initialState.testLogs[logsType])],
      state
    );
  },
  [ActionTypes.RESET_TEST_LOGS](state) {
    return update(['testLogs', $set(initialState.testLogs)], state);
  },
  [ActionTypes.LIFECYCLE_REQUEST](state) {
    return update(['versions', 'lifecycle', 'started', $set(true)], state);
  },
  [ActionTypes.LIFECYCLE_REQUEST_DONE](state) {
    return update(['versions', 'lifecycle', 'success', $set(true)], state);
  },
  // need to grab error value and store it in state instead of just bool
  [ActionTypes.LIFECYCLE_REQUEST_FAIL](state: State, { errorMessage }) {
    return update(
      ['versions', 'lifecycle', 'errorMessage', $set(errorMessage)],
      state
    );
  },
  [ActionTypes.LIFECYCLE_REQUEST_RESET](state) {
    return update(
      ['versions', 'lifecycle', $set(initialState.versions.lifecycle)],
      state
    );
  },
  [ActionTypes.SET_UNSAVED_CHANGES](state: State, { hasUnsavedChanges }) {
    return update(['hasUnsavedChanges', $set(hasUnsavedChanges)], state);
  },
  [ActionTypes.UPDATE_NEW_ADMIN](state: State, { newAdmin }) {
    const nextNewAdmin = {
      ...state.newAdmin,
      ...newAdmin,
    };
    const nextState = {
      ...state,
      newAdmin: nextNewAdmin,
      errors: [],
    };

    if (_.isEmpty(newAdmin)) {
      return nextState;
    }

    const nextFieldErrors = _.omitBy(
      state.fieldErrors,
      (_fieldError, fieldName) => newAdmin.hasOwnProperty(fieldName)
    );

    return { ...nextState, fieldErrors: nextFieldErrors };
  },
  [ActionTypes.LOAD_TASKS_DONE](state: State, { result }) {
    return { ...state, tasks: result.objects };
  },
  [ActionTypes.LOAD_ZAP_DETAILS_DONE](state: State, { details }) {
    return update(['currentApp', 'zapDetails', $set(details)], state);
  },
};

export default createReducer(handlers, initialState);
