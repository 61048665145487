import { IEntity, BaseSelectors, BaseActions } from 'app/entities/types';
import { APP_STATUSES_NAME } from 'app/developer-v3/constants/status';
import { VersionChanges } from 'app/developer-v3/components/SidebarRight/Versioning/Promote/Start';

export enum LifecycleStage {
  requested = 'requested',
  /** Start of lifecycle change process. We first estimate Zaps/Users to update */
  estimating = 'estimating',
  /** Version lifecycle change is in progress */
  inProgress = 'in_progress',
  /** Done updating. The lifecycle process is now complete.
   * The integration should now be editable */
  complete = 'complete',
  /** The lifecycle change has been paused by the State Machine service */
  paused = 'paused',
  aborted = 'aborted',
  errored = 'errored',
}

type LifecycleStepName = string;

export type LifecycleJobStepData = {
  /** The accumulated average speed (number of points / min). */
  avg_speed: number;
  /** The average speed since last update (number of points / min). */
  current_speed: number;
  /** The estimated effort of work to finish the step. he unit of this number is relative but should be consistent with finished_points */
  estimated_points: number;
  /** The effort of work finished so far. Only available when stage is migrated and always 0 when stage is estimated */
  finished_points: number;
  /** The name of the migration step */
  name: LifecycleStepName;
  skipped_points: number;
  /** The timestamp (ms) when this step started. 0 if not started. */
  started_at: number;
  /** The step status: not started, in_progress, failed, success */
  status: 'pending' | 'success' | string;
  /** The timestamp (ms) when this step last runs. 0 if not started. */
  updated_at: number;
};

/** MigrationStep: https://docs.k8s.zapier.com/avro-schemas/#/schema/event.zapier.wb_conversion.stage_done.MigrationStep */
export type LifecycleJobStep = {
  /** The name of the migration step */
  name: LifecycleStepName;
  /** The estimated effort of work to finish the step. he unit of this number is relative but should be consistent with finished_points */
  estimatedPoints: number;
  /** The effort of work finished so far. Only available when stage is migrated and always 0 when stage is estimated */
  finishedPoints: number;
  skippedPoints: number;
  /** The timestamp (ms) when this step started. 0 if not started. */
  startedAt: number;
  /** The timestamp (ms) when this step last runs. 0 if not started. */
  updatedAt: number;
  /** The average speed since last update (number of points / min). */
  currentSpeed: number;
  /** The accumulated average speed (number of points / min). */
  avgSpeed: number;
  /** The step status: not started, in_progress, failed, success */
  status: 'pending' | string;
};

export type LifecycleJob = {
  progress: number;
  currentStep: LifecycleJobStep;
  selectedApi: { from: string; to: string };
  stage: LifecycleStage;
};

/**
 * We should only display `migrate` or `promote`.
 */
export enum LifecycleChangeType {
  migrate = 'migrate',
  promote = 'promote',
  demote = 'demote',
  custom = 'custom',
}

type LifecycleProgressData = {
  /** The current step in the Lifecycle job */
  current_step: LifecycleJobStepData;
  /** How far the Lifecycle job has progressed—float: in range of [0.0, 1.0] */
  overall_progress: number;
  steps: LifecycleJobStepData[];
};

/**
 * A response from /api/platform/cli/apps/{app_id}/migrations
 *
 * The structure is very similar to the following two Avro events:
 *   - https://docs.k8s.zapier.com/avro-schemas/#/schema/platform.app.migration.AppMigrationStageChangeEvent
 *   - https://docs.k8s.zapier.com/avro-schemas/#/schema/platform.app.migration.progress.AppMigrationProgressCheckEvent
 *
 * from which you can find most of the docs.
 */
type VersionLifecycleData = {
  /** ID of the integration associated with the Lifecycle change */
  app_id: number;
  app_image_url: string | null;
  app_slug: string;
  app_status: Pick<typeof APP_STATUSES_NAME, 'public' | 'pending' | 'private'>;
  app_title: string;
  error?: { messages: string[] };
  /** The base selected_api of the Lifecycle job */
  from_selected_api: string;
  /** The ID of the job */
  job_id: string;
  /** What kind of Lifecycle change is this? */
  job_kind: LifecycleChangeType;
  job_request: {
    emails_to_migrate: string[] | null;
    initiated_by: {
      customuser_id: number;
      email: string;
    };
    percentage_of_users_to_migrate: number | null;
    promote_changelog: string | null;
  };
  /** The current stage the job is in. */
  job_stage: LifecycleStage;
  progress: LifecycleProgressData;
  /** When the jobs was requested */
  requested_at: number;
  /** When the worker took the job */
  started_at: number;
  /** The target selected_api of the job. */
  to_selected_api: string;
  /** Last time the job was executed */
  updated_at: number;
};

export interface IApi {
  /** GET /migrations */
  loadProgress: (
    id: ID
  ) => Promise<{
    objects: Array<VersionLifecycleData>;
  }>;
  /** POST to /lifecycle/migrate */
  migrate(
    id: ID,
    fromVersion: string,
    toVersion: string,
    options: { email: string } | { percent: number }
  ): Promise<any>;
  /** POST to /lifecycle/promote */
  promote: (
    id: ID,
    version: string,
    changelog: string,
    changes?: VersionChanges
  ) => Promise<any>;
}

export type LifecycleError = {
  messages: string[];
};

export type VersionLifecycleEntity = IEntity<VersionLifecycleData>;

type CollectionSelectors = {
  findByAppId: (appId: ID, state: any) => VersionLifecycleEntity;
};

/** Selectors for the WbConversion Entity */
export interface ISelectors
  extends BaseSelectors<VersionLifecycleEntity, CollectionSelectors> {
  id: (entity: VersionLifecycleEntity) => ID;
  /** The integration associated with this lifecycle change */
  appId: (entity: VersionLifecycleEntity) => string;
  /** Stage of the lifecycle change, check LifecycleStage to see the possible values */
  stage: (entity: VersionLifecycleEntity) => LifecycleStage;
  /** Overall progress of the lifecycle change, returns a float */
  progress: (entity: VersionLifecycleEntity) => number;
  /** Returns the object with the information about the current step of the lifecycle change */
  currentStep: (entity: VersionLifecycleEntity) => LifecycleJobStep | {};
  /** Is any lifecycle change completed?  */
  isDone: (entity: VersionLifecycleEntity) => boolean;
  /** Are we working through any lifecycle change?  */
  isPending: (entity: VersionLifecycleEntity) => boolean;
  isMigrating: (entity: VersionLifecycleEntity) => boolean;
  isPromoting: (entity: VersionLifecycleEntity) => boolean;
  selectedApi: (entity: VersionLifecycleEntity) => { from: string; to: string };
  steps: (entity: VersionLifecycleEntity) => LifecycleJobStep[];
  /** The type of lifecycle change we've loaded. This is derived from the state machine found in 'name' */
  type: (entity: VersionLifecycleEntity) => LifecycleChangeType;
  /** The error when aborted. */
  error?: (entity: VersionLifecycleEntity) => LifecycleError;
  updatedAt: (entity: VersionLifecycleEntity) => number;
}

export interface IActions extends BaseActions<VersionLifecycleData> {
  /** Triggers the start of a version migration. */
  migrate(
    id: ID,
    fromVersion: string,
    toVersion: string,
    options: { email: string } | { percent: number }
  ): void;
  /** Triggers the start of a version promotion */
  promote(
    appId: ID,
    version: string,
    changelog?: string,
    changes?: VersionChanges
  ): void;
  /** Fetches the current progress of a lifecycle change */
  getProgress(id: ID): void;
}
