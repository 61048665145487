import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import useNotify from './useNotify';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { getCSRFToken } from './useCSRFToken';
import { getQueryKey } from './useQuerySelfSignupDomains';

type Args =
  | { allowed: boolean; domains?: string[] }
  | { allowed?: boolean; domains: string[] };
type Return = Record<string, any>;

const MUTATION_KEY_PREFIX = 'add-self-signup-domains';
const getMutationKey = (integrationId: string) =>
  `${MUTATION_KEY_PREFIX}-${integrationId}`;

function useUpdateSelfSignupDomains(
  integrationId: string,
  options?: UseMutationOptions<Record<string, any>, Error, Args, unknown>
) {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mutationKey = getMutationKey(integrationId);

  const mutation = useMutation<Return, Error, Args>({
    mutationKey,
    async mutationFn(args) {
      const { allowed, domains } = args;

      return await fetchJson(
        `${ZAPIER_APP_BASE_URL}/api/platform/cli/apps/${integrationId}/self_signup/allowed_domains`,
        {
          credentials: 'include',
          body: JSON.stringify({
            ...(allowed !== undefined ? { self_signup_allowed: allowed } : {}),
            ...(domains !== undefined
              ? { allowed_self_signup_domains: domains }
              : {}),
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': await getCSRFToken(),
          },
          method: 'PUT',
        }
      );
    },
    onError(_err) {
      notify.failure('Failed to update the settings!');
    },
    onSuccess(_data) {
      queryClient.invalidateQueries(getQueryKey(integrationId));
      notify.success('Successfully updated the settings');
    },
    ...options,
  });

  const { isLoading, ...rest } = mutation;

  return {
    ...rest,
    isMutating: isLoading,
    mutate: mutation.mutateAsync,
    mutationKey,
  };
}

export default useUpdateSelfSignupDomains;
export { MUTATION_KEY_PREFIX, getMutationKey };
