import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import {
  Review,
  getQueryKey as getQueryKeyIntegrationReview,
} from '../useQueryIntegrationReview';

import type { PublishingForm } from './types';

const MUTATION_KEY = 'mutate-submit-publishing-form';

function formatToRequestBody(publishingForm: PublishingForm, version: string) {
  const {
    apiDocsUrl,
    expectedResponseTime,
    homepageUrl,
    id,
    marketingContactEmail,
    paidApi,
    primaryColor,
    secondaryColor,
    technicalContactEmail,
    testAccountNotes,
    testAccountPassword,
    testAccountUsername,
    twitterUsername,
  } = publishingForm;

  return {
    api_docs_url: apiDocsUrl,
    expected_response_time: expectedResponseTime,
    homepage_url: homepageUrl,
    id: String(id),
    marketing_contact_email: marketingContactEmail,
    paid_api: paidApi,
    primary_color: primaryColor,
    secondary_color: secondaryColor,
    technical_contact_email: technicalContactEmail,
    test_account_notes: testAccountNotes,
    test_account_password: testAccountPassword,
    test_account_username: testAccountUsername,
    twitter_username: twitterUsername,
    version,
  };
}

function useMutationSubmitPublishingForm(
  args: { integrationId: number },
  options?: UseMutationOptions<
    void,
    Error,
    { publishingForm: PublishingForm; version: string },
    { publishingForm: PublishingForm; review: Review }
  >
) {
  const { integrationId } = args;
  const notify = useNotify();

  const queryClient = useQueryClient();
  const queryKeyIntegrationReview = getQueryKeyIntegrationReview({
    integrationId,
  });

  const mutationKey = MUTATION_KEY;

  const mutation = useMutation({
    mutationKey,
    async mutationFn({
      publishingForm,
      version,
    }: {
      publishingForm: PublishingForm;
      version: string;
    }) {
      const url = new URL(
        `api/platform/cli/apps/${encodeURIComponent(
          integrationId
        )}/versions/${encodeURIComponent(version)}/activate`,
        ZAPIER_APP_BASE_URL
      );

      return fetchJson(url.href, {
        body: JSON.stringify(formatToRequestBody(publishingForm, version)),
        method: 'POST',
      });
    },
    onError: (error: any, _variables) =>
      notify.failure(
        `There was a problem submitting your review request: ${
          JSON.parse(error?.responseText ?? {}).errors?.[0] ?? 'unknown'
        }. Please try again later or contact support.`,
        mutationKey
      ),
    onSuccess: () => {
      notify.success(
        'You’ve submitted your review request for publication on the Zapier Platform!'
      );
      queryClient.invalidateQueries(queryKeyIntegrationReview);
    },
    ...options,
  });

  return { ...mutation, mutationKey };
}

export default useMutationSubmitPublishingForm;
