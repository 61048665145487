import { filter, set, flow } from 'lodash';

import { NEW_SERVICE_FIELD } from 'app/developer-v3/constants';

import type {
  ErrorList,
  ValidatorFormValues,
  ValidatorFunction,
  ValidatorResult,
} from 'app/developer-v3/types/validator';
import type { FormFieldTemplate } from 'app/developer-v3/types';

const isValidSchemaKey = /^[A-Z][A-Z0-9_]*$/i;

const minHandlers = {
  string: (value, min) => value.length < min,
  number: (value, min) => value < min,
};

/**
 * Returns errors for required fields that don't have a value
 * @param fields
 */
const validateRequired: ValidatorFunction = (fields: FormFieldTemplate[]) => ({
  values,
  errors = {},
}: ValidatorResult) => {
  const requiredErrors = filter(
    fields,
    field =>
      field.required === true ||
      (typeof field.required === 'function' && field.required(values))
  ).reduce(
    (fieldErrors, field) => {
      const value = values[field.key] || '';

      if (!String(value).trim() || value === NEW_SERVICE_FIELD) {
        fieldErrors[field.key] = `${field.label} is required`;
      }

      return fieldErrors;
    },
    { ...errors }
  );

  return { errors: requiredErrors, values };
};

/**
 * Returns errors for fields that have values below the min
 * @param fields
 */
const validateMin: ValidatorFunction = (fields: FormFieldTemplate[]) => ({
  values,
  errors = {},
}: ValidatorResult) => {
  const minErrors = filter(fields, 'min').reduce(
    (fieldErrors, field) => {
      const value = values[field.key];
      const isError = minHandlers[typeof value];
      const min = Number(field.min);

      if (value && isError(value, min)) {
        fieldErrors[field.key] = `${field.label} must be at least ${min}${
          typeof value === 'string' ? ' characters' : ''
        }`;
      }

      return fieldErrors;
    },
    { ...errors }
  );

  return { values, errors: minErrors };
};

/**
 * Returns errors for values that are not allowed
 * @param fields
 */
const validateDisallowed: ValidatorFunction = (
  fields: FormFieldTemplate[]
) => ({ values, errors = {} }: ValidatorResult) => {
  const disallowedErrors = filter(fields, 'disallowed').reduce(
    (fieldErrors, field) => {
      const value = values[field.key];
      const isError = (disallowed: string[], input: string) =>
        disallowed.includes(input);
      const disallowedValues = field.disallowed || [];

      if (value && isError(disallowedValues, value)) {
        fieldErrors[
          field.key
        ] = `You've already got field with the "${value}" key`;
      }

      return fieldErrors;
    },
    { ...errors }
  );

  return { values, errors: disallowedErrors };
};

/**
 * Returns errors if the field value does not match the schema key
 * @param fields
 */
const validateSchemaKey: ValidatorFunction = (fields: FormFieldTemplate[]) => ({
  values,
  errors = {},
}: ValidatorResult) => {
  const invalidKeyErrors = filter(fields, 'schemaKey').reduce(
    (fieldErrors, field) => {
      const value = values[field.key];

      if (!isValidSchemaKey.test(value)) {
        fieldErrors[field.key] =
          'Keys must start with a letter and contain only alphanumeric and underscore characters.';
      }

      return fieldErrors;
    },
    { ...errors }
  );

  return { values, errors: invalidKeyErrors };
};

const validateDynamicDropdown: ValidatorFunction = () => ({
  values,
  errors = {},
}: ValidatorResult) => {
  if (!values.dynamic) {
    return { values, errors };
  }

  const { source, name, label } = values.dynamic || {};
  const dropdownErrors = { ...errors };

  // If there's no dropdown.source, we don't render anything else
  if (!source) {
    dropdownErrors.dynamic = { source: 'Dropdown Source is required.' };
  } else {
    if (!name) {
      set(dropdownErrors, ['dynamic', 'name'], 'Field Name is required.');
    }

    if (label && !isValidSchemaKey.test(label)) {
      set(
        dropdownErrors,
        ['dynamic', 'label'],
        'Field Labels must start with a letter and contain only alphanumeric and underscore characters.'
      );
    }
  }

  return { values, errors: dropdownErrors };
};

const createValidator = (
  fields: FormFieldTemplate[],
  validators: ValidatorFunction[]
) => {
  const appliedValidators = validators.map(v => v(fields));

  return (values: ValidatorFormValues): ErrorList => {
    const { errors } = flow(...appliedValidators)({
      values,
      errors: {},
    });

    return errors;
  };
};

export {
  validateRequired,
  validateMin,
  validateDisallowed,
  validateSchemaKey,
  validateDynamicDropdown,
  createValidator,
};
