// @flow

import { connect } from 'react-redux';

import { DateProvider } from '@zapier/date';

import { Profile } from '@zapier/shared-entities';

const mapStateToProps = state => {
  const profile = Profile.selectors.all.currentProfile(state);

  return {
    timezone: Profile.selectors.timezone(profile),
  };
};

export default connect(mapStateToProps)(DateProvider);
