// @flow

import { max, mean, min, size } from 'lodash';

import type { PerformanceStats } from 'app/perf-utils/types';

export const calculatePerformanceStats = (
  samples: Array<number>
): PerformanceStats => {
  return {
    samples,
    count: size(samples),
    min: min(samples),
    max: max(samples),
    avg: mean(samples),
  };
};
