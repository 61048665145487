import { difference, filter, intersectionBy } from 'lodash';

import {
  ACTION_INPUT_TYPES,
  FIELD_KEYS,
  NEW_SERVICE_FIELD,
} from 'app/developer-v3/constants';

import type { ActionInputType } from 'app/developer-v3/types';
import type {
  FieldOrFunctionSchema,
  FieldSchema,
} from 'app/developer-v3/platformSchema/field';

const findCommonFields = (
  fields: Array<FieldSchema>,
  newFields: Array<FieldSchema>
) => {
  const commonKeyedFields = filter(
    intersectionBy(newFields, fields, FIELD_KEYS.key),
    FIELD_KEYS.key
  );
  const commonDynamicFields = filter(
    intersectionBy(newFields, fields, FIELD_KEYS.source),
    FIELD_KEYS.source
  );
  return [...commonKeyedFields, ...commonDynamicFields];
};

const findUniqueFields = (
  fields: Array<FieldSchema>,
  newFields: Array<FieldSchema>
) => {
  const commonFields = findCommonFields(fields, newFields);
  const uniqueFields = difference(newFields, commonFields);

  return uniqueFields;
};

const getInputFieldType = (field: FieldOrFunctionSchema): ActionInputType => {
  if (field.source) {
    return ACTION_INPUT_TYPES.DYNAMIC;
  }

  if (field.children) {
    return ACTION_INPUT_TYPES.GROUP;
  }

  return ACTION_INPUT_TYPES.INPUT;
};

const isNewServiceField = (
  inputField?: number | string | Record<string, string>
): boolean => {
  if (!inputField) {
    return false;
  }

  if (typeof inputField === 'number') return false;
  if (typeof inputField === 'string') return inputField === NEW_SERVICE_FIELD;
  return inputField.key === NEW_SERVICE_FIELD;
};

export {
  findCommonFields,
  findUniqueFields,
  getInputFieldType,
  isNewServiceField,
};
