import { urlWithNext, stripTrailingSlash } from '@zapier/url-utils';

import { useRouter } from 'app/developer-v3/hooks';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

function useRedirectToAuth() {
  const {
    pathname,
    params: { secret },
    routeTo,
  } = useRouter();
  const nextPathname = secret
    ? stripTrailingSlash(pathname).replace(`/${secret}`, '')
    : pathname;
  const redirectToAuth = () =>
    routeTo(urlWithNext(`${ZAPIER_APP_BASE_URL}/app/login`, nextPathname));

  return { secret, redirectToAuth };
}

export default useRedirectToAuth;
