// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import { Account } from '@zapier/shared-entities';

import AccountContext, { type AccountType } from './AccountContext';

type Props = {
  account: AccountType,
  children: React.Node,
  isAccountLoading: boolean,
  loadAccount: () => void,
};

const createLazyAccountContext = ({
  account,
  isAccountLoading,
  loadAccount,
}) => ({
  // Using a getter here enables lazy loading of the account
  get account() {
    if (!account && !isAccountLoading) {
      loadAccount();
    }
    return account;
  },

  isAccountLoading,
});

const AccountProvider = ({ children, ...accountProps }: Props) => (
  <AccountContext.Provider value={createLazyAccountContext(accountProps)}>
    {children}
  </AccountContext.Provider>
);

const mapStateToProps = state => ({
  account: Account.selectors.all.currentAccount(state),
  isAccountLoading: Account.selectors.all.isLoadImpending(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadAccount() {
    dispatch(Account.actions.loadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountProvider);
