// @flow

import { includes } from 'lodash';

import { frame } from 'app/perf-utils/rules/Constants';

import type {
  PerformanceLintRule,
  PerformanceMeasurementContext,
  PerformanceMeasurementItem,
  PerformanceStats,
} from 'app/perf-utils/types';

const threshold = {
  critical: 1 * frame,
};

const rule: PerformanceLintRule = {
  id: 'avoidable-render',

  description: `
    Avoidable re-renders occur when a component's state and props are unchanged (either by reference or deep equality of values).
    Learn more https://github.com/zapier/frontend-guide/tree/master/performance#when-does-react-re-render`,

  check(
    item: PerformanceMeasurementItem,
    stats: PerformanceStats,
    context: PerformanceMeasurementContext
  ) {
    const isAvoidableUpdate =
      item.phase === 'update' && includes(context.avoidableRenders, item.name);

    if (isAvoidableUpdate && stats.max > threshold.critical) {
      return 'critical';
    }
    return 'success';
  },
};

export default rule;
