// @flow

import { debounce, isEmpty, negate, some, values } from 'lodash';

import AvoidableRender from 'app/perf-utils/rules/AvoidableRender';
import AvoidableRenders from 'app/perf-utils/data/AvoidableRenders';
import Measurements from 'app/perf-utils/data/Measurements';
import SlowComponentOperation from 'app/perf-utils/rules/SlowComponentOperation';
import SlowShouldComponentUpdate from 'app/perf-utils/rules/SlowShouldComponentUpdate';
import linter from 'app/perf-utils/linter';
import reporter from 'app/perf-utils/reporter/DocumentReporter/';

import SlowFunction from './rules/SlowFunction';

import type { PerformanceLintReport } from 'app/perf-utils/types';

const isReportEmpty = (report: PerformanceLintReport): boolean => {
  if (!isEmpty(report)) {
    return !some(values(report), negate(isEmpty));
  }
  return true;
};

const runLinter = (
  avoidableRenders: AvoidableRenders,
  measurements: Measurements
) => {
  const options = {};

  const rules = [
    AvoidableRender,
    SlowComponentOperation,
    SlowFunction,
    SlowShouldComponentUpdate,
  ];

  const measurementSets = measurements.getMeasurementSets();

  const context = {
    avoidableRenders: avoidableRenders.getCompletelyAvoidable(),
  };

  const report = linter(options, rules, measurementSets, context);

  if (!isReportEmpty(report) && reporter) {
    reporter(report);
  }
};

export default debounce(runLinter, 5000);
