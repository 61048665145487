// @flow weak
/*
Send errors off to Sentry.
*/

import _ from 'lodash';

import {
  commonErrors as Errors,
  shouldIgnoreNetworkError,
  trackException,
} from '@zapier/toolbox-redux-framework';

export const sentryLoggingKey = '__enableSentryLogging';

/**
 * Attaches the Sentry logging key to enable query info to be sent to Sentry.
 */
export const enableSentryRequestLogging = (obj: Object = {}) => {
  return {
    ...obj,
    [sentryLoggingKey]: true,
  };
};

export const shouldLogRequestToSentry = (obj: Object) =>
  _.get(obj, [sentryLoggingKey], false) === true;

const ACTION_HISTORY_SIZE = 100;

const isExpectedPauseOrUnpauseError = error => {
  return (
    // Upgrade, too many steps, other user-fixable errors.
    error.status === 426 ||
    // Zap is already paused/unpaused.
    error.status === 409 ||
    // Some auth problem while pausing/unpausing the Zap.
    error.status === 403 ||
    // Node no longer exists
    error.status === 404 ||
    // Server issue, nothing frontend can do about it.
    // Could be generic 500, 504 gateway timeout, 502 proxy issue, etc
    error.status >= 500
  );
};

// If an error is expected, and there is no value in sending it to Sentry, you
// can add a test for it here. These expected exceptions should have some
// associated user-facing message that explains the error and how to solve it.
// The tests should be fairly fine-grained so that we don't accidentally
// swallow "true" exceptions that tell us something is wrong with the app.
const expectedErrors = {
  UnpauseError: isExpectedPauseOrUnpauseError,
  PauseError: isExpectedPauseOrUnpauseError,

  // Only errors if the status code is not expected.
  DataGetError: shouldIgnoreNetworkError,
  DataPutError: shouldIgnoreNetworkError,
  DataPostError: shouldIgnoreNetworkError,
  DataPatchError: shouldIgnoreNetworkError,
  DataDeleteError: shouldIgnoreNetworkError,
  LoadItemsNetworkError: shouldIgnoreNetworkError,
  // Failed network requests are likely a problem on the user's side and not a
  // problem with the code. Hypothetically, we could have a bug in our url, like
  // a typo on our domain name, but the noise is just too high to get any signal
  // here.
  NetworkError() {
    return true;
  },
  PlatformError(error) {
    return shouldIgnoreNetworkError(error.response);
  },
  SignUpValidationError() {
    return true;
  },
  SignUpError(error) {
    const stringError = error.toString();
    return stringError.includes('too often');
  },
};

export const isExpectedError = error =>
  expectedErrors[error.name] && expectedErrors[error.name](error);

export default () => next => {
  const actionHistory = [];

  return action => {
    if (!action) {
      return next(action);
    }

    actionHistory.push(action.type);
    if (actionHistory.length > ACTION_HISTORY_SIZE) {
      actionHistory.shift();
    }

    const { error } = action;

    if (error) {
      const isExpected = isExpectedError(error);
      const isCustomError = _.has(Errors, error.name);
      const hasRequestInfo = _.has(error, 'request');
      const isRequestLoggingEnabled = shouldLogRequestToSentry(error);

      if (!isExpected) {
        const data = {
          extra: {
            action,
            actionHistory,
            // Allows us to attach request info for further debugging
            ...(isRequestLoggingEnabled && hasRequestInfo
              ? { request: error.request }
              : {}),
            // Error might have been derived from a response.
            status: error.status,
            // If its a custom error, then log the `errors` in Sentry
            ...(isCustomError
              ? { errors: Errors[error.name].errors(error) }
              : {}),
          },
        };

        trackException(error, data);
      }
    }

    return next(action);
  };
};
