import { useQuery } from 'react-query';
import useNotify from './useNotify';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

const QUERY_KEY_PREFIX = 'active-users-retention-data-data';
const getQueryKey = integrationId => `${QUERY_KEY_PREFIX}-${integrationId}`;

export type IntegrationActivationRetention = {
  outgest_id: string;
  outgest_run_timestamp_ms: string;
  first_activation_month_end_date: string; // ISO string
  app_integration_id: number;
  app_name: string;
  users_active: number;
  month1_user_retention?: number;
  month2_user_retention?: number;
  month3_user_retention?: number;
  month4_user_retention?: number;
  month5_user_retention?: number;
  month6_user_retention?: number;
  month7_user_retention?: number;
  month8_user_retention?: number;
  month9_user_retention?: number;
  month10_user_retention?: number;
  month11_user_retention?: number;
  month12_user_retention?: number;
  month1_retention_rate?: number;
  month2_retention_rate?: number;
  month3_retention_rate?: number;
  month4_retention_rate?: number;
  month5_retention_rate?: number;
  month6_retention_rate?: number;
  month7_retention_rate?: number;
  month8_retention_rate?: number;
  month9_retention_rate?: number;
  month10_retention_rate?: number;
  month11_retention_rate?: number;
  month12_retention_rate?: number;
};

type Result = { integration_activity: IntegrationActivationRetention[] };

function useQueryInsightsActiveUsersRetentionData(integrationId: string) {
  const notify = useNotify();
  const queryKey = getQueryKey(integrationId);

  const { data, error, ...rest } = useQuery(
    queryKey,
    (): Promise<Result> =>
      fetch(
        `${ZAPIER_APP_BASE_URL}/developer/insights/v1/${integrationId}/activation-retention`,
        {
          credentials: 'include',
        }
      ).then(res => res.json())
  );

  if (error) {
    console.error(error);
    notify.failure('Failed to fetch insights activity daily data.');
  }

  return { data: data?.integration_activity ?? [], error, ...rest };
}

export default useQueryInsightsActiveUsersRetentionData;
