// @flow

import runLinter from 'app/perf-utils/runner';
import runWhenIdle from 'app/common/utils/runWhenIdle';

import type { PerformanceLinterState } from 'app/perf-utils/state';
import type { WhyDidYouUpdateDiffs } from 'app/perf-utils/types';

export const createNotifier = (state: PerformanceLinterState) => (
  groupByComponent: boolean,
  collapseComponentGroups: boolean,
  displayName: string,
  diffs: WhyDidYouUpdateDiffs
) => {
  const { avoidableRenders, measurements } = state;

  // Queue up the diffs
  avoidableRenders.record(diffs);

  // Process and report when idle
  runWhenIdle(() => {
    if (state.isEnabled) {
      runLinter(avoidableRenders, measurements);
    }
  });
};
