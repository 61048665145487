// @flow

import React from 'react';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';

import { useTrackingContext } from '@zapier/toolbox-tracking';

import { PolicyPendingCheck } from '@zapier/design-system-v6';
import { RouterSelectors } from '@zapier/toolbox-redux-framework';

import { Profile } from '@zapier/shared-entities';
import maybeRender from 'app/common/components/maybeRender';

type Props = {
  pathname: string,
};

const CheckPendingPolicies = ({ pathname }: Props) => (
  <PolicyPendingCheck
    currentPathname={pathname}
    useTrackingContext={useTrackingContext}
  />
);

const stripPathSlashes = pathname => pathname.replace(/^\/+|\/+$/gm, '');

const mapStateToProps = state => ({
  pathname: stripPathSlashes(
    RouterSelectors.getCurrentPathname(state) || window.location.pathname || ''
  ),
  currentProfile: Profile.selectors.all.currentProfile(state),
  isAdminMasqueraded: Profile.selectors.all.isMasquerading(state),
});

export default flowRight(
  connect(mapStateToProps),
  maybeRender(props => !!props.currentProfile && !props.isAdminMasqueraded)
)(CheckPendingPolicies);
