import { isEmpty, mapValues } from 'lodash';
import { cleanRequest } from './cleanRequest';

import type { CreateActionSchema } from 'app/developer-v3/platformSchema/create-action';
import type { SearchActionSchema } from 'app/developer-v3/platformSchema/search-action';

function cleanAction(action: CreateActionSchema | SearchActionSchema) {
  const { operation, ...rest } = action;
  const { perform, ...restOperation } = operation;

  const hasPerform = !isEmpty(perform);

  return {
    ...rest,
    operation: {
      ...restOperation,
      ...(hasPerform ? { perform: cleanRequest(perform) } : {}),
    },
  };
}

function cleanActions(
  actions: Record<string, CreateActionSchema | SearchActionSchema>
) {
  return mapValues(actions, cleanAction);
}

export { cleanAction, cleanActions };
