// @flow

import React, { useContext } from 'react';

export type AccountType = {} | null;
export type AccountContextType = {
  account: AccountType,
  isAccountLoading: boolean,
};

const DEFAULT_VALUE: AccountContextType = {
  account: {},
  isAccountLoading: false,
};

const AccountContext = React.createContext(DEFAULT_VALUE);

export default AccountContext;

export const useAccount = () => useContext(AccountContext);
