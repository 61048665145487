import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ReviewStatus } from 'app/developer-v3/types/statuses';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

type Args = {
  integrationId: number;
};

const QUERY_KEY_PREFIX = 'query-integration-review';
const getQueryKey = (args: Args) => `${QUERY_KEY_PREFIX}-${args.integrationId}`;

export type Review = {
  id: number;
  // undefined (private before Bouncer) | not-submitted (private after Bouncer) -> in-review (pending) [-> pushed-back (pushed back)] -> in-review (resubmitted) -> (approved) // rejected?
  status: ReviewStatus;
};

function formatResponseBody(body: any): Review {
  const { id, status } = body;

  return id ? { id, status } : undefined;
}

function useQueryIntegrationReview(
  args: Args,
  options?: UseQueryOptions<Review, Error, Review>
) {
  const { integrationId } = args;
  const notify = useNotify();

  const queryKey = getQueryKey(args);
  const url = new URL(
    `api/platform/cli/apps/${encodeURIComponent(integrationId)}/review`,
    ZAPIER_APP_BASE_URL
  );

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryIntegrationReview;
export { getQueryKey, QUERY_KEY_PREFIX };
