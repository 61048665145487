import useActiveProfile from './useActiveProfile';
import useQueryAdmins from './useQueryAdmins';

function useIsIntegrationAdmin(
  { integrationId },
  { enabled } = { enabled: true }
) {
  const {
    activeProfile,
    isLoading: isLoadingActiveProfile,
    error: errorActiveProfile,
  } = useActiveProfile();

  if (errorActiveProfile) {
    console.error(errorActiveProfile);
  }

  const isStaff = activeProfile?.isStaff ?? false;
  const activeProfileId = activeProfile?.id;

  const {
    admins,
    isLoading: isLoadingIsIntegrationAdmin,
    error: errorIsIntegrationAdmin,
    ...rest
  } = useQueryAdmins(integrationId, {
    enabled:
      enabled &&
      !isStaff &&
      !errorActiveProfile &&
      !isLoadingActiveProfile &&
      !!activeProfile,
  });

  const isAdmin =
    isStaff || !!admins.find(admin => admin?.id === `a${activeProfileId}`);

  return {
    ...rest,
    error: errorIsIntegrationAdmin,
    isAdmin,
    isLoading: isLoadingActiveProfile || isLoadingIsIntegrationAdmin,
  };
}

export default useIsIntegrationAdmin;
