// @flow

import { chain, map } from 'lodash';

import type {
  PerformanceLintRule,
  PerformanceLintSeverity,
  PerformanceLinter,
  PerformanceLinterOptions,
  PerformanceMeasurementContext,
  PerformanceMeasurementSet,
} from 'app/perf-utils/types';

const createMeasurementRuleExecutor = (
  rule: PerformanceLintRule,
  context: PerformanceMeasurementContext,
  options: PerformanceLinterOptions
) => ({ item, stats }: PerformanceMeasurementSet) => {
  if (options && options.log) {
    console.info(`Execute ${rule.id} with measurement`, options, item, stats);
  }

  const severity: ?PerformanceLintSeverity = rule.check(
    item,
    stats,
    context,
    options
  );

  return severity && severity !== 'success'
    ? {
        item,
        severity,
        rule,
        stats,
      }
    : null;
};

const linter: PerformanceLinter = (
  options: PerformanceLinterOptions,
  rules: Array<PerformanceLintRule>,
  measurementSets: Array<PerformanceMeasurementSet>,
  context: PerformanceMeasurementContext
) =>
  chain(rules)
    // Apply each rule to all measurements and collect the results
    .map((rule: PerformanceLintRule) => {
      const perRuleViolations = map(
        measurementSets,
        createMeasurementRuleExecutor(rule, context, options)
      );
      return perRuleViolations;
    })
    // Flatten per-rule violations into a single list
    .flatten()
    // Throw out all the empty violations
    .compact()
    .sortBy('severity')
    .groupBy('severity')
    .value();

export default linter;
