import { useRedux } from '@zapier/toolbox-redux-framework';

import * as VersionLifecycle from 'app/entities/VersionLifecycle';

import useVersionLifecycle from './useVersionLifecycle';

import type { LifecycleJobStep } from 'app/entities/VersionLifecycle/types';
import { VersionChanges } from '../components/SidebarRight/Versioning/Promote/Start';

const mapDispatch = dispatch => ({
  promoteVersion(
    appId: ID,
    version: string,
    changelog?: string,
    changes?: VersionChanges
  ) {
    return dispatch(
      VersionLifecycle.actions.promote(appId, version, changelog, changes)
    );
  },
});

type BaseTypes = ReturnType<typeof useVersionLifecycle>;

type UseVersionPromotion = {
  error: { messages: string[] };
  hasError: boolean;
  /** The current step in the promotion job. */
  currentStep: LifecycleJobStep;
  id: BaseTypes['id'];
  /** Are we waiting for a promote request to finish? */
  isLoading: boolean;
  /** Is this a promotion that hasn’t completed (in-progress or aborted)? */
  isPending: boolean;
  /** Are we currently promoting any version of this integration? */
  isPromoting: boolean;
  isPromoted: boolean;
  job: BaseTypes['job'];
  promote(
    version: string,
    changelog?: string,
    changes?: VersionChanges
  ): Promise<void>;
  promotedDate: number;
  progress: BaseTypes['progress'];
  loadProgress: BaseTypes['loadProgress'];
  version: string;
};

/** An extension of useVersionLifecycle suited for promotions */
export default function useVersionPromotion(appId: ID): UseVersionPromotion {
  const lifecycle = useVersionLifecycle(appId);
  const { promoteVersion } = useRedux(null, mapDispatch);

  return {
    get error() {
      return lifecycle.error;
    },

    get hasError() {
      return lifecycle.hasError;
    },

    get isLoading() {
      return lifecycle.isLoading;
    },

    get isPending() {
      return lifecycle.isPendingByType(VersionLifecycle.ChangeType.promote);
    },

    get isPromoting() {
      return lifecycle.isPendingByType(VersionLifecycle.ChangeType.promote);
    },

    get isPromoted() {
      return lifecycle.isDoneByType(VersionLifecycle.ChangeType.promote);
    },

    get currentStep() {
      return lifecycle.job.currentStep;
    },

    get progress() {
      return lifecycle.progress;
    },

    promote(version, changelog, changes) {
      return promoteVersion(appId, version, changelog, changes);
    },

    get promotedDate() {
      return lifecycle.isDoneByType(VersionLifecycle.ChangeType.promote)
        ? lifecycle.updatedAt
        : null;
    },

    get job() {
      return lifecycle.job;
    },

    id: lifecycle.id,

    loadProgress: lifecycle.loadProgress,

    version: lifecycle.job.selectedApi.to,
  };
}
