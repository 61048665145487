import { useQuery } from 'react-query';
import useNotify from './useNotify';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

const QUERY_KEY_PREFIX = 'activity-daily-data';
const getQueryKey = () => QUERY_KEY_PREFIX;

export type IntegrationActivityDaily = {
  outgest_id: string;
  outgest_run_date_time: string; // isoString
  app_integration_name: string;
  app_integration_id: number;
  date: string; // isoString
  users_active_daily: number;
  zaps_active_daily: number;
};

type Result = { integration_activity: IntegrationActivityDaily[] };

function useQueryInsightsActivityDailyData(integrationId: string) {
  const notify = useNotify();
  const queryKey = getQueryKey();

  const { data, error, ...rest } = useQuery(
    queryKey,
    (): Promise<Result> =>
      fetch(
        `${ZAPIER_APP_BASE_URL}/developer/insights/v1/${integrationId}/activity-daily`,
        {
          credentials: 'include',
        }
      ).then(res => res.json())
  );

  if (error) {
    console.error(error);
    notify.failure('Failed to fetch insights activity daily data.');
  }

  return { data: data?.integration_activity ?? [], error, ...rest };
}

export default useQueryInsightsActivityDailyData;
