import { useMutation } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import useAddCollaborator from './useAddCollaborator';

type Args = { email: string; message: string; serviceId: string };
type Return = Record<string, any>;

const MUTATION_KEY = 'join-integration';

function useJoinIntegration() {
  const mutationKey = MUTATION_KEY;

  const { mutate: addCollaborator } = useAddCollaborator();

  const mutation = useMutation<Return, Error, Args>({
    mutationKey,
    async mutationFn({ email, message, serviceId }) {
      const { app_id: integrationId } = await fetchJson(
        `${ZAPIER_APP_BASE_URL}/api/platform/cli/services/${serviceId}`
      );
      return addCollaborator({ email, integrationId, message });
    },
  });

  const { isLoading } = mutation;

  return { isMutating: isLoading, mutate: mutation.mutateAsync, mutationKey };
}

export default useJoinIntegration;
