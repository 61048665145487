// @flow weak
import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

export const cloneComponentChild = (child, extraProps) => {
  if (!child) {
    return null;
  }

  if (_.isFunction(child)) {
    return child(extraProps);
  }

  return React.cloneElement(child, extraProps);
};

export const childOrWrapChildren = (children, arrayContainer = 'div') => {
  if (!children) {
    return null;
  }

  if (_.isFunction(children)) {
    return children();
  }

  if (!_.isArray(children)) {
    return children;
  }

  return React.createElement(arrayContainer, {}, ...children);
};

export const setRef = (Component, key) => nodeOrComponent => {
  // The ref might be a real DOM node _or_ a representation of a component
  _.set(Component, ['dangerousRefs', key], nodeOrComponent);
};

export const getRef = (Component, key) =>
  // Regardless of whether the ref is a real DOM node or a component,
  // always return the representative DOM node.
  ReactDOM.findDOMNode(_.get(Component, ['dangerousRefs', key]));

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'UnknownComponent';

export const makeWrappedDisplayName = (displayName, WrappedComponent) =>
  `${displayName}(${getDisplayName(WrappedComponent)})`;
