import { $none, $set, update } from 'qim';
import {
  differenceBy,
  findIndex,
  get,
  intersection,
  isEmpty,
  kebabCase,
  map,
  omit,
  partial,
  toUpper,
} from 'lodash';

import {
  MUTATION_TYPES,
  SERVICE_TYPE,
  WRITE_METHODS,
} from 'app/developer-v3/constants';

import { findUniqueFields } from './field';
import { quickCopy } from './definition';

import type { AppService } from 'app/developer-v3/types';
import type { ServiceType } from 'app/developer-v3/types/service';
import {
  AuthenticationSchema,
  AuthenticationType,
} from 'app/developer-v3/platformSchema/authentication';
import type { FieldSchema } from 'app/developer-v3/platformSchema/field';
import type { RequestSchema } from 'app/developer-v3/platformSchema/request';
import { BundleType } from 'app/developer-v3/types/request';

const makeBundlize = (bundleType: BundleType) => (key: string) =>
  `{{bundle.${bundleType}.${key}}}`;
const bundlizeAuthData = makeBundlize(BundleType.authData);
const bundlizeInputData = makeBundlize(BundleType.inputData);

const getRequestMethod = (request, serviceType) => {
  const defaultMethod = serviceType === SERVICE_TYPE.creates ? 'POST' : 'GET';
  return request.method || defaultMethod;
};

// converts "api_key" to "X-API-KEY", and "api key" to "X-API-KEY"
const getHeaderKey = (fieldKey: string): string =>
  `X-${toUpper(kebabCase(fieldKey))}`;

const addFieldToRequest = (
  fields,
  newFields,
  request,
  bundlize,
  serviceType
) => {
  const newField = findUniqueFields(fields, newFields)[0];

  const requestMethod = getRequestMethod(request, serviceType);
  const requestKey = WRITE_METHODS.includes(requestMethod) ? 'body' : 'params';

  return update(
    [requestKey, newField.key, $set(bundlize(newField.key))],
    request
  );
};

const removeFieldFromRequest = (fields, newFields, request) => {
  const field = findUniqueFields(newFields, fields)[0];

  const newRequest = update(
    [
      ['body', field.key, $none],
      ['params', field.key, $none],
    ],
    request
  );
  if (isEmpty(newRequest.body)) {
    delete newRequest.body;
  }
  if (isEmpty(newRequest.params)) {
    delete newRequest.params;
  }

  return newRequest;
};

const updateFieldInRequest = (
  fields: FieldSchema[],
  newFields: FieldSchema[],
  request: RequestSchema,
  bundlize: (v: string) => string
) => {
  const [field] = differenceBy(fields, newFields, 'key');
  const newRequest = quickCopy(request);

  if (!field) {
    return newRequest;
  }

  const [newField] = differenceBy(newFields, fields, 'key');
  const fieldIndex = findIndex(fields, ['key', field.key]);
  const newFieldIndex = findIndex(newFields, ['key', newField.key]);
  const { key } = fields[fieldIndex];
  const { key: newKey } = newFields[newFieldIndex];
  const bundleField = bundlize(key);
  const newBundleField = bundlize(newKey);

  if (newRequest.headers) {
    delete newRequest.headers[getHeaderKey(key)];
    newRequest.headers[getHeaderKey(newKey)] = newBundleField;
  }

  if (newRequest.body) {
    delete newRequest.body[key];
    newRequest.body[newKey] = newBundleField;
  }

  if (newRequest.params) {
    delete newRequest.params[key];
    newRequest.params[newKey] = newBundleField;
  }

  if (newRequest.url) {
    newRequest.url = newRequest.url.replace(bundleField, newBundleField);
  }

  return newRequest;
};

const getFieldMutationType = (fields, newFields) => {
  if (fields.length > newFields.length) {
    return MUTATION_TYPES.remove;
  }

  if (fields.length < newFields.length) {
    return MUTATION_TYPES.add;
  }

  return MUTATION_TYPES.update;
};

const mutateFieldsInRequest = (
  bundlize: (v: string) => string,
  fields: FieldSchema[] = [],
  newFields: FieldSchema[] = [],
  request: RequestSchema,
  serviceType?: ServiceType
): RequestSchema => {
  const newRequest = quickCopy(request);

  if (request.source) {
    return newRequest;
  }

  const mutationType = getFieldMutationType(fields, newFields);
  return mutationType === MUTATION_TYPES.add
    ? addFieldToRequest(fields, newFields, newRequest, bundlize, serviceType)
    : mutationType === MUTATION_TYPES.update
    ? updateFieldInRequest(fields, newFields, newRequest, bundlize)
    : removeFieldFromRequest(fields, newFields, newRequest);
};

const mutateAuthFieldsInRequest = partial(
  mutateFieldsInRequest,
  bundlizeAuthData
);
const mutateInputFieldsInRequest = partial(
  mutateFieldsInRequest,
  bundlizeInputData
);

// Mutate the headers to include or remove the default OAuth2 Authorization Bearer header.
const mutateDefaultOAuth2Header = (
  request: RequestSchema,
  shouldDelete?: boolean
): RequestSchema => {
  const newRequest = quickCopy(request);
  if (shouldDelete) {
    delete newRequest?.headers?.Authorization;
  } else {
    newRequest.headers = {
      ...newRequest.headers,
      Authorization: `Bearer {{bundle.authData.access_token}}`,
    };
  }
  return newRequest;
};

/**
 * When an auth type is configured, this function sprinkles a bit of magic
 * to the newly added fields. This includes:
 * 1. Adding the new field to the request template, with the value
 *   bundlized with the auth data.
 * 2. Adding the Authorization header for OAuth2.
 * 3. Refreshing the values of the headers and params when new fields are updated.

 */
const mutateRequestTemplate = (
  requestTemplate: RequestSchema = {},
  authType: AuthenticationType,
  fields: Array<FieldSchema> = [],
  newFields: Array<FieldSchema> = [],
  shouldDeleteDefaultOAuth2Header = false
) => {
  const requestTemplateWithFields = mutateAuthFieldsInRequest(
    fields,
    newFields,
    requestTemplate
  );

  const newRequestTemplate =
    authType === 'oauth2'
      ? mutateDefaultOAuth2Header(
          requestTemplateWithFields,
          shouldDeleteDefaultOAuth2Header
        )
      : requestTemplateWithFields;

  // We refresh the values when new fields are updated.
  newRequestTemplate.headers = omit(
    newRequestTemplate.headers,
    map(fields, ({ key }) => getHeaderKey(key))
  );
  newFields.forEach(({ key }) => {
    newRequestTemplate.headers = {
      ...newRequestTemplate.headers,
      [getHeaderKey(key)]: bundlizeAuthData(key),
    };
  });
  if (isEmpty(newRequestTemplate.headers)) {
    delete newRequestTemplate.headers;
  }

  // Similarly, we refresh the params when new fields are updated.
  if (authType !== 'oauth2') {
    newRequestTemplate.params = omit(
      newRequestTemplate.params,
      map(fields, 'key')
    );
    newFields.forEach(({ key }) => {
      newRequestTemplate.params = {
        ...newRequestTemplate.params,
        [key]: bundlizeAuthData(key),
      };
    });
    if (isEmpty(newRequestTemplate.params)) {
      delete newRequestTemplate.params;
    }
  }

  return newRequestTemplate;
};

// Sets the new fields in authentication.fields.
const mutateFieldsInAuthentication = (
  authentication: AuthenticationSchema,
  newFields: Array<FieldSchema> = []
): AuthenticationSchema => {
  const newAuthentication = update(
    [['fields', $set(newFields)]],
    authentication
  );
  return newAuthentication;
};

/**
 * This is used to retain backwards compatibility with old requests.
 * When a field is removed, we should check the authentication.test
 * definition as it may be using the field.
 **/
const removeAuthFieldsInTestRequest = (
  authTestDefinition: RequestSchema = {},
  requestTemplate: RequestSchema,
  fields: Array<FieldSchema> = [],
  newFields: Array<FieldSchema> = []
): RequestSchema => {
  if (
    isEmpty(authTestDefinition) ||
    get(authTestDefinition, 'source') ||
    get(authTestDefinition, 'require')
  ) {
    return authTestDefinition;
  }

  const testDefinition = quickCopy(authTestDefinition);
  // remove the field from the test definition
  const removedField = findUniqueFields(newFields, fields)[0];
  if ('headers' in testDefinition) {
    const newHeaders = omit(
      testDefinition.headers,
      getHeaderKey(removedField.key)
    );
    testDefinition.headers = newHeaders;
  }
  const newTest = removeFieldFromRequest(fields, newFields, testDefinition);

  const commonFields = field =>
    intersection(
      Object.keys(requestTemplate?.[field] ?? {}),
      Object.keys(newTest?.[field] ?? {})
    );

  // check request template fields, if the field exists there,
  // we should remove it from the test definition.
  const commonHeaders = commonFields('headers');
  const commonParams = commonFields('params');
  const commonBody = commonFields('body');
  commonHeaders.forEach(field => delete newTest.headers[field]);
  commonParams.forEach(field => delete newTest.params[field]);
  commonBody.forEach(field => delete newTest.body[field]);

  return newTest;
};

// Include in request's headers/params.
const includeAuthFieldsInRequest = (
  authType: AuthenticationType,
  fields: FieldSchema[],
  request: RequestSchema
): RequestSchema => {
  if (request.source || request.require) {
    return request;
  }

  const newRequest = quickCopy(request);

  if (authType === 'oauth2') {
    newRequest.headers = {
      ...newRequest.headers,
      Authorization: `Bearer {{bundle.authData.access_token}}`,
    };
  }

  if (fields.length === 0) {
    return newRequest;
  }

  fields.forEach(({ key }) => {
    const headerKey = getHeaderKey(key);
    newRequest.headers = {
      ...newRequest.headers,
      [headerKey]: bundlizeAuthData(key),
    };
  });

  if (authType !== 'oauth2') {
    fields.forEach(({ key }) => {
      newRequest.params = {
        ...newRequest.params,
        [key]: bundlizeAuthData(key),
      };
    });
  }

  return newRequest;
};

// Include in body and/or params.
const includeInputFieldsInOperation = (
  newService: AppService,
  fields: Array<FieldSchema> = [],
  serviceType?: ServiceType
): AppService => {
  const newPerform = mutateInputFieldsInRequest(
    fields,
    newService.operation.inputFields,
    newService.operation.perform,
    serviceType
  );
  return update(['operation', 'perform', $set(newPerform)], newService);
};

/**
 * Checks if the status code matches 500 or if it's a network error
 *
 * We check for the network error because if it's a failure where the browser doesn't make the request
 * (like Chrome and CORS preflights), we'll always return a custom NetworkError.
 * In that case, maybe should check for the name of the error if there's no statusCode
 * @param {Number} statusCode
 * @param {Array} errorName
 * @returns {Boolean}
 */
const isAPIDown = (
  statusCode: number = 0,
  statusMessages: Array<string> = []
) => statusCode >= 500 || statusMessages.includes('NetworkError');

export {
  bundlizeAuthData,
  bundlizeInputData,
  getHeaderKey,
  includeAuthFieldsInRequest,
  includeInputFieldsInOperation,
  isAPIDown,
  mutateDefaultOAuth2Header,
  mutateFieldsInAuthentication,
  removeAuthFieldsInTestRequest,
  mutateRequestTemplate,
};
