import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import useQueryImplementation from './useQueryImplementation';

type Args = {
  integrationId: number;
  mode?: 'activate' | 'promote';
  version: string;
};

const QUERY_KEY_PREFIX = 'query-integration-implementation-check';
const getQueryKey = (args: Args) =>
  `${QUERY_KEY_PREFIX}-${args.integrationId}-${args.version}-${args.mode}`;

type Results = {
  results: {
    type: 'read' | 'write' | 'search' | 'auth' | 'meta';
    violations: {
      type: string;
      results: {
        deepLink: string;
        message: string;
        tag: string;
      }[];
    }[];
  }[];
  totalFailures: number;
};

export type Check = {
  activationTasks: Results;
  errors: Results;
  hasFailures: boolean;
  hasActivationTasks: boolean;
  hasErrors: boolean;
  hasWarnings: boolean;
  isPassing: boolean;
  passes: { tag: string }[];
  totalChecks: number;
  totalFailures: number;
  warnings: Results;
};

function formatResults(results: any): Results {
  return {
    results: results.results.map((result: any) => ({
      type: result.type,
      violations: result.violations.map((violation: any) => ({
        type: violation.type,
        results: violation.results.map(({ deep_link, message, tag }) => ({
          deepLink: deep_link,
          message,
          tag,
        })),
      })),
    })),
    totalFailures: results.total_failures,
  };
}

function formatResponseBody(body: any): Check {
  const totalFailures = body?.total_failures ?? 0;

  const passes = body?.passes ?? [];

  const activationTasks = formatResults(body?.warnings ?? []);
  const errors = formatResults(body?.errors ?? []);
  const warnings = formatResults(body?.suggestions ?? []);

  return {
    activationTasks,
    errors,
    hasFailures: totalFailures > 0,
    hasActivationTasks: activationTasks.totalFailures > 0,
    hasErrors: errors.totalFailures > 0,
    hasWarnings: warnings.totalFailures > 0,
    isPassing: totalFailures === 0,
    passes: body?.passes,
    totalChecks: totalFailures + passes.length,
    totalFailures,
    warnings,
  };
}

/*
 * Validate an integration version/implementation.
 *
 * @param {Object} args - arguments of the query
 * @param {Number} args.integratoinId - id of an integration
 * @param {'activate' | 'promote'} [args.mode] - mode to validate an integration check.
 *  'activate' for the purpose of submitting a publishing request
 *  'promote' for the purpose of promoting an integration version,
 *  undefined, or left off, for a regular verfication.
 * @param {String} args.version - version of an integration
 * @param {UseQueryOptions} options - config options of the query
 */
function useQueryImplementationCheck(
  args: Args,
  options?: UseQueryOptions<Check, Error>
) {
  const { integrationId, mode, version } = args;
  const notify = useNotify();

  const queryImplementation = useQueryImplementation({
    integrationId,
    version,
  });

  const queryKey = getQueryKey({ integrationId, mode, version });
  const url = new URL(`api/platform/cli/check`, ZAPIER_APP_BASE_URL);
  if (mode) {
    url.search = new URLSearchParams({ for: mode }).toString();
  }

  const query = useQuery({
    queryKey,
    queryFn: async () =>
      formatResponseBody(
        await fetchJson(url.href, {
          body: JSON.stringify({
            app_definition: queryImplementation.data.definition,
            app_id: integrationId,
            version,
          }),
          method: 'POST',
        })
      ),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    enabled: !queryImplementation.isLoading,
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryImplementationCheck;
export { getQueryKey, QUERY_KEY_PREFIX };
