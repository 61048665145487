// @flow

import { connect } from 'react-redux';

import CatchError from 'app/common/components/CatchError';
import { ErrorActions } from '@zapier/toolbox-redux-framework';

import type { CatchErrorProps } from 'app/common/components/CatchError';
import type { Dispatch } from 'app/common/types';

// Why is this necessary, and why do they have to be optional?
type DispatchProps = {
  onError?: $PropertyType<CatchErrorProps, 'onError'>,
};

export default connect(
  () => ({}),
  (dispatch: Dispatch): DispatchProps => ({
    onError: (error, { isTryingAgain }) => {
      // If this is a retry, let's not send that to Sentry.
      if (!isTryingAgain) {
        dispatch(ErrorActions.addError(error, { shouldGlobalizeError: false }));
      }
    },
  })
)(CatchError);
