// @flow

import { toComponentLifecycleMeasure } from 'app/perf-utils/parsing';

import type { PerformanceLinterState } from 'app/perf-utils/state';
import type { PerformanceMeasure } from 'app/perf-utils/types';

export const createPerformanceObserver = (state: PerformanceLinterState) => {
  if (window.PerformanceObserver) {
    const listener = list => {
      const { measurements } = state;
      const entries: PerformanceMeasure[] = list.getEntries();

      entries.forEach(entry => {
        if (entry.duration > 0) {
          const lifecycleMeasure = toComponentLifecycleMeasure(entry);

          measurements.record(
            lifecycleMeasure.name,
            entry.duration,
            lifecycleMeasure.phase
          );
        }
      });
    };

    const observer = new window.PerformanceObserver(listener);
    observer.observe({ entryTypes: ['measure'] });
  } else {
    console.warn(
      "Unable to install the performance monitor. This browser doesn't support the PerformanceMonitor API"
    );
  }
};
