import ReactDOM from 'react-dom';
import thunkMiddleware from 'redux-thunk';
import { createRouterMiddleware } from 'redux-router-kit';

import accountSwitcherMiddleware from 'app/common/middlewares/accountSwitcherMiddleware';
import appCuesMiddleware from 'app/common/middlewares/appCuesMiddleware';
import intercomMiddleware from 'app/common/middlewares/intercomMiddleware';
import arrayMiddleware from 'app/common/middlewares/arrayMiddleware';
import autoRouteMiddleware from 'app/common/middlewares/autoRouteMiddleware';
import clearAddThisMiddleware from 'app/common/middlewares/clearAddThisMiddleware';
import createAuthenticationMiddleware from 'app/common/middlewares/authenticationMiddleware';
import createAutoPromiseMiddleware from 'app/common/middlewares/autoPromiseMiddleware';
import createScrollToTopMiddleware from 'app/common/middlewares/scrollToTopMiddleware';
import entityNotificationMiddleware from 'app/common/middlewares/entityNotificationMiddleware';
import fetchMiddleware from 'app/common/middlewares/fetchMiddleware';
import googleTagManagerMiddleware from 'app/common/middlewares/googleTagManagerMiddleware';
import legacyErrorMiddleware from 'app/common/middlewares/legacyErrorMiddleware';
import localStorageEnforcementMiddleware from 'app/common/middlewares/localStorageEnforcementMiddleware';
import localStorageMiddleware from 'app/common/middlewares/localStorageMiddleware';
import loggerMiddleware from 'app/common/middlewares/loggerMiddleware';
import openExternalBlogLinksNewTabMiddleware from 'app/common/middlewares/openExternalBlogLinksNewTabMiddleware';
import safeRedirectMiddleware from 'app/common/middlewares/safeRedirectMiddleware';
import sentryErrorsMiddleware from 'app/common/middlewares/sentryErrorsMiddleware';
import thunkPromiseMiddleware from 'app/common/middlewares/thunkPromiseMiddleware';
import timeToMountTrackingMiddleware from 'app/perf-utils/middleware/timeToMountTrackingMiddleware';
import trackMiddleware from 'app/common/middlewares/trackMiddleware';
import validateActionMiddleware from 'app/common/middlewares/validateActionMiddleware';
import willPromiseMiddleware from 'app/common/middlewares/willPromiseMiddleware';

export default function buildMiddleware(routes) {
  // Create route based-middleware
  const routerMiddleware = createRouterMiddleware({
    routes,
    batchedUpdates: ReactDOM.unstable_batchedUpdates,
  });
  const autoPromiseMiddleware = createAutoPromiseMiddleware({ routes });
  const authMiddleware = createAuthenticationMiddleware({ routes });
  const scrollToTopMiddleware = createScrollToTopMiddleware({ routes });

  const allMiddleware = [
    timeToMountTrackingMiddleware,
    arrayMiddleware,
    thunkPromiseMiddleware,
    thunkMiddleware,
    autoRouteMiddleware,
    legacyErrorMiddleware,
    // This *must* precede `autoPromiseMiddleware` because it
    // creates a `promise` key.
    willPromiseMiddleware,
    autoPromiseMiddleware,
    sentryErrorsMiddleware,
    validateActionMiddleware,
    routerMiddleware,
    localStorageEnforcementMiddleware,
    accountSwitcherMiddleware,
    authMiddleware,
    appCuesMiddleware,
    intercomMiddleware,
    googleTagManagerMiddleware,
    safeRedirectMiddleware,
    trackMiddleware,
    loggerMiddleware,
    scrollToTopMiddleware,
    localStorageMiddleware,
    fetchMiddleware,
    clearAddThisMiddleware,
    openExternalBlogLinksNewTabMiddleware,
    entityNotificationMiddleware,
  ];

  return {
    allMiddleware,
    authMiddleware,
    routerMiddleware,
    scrollToTopMiddleware,
  };
}
