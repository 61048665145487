export enum BASE_SCHEMA_TYPES {
  creates = 'creates',
  searches = 'searches',
  triggers = 'triggers',
}

export enum SERVICE_TYPE {
  creates = 'creates',
  searches = 'searches',
  triggers = 'triggers',
  actions = 'actions',
  searchOrCreates = 'searchOrCreates',
  authentication = 'authentication',
}
