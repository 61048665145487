import useQueryCustomActions from './useQueryCustomActions';
import useMakeCustomActionPublic from './useMakeCustomActionPublic';

const useCustomActions = (selectedApi: string) => {
  const {
    customActions,
    isLoading: isLoadingCustomActions,
  } = useQueryCustomActions(selectedApi);

  const {
    isMutating: isMakingCustomActionPublic,
    mutateAsync: makeCustomActionPublic,
  } = useMakeCustomActionPublic();

  return {
    customActions,
    isLoadingCustomActions,
    isMakingCustomActionPublic,
    makeCustomActionPublic,
  };
};

export default useCustomActions;
