import { useMutation, useQueryClient } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import useNotify from './useNotify';
import { getCSRFToken } from './useCSRFToken';
import { getQueryKey } from './useQueryAdmins';

type Args = {
  causationId: string;
  correlationId: string;
  email: string;
  integrationId: string;
  memberId: string;
};
type Return = Record<string, any>;

const MUTATION_KEY = 'delete-admin';

function useDeleteAdmin() {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mutationKey = MUTATION_KEY;

  const mutation = useMutation<Return, Error, Args>({
    mutationKey,
    async mutationFn({
      causationId: causation_id,
      correlationId: correlation_id,
      memberId,
      integrationId,
    }) {
      return await fetchJson(
        `${ZAPIER_APP_BASE_URL}/api/platform/cli/apps/${integrationId}/collaborators/${memberId}`,
        {
          body: JSON.stringify({ causation_id, correlation_id }),
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': await getCSRFToken(),
          },
          method: 'DELETE',
        }
      );
    },
    onError(_err, { email }) {
      notify.failure(`Failed to remove ${email} from your team.`);
    },
    onSuccess(_data, { email, integrationId }) {
      queryClient.invalidateQueries(getQueryKey(integrationId));
      notify.success(`${email} has been removed from your team.`);
    },
  });

  const { isLoading } = mutation;

  return { isMutating: isLoading, mutate: mutation.mutateAsync, mutationKey };
}

export default useDeleteAdmin;
