export const DEV_BREAK_POINTS = {
  default: '756px',
  inputTable: '512px',
};
export const ALL_VERSIONS_TEXT = 'All';
export const MUTATION_TYPES = {
  add: 'add',
  remove: 'remove',
  update: 'update',
};
export const rootKey = 'developerV3';

// Matches KEY_APPEND in developer_cli/constants.py.
export const KEY_SUFFIX = 'CLIAPI';

// Derived from the back-end.
export enum FORM_TYPES {
  addInvitee = 'addInviteeCli',
  editApp = 'metaCli',
  newApp = 'create',
}
