// @flow

import React from 'react';

import { ButtonGroup } from '@zapier/design-system';

import LinkButton from 'app/common/components/LinkButton';
import Modal from 'app/common/components/Modal';
import InfoBlock, {
  InfoBlockDescription,
  InfoBlockStaticImage,
  InfoBlockTitle,
} from 'app/common/components/InfoBlock';

type DowngradedModalProps = {
  ownerName: string,
  planName: string,
  profileId: number,
  trackingKey: string,
  onClose: (trackingKey: string, profileId: number) => void,
};

const DowngradedModal = (props: DowngradedModalProps) => {
  const onClose = () => props.onClose(props.trackingKey, props.profileId);
  return (
    <Modal
      closeOnEsc={false}
      closeOnOutsideClick={false}
      contentClassName=""
      isOpened={true}
      kind="narrow"
      onClose={onClose}
    >
      <InfoBlock kind="bare centered">
        <InfoBlockStaticImage
          alt="Your account has been downgraded"
          src="images/zaplings/folder-wave.svg"
        />
        <InfoBlockTitle>Your Teams Plan Was Paused</InfoBlockTitle>
        <InfoBlockDescription>
          Your team owner, {props.ownerName}, paused your team’s plan. Don’t
          worry: you can still use Zapier on your {props.planName} plan—with a
          few changes.
        </InfoBlockDescription>
        <ButtonGroup columnSize="minmax(100px, max-content)" justify="center">
          <LinkButton
            color="secondary"
            href="/help/account/teams/cancel-your-zapier-for-teams-plan"
            onClick={onClose}
            size="large"
          >
            Got it!
          </LinkButton>
          <LinkButton
            href="/help/account/teams/cancel-your-zapier-for-teams-plan"
            size="large"
          >
            See What Changed
          </LinkButton>
        </ButtonGroup>
      </InfoBlock>
    </Modal>
  );
};

export default DowngradedModal;
