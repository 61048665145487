import _ from 'lodash';
import { ROUTE_TO_NEXT, ROUTE_TO_WINDOW } from 'redux-router-kit';

import { RouterSelectors } from '@zapier/toolbox-redux-framework';
import { isSubUrlOf } from '@zapier/url-utils';

// Routes that should open external links in a new tab
const acceptableUrlPredicates = [
  currentUrl => isSubUrlOf(currentUrl, '/blog/'),
  currentUrl => isSubUrlOf(currentUrl, '/learn/'),
];

// This middleware is used to open external links in a new tab.
const openExternalBlogLinksNewTabMiddleware = ({
  dispatch,
  getState,
}) => next => action => {
  if (action && action.type && action.type === ROUTE_TO_NEXT) {
    const state = getState();
    const nextHref = _.get(action, ['meta', 'location', 'href']);
    const currentLocation = RouterSelectors.getCurrentPathname(state);
    const currentOrigin = RouterSelectors.getCurrentOrigin(state);

    const shouldOpenExternalLinkInNewTab = acceptableUrlPredicates.some(
      predicate => predicate(currentLocation)
    );

    const isSameOrigin = nextHref && _.startsWith(nextHref, currentOrigin);

    if (!isSameOrigin && shouldOpenExternalLinkInNewTab) {
      return dispatch({
        ...action,
        type: ROUTE_TO_WINDOW,
      });
    }
  }

  return next(action);
};

export default openExternalBlogLinksNewTabMiddleware;
