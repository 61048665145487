import { partial } from 'lodash';

import { stripTrailingSlash } from '@zapier/url-utils';
import {
  buildEntityActions,
  buildEntityApi,
  buildEntitySelectors,
} from '@zapier/toolbox-redux-framework';

import { currentAppId, currentAppVersion } from 'app/developer-v3/selectors';
import type {
  IApi,
  ISelectors,
  IActions,
  EnvironmentVariableEntity,
} from './types';

export const type = 'environmentVariable';

export const api: IApi = buildEntityApi(type, (_path, getState) => {
  const state = getState();
  const appId = currentAppId(state);
  const appVersion = currentAppVersion(state);
  return stripTrailingSlash(
    `/api/platform/cli/apps/${appId}/versions/${appVersion}/environment`
  );
});

export const selectors: ISelectors = buildEntitySelectors(
  type,
  (sel: ISelectors) => {
    sel.appId = partial(sel.dataItem, 'app_id');
    sel.appVersion = partial(sel.dataItem, 'app_version');
    sel.key = partial(sel.dataItem, 'key');
    sel.value = partial(sel.dataItem, 'value');

    const hasAppIdAndVersion = (
      appId: ID,
      version: string,
      entity: EnvironmentVariableEntity
    ) => {
      return (
        sel.appId(entity) === Number(appId) &&
        sel.appVersion(entity) === version
      );
    };

    sel.all.environmentVariablesForVersion = partial(
      sel.all.filterBy,
      hasAppIdAndVersion
    );

    const hasAppIdAndVersionAndKey = (appId, version, key, entity) => {
      return (
        sel.appId(entity) === Number(appId) &&
        sel.appVersion(entity) === version &&
        sel.key(entity) === key
      );
    };

    sel.all.entityForKeyAndVersion = partial(
      sel.all.findBy,
      hasAppIdAndVersionAndKey
    );
  }
);

export const actions: IActions = buildEntityActions(
  type,
  selectors,
  api,
  (environmentVariableActions: IActions) => {
    environmentVariableActions.updateValue = (entityId, newValue) => {
      return async dispatch => {
        dispatch(actions.updateEntityDataItem('value', newValue, entityId));
        return await dispatch(
          actions.saveEntity(
            { failure: 'There was a problem saving the environment variable' },
            entityId
          )
        );
      };
    };
  }
);

export const constants = {
  OAUTH_CLIENT_ID: 'CLIENT_ID',
  OAUTH_CLIENT_SECRET: 'CLIENT_SECRET',
};
