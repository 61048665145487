import useQueryIssue from './useQueryIssue';
import useMutationIssue from './useMutationIssue';
import useMutationComment from './useMutationComment';
import { Status, WorkStatus, ResolutionStatus } from './constants';
import { getStatusHelpers } from './utils';

function useIssue(args: { integrationId: number; issueId: number }) {
  const query = useQueryIssue(args);
  const mutationComment = useMutationComment(args);
  const mutationIssue = useMutationIssue(args);

  return {
    comment: {
      save: ({ body, commentId }: { body?: string; commentId?: number }) =>
        mutationComment.mutateAsync({ body, commentId, isPublic: true }),
      isSaving: mutationComment.isLoading,
    },
    isLoading: query.isLoading,
    isMutatingIssue: mutationIssue.isLoading,
    issue: query.data,
    toggleStatus: (status: Status, resolutionStatus: ResolutionStatus = null) =>
      mutationIssue.mutateAsync({
        status: getStatusHelpers(status).isOpen ? Status.resolved : Status.open,
        resolutionStatus,
      }),
    updateWorkStatus: (workStatus: WorkStatus) => {
      mutationIssue.mutateAsync({ workStatus });
    },
  };
}

export default useIssue;
