import { useEffect, useState } from 'react';

import { getUrls } from '../utils/urls';

export default function useUrls() {
  const [host, setHost] = useState('');
  useEffect(() => {
    setHost(window.location.host);
  }, []);

  return {
    host,
    urls: getUrls(host),
  };
}
