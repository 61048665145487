import { useQuery } from 'react-query';
import useNotify from './useNotify';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

const QUERY_KEY_PREFIX = 'summary-activity-data';
const getQueryKey = () => QUERY_KEY_PREFIX;

export type SummaryIntegrationActivity = {
  outgest_id: string;
  outgest_run_date_time: string; // isoString
  app_integration_name: string;
  app_integration_id: number;
  new_user_last_7days: number;
  new_user_last_30days: number;
  new_user_last_90days: number;
  active_zaps_last_7days: number;
  active_zaps_last_30days: number;
  active_zaps_last_90days: number;
};

type Result = { integration_activity: SummaryIntegrationActivity[] };

function useQueryInsightsSummaryActivityData(integrationId: string) {
  const notify = useNotify();
  const queryKey = getQueryKey();

  const { data, error, ...rest } = useQuery(
    queryKey,
    (): Promise<Result> =>
      fetch(
        `${ZAPIER_APP_BASE_URL}/developer/insights/v1/${integrationId}/summary-activity`,
        {
          credentials: 'include',
        }
      ).then(res => res.json())
  );

  if (error) {
    console.error(error);
    notify.failure('Failed to fetch insights summary data.');
  }

  return { data: data?.integration_activity ?? [], error, ...rest };
}

export default useQueryInsightsSummaryActivityData;
