export enum RequestMethodTypes {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
}

export enum BundleType {
  inputData = 'inputData',
  authData = 'authData',
}
