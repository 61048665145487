export enum ACTION_TYPES {
  creates = 'creates',
  searches = 'searches',
}
export const APP_ACTIONS: Array<string> = Object.keys(ACTION_TYPES);

export enum ACTION_INPUT_TYPES {
  INPUT = 'default',
  DYNAMIC = 'dynamic',
  GROUP = 'group',
}

export const ACTION_INPUT_TYPE_TEXTS = Object.freeze({
  [ACTION_INPUT_TYPES.INPUT]: 'Input Field',
  [ACTION_INPUT_TYPES.DYNAMIC]: 'Dynamic Field',
  [ACTION_INPUT_TYPES.GROUP]: 'Line Item Group',
});
export const ACTION_INPUT_ROUTES = Object.freeze({
  [ACTION_INPUT_TYPES.INPUT]: '',
  [ACTION_INPUT_TYPES.DYNAMIC]: 'dynamic',
  [ACTION_INPUT_TYPES.GROUP]: 'line-item',
});
