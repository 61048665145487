import { TimePeriodDays } from 'app/developer-v3/hooks/useIntegrationInsights';
import { MonthlyActiveUsers } from 'app/developer-v3/hooks/useQueryInsightsMonthlyActiveUsers';

export type ValuesOf<T> = T[keyof T];

export const TimePeriod = {
  Last_Seven_days: 'Last 7 days',
  Last_Thirty_days: 'Last 30 days',
  Last_Ninety_days: 'Last 90 days',
} as const;
export type TimePeriod = ValuesOf<typeof TimePeriod>;

type NumberItem = {
  label: string;
  value: number;
};

export const defaultSelectedValuePeriodDays = TimePeriodDays.Last_Thirty_days;

export const defaultInitialSelectedItem = {
  label: TimePeriod.Last_Thirty_days,
  value: defaultSelectedValuePeriodDays,
};

export function generateYearsDropdownOptions(
  monthlyActiveUsers: MonthlyActiveUsers[]
): NumberItem[] {
  const seenYears: Record<number, boolean> = {};
  const dropDownYearOptions: NumberItem[] = [];

  // include the current year by default, if there is no data show the no results state
  const currentYear = new Date().getFullYear();
  seenYears[currentYear] = true;
  dropDownYearOptions.push({
    label: currentYear.toString(),
    value: currentYear,
  });

  for (const { activity_month_end_date } of monthlyActiveUsers) {
    const year = new Date(activity_month_end_date).getFullYear();
    if (!seenYears[year]) {
      seenYears[year] = true;
      dropDownYearOptions.push({
        label: year.toString(),
        value: year,
      });
    }
  }

  // sort options in descending order by year
  dropDownYearOptions.sort((a, b) => b.value - a.value);

  return dropDownYearOptions;
}

export const timePeriodDaysItems = [
  {
    label: TimePeriod.Last_Seven_days,
    value: TimePeriodDays.Last_Seven_days,
  },
  {
    label: TimePeriod.Last_Thirty_days,
    value: TimePeriodDays.Last_Thirty_days,
  },
  {
    label: TimePeriod.Last_Ninety_days,
    value: TimePeriodDays.Last_Ninety_days,
  },
];

export async function sleep(duration: number): Promise<void> {
  await new Promise(resolve => {
    setTimeout(resolve, duration);
  });
}

const div = document.createElement('div');
export function escapeHTML(text: string): string {
  div.textContent = text;
  return div.innerHTML;
}

export const PartnerInteractionTypeHumanized = {
  all: 'All Embeds',
  app_directory_element: 'App Directory Element',
  custom_embed: 'Custom Embed',
  full_zapier_experience: 'Full Zapier Experience',
  partner_api: 'Partner API',
  zap_template_element: 'Zap Templates Element',
} as const;

export const PartnerInteractionType = {
  all: 'all',
  app_directory_element: 'app_directory_element',
  custom_embed: 'custom_embed',
  full_zapier_experience: 'full_zapier_experience',
  partner_api: 'partner_api',
  zap_template_element: 'zap_template_element',
} as const;
export type PartnerInteractionType = ValuesOf<typeof PartnerInteractionType>;

export const partnerEmbedTypeItems = [
  {
    label: PartnerInteractionTypeHumanized.all,
    value: PartnerInteractionType.all,
  },
  {
    label: PartnerInteractionTypeHumanized.app_directory_element,
    value: PartnerInteractionType.app_directory_element,
  },
  {
    label: PartnerInteractionTypeHumanized.custom_embed,
    value: PartnerInteractionType.custom_embed,
  },
  {
    label: PartnerInteractionTypeHumanized.full_zapier_experience,
    value: PartnerInteractionType.full_zapier_experience,
  },
  {
    label: PartnerInteractionTypeHumanized.partner_api,
    value: PartnerInteractionType.partner_api,
  },
  {
    label: PartnerInteractionTypeHumanized.zap_template_element,
    value: PartnerInteractionType.zap_template_element,
  },
] as const;

export function convertToPercentage(value: number): number {
  const percentage = value * 100;
  return Number.isInteger(percentage)
    ? percentage
    : Number(percentage.toFixed(1));
}
