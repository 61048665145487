// @flow

import TrackActions from 'app/track/TrackActions';

import type { Dispatch } from 'app/common/types';
import type { TimeToMountData } from 'app/perf-utils/TimeToMount';

// Track Time To Mount data - could be FCP, FMP, TTI, etc...
export const trackTimeToMount = (data: TimeToMountData) => (
  dispatch: Dispatch
) => dispatch(TrackActions.track('performance: mount', data));
