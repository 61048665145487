import type { FieldSchema } from 'app/developer-v3/platformSchema/field';
import type { RequestSchema } from 'app/developer-v3/platformSchema/request';

export enum AuthenticationType {
  none = 'none',
  basic = 'basic',
  session = 'session',
  custom = 'custom',
  oauth1 = 'oauth1',
  oauth2 = 'oauth2',
  digest = 'digest',
}

export type AuthenticationSchema = {
  type?: AuthenticationType;
  test?: RequestSchema;
  basicConfig?: Record<string, any>;
  customConfig?: Record<string, any>;
  digestConfig?: Record<string, any>;
  oauth1Config?: Record<string, any>;
  oauth2Config?: Record<string, any>;
  sessionConfig?: Record<string, any>;
  connectionLabel?: string | { source: string };
  fields?: FieldSchema[];
};
