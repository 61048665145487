import { Dispatch, createContext } from 'react';

type Context = {
  correlationIds: Record<string, string>;
  updateCorrelationIds: Dispatch<Partial<Record<string, string>>>;
};

const defaultValues: Context = {
  correlationIds: {},
  updateCorrelationIds: () => {},
};

const AvroEventContext = createContext<Context>(defaultValues);

export default AvroEventContext;
