export enum APP_STATUSES_NAME {
  private = 'private',
  pending = 'pending',
  beta = 'beta',
  public = 'public',
  inviteOnly = 'inviteOnly',
}

export enum APP_STATUSES_DISPLAY_NAME {
  private = 'Private',
  pending = 'Pending',
  beta = 'Public',
  public = 'Public',
  inviteOnly = 'Invite-Only',
}

export const APP_STATUSES = Object.freeze({
  private: {
    name: APP_STATUSES_NAME.private,
    display: APP_STATUSES_DISPLAY_NAME.private,
    lifecycle: 'build',
    published: false,
  },
  pending: {
    name: APP_STATUSES_NAME.pending,
    display: APP_STATUSES_DISPLAY_NAME.pending,
    lifecycle: 'review',
    published: false,
  },
  beta: {
    name: APP_STATUSES_NAME.beta,
    display: APP_STATUSES_DISPLAY_NAME.beta,
    lifecycle: 'beta',
    published: true,
  },
  public: {
    name: APP_STATUSES_NAME.public,
    display: APP_STATUSES_DISPLAY_NAME.public,
    lifecycle: 'partnered',
    published: true,
  },
});

export enum INVITE_STATUSES {
  accepted = 'accepted',
  waiting = 'waiting',
}

export const APP_STATUS_INVITE_ONLY = {
  name: APP_STATUSES_NAME.inviteOnly,
  display: APP_STATUSES_DISPLAY_NAME.inviteOnly,
  published: false,
};

export const DEPLOYMENT_STATUSES = Object.freeze({
  production: 'production',
  private: 'non-production',
  deprecated: 'deprecated',
});

export enum VersionStatus {
  deprecated = 'deprecated',
  private = 'private',
  public = 'public',
}

/* Number of users where we make an app not editable */
export const USER_LIMIT_COUNT = 5;
