import React, { PropsWithChildren, useContext } from 'react';

import PartnerProfileContext from './PartnerProfileContext';
import useMutationPartnerProfile from './useMutationPartnerProfile';
import useQueryPartnerProfile from './useQueryPartnerProfile';

function Provider({ children }: PropsWithChildren<{}>) {
  const query = useQueryPartnerProfile();
  const mutation = useMutationPartnerProfile();

  return (
    <PartnerProfileContext.Provider
      value={{
        isLoading: query.isLoading || mutation.isLoading,
        partnerProfile: query.data,
        updatePartnerProfile: mutation.mutateAsync,
      }}
    >
      {children}
    </PartnerProfileContext.Provider>
  );
}

function usePartnerProfile() {
  return useContext(PartnerProfileContext);
}

export default Provider;
export { usePartnerProfile };
