import { isEmpty, mapValues } from 'lodash';
import { cleanRequest } from './cleanRequest';

import type { TriggerSchema } from 'app/developer-v3/platformSchema/trigger';

function cleanTrigger(trigger: TriggerSchema) {
  const { operation, ...rest } = trigger;
  const {
    perform,
    performList,
    performSubscribe,
    performUnsubscribe,
    ...restOperation
  } = operation;

  const hasPerform = !isEmpty(perform);
  const hasPerformList = !isEmpty(performList);
  const hasPerformSubscribe = !isEmpty(performSubscribe);
  const hasPerformUnsubscribe = !isEmpty(performUnsubscribe);

  return {
    ...rest,
    operation: {
      ...restOperation,
      ...(hasPerform ? { perform: cleanRequest(perform) } : {}),
      ...(hasPerformList ? { performList: cleanRequest(performList) } : {}),
      ...(hasPerformSubscribe
        ? { performSubscribe: cleanRequest(performSubscribe) }
        : {}),
      ...(hasPerformUnsubscribe
        ? { performUnsubscribe: cleanRequest(performUnsubscribe) }
        : {}),
    },
  };
}

function cleanTriggers(triggers: Record<string, TriggerSchema>) {
  return mapValues(triggers, cleanTrigger);
}

export { cleanTrigger, cleanTriggers };
