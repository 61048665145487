// @flow

import React from 'react';
import ReactDOM from 'react-dom';

import Report from 'app/perf-utils/reporter/DocumentReporter/Report';

import type { PerformanceLinterState } from 'app/perf-utils/state';

let theReporter = null;

const registerReporter = reporter => {
  theReporter = reporter;
};

const render = () => {
  const containerElement = document.createElement('div');
  containerElement.id = 'performance-nag-root';

  if (document.body) {
    document.body.append(containerElement);
  }

  const linterState: PerformanceLinterState = require('app/perf-utils/state')
    .default;

  const reportElement = (
    <Report linterState={linterState} registerReporter={registerReporter} />
  );
  ReactDOM.render(reportElement, containerElement);
  return reportElement;
};

render();

export default theReporter;
