import { unionBy } from 'lodash';

import { BASE_BUNDLE_META } from 'app/developer-v3/constants';

import { BundleInfo, TriggerBundle } from 'app/developer-v3/types/bundle';
import type { FieldSchema } from 'app/developer-v3/platformSchema/field';

const hookSetupMethods = ['performSubscribe', 'performUnsubscribe'];

const getMetaFields = (requestType: string): BundleInfo => {
  const zapIdMeta = hookSetupMethods.includes(requestType)
    ? [
        {
          key: ['zap', 'id'],
          help: '',
        },
      ]
    : [];

  return BASE_BUNDLE_META.concat(zapIdMeta);
};

const getTriggerBundle = (
  isPolling: boolean,
  requestType?: string
): TriggerBundle => {
  if (isPolling) {
    return {};
  }

  const manageHookBundle = hookSetupMethods.includes(requestType)
    ? {
        targetUrl: {
          help: '',
        },
      }
    : {};

  const unsubscribeBundle =
    requestType === 'performUnsubscribe'
      ? {
          subscribeData: {
            help: '',
          },
        }
      : {};

  return { ...manageHookBundle, ...unsubscribeBundle };
};

const createBundleItem = (
  bundleField: string,
  key?: string | string[]
): string => {
  if (key) {
    const keyPath = Array.isArray(key) ? key.join('.') : key;
    return `bundle.${bundleField}.${keyPath}`;
  }

  return `bundle.${bundleField}`;
};

const mergeFields = (
  userFields: FieldSchema[],
  baseFields: FieldSchema[]
): FieldSchema[] => unionBy(userFields, baseFields, field => field.key);

export { createBundleItem, getMetaFields, getTriggerBundle, mergeFields };
