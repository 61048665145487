// @flow

import z from 'app/common/z';

export const level = {
  get verbose() {
    try {
      const localStorageVerboseFlag = localStorage.getItem('Z_VERBOSE');
      return process.env.Z_VERBOSE || localStorageVerboseFlag;
    } catch (e) {
      console.warn('Unable to read verbose log level configuration');
      return false;
    }
  },
};

const log = (...args: mixed[]) => {
  // eslint-disable-next-line no-console
  if (z.cfg.debug && console && console.log) {
    console.log.apply(console, args); // eslint-disable-line no-console
  }
};

export default log;
