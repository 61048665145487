// @flow

import { Colors } from '@zapier/theme';

import type { PerformanceLintSeverity } from 'app/perf-utils/types';

type SeverityLabels = { [PerformanceLintSeverity]: string };

export const severityLabels: SeverityLabels = {
  critical: 'Critical',
  failure: 'Failures',
  warning: 'Warnings',
};

export const severityIcons: SeverityLabels = {
  critical: 'error',
  failure: 'alert',
  warning: 'alert',
};

export const severityColors: SeverityLabels = {
  critical: Colors.cyclops,
  failure: Colors.armor,
  warning: Colors.wolverine,
};

export default {
  explainSlowComponentOperation: (threshold: number) =>
    `A component operation is considered slow if it takes longer than the ${threshold} ms warning threshold. Learn more about this threshold https://github.com/zapier/frontend-guide/tree/master/performance#warning-threshold`,

  explainAvoidableRenders: () =>
    `Avoidable re-renders occur when a component's state and props are unchanged (either by reference or deep equality of values). Learn more https://github.com/zapier/frontend-guide/tree/master/performance#when-does-react-re-render`,

  explainAvoidableRerenderData: () => `Not all re-renders are avoidable.`,

  explainPerformanceNagMeta: (nagCost: number | string) =>
    `[META] This nag took ${nagCost} ms. Don't worry too much though; it was performed within requestIdleCallback. (And it won't happen in production). Why this is worth measuring? https://github.com/zapier/frontend-guide/tree/master/performance#observer-effect`,
};
