import { v4 as uuidv4 } from 'uuid';

import { EVENT_TYPE } from './constants';
import { emit } from './utils';

import type { EventAction } from './types';

const TYPE = EVENT_TYPE.PARTNER_USER_INTERACTION;

type PartnerUserInteractionEvent = {
  account_id: number;
  causation_id?: string;
  correlation_id: string;
  customuser_id?: number;
  event_action: EventAction;
  event_id: string;
  integration_id: number;
  interaction_goal: string;
  interaction_name: string;
  interaction_raw_text?: string;
  option_selected?: string;
  referer?: string;
  screen_size?: string;
  target_access_level?: string;
  target_customuser_id?: number;
  target_email_address?: string;
  timestamp_ms: number;
  url_address?: string;
  user_agent?: string;
};

function emitPartnerUserInteractionEvent(eventProperties: {
  accountId: number;
  causationId?: string;
  correlationId?: string;
  customuserId?: number;
  eventAction: EventAction;
  eventId?: string;
  integrationId: number;
  interactionGoal: string;
  interactionName: string;
  interactionRawText?: string;
  optionSelected?: string;
  targetAccessLevel?: string;
  targetCustomuserId?: number;
  targetEmailAddress?: string;
  urlAddress?: string;
}) {
  const properties: PartnerUserInteractionEvent = {
    account_id: eventProperties.accountId,
    correlation_id: eventProperties.correlationId ?? uuidv4(),
    event_action: eventProperties.eventAction,
    event_id: eventProperties.eventId ?? uuidv4(),
    integration_id: eventProperties.integrationId,
    interaction_goal: eventProperties.interactionGoal,
    interaction_name: eventProperties.interactionName,
    option_selected: eventProperties.optionSelected ?? 'not_applicable',
    referer: document.referrer,
    screen_size: `${screen.width}x${screen.height}`,
    timestamp_ms: Date.now(),
    user_agent: navigator.userAgent,
  };
  if (eventProperties.causationId) {
    properties.causation_id = eventProperties.causationId;
  }
  if (eventProperties.customuserId) {
    properties.customuser_id = eventProperties.customuserId;
  }
  if (eventProperties.interactionRawText) {
    properties.interaction_raw_text = eventProperties.interactionRawText;
  }
  if (eventProperties.optionSelected) {
    properties.option_selected = eventProperties.optionSelected;
  }
  if (eventProperties.targetAccessLevel) {
    properties.target_access_level = eventProperties.targetAccessLevel;
  }
  if (eventProperties.targetCustomuserId) {
    properties.target_customuser_id = eventProperties.targetCustomuserId;
  }
  if (eventProperties.targetEmailAddress) {
    properties.target_email_address = eventProperties.targetEmailAddress;
  }
  if (eventProperties.urlAddress) {
    properties.url_address = eventProperties.urlAddress;
  }

  emit(properties, TYPE);

  return properties;
}

export default emitPartnerUserInteractionEvent;
