import { Dispatch } from 'redux';
import { routeTo } from 'redux-router-kit';

import { useRedux } from '@zapier/toolbox-redux-framework';

type RouteToOptions = {
  event?: string;
  exit?: string;
  replace?: Boolean;
};

const mapStateToProps = (state: any) => ({
  href: state?.router?.current?.location?.href,
  params: state?.router?.current?.params,
  pathname: state?.router?.current?.location?.pathname,
  query: state?.router?.current?.query,
  queryPrevious: state?.router?.previous?.query,
  url: state?.router?.current?.url,
  urlPrevious: state?.router?.previous?.url,
  routeKey: state?.router?.current?.routeKey,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchRouteTo(href: string, options?: RouteToOptions) {
    return dispatch(routeTo(href, options));
  },
});

type ReturnValue = {
  href: string;
  params: {
    id?: string;
    issueId?: string;
    secret?: string;
    status?: string;
    version?: string;
  };
  pathname: string;
  query: Record<string, string>;
  queryPrevious: Record<string, string>;
  routeTo(href: string, options?: RouteToOptions): void;
  url: string;
  urlPrevious: string;
  routeKey: string[];
};

// TODO: Bump @zapier/toolbox-redux-framework in frontend/package.json to
// version 1.2.0 or newer for an useRouter hook out of the box in place of this
// hook. We're currently stuck at version 1.1.2, which is a version right before
// 1.2.0, because a lot of TS type errors are thrown with version 1.2.0 around
// the use of Entity mixed in app/entities and @zapier/toolbox-redux-framework.
// It's not worth fixing the errors since we will have to get rid of Entity
// entirely. The errors will go away once we are off Entity.
export default function useRouter(): ReturnValue {
  const {
    dispatchRouteTo,
    href,
    params,
    pathname,
    query,
    queryPrevious,
    url,
    urlPrevious,
    routeKey,
  } = useRedux(mapStateToProps, mapDispatchToProps);
  return {
    href,
    params,
    pathname,
    query,
    queryPrevious,
    routeTo: dispatchRouteTo,
    url,
    urlPrevious,
    routeKey,
  };
}
