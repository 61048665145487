// @flow weak

import { ROUTE_TO } from 'redux-router-kit';

const ENABLE_DISPATCH_PROFILING = false;

// Reset the performance linter upon routing to another page
const createMiddleware = resetLinter => (/*store*/) => next => action => {
  if (action.type === ROUTE_TO) {
    resetLinter();
  }

  if (ENABLE_DISPATCH_PROFILING && action.type) {
    const { type } = action;
    performance.mark(type + ':start');
    try {
      return next(action);
    } finally {
      performance.mark(type + ':stop');
      performance.measure(
        `✴️ Dispatch ${type}`,
        type + ':start',
        type + ':stop'
      );
    }
  }

  return next(action);
};

export default createMiddleware;
