/** @jsx jsx */

import { connect } from 'react-redux';
import { flatMap } from 'lodash';
import { useRef } from 'react';

import { Field, Typeahead } from '@zapier/design-system';
import { css, jsx } from '@emotion/core';

import { State } from 'app/common/types';
import { IssueItem as Issue, useQueryIssues } from 'app/developer-v3/hooks';
import {
  appActions,
  appTriggers,
  currentAppId,
} from 'app/developer-v3/selectors';

const styleRoot = css`
  position: relative;
  margin: 8px 0;

  [class*='FloatingMenu__list'][class][class][class][class][class] {
    max-height: 465px; // 465px is the height to show 10 items
  }
`;

type actionTypes = 'read' | 'search' | 'write';

const serviceTypesToTypeOf = {
  triggers: 'read',
  searches: 'search',
  creates: 'write',
} as const;

type CommonActionTrigger = {
  key: string;
  display: {
    label: string;
    hidden?: boolean;
  };
  serviceType: 'triggers' | 'searches' | 'creates';
};

export type FormatItems = {
  label: string;
  value: string;
  issue?: number;
  key?: string;
  type?: actionTypes;
};

interface TriggerActionAndOthersDropdownProps {
  label: string;
  appId: string;
  actions: CommonActionTrigger[];
  triggers: CommonActionTrigger[];
  onSelect: (item: FormatItems) => void;
  selectedItems: FormatItems[];
}

export const formatActionTriggerItems = (
  items: CommonActionTrigger[],
  label: string
): FormatItems[] => {
  return (
    items
      ?.map(item => ({
        label: `${label}: ${item.display.label}`,
        value: `${item.serviceType}_${item.key}`,
        key: item.key,
        type: serviceTypesToTypeOf[item.serviceType],
      }))
      ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
  );
};

const formatIssuesItems = (issues: Issue[], label: string): FormatItems[] => {
  return (
    issues
      ?.map(issue => ({
        issue: issue.id,
        label: `${label}: ${issue.title}`,
        value: `issue_${issue.id}`,
      }))
      ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
  );
};

const _TriggerActionAndOthersDropdown = ({
  appId,
  label,
  actions,
  triggers,
  onSelect,
  selectedItems,
}: TriggerActionAndOthersDropdownProps) => {
  const { data } = useQueryIssues({
    actions: [],
    integrationId: Number(appId),
    orderBy: 'priority',
    orderDirection: 'ASC',
    page: 1,
  });
  const { issues = [] } = data ?? {};

  const clearSelectionRef = useRef(() => {});

  const setClearSelection = f => (clearSelectionRef.current = f);

  const isBugLabel = label.endsWith('bug');

  const items = [
    ...formatActionTriggerItems(triggers, 'Trigger'),
    ...formatActionTriggerItems(actions, 'Action'),
    ...formatIssuesItems(
      issues.filter(
        ({ typeOfDisplay }) =>
          typeOfDisplay === (isBugLabel ? 'Bug' : 'Feature Request')
      ),
      isBugLabel ? 'Bug' : 'Feature'
    ),
  ].filter(
    item => !selectedItems.find(selected => selected.value === item.value)
  );

  return (
    <div css={styleRoot}>
      <Field
        label={label}
        renderInput={inputProps => (
          <Typeahead
            getKeyForItem={item => item?.value}
            getLabelForItem={item => item?.label}
            inputId={inputProps.id}
            items={items}
            menuAriaLabel={`Select a ${label}`}
            onChange={item => {
              onSelect(item);
              // clear the input after selection
              clearSelectionRef.current();
            }}
            openOnFocus={true}
            placeholder="Select"
            setClearSelection={setClearSelection}
          />
        )}
      />
    </div>
  );
};

const applyServiceTypeToActions = (services, serviceType) => {
  return services.map(service => ({ ...service, serviceType }));
};

const getAppActions = (state: State) =>
  flatMap(appActions(state), applyServiceTypeToActions);

const mapStateToProps = (state: State) => {
  return {
    appId: currentAppId(state),
    actions: getAppActions(state) || [],
    triggers: (appTriggers(state) || []).map(trigger => {
      return { ...trigger, serviceType: 'triggers' as const };
    }),
  };
};

export const TriggerActionAndOthersDropdown = connect(mapStateToProps)(
  _TriggerActionAndOthersDropdown
);
