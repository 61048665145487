// @flow

import * as React from 'react';

import { ToastNotification } from '@zapier/shared-entities';

export const notify = {
  success: (message: React.Node) =>
    ToastNotification.actions.createSuccess(message),
  failure: (message: React.Node) =>
    ToastNotification.actions.createFailure(message, undefined, Infinity),
  info: (message: React.Node) => ToastNotification.actions.createInfo(message),
};
