import { ROUTE_TO_NEXT } from 'redux-router-kit';
import { pushToDataLayer } from '@zapier/common-utils';
import { Account } from '@zapier/shared-entities';

const googleTagManagerMiddleware = store => next => action => {
  // Tell google tag manager a route has changed
  if (action && action.type === ROUTE_TO_NEXT) {
    pushToDataLayer({
      event: 'routeChage',
    });
  }

  const state = store.getState();
  const currentAccount = Account.selectors.all.currentAccount(state);
  const hasUpdatedPersonalInfo = (window.dataLayer || []).find(
    data => data.event === 'updateAccountData'
  );
  if (currentAccount && !hasUpdatedPersonalInfo) {
    const {
      is_trialing_companies,
      companies_trial_end_date,
      plan,
    } = currentAccount?.data;
    pushToDataLayer({
      event: 'updateAccountData',
      isTrialingCompanies: is_trialing_companies,
      companiesTrialEndDate: companies_trial_end_date,
      planName: plan.name,
    });
  }
  return next(action);
};

export default googleTagManagerMiddleware;
