// @flow weak

import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class'; // eslint-disable-line no-restricted-syntax

import { commonErrors as Errors } from '@zapier/toolbox-redux-framework';

const ErrorDetail = createReactClass({
  displayName: 'ErrorDetail',

  propTypes: {
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ]).isRequired,
  },

  detail() {
    if (this.props.error.name) {
      const ErrorType = Errors[this.props.error.name];
      if (ErrorType) {
        return ErrorType.detail(this.props.error);
      }
    }
    return undefined;
  },

  render() {
    return <div className="error-details">{this.detail()}</div>;
  },
});

export default ErrorDetail;
