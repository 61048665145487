import type {
  BaseSelectors,
  IEntity,
  BaseActions,
  ResponseMessage,
} from 'app/entities/types';
import { AppDefinitionSchema } from 'app/developer-v3/platformSchema/app-definition';
import { AuthenticationSchema } from 'app/developer-v3/platformSchema/authentication';
import { SubmitStatus } from 'app/developer-v3/types/statuses';

export type Deployment = 'non-production' | 'production' | 'deprecated';

/** An Implementation of an Integration. Also known as AppVersion. */
export class CliAppDefinitionData {
  constructor(partial: Partial<CliAppDefinitionData> = {}) {
    for (const [key, value] of Object.entries(partial)) {
      this[key] = value;
    }
  }

  id: ID = null;

  /** Date this was created */
  date = '';

  /** Date this was updated */
  last_changed = '';

  /** The ID of the associated App aka Integration */
  app_id: ID = null;

  /** The semver string designating the version number */
  version = '1.0.0';

  /** The version of Zapier core used by this implementation */
  platform_version = '';

  /** An ID used by Zapier to identify this implementation */
  selected_api = '';

  /** How this implementation is seen by Zapier customers */
  deployment: Deployment = 'non-production';

  /** Used by visual-builder to define operations of an app's integration */
  definition_override: AppDefinitionSchema = {};

  /** Used by the CLI to define operations of an app's integration  */
  definition: AppDefinitionSchema | null = null;

  /** Used to faciliate multiple developers working on the same integration at once. */
  etag = '';

  /** When users no longer be able to use this implementation */
  deprecation_date: string | null = null;

  /** Environment variables */
  environment: Record<string, string> = {};

  /** How many users have created Zaps with this implementation */
  user_count = 0;

  /** When was the user count last updated */
  user_count_updated_at = 0;

  /** Unique redirect URI to facilitate OAuth2 */
  oauth_redirect_uri = '';

  /** URL used to invite users to this version */
  invite_url = '';

  /** Version of Zapier Platform Core package */
  core_npm_version = '';

  /** Collection of changes for the version */
  changelog: string | null = null;

  /** Is this version maintined in visual-builder or the CLI */
  is_ui = true;

  /** The verison's Bouncer submission status for App Review */
  submit_status: SubmitStatus | null = null;
}

export type CliAppDefinitionEntity = IEntity<CliAppDefinitionData>;

type CollectionSelectors = {
  findByAppIdAndVersion: (
    appId: ID,
    version: string,
    state: any
  ) => CliAppDefinitionEntity;
};

export interface ISelectors
  extends BaseSelectors<CliAppDefinitionEntity, CollectionSelectors> {
  appId: (entity: CliAppDefinitionEntity) => number;
  /** Returns the AppDefinitionSchema */
  definition: (entity: CliAppDefinitionEntity) => AppDefinitionSchema;
  definitionOverride: (entity: CliAppDefinitionEntity) => AppDefinitionSchema;
  definitionOverrideClean: (
    entity: CliAppDefinitionEntity
  ) => AppDefinitionSchema;
  etag: (entity: CliAppDefinitionEntity) => string;
  version: (entity: CliAppDefinitionEntity) => string;
  selectedApi: (entity: CliAppDefinitionEntity) => string;
  deployment: (entity: CliAppDefinitionEntity) => Deployment;
  deprecationDate: (entity: CliAppDefinitionEntity) => string;
  /**  Number of active users in the integration */
  userCount: (entity: CliAppDefinitionEntity) => number;
  userCountUpdatedAt: (entity: CliAppDefinitionEntity) => number;
  changelog: (entity: CliAppDefinitionEntity) => string;
  createdAt: (entity: CliAppDefinitionEntity) => string;
  updatedAt: (entity: CliAppDefinitionEntity) => string;
  authorEmail: (entity: CliAppDefinitionEntity) => string;
  oauthRedirectUri: (entity: CliAppDefinitionEntity) => string;
  /** If true, this was created by the GUI platform. */
  isGUIIntegration: (entity: CliAppDefinitionEntity) => boolean;
  /** Returns the AuthenticationSchema */
  auth: (entity: CliAppDefinitionEntity) => AuthenticationSchema;
  authFields: (entity: CliAppDefinitionEntity) => [];
  /** Returns the AuthenticationType TODO: Update return value to AuthenticationType*/
  authType: (entity: CliAppDefinitionEntity) => string;
  /** Returns true if auth type is oauth2 */
  isOauth2: (entity: CliAppDefinitionEntity) => boolean;
  /** Check the latest log to see if app was submitted or pushed back. */
  submitStatus: (entity: CliAppDefinitionEntity) => SubmitStatus;
  /** Returns true if the userCount exceeds USER_LIMIT_COUNT */
  doesUserCountExceedLimit: (entity: CliAppDefinitionEntity) => boolean;
  /** Returns true if deployment is production */
  isProduction: (entity: CliAppDefinitionEntity) => boolean;
  /** Returns true if deployment is production and the user count is bigger than the limit */
  isReadOnly: (entity: CliAppDefinitionEntity) => boolean;
  isAPIDown: (entity: CliAppDefinitionEntity) => boolean;
  statusMessages: (
    entity: CliAppDefinitionEntity
  ) => { code?: number; definition: CliAppDefinitionData }[];
  platformVersion: (entity: CliAppDefinitionEntity) => string;
}

export interface IActions extends BaseActions<CliAppDefinitionData> {
  updateAppDefinition: (
    definition_override: AppDefinitionSchema,
    etag?: string,
    messages?: ResponseMessage
  ) => void;
  updateEntityData: (
    parameters: { definition_override: AppDefinitionSchema; etag: string },
    entityId: ID
  ) => void;
  saveEntity: (messages: {}, entityId: ID) => void;
}
