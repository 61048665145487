const asyncRoutes = {
  '/': {
    fetch: () =>
      import(
        /* webpackChunkName: 'developer-v3-route' */ 'app/developer-v3/routes'
      ).then(module => module.default),
  },
};

export default asyncRoutes;
