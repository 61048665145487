// @flow weak

import _ from 'lodash';
import { ROUTE_TO_NEXT, routeTo } from 'redux-router-kit';

import { canUseLocalStorage } from 'app/common/utils/BrowserFeatures';

const storageUnavailableUrl = '/app/login/cookies-disabled';

const shouldRouteToStorageUnavailableUrl = action => {
  return (
    action &&
    action.type === ROUTE_TO_NEXT &&
    _.get(action, ['payload', 'href']) !== storageUnavailableUrl
  );
};

export default store => next => action => {
  // If we can't use `localStorage`, ensure that we're redirecting users
  // to `storageUnavailableUrl`. It's possible other routing actions may
  // be dispatched which is why we want to prevent them and essentially
  // lock users to `storageUnavailableUrl`.
  if (!canUseLocalStorage && shouldRouteToStorageUnavailableUrl(action)) {
    return store.dispatch(routeTo(storageUnavailableUrl));
  }

  return next(action);
};
