import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import { PublishingForm } from './types';

type Args = {
  integrationId: number;
};

const QUERY_KEY_PREFIX = 'query-publishing-form';
const getQueryKey = (args: Args) => `${QUERY_KEY_PREFIX}-${args.integrationId}`;

export function formatResponseBody(body: any): PublishingForm {
  const { results = [] } = body;
  const publishingForm = results[0];

  return {
    apiDocsUrl: publishingForm?.api_docs_url ?? '',
    expectedResponseTime: publishingForm?.expected_response_time ?? '',
    homepageUrl: publishingForm?.homepage_url ?? '',
    id: publishingForm?.id ?? NaN,
    marketingContactEmail: publishingForm?.marketing_contact_email ?? '',
    paidApi: publishingForm?.paid_api ?? '',
    primaryColor: publishingForm?.primary_color ?? '',
    secondaryColor: publishingForm?.secondary_color ?? '',
    technicalContactEmail: publishingForm?.technical_contact_email ?? '',
    testAccountPassword: publishingForm?.test_account_password ?? '',
    testAccountUsername: publishingForm?.test_account_username ?? '',
    testAccountNotes: publishingForm?.test_account_notes ?? '',
    twitterUsername: publishingForm?.twitter_username ?? '',
  };
}

function useQueryPublishingForm(
  args: Args,
  options?: UseQueryOptions<PublishingForm, Error, PublishingForm>
) {
  const { integrationId } = args;
  const notify = useNotify();

  const queryKey = getQueryKey({ integrationId });
  const url = new URL(
    `api/platform/cli/apps/${encodeURIComponent(
      integrationId
    )}/publishing-form`,
    ZAPIER_APP_BASE_URL
  );

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryPublishingForm;
export { getQueryKey, QUERY_KEY_PREFIX };
