import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

const QUERY_KEY_PREFIX = 'query-integration-version-analytics';
const getQueryKey = (integrationId: number, version: string) =>
  `${QUERY_KEY_PREFIX}-${integrationId}-${version}`;

export type VerbAnalytics = {
  name: string;
  zapsLive: number;
  zapsPaused: number;
  zapsTotal: number;
};
type Analytics = {
  creates: VerbAnalytics[];
  searches: VerbAnalytics[];
  timestamp: number;
  triggers: VerbAnalytics[];
  app: VerbAnalytics[];
};

function transformVerb(record: Record<string, any>) {
  return Object.entries(record).map(([key, { live, paused, total }]) => ({
    name: key,
    zapsLive: live,
    zapsPaused: paused,
    zapsTotal: total,
  }));
}

function formatResponseBody(body: any): Analytics {
  return {
    app: transformVerb(body?.app ?? {}),
    creates: transformVerb(body?.action ?? {}),
    searches: transformVerb(body?.search ?? {}),
    timestamp: body?.meta?.timestamp,
    triggers: transformVerb(body?.trigger ?? {}),
  };
}

function useQueryAnalytics(
  args: { integrationId: number; version: string },
  options?: UseQueryOptions<Analytics, Error>
) {
  const { integrationId, version } = args;
  const notify = useNotify();

  const queryKey = getQueryKey(integrationId, version);
  const url = new URL(
    `api/platform/cli/apps/${encodeURIComponent(
      integrationId
    )}/${version}/usage-stats`,
    ZAPIER_APP_BASE_URL
  );

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryAnalytics;
export { getQueryKey, QUERY_KEY_PREFIX };
