import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import useNotify from './useNotify';

const QUERY_KEY_PREFIX = 'query-active-profile';
const getQueryKey = () => QUERY_KEY_PREFIX;

type Profile = {
  email: string;
  emailHash: string;
  id: number;
  isDeveloper: boolean;
  isStaff: boolean;
  firstName: string;
  lastName: string;
  username: string;
};

function formatResponseBody(body: any): Profile {
  const activeId = body?.active_id ?? NaN;
  const activeProfile = (body?.objects ?? []).find(({ id }) => id === activeId);

  return {
    email: activeProfile.email,
    emailHash: activeProfile.email_hash,
    id: activeProfile.id,
    isDeveloper: activeProfile.is_developer,
    isStaff: activeProfile.is_staff,
    firstName: activeProfile.first_name,
    lastName: activeProfile.last_name,
    username: activeProfile.username,
  };
}

function useActiveProfile(options?: UseQueryOptions<Profile, Error, Profile>) {
  const notify = useNotify();
  const queryKey = getQueryKey();

  const query = useQuery({
    queryKey,
    queryFn: async () =>
      formatResponseBody(
        await fetchJson(`${ZAPIER_APP_BASE_URL}/api/v3/profiles`)
      ),
    onError: async error => notify.failure(error.message, queryKey),
    ...options,
  });

  const { data, ...rest } = query;

  return { ...rest, activeProfile: data, queryKey };
}

export default useActiveProfile;
export { QUERY_KEY_PREFIX, getQueryKey };
