// We leverage Formik(https://formik.org) behind the scenes at the moment, but
// this hook allows us to use another form package or roll our own as long as
// the API of the useForm hook sticks.
import { useFormik } from 'formik';

/*
 * A React hook for form development and management. It focuses on form
 * data/state only. Consumers of this hook use the data to render form fields.
 *
 * @param enableReinitialize - Control whether forms should reset if initialValues changes.
 * @param initialValues - Initial field values of the form.
 * @param onSubmit - The form's submission handler.
 * @param validate - Validate the form's values with function.
 *
 * @return errors - Validation errors that match the shape of the form's values defined in initialValues.
 * @return isDirty - True if values are not deeply equal to initial values, false otherwise.
 * @return isSubmitting - True if submission is in progress and false otherwise.
 * @return isTouched - Touched fields. Each key corresponds to a field that has been touched/visited.
 * @return isValid - True if there are no errors and false otherwise.
 * @return onBlur - onBlur event handler. Useful for when you need to track whether an input has been touched or not.
 * @return onChange - General input change event handler. This will update the values[key] where key is the event-emitting input's name attribute. If the name attribute is not present, it will look for an input's id attribute.
 * @return onSubmit - Submit handler.
 * @return setFieldTouched - Set the touched state of a field imperatively.
 * @return setFieldValue - Set the value of a field imperatively.
 * @return values - Your form's values for indivial fields.
 */
function useForm<Values>({
  enableReinitialize,
  initialValues,
  onSubmit,
  validate,
}: {
  enableReinitialize: true;
  initialValues: Values;
  onSubmit: (valuesNext: Values) => Promise<void>;
  validate: (
    valuesNext: Values
  ) => Partial<Record<keyof Values, string>> | Promise<any>;
}) {
  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = useFormik({
    enableReinitialize,
    initialValues,
    onSubmit,
    validate,
  });

  return {
    errors,
    isDirty: dirty,
    isSubmitting,
    isTouched: touched,
    isValid,
    onBlur: handleBlur,
    onChange: handleChange,
    onSubmit: handleSubmit,
    setFieldTouched,
    setFieldValue,
    values,
  };
}

export default useForm;
