// @flow

import fuzzysearch from 'fuzzysearch';

import { toLowerCaseString } from 'app/common/CommonUtils';

export default (needle: string, haystack: string) => {
  needle = toLowerCaseString(needle).replace(/ /g, '');
  haystack = toLowerCaseString(haystack);
  return fuzzysearch(needle, haystack);
};
