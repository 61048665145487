// @flow
// This limits the size of underscore.string to just the functions we use.

export default {
  trim: require('underscore.string/trim'),
  words: require('underscore.string/words'),
  truncate: require('underscore.string/truncate'),
  titleize: require('underscore.string/titleize'),
  underscored: require('underscore.string/underscored'),
  capitalize: require('underscore.string/capitalize'),
};
