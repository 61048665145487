import _ from 'lodash';
import { ROUTE_TO_NEXT } from 'redux-router-kit';

import { RouterSelectors } from '@zapier/toolbox-redux-framework';
import { addTruthyParamsToUrl, removeParamsFromUrl } from '@zapier/url-utils';

import { Account } from '@zapier/shared-entities';

const accountQueryParam = 'context';
const accountSwitcherUrlKey = 'accountSwitcherUrl';

const accountSwitcherMiddleware = store => next => action => {
  if (!action || action.type !== ROUTE_TO_NEXT) {
    return next(action);
  }

  const accountIdQueryParam = parseInt(
    _.get(action, ['meta', 'assign', 'query', accountQueryParam]),
    10
  );

  if (!accountIdQueryParam) {
    return next(action);
  }

  const currentAccountId = Account.selectors.all.currentAccountId(
    store.getState()
  );
  if (currentAccountId) {
    if (currentAccountId !== accountIdQueryParam) {
      store.dispatch(
        Account.actions.switchAccount(accountIdQueryParam, null, 0)
      );
    }
  } else {
    // initalizeCurrentAccount() didn't run yet, this is a fresh page request.
    // We can't validate, nor need to refresh via switchAccount().
    // Just store it and initalizeCurrentAccount() will validate and fall back
    // to the primary account if it's not.
    store.dispatch(Account.actions.storeCurrentAccountId(accountIdQueryParam));
  }

  return next(action);
};

export const attachAccountIdToUrl = (accountId, url) => {
  return addTruthyParamsToUrl(url, { [accountQueryParam]: accountId });
};

export const assignAccountSwitcherUrl = url => ({
  [accountSwitcherUrlKey]: url,
});

export const getAccountSwitcherUrl = state => {
  return _.flow(
    innerState =>
      RouterSelectors.getCurrentRouteItem(accountSwitcherUrlKey, innerState) ||
      RouterSelectors.getCurrentPathname(innerState),
    // Remove `joined_org` to prevent welcome screen
    // from popping up again when switching accounts.
    url => removeParamsFromUrl(url, ['joined_org'])
  )(state);
};

export default accountSwitcherMiddleware;
