import { useEffect, useState } from 'react';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

type OAuthAppData = {
  allowlistDomains: string[];
  clientID: string;
  id: number;
  redirectUris: string[];
};

async function fetchService(
  appSlug: string
): Promise<OAuthAppData | undefined> {
  const url = new URL('/api/v4/oauth/applications/', ZAPIER_APP_BASE_URL);
  url.searchParams.set('service_slug', appSlug);
  const resp = await fetch(url.href, { credentials: 'include' });
  if (!resp.ok) {
    throw new Error(resp.statusText);
  }
  const json = await resp.json();
  const result = json?.results?.[0];
  return {
    allowlistDomains: result?.allowlist_domains ?? [],
    clientID: result?.client_id,
    id: result?.id,
    redirectUris: result?.redirect_uris ?? [],
  };
}

type OAuthAppResult =
  | { type: 'ok'; data: OAuthAppData }
  | { type: 'fail'; error: Error }
  | { type: 'loading' };

function useOAuthApp({ slug }: { slug: string }): OAuthAppResult {
  const [result, setResult] = useState<OAuthAppResult>({ type: 'loading' });

  useEffect(() => {
    async function load() {
      try {
        if (!slug) {
          throw new Error('Missing slug');
        }
        const appData = await fetchService(slug);
        if (appData.id) {
          setResult({ type: 'ok', data: appData });
        } else {
          setResult({ type: 'fail', error: new Error('No OAuth app found') });
        }
      } catch (err) {
        console.error(err);
        setResult({
          type: 'fail',
          error: new Error('Failed to load OAuth app data'),
        });
      }
    }
    load();
  }, [slug]);

  return result;
}

export default useOAuthApp;
