/** @jsx jsx */

import { ComponentProps } from 'react';
import { map } from 'lodash';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Zindexes } from '@zapier/theme';
import { css, jsx } from '@emotion/core';

import Notification from './Notification';
import { ANIM_TRANSFORM, TRANSITION_DURATION } from './constants';

const Styles = {
  root: css`
    left: 50%;
    position: fixed;
    top: 65px;
    transform: translate(-50%);
    z-index: ${Zindexes.pageNotification};

    .notification-enter {
      transform: ${ANIM_TRANSFORM};
    }
    .notification-exit {
      transform: ${ANIM_TRANSFORM};
    }
  `,
};

type Props = {
  notifications: {
    dismissAfter: ComponentProps<typeof Notification>['dismissAfter'];
    id: ComponentProps<typeof Notification>['id'];
    message: ComponentProps<typeof Notification>['message'];
    type: ComponentProps<typeof Notification>['type'];
  }[];
  onDismiss: ComponentProps<typeof Notification>['onDismiss'];
};

function PageNotification(props: Props) {
  const { onDismiss, notifications } = props;

  return (
    <div css={Styles.root}>
      <TransitionGroup>
        {map(
          notifications,
          ({ dismissAfter, id, message, type }: Props['notifications'][0]) =>
            !!message && (
              <CSSTransition
                classNames="notification"
                key={id}
                timeout={{
                  enter: TRANSITION_DURATION,
                  exit: TRANSITION_DURATION,
                }}
              >
                <Notification
                  dismissAfter={dismissAfter}
                  id={id}
                  message={message}
                  onDismiss={onDismiss}
                  type={type}
                />
              </CSSTransition>
            )
        )}
      </TransitionGroup>
    </div>
  );
}

export default PageNotification;
