import useMutationPublishingForm from './useMutationPublishingForm';
import useMutationSubmitPublishingForm from './useMutationSubmitPublishingForm';
import useQueryPublishingForm from './useQueryPublishingForm';

const usePublishingForm = ({ integrationId }: { integrationId: number }) => {
  const { data: publishingForm, isLoading } = useQueryPublishingForm({
    integrationId,
  });
  const {
    isLoading: isUpdating,
    mutateAsync: update,
  } = useMutationPublishingForm({ integrationId });
  const {
    isLoading: isSubmitting,
    mutateAsync: submit,
  } = useMutationSubmitPublishingForm({ integrationId });

  return {
    isLoading,
    isSubmitting,
    isUpdating,
    isWorking: isLoading || isSubmitting || isUpdating,
    publishingForm,
    submit,
    update,
  };
};

export default usePublishingForm;
