import { find } from 'lodash';

/**
 * Utility functions for arrays
 */

/**
 * Converts an array to a CSV
 * Returns the value if it is a string
 * @param {array|string} values
 * @returns {array|string}
 */
const arrayToCsv = (values: string[] | string): string =>
  Array.isArray(values) ? values.join(', ') : values.toString();

/**
 * Transforms a CSV to an array, it will trim whitespaces
 * Returns the value if it is not a CSV
 * @param {string} values
 * @returns {string}
 */
const csvToArray = (values: string): string | string[] =>
  values.includes(',') ? values.split(',').map(item => item.trim()) : values;

/**
 * Finds item in array either by key or index, depending on the provided selector
 */
const findByKeyOrIndex = <T>(array?: T[], selector?) => {
  const found = find(array, { key: selector });
  if (found) {
    return found;
  }

  const index = Number.parseInt(selector, 10);
  if (Number.isInteger(index) && index < array.length) {
    return array[index];
  }

  return undefined;
};

/**
 * Reorders a list based on the index provided
 *
 * @param {array} list
 * @param {number} targetIndex
 * @param {number} newIndex
 * @returns {array}
 */
function reorderList<T>(
  list: Array<T>,
  targetIndex: number,
  newIndex: number
): Array<T> {
  const targetItem = list[targetIndex];
  const listCopy = list.slice(0);
  const adjustedNewIndex = newIndex === -1 ? list.length : newIndex;

  listCopy.splice(targetIndex, 1);
  listCopy.splice(adjustedNewIndex, 0, targetItem);

  return listCopy;
}

export { arrayToCsv, csvToArray, findByKeyOrIndex, reorderList };
