// !!! IMPORTANT !!!
// !!! IF YOU CHANGE THIS FILE YOU NEED TO TEST BOTH EDITORS, CLASSIC AND BETA !!!
//
// This file affects the global flows redux state, used in lots of places.
// Edit with caution and test changes thoroughly!

import { createPrefixedActions } from '@zapier/toolbox-redux-framework';

export default createPrefixedActions(
  'flow',
  `
    CREATE_ZAP
    CREATE_ZAP_DONE
    CREATE_ZAP_FAIL

    ON_WAIT_FOR_SAMPLE_STATE

    ON_LOAD_SAMPLE_STATE
    ON_LOAD_SAMPLE_STATE_DONE

    ON_RETRY_SAMPLE_STATE
    ON_RETRY_SAMPLE_STATE_DONE

    ON_COMPLETE_WAIT_FOR_SAMPLE

    MARK_FLOW

    MARK_ZAP_DIRTY

    LOAD_RECENT_INCIDENTS
    LOAD_RECENT_INCIDENTS_DONE

    LOAD_ZAPS
    LOAD_ZAPS_DONE
    LOAD_ZAPS_FAIL

    LOAD_FLOW
    LOAD_FLOW_DONE
    LOAD_FLOW_FAIL

    LOAD_FLOW_PROGRESS

    LOAD_PUBLIC_ZAP

    CLEAR_FLOW_ERRORS_SCROLL

    LOAD_FLOW_PAGE
    LOAD_FLOW_PAGE_DONE
    LOAD_FLOW_PAGE_FAIL

    UPDATE_FLOW_OWNER
    UPDATE_FLOW_TITLE
    UPDATE_FLOW_OWNER_DONE
    UPDATE_FLOW_OWNER_FAIL
    UPDATE_FLOW_TITLE_DONE
    UPDATE_FLOW_TITLE_FAIL

    ADD_NODE
    ADD_NODE_DONE
    ADD_NODE_FAIL

    COPY_FLOW
    COPY_FLOW_DONE
    COPY_FLOW_FAIL
    CLEAR_COPIED_ZAPS

    MOVE_FLOW
    MOVE_FLOW_DONE
    MOVE_FLOW_FAIL

    REMOVE_FLOW
    REMOVE_FLOW_DONE
    REMOVE_FLOW_FAIL

    REMOVE_FLOWS
    REMOVE_FLOWS_DONE
    REMOVE_FLOWS_FAIL

    REMOVE_NODE
    REMOVE_NODE_DONE
    REMOVE_NODE_FAIL

    UPDATE_NODE
    UPDATE_NODE_DONE
    UPDATE_NODE_FAIL

    LOAD_NODE_DYNAMIC_DROPDOWN
    LOAD_NODE_DYNAMIC_DROPDOWN_DONE
    LOAD_NODE_DYNAMIC_DROPDOWN_FAIL

    REMOVE_NODE_SAMPLE
    REMOVE_NODE_SAMPLE_DONE
    REMOVE_NODE_SAMPLE_FAIL

    ADD_NODE_SUBSCRIPTION
    ADD_NODE_SUBSCRIPTION_DONE
    ADD_NODE_SUBSCRIPTION_FAIL

    REMOVE_NODE_SUBSCRIPTION
    REMOVE_NODE_SUBSCRIPTION_DONE
    REMOVE_NODE_SUBSCRIPTION_FAIL

    RUN_NODE
    RUN_NODE_DONE
    RUN_NODE_FAIL

    LOAD_APP_SAMPLE
    LOAD_APP_SAMPLE_DONE
    LOAD_APP_SAMPLE_FAIL

    AUTOSETUP_NODE
    AUTOSETUP_NODE_DONE
    AUTOSETUP_NODE_FAIL

    REFRESH_NODE
    REFRESH_NODE_DONE
    REFRESH_NODE_FAIL

    LOAD_NODE_NEEDS
    LOAD_NODE_NEEDS_DONE
    LOAD_NODE_NEEDS_FAIL

    LOAD_NODE_GIVES
    LOAD_NODE_GIVES_DONE
    LOAD_NODE_GIVES_FAIL

    LOAD_NODE_INVALID_FIELD_MAPPINGS
    LOAD_NODE_INVALID_FIELD_MAPPINGS_DONE
    LOAD_NODE_INVALID_FIELD_MAPPINGS_FAIL

    UPDATE_NODE_META
    UPDATE_NODE_META_DONE
    UPDATE_NODE_META_FAIL

    UPDATE_NODE_PARAMS
    UPDATE_NODE_PARAMS_DONE
    UPDATE_NODE_PARAMS_FAIL

    SET_FLOW_AUTH_STATUS

    UNPAUSE_FLOW
    UNPAUSE_FLOW_DONE
    UNPAUSE_FLOW_FAIL

    PAUSE_FLOW
    PAUSE_FLOW_DONE
    PAUSE_FLOW_FAIL

    TOGGLE_STEP_MENU

    OPEN_STEP_MENU

    CLOSE_STEP_MENU

    SANITY_CHECK_FAILED

    CLEAN_NODE_PARAMS

    EDIT_STEP_TITLE

    CLOSE_STEP_TITLE_EDITOR

    LOAD_FLOW_NOTES
    LOAD_FLOW_NOTES_DONE
    LOAD_FLOW_NOTES_FAIL

    UPDATE_FLOW_NOTES

    SAVE_FLOW_NOTES
    SAVE_FLOW_NOTES_DONE

    UPDATE_FLOW_TIMEZONE
    UPDATE_FLOW_TIMEZONE_DONE

    SET_DEFAULT_PREVIEW_TOGGLE_STATE
    TOGGLE_PREVIEW_STATE

    EXIT_FLOW
    EXIT_FLOWS

    GET_PUBLISH_FORM
    GET_PUBLISH_FORM_DONE
    GET_PUBLISH_FORM_FAIL

    UPDATE_PUBLISH_FORM
    UPDATE_PUBLISH_FORM_DONE
    UPDATE_PUBLISH_FORM_FAIL
    UPDATE_PUBLISH_META
    UPDATE_PUBLISH_MESSAGES

    CREATE_PATH
    CREATE_PATH_DONE
    CREATE_PATH_FAIL

    CREATE_PATHS_GROUP
    CREATE_PATHS_GROUP_DONE
    CREATE_PATHS_GROUP_FAIL

    CREATE_INITIAL_PATHS_FOR_GROUP
    CREATE_INITIAL_PATHS_FOR_GROUP_DONE
    CREATE_INITIAL_PATHS_FOR_GROUP_FAIL

    ON_OPEN_ADD_STEP_PICKER
    ON_CLOSE_ADD_STEP_PICKER

    ADD_STEP
    ADD_STEP_DONE

    SWITCH_EDITOR
    SWITCH_EDITOR_DONE

    SHOW_EDITOR_ISSUES
    DISMISS_EDITOR_ERROR

    SHOW_SIMPLIFIED_EDITOR_OPT_OUT_SURVEY_MODAL
    HIDE_SIMPLIFIED_EDITOR_OPT_OUT_SURVEY_MODAL

    SAVE_NODE_ATTRS
    SAVE_NODE_ATTRS_DONE
    SAVE_NODE_ATTRS_FAIL

    LOAD_ALL_NEEDS_FOR_ZAP
    LOAD_ALL_NEEDS_FOR_ZAP_DONE
    LOAD_ALL_NEEDS_FOR_ZAP_FAIL

    SCROLL_TO_NODE
    SCROLL_TO_NODE_RESET

    UPDATE_NODE_VALIDATION
    CLEAR_NODE_VALIDATION

    CHANGE_APP
    CHANGE_ACTION
  `
);
