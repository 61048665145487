// @flow weak

import { ROUTE_TO_NEXT, routeTo } from 'redux-router-kit';

import { getSanitizedRedirect } from 'app/common/utils/RouterUtils';

export default ({ dispatch }) => next => action => {
  if (!action || !action.type) {
    return next(action);
  }

  if (action.type === ROUTE_TO_NEXT) {
    const { payload, meta } = action;
    if (payload.isMaybeUnsafe) {
      // This is a frontend route, so it's safe.
      if (meta.routeKey) {
        return next(action);
      }

      // Otherwise, the frontend route doesn't know this route, so make the
      // server decide if it's safe.
      return dispatch(routeTo(getSanitizedRedirect(payload.href)));
    }
  }

  return next(action);
};
