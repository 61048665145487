// @flow weak
/*
Open legacy error modal.
*/

import _ from 'lodash';

import z from 'app/common/z';

export default (/*store*/) => next => action => {
  if (!action) {
    return next(action);
  }
  if (!action.shouldShowLegacyError) {
    return next(action);
  }

  action = _.extend({}, action);
  delete action.shouldShowLegacyError;

  const dispatchResult = next(action);

  if (_.isObject(dispatchResult) && _.isFunction(dispatchResult.then)) {
    return dispatchResult.catch(error => {
      z.app.errorHandler.showErrorMessage(
        error.status,
        error.statusText,
        error.responseText
      );
      throw error;
    });
  }

  return dispatchResult;
};
