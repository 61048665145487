import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';
import { getQueryKey } from './useQueryPublishingForm';

import type { PublishingForm } from './types';

const MUTATION_KEY = 'mutate-update-publishing-form';

function formatToRequestBody(
  publishingForm: PublishingForm,
  implementationId: number
) {
  const {
    apiDocsUrl,
    expectedResponseTime,
    homepageUrl,
    id,
    marketingContactEmail,
    paidApi,
    primaryColor,
    secondaryColor,
    technicalContactEmail,
    testAccountNotes,
    testAccountPassword,
    testAccountUsername,
    twitterUsername,
  } = publishingForm;

  return {
    form: {
      api_docs_url: apiDocsUrl,
      expected_response_time: expectedResponseTime,
      homepage_url: homepageUrl,
      ...(id ? { id: String(id) } : {}),
      marketing_contact_email: marketingContactEmail,
      paid_api: paidApi,
      primary_color: primaryColor,
      secondary_color: secondaryColor,
      technical_contact_email: technicalContactEmail,
      test_account_notes: testAccountNotes,
      test_account_password: testAccountPassword,
      test_account_username: testAccountUsername,
      twitter_username: twitterUsername,
    },
    implementationId,
  };
}

function useMutationPublishingForm(
  args: { integrationId: number },
  options?: UseMutationOptions<
    void,
    Error,
    { implementationId: number; publishingForm: PublishingForm },
    { publishingForm: PublishingForm }
  >
) {
  const { integrationId } = args;
  const notify = useNotify();

  const queryClient = useQueryClient();
  const queryKey = getQueryKey({ integrationId });
  const mutationKey = MUTATION_KEY;
  const url = new URL(
    `api/platform/cli/apps/${encodeURIComponent(
      integrationId
    )}/publishing-form`,
    ZAPIER_APP_BASE_URL
  );

  const mutation = useMutation({
    mutationKey,
    async mutationFn({
      implementationId,
      publishingForm,
    }: {
      implementationId: number;
      publishingForm: PublishingForm;
    }) {
      return fetchJson(url.href, {
        body: JSON.stringify(
          formatToRequestBody(publishingForm, implementationId)
        ),
        method: 'PATCH',
      });
    },
    onError: (error: any, _variables) => {
      notify.failure(
        'There was a problem updating your review-request form. Please check the form values or try again.',
        mutationKey
      );
      console.error(JSON.stringify(error.responseText));
    },
    onSuccess: () => {
      notify.success('Your review-request form is updated.');
      queryClient.invalidateQueries(queryKey);
    },
    ...options,
  });

  return { ...mutation, mutationKey };
}

export default useMutationPublishingForm;
