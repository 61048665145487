import { camelCase, mapKeys, partial } from 'lodash';

import {
  buildEntityActions,
  buildEntityApi,
  buildEntitySelectors,
  EntityActions,
} from '@zapier/toolbox-redux-framework';
import { getJson, postJson, putJson } from '@zapier/toolbox-browser-fetch';

import {
  IActions,
  IApi,
  ISelectors,
  LifecycleStage,
  LifecycleChangeType,
} from './types';

const doneStatuses = [
  LifecycleStage.aborted,
  LifecycleStage.complete,
  LifecycleStage.errored,
];

const pendingStatuses = [
  LifecycleStage.requested,
  LifecycleStage.estimating,
  LifecycleStage.inProgress,
];

export const type = 'versionLifecycle';

const API_BASE = '/api/platform/cli/apps';

export const api: IApi = buildEntityApi(
  type,
  API_BASE,
  (versionLifecycleApi: IApi) => {
    versionLifecycleApi.migrate = (appId, fromVersion, toVersion, options) =>
      postJson(
        `${API_BASE}/${appId}/versions/${fromVersion}/migrate-to/${toVersion}`,
        options
      );

    versionLifecycleApi.promote = (appId, version, changelog, changes) =>
      putJson(`${API_BASE}/${appId}/versions/${version}/promote/production`, {
        changelog,
        ...changes,
      });

    versionLifecycleApi.loadProgress = appId =>
      getJson(`${API_BASE}/${appId}/migrations`);
  }
);

export const selectors: ISelectors = buildEntitySelectors(
  type,
  (sel: ISelectors) => {
    sel.id = partial(sel.dataItem, 'job_id');
    sel.appId = partial(sel.dataItem, 'app_id');
    sel.error = partial(sel.dataItem, 'error');
    sel.stage = partial(sel.dataItem, 'job_stage');
    sel.progress = entity =>
      sel.dataItem(['progress', 'overall_progress'], entity) || 0;
    sel.currentStep = entity => {
      const step = sel.dataItem(['progress', 'current_step'], entity);
      return mapKeys(step, (_, key) => camelCase(key));
    };
    sel.type = partial(sel.dataItem, 'job_kind');
    sel.isDone = entity => {
      const status = sel.stage(entity);
      return doneStatuses.includes(status);
    };
    sel.isPending = entity => pendingStatuses.includes(sel.stage(entity));
    sel.selectedApi = entity => ({
      from: sel.dataItem('from_selected_api', entity),
      to: sel.dataItem('to_selected_api', entity),
    });
    sel.steps = entity => {
      const steps = sel.dataItem(['progress', 'steps'], entity) || [];
      return steps.map(step => mapKeys(step, (_, key) => camelCase(key)));
    };
    sel.updatedAt = partial(sel.dataItem, 'updated_at');

    sel.all.findByAppId = partial(sel.all.findBy, (appId, entity) => {
      const parsedId = Number.isInteger(appId) ? appId : parseInt(appId, 10);
      return sel.appId(entity) === parsedId;
    });
  }
);

const createErroredLifecycle = (id: any, errorMessages: string[]) => ({
  id,
  app_id: id,
  error: { messages: errorMessages },
  job_stage: LifecycleStage.errored,
  progress: { overall_progress: 0 },
});

const createRequestedLifecycle = id => ({
  id,
  app_id: id,
  job_stage: LifecycleStage.requested,
  progress: { overall_progress: 0 },
});

export const actions: IActions = buildEntityActions(
  type,
  selectors,
  api,
  (versionLifecycleActions: IActions) => {
    versionLifecycleActions.getProgress = appId => {
      return EntityActions.loadEntity(
        type,
        async () => {
          const { objects } = await api.loadProgress(appId);
          const latestLifecycleChange = objects[0] || {};

          return { id: appId, ...latestLifecycleChange };
        },
        {},
        appId
      );
    };

    versionLifecycleActions.promote = (appId, version, changelog, changes) => {
      return EntityActions.saveEntity(
        type,
        () => {},
        async () => {
          try {
            const result = await api.promote(
              appId,
              version,
              changelog,
              changes
            );

            // We apply some additional fields since we currently call the old
            // promote endpoint which predates transaction manager.
            // When our promote endpoint updates to the transaction manager, we can
            // let its response do the job.
            return {
              ...result,
              ...createRequestedLifecycle(appId),
              to_selected_api: result.selected_api,
              job_kind: LifecycleChangeType.promote,
            };
          } catch (error) {
            const responseText = JSON.parse(error.responseText);
            const errors = responseText?.errors;
            const passes = responseText?.passes;
            const errorMessages =
              Array.isArray(errors) && typeof errors[0] === 'string'
                ? errors
                : errors.total_failures && passes
                ? [
                    'Your integration version doesn’t pass validation. Please validate your integration to see the issues in the validate tab of the right sidebar.',
                  ]
                : [];
            return {
              ...createErroredLifecycle(appId, errorMessages),
              job_kind: LifecycleChangeType.promote,
            };
          }
        },
        [],
        appId
      );
    };

    versionLifecycleActions.migrate = (
      appId: ID,
      fromVersion: string,
      toVersion: string,
      options: { email: string } | { percent: number }
    ) => {
      return EntityActions.createEntity(
        type,
        () => {},
        async () => {
          try {
            const result = await api.migrate(
              appId,
              fromVersion,
              toVersion,
              options
            );

            return {
              ...result,
              ...createRequestedLifecycle(appId),
              job_kind: LifecycleChangeType.migrate,
            };
          } catch (error) {
            const errors = JSON.parse(error.responseText)?.errors;
            const errorMessages =
              Array.isArray(errors) && typeof errors[0] === 'string'
                ? errors
                : [];
            return {
              ...createErroredLifecycle(appId, errorMessages),
              job_kind: LifecycleChangeType.migrate,
            };
          }
        },
        [],
        appId
      );
    };
  }
);
