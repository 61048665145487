import { compact } from 'lodash';
import { enableBatching } from 'redux-batched-actions';
import { routerReducer } from 'redux-router-kit';

import buildMiddleware from 'app/common/buildMiddleware';
import createReducer from 'app/common/createReducer';
import { safeExtend } from 'app/common/CommonUtils';

export default function getAllReducersAndMiddleware(plugins) {
  // Grab all the action types objects.
  const actionTypesArgs = compact(plugins.actionTypes);

  // Make sure they're all unique.
  const safeExtendActionTypesArgs = [{}].concat(actionTypesArgs);
  safeExtend(...safeExtendActionTypesArgs);

  // Grab all the reducers.
  const safeExtendReducersArgs = [{ router: routerReducer }].concat(
    plugins.reducers
  );

  const reducers = {
    ...safeExtend(...safeExtendReducersArgs),
  };

  return {
    get rootReducer() {
      return enableBatching(createReducer(reducers));
    },

    reducers,
    ...buildMiddleware(plugins.routes),
  };
}
