/**
 * Formats a json object into a formatted string
 */
const prettyFormatJsonString = (obj: {} | Object[]): string =>
  JSON.stringify(obj, null, 2);

/**
 * If the string provided is a valid JSON object,
 * returns a formatted string with the content of the JSON object
 * otherwise returns the string without formatting
 */
const maybePrettyJsonString = (string: string): string => {
  try {
    const object = JSON.parse(string);
    return prettyFormatJsonString(object);
  } catch (e) {
    return string;
  }
};

export { maybePrettyJsonString, prettyFormatJsonString };
