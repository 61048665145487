/** @jsx jsx */

import { css, jsx } from '@emotion/core';

import { Button } from '@zapier/design-system';

import Link from './Link';

const Styles = {
  root: css`
    display: inherit;
  `,
};

/*
 * This leverages our custom Link component to perform routing.
 */
function LinkButton(props: React.ComponentProps<typeof Button>) {
  const { href, target, ...buttonProps } = props;

  return (
    <Link css={Styles.root} href={href} target={target}>
      <Button isFullWidth={true} {...buttonProps} />
    </Link>
  );
}

export default LinkButton;
