// import-sort-ignore
import _ from 'lodash';

import { isInIframe, isProduction } from '@zapier/common-utils';
import { RouterSelectors } from '@zapier/toolbox-redux-framework';

import { Account } from '@zapier/shared-entities';

import Data from 'app/track/Data';
import { getCurrentReferrer } from 'app/track/Selectors';
import { setLanded, setVisited } from 'app/track/utils/TrackUtils';
import log from 'app/common/log';

const isProd = isProduction();

const debouncedTrackByMessage = {};

const logForAbTestValidation = (message, data) => {
  const isUsingOldLaneProp = data && data.lane;
  if (isUsingOldLaneProp) {
    console.warn(
      '!!! using the `lane` property is deprecated -- use `variant` or ideally maybeRenderIfAbTest()!'
    );
  }

  const isBirthButImproper =
    _.endsWith(message, 'birth') &&
    (!_.startsWith(message, 'test: ') || !_.endsWith(message, '_birth'));

  const isTestButNotBirth =
    _.startsWith(message, 'test: ') && !_.endsWith(message, '_birth');

  if (isBirthButImproper || isTestButNotBirth) {
    console.warn(
      '!!! birth events should follow `test: {name}_birth` -- consider using maybeRenderIfAbTest()!'
    );
  }

  const isMissingVariant =
    message &&
    _.startsWith(message, 'test: ') &&
    _.endsWith(message, '_birth') &&
    data &&
    !data.variant;
  if (isMissingVariant) {
    console.warn(
      '!!! birth events should include a `variant` property -- consider using maybeRenderIfAbTest()!'
    );
  }
};

const send = (message, data) => {
  log('tracking an event:', message, data);
  if (!isProd) logForAbTestValidation(message, data);
  Data.trackEvent(message, data);
};

const TrackActions = {
  track(message, data, wait = 200) {
    return (dispatch, getState) => {
      if (!debouncedTrackByMessage[message]) {
        debouncedTrackByMessage[message] = _.throttle(send, wait);
      }

      return debouncedTrackByMessage[message](message, {
        ...data,
        account_id: Account.selectors.all.currentAccountId(getState()),
      });
    };
  },

  trackPageView(path, url) {
    return () => {
      Data.trackPageView(path, url);
    };
  },

  trackLanding(landingPageName, data) {
    return dispatch => {
      dispatch(TrackActions.track(`Landing Page: ${landingPageName}`, data));
      setLanded();
    };
  },

  trackVisited(data) {
    return dispatch => {
      dispatch(TrackActions.track('Visit Zapier (all)', data));
      setVisited();
    };
  },

  // To add a new use-case-found event:
  // 1. Update SOURCES list with the new eventSource
  // 2. Add referrer param to the editor url being created, E.g; ?referrer=eventSource
  // In most cases, the above two steps should be sufficient as trackUseCaseFound is
  // fired inside the editor when a zap is being created
  trackUseCaseFound(data) {
    const { referrer, editor, ...eventData } = data;

    const servicePageRegexes = [
      // Looks for the URL pattern apps/gmail/integrations/slack and captures
      // the names of the app (gmail and slack, for example) in a group
      /apps\/([\w-]+)\/integrations\/{0,1}([\w-]*)/,
      // Looks for the URL pattern apps/gmail/help and captures name of the app
      /apps\/([\w-]+)\/help/,
      // Looks for the URL pattern apps/gmail/tutorials and captures name of the app
      /apps\/([\w-]+)\/tutorials/,
    ];

    const SOURCES = new Map([
      ['1-service-page', true],
      ['2-service-page', true],
      ['beta-editor', true],
      ['beta-editor-goal', true],
      ['classic-editor', true],
      ['developerInvite', true],
      ['explore-page', true],
      ['member-home-make-a-zap', true],
      ['member-home-make-a-zap-zt', true],
      ['member-home-recommended-zaps-zt', true],
      ['partnerEmbed', true],
      ['zapierEmbed', true],
      ['shared-zap', true],
      ['universal-zap-templates', true],
      ['copied-zap', true],
      ['use-case', true],
      ['dashboard-use-case', true],
      ['platform-visual-builder', true],
    ]);

    return (dispatch, getState) => {
      const state = getState();
      const currentLocation = `${
        RouterSelectors.getCurrentOrigin(state) || ''
      }${RouterSelectors.getCurrentPathname(state) || ''}`;

      const previousLocation =
        `${RouterSelectors.getPreviousOrigin(state) || ''}${
          RouterSelectors.getPreviousLocation(state) || ''
        }` || _.get(document, ['referrer']);

      let eventOrigin = referrer ? referrer : getCurrentReferrer(state);

      // A user should have at least a trigger app selected
      // for it to qualify as a use-case-found event for make-a-zap module
      // Or there should be a templateId if make-a-zap module is creating zap from a template
      const selectedTrigger = RouterSelectors.getQueryParam(
        state,
        'template__0__selected_api'
      );
      const zapTemplateId = RouterSelectors.getQueryParam(
        state,
        'zapTemplateId'
      );
      if (
        referrer === 'member-home-make-a-zap' &&
        !(selectedTrigger || zapTemplateId)
      ) {
        return;
      }

      if (!eventData.zapTemplate && zapTemplateId) {
        eventData.zapTemplate = zapTemplateId;
      }

      // Check for 1-service or 2-service pages
      // We receive auto-create param for cases when a zap is auto-created
      // through /snapbook/create url, which is handled by the BE service
      // Create param is sent when a user creates a zap using connect module on
      // a 2-service page
      const isAutoCreated =
        RouterSelectors.getQueryParam(state, 'auto-create') ||
        RouterSelectors.getQueryParam(state, 'create');

      const servicePageTest = servicePageRegexes.reduce(
        (result, currentRegex) =>
          result ||
          currentRegex.exec(previousLocation) ||
          currentRegex.exec(RouterSelectors.getQueryParam(state, 'referrer')),
        false
      );

      if (
        servicePageTest &&
        servicePageTest.length >= 2 &&
        (eventData.zapTemplate || isAutoCreated)
      ) {
        eventOrigin =
          servicePageTest[1].length &&
          servicePageTest[2] &&
          servicePageTest[2].length
            ? '2-service-page'
            : '1-service-page';
      }

      // Check for Partner Embeds (users creating zaps from within partner sites)
      const isEmbedded =
        RouterSelectors.getQueryParam(state, 'embedded') || isInIframe();
      let isZapier = false;
      try {
        isZapier =
          _.includes(window.top, 'zapier.com') ||
          _.includes(referrer, 'zapier.com');
      } catch (e) {
        // protect against xframe exceptions
      }

      if (isEmbedded) {
        eventOrigin = isZapier ? 'zapierEmbed' : 'partnerEmbed';
        if (referrer) eventData.partnerReferrer = referrer;
      }

      // Use Case referrer data includes the use-case slug and id
      // as in use-case/{useCaseId}/{useCaseSlug}
      // So we need to parse for these
      if ((eventOrigin || '').includes('use-case')) {
        const useCaseData = eventOrigin.split('/');
        eventOrigin = useCaseData[0];
        eventData.useCaseId = useCaseData[1] || '';
        eventData.useCaseSlug = useCaseData[2] || '';
      }

      if (SOURCES.get(eventOrigin)) {
        dispatch(
          /**
           * @trackingEvent use-case-found
           * @trackingCompletionEvent none
           * @trackingParam account_id - current user account id
           * @trackingParam userId - current user id
           * @trackingParam currentLocation - current router location
           * @trackingParam eventOrigin - use case referrer data
           * @trackingParam nodeId - node id of current step in editor
           * @trackingParam path - current relative editor url
           * @trackingParam previousLocation - previous outer location
           * @trackingDescription Track use case found.
           */
          TrackActions.track('use-case-found', {
            ...eventData,
            currentLocation,
            previousLocation,
            eventOrigin,
            editor,
          })
        );
      }
    };
  },
};

export default TrackActions;
