import { useQuery } from 'react-query';
import useNotify from './useNotify';
import { PartnerInteractionType } from 'app/developer-v3/pages/insights/utils';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

const QUERY_KEY_PREFIX = 'embed-insights-referral-funnel';
const getQueryKey = ({ integrationId }) =>
  `${QUERY_KEY_PREFIX}-${integrationId}`;

export type EmbedReferralFunnelDatum = {
  outgest_id: string;
  outgest_run_date_time: string;
  app_integration_id: number;
  app_name: string;
  partner_interaction_type: PartnerInteractionType;
  timeframe_days: number;
  count_referred_sessions: number;
  count_user_signups: number;
  count_users_zap_created: number;
  count_users_zap_enabled: number;
  count_users_zap_activated: number;
};

type Result = { integration_activity: EmbedReferralFunnelDatum[] };

export function useQueryEmbedInsightsReferralFunnel(integrationId: string) {
  const notify = useNotify();
  const queryKey = getQueryKey({ integrationId });

  const { data, error, ...rest } = useQuery(queryKey, async () => {
    const resp = await fetch(
      `${ZAPIER_APP_BASE_URL}/developer/insights/v1/${integrationId}/embed-referral-funnel`,
      {
        credentials: 'include',
      }
    );
    const json: Result = await resp.json();
    return json;
  });

  if (error) {
    console.error(error);
    notify.failure('Failed to fetch insights activity daily data.');
  }

  return { data: data?.integration_activity ?? [], error, ...rest };
}

export default useQueryEmbedInsightsReferralFunnel;
