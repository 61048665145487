import _ from 'lodash';
import marked from 'marked';
import sanitizeHtml from 'sanitize-html';

const sanitizeOptions = {
  // From https://github.com/zapier/zapier/blob/c5740caca228263d55c06ecb9e2b03a97e4aad58/useful/markdown_tools.py#L147
  allowedTags: [
    'a',
    'p',
    'code',
    'pre',
    'blockquote',
    'b',
    'strong',
    'i',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'ol',
    'ul',
    'li',
  ],
  // From https://www.npmjs.com/package/sanitize-html#what-are-the-default-options
  allowedAttributes: {
    a: ['href', 'target', 'rel'],
  },
};

const stripHtmlOptions = {
  allowedTags: [],
  allowedAttributes: [],
};

const sanitize = options => dirtyHtml => sanitizeHtml(dirtyHtml, options);

const parseMarkdown = options => markdown => marked(markdown, options);

const isZapierLink = string => string.startsWith('https://zapier.com/');

const getRelAttr = href =>
  `rel="${isZapierLink(href) ? '' : 'nofollow '}noopener noreferrer"`;

const renderer = new marked.Renderer();

renderer.image = () => '';

// We strip out the title, so no need to even add it
renderer.link = (href, _title, text) =>
  `<a href="${href}" target="_blank" ${getRelAttr(href)}>${text}</a>`;

const renderMarkdown = _.flow(
  sanitize(stripHtmlOptions),
  parseMarkdown({ renderer }),
  sanitize(sanitizeOptions)
);

export { renderMarkdown };
