import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

type Args = {
  checkId: string;
};

const QUERY_KEY_PREFIX = 'query-check-doc';
const getQueryKey = (args: Args) => `${QUERY_KEY_PREFIX}-${args.checkId}`;

type Doc = {
  body: string;
  title: string;
};

function formatResponseBody(body: any): Doc {
  return {
    body: body?.doc?.body,
    title: body?.doc?.title,
  };
}

function useQueryCheckDoc(args: Args, options?: UseQueryOptions<Doc, Error>) {
  const { checkId } = args;
  const notify = useNotify();

  const queryKey = getQueryKey({ checkId });
  const url = new URL(
    `api/platform/cli/check/${encodeURIComponent(checkId)}`,
    ZAPIER_APP_BASE_URL
  );

  const query = useQuery({
    queryKey,
    queryFn: async () =>
      formatResponseBody(
        await fetchJson(url.href, { body: '{}', method: 'POST' })
      ),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryCheckDoc;
export { getQueryKey, QUERY_KEY_PREFIX };
