// @flow weak

import _ from 'lodash';

import { getKeyInStorage, setKeyInStorage } from '@zapier/local-storage';

const GET_IN_STORAGE = 'GET_IN_STORAGE';
const SET_IN_STORAGE = 'SET_IN_STORAGE';

const handlers = {
  [GET_IN_STORAGE]: {
    isActionValid: action =>
      Array.isArray(action.keys) && _.isFunction(action.onGet),
    handle: (action, dispatch, getState) => {
      const data = action.keys.reduce(
        (accum, key) => ({
          ...accum,
          [key]: action.readFromStorage(key),
        }),
        {}
      );
      action.onGet(data, dispatch, getState);
    },
  },

  [SET_IN_STORAGE]: {
    isActionValid: action =>
      _.isPlainObject(action.data) &&
      (!action.onSet || _.isFunction(action.onSet)),
    handle: (action, dispatch, getState) => {
      const { data, onSet = _.noop } = action;
      try {
        Object.keys(data).forEach(key => {
          action.writeToStorage(key, data[key]);
        });
        onSet(true, dispatch, getState);
      } catch (e) {
        onSet(false, dispatch, getState);
      }
    },
  },
};

export default store => next => action => {
  if (!(action && action.type in handlers)) {
    return next(action);
  }

  const handler = handlers[action.type];

  if (!handler.isActionValid(action)) {
    console.error(`localStorageMiddleware - ${action.type} is invalid`, action);
  } else {
    handler.handle(action, store.dispatch, store.getState);
  }

  return undefined;
};

export const getLocalStorage = (
  keys,
  onGet,
  { readFromStorage = getKeyInStorage } = {}
) => ({
  type: GET_IN_STORAGE,
  keys,
  onGet,
  readFromStorage,
});

export const setLocalStorage = (
  data,
  onSet,
  { writeToStorage = setKeyInStorage } = {}
) => ({
  type: SET_IN_STORAGE,
  data,
  onSet,
  writeToStorage,
});
