import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import type { Issue, Event, Tag, User } from './types';

type Args = { integrationId: number; issueId: number };

const QUERY_KEY_PREFIX = 'query-integration-issue';
const getQueryKey = (args: Args) =>
  `${QUERY_KEY_PREFIX}-${args.integrationId}-${args.issueId}`;

function formatUser(author: any): User {
  return {
    country: author?.country,
    createdAt: author?.created_at,
    dateJoined: author?.date_joined,
    email: author?.email,
    firstName: author?.first_name,
    gravatar: author?.gravatar,
    id: author?.id,
    isStaff: author?.is_staff,
    lastName: author?.last_name,
    name: author?.name,
    notifyVia: author?.notify_via,
    // Should this user get notifications when there are Loki updates to issues for integration's
    // they are listed as a developer for?
    publicAlias: author?.public_alias,
    shouldNotify: author?.should_notify,
    timezone: author?.timezone,
    username: author?.username,
    zapierUserId: author?.zapier_user_id,
  };
}

function formatEvent(event: any): Event {
  return {
    contentObject: {
      backgroundColor: event?.content_object?.background_color,
      name: event?.content_object?.name,
    },
    createdAt: event?.created_at,
    id: event?.id,
    typeOf: event?.type_of,
    typeOfDisplay: event?.type_of_display,
    user: formatUser(event?.user),
  };
}

function formatTag(tag: any): Tag {
  return {
    backgroundColor: tag?.background_color,
    id: tag?.id,
    isPublic: tag?.is_public,
    name: tag?.name,
    order: tag?.order,
    slug: tag?.slug,
  };
}

function formatResponseBody(body: any): Issue {
  return {
    absoluteId: body.absolute_id,
    actions: body.actions,
    app: {
      selectedApi: body.app?.selected_api,
      title: body.app?.title,
    },
    appVersions: body.app_versions,
    assignee: body.assignee,
    author: formatUser(body.author),
    body: body.body,
    // Make an array out of the values. Not pupulated in an issues array.
    comments: Object.entries(body.comments || {}).map(
      ([_commentId, comment]: [string, any]) => ({
        author: formatUser(comment?.author),
        body: comment?.body,
        children: (comment?.children ?? []).map((child: any) => ({
          author: formatUser(child?.author),
          body: child?.body,
          createdAt: child?.created_at,
          errorMsg: child?.error_msg,
          htmlBody: child?.html_body,
          id: child?.id,
          isPublic: child?.is_public,
          markdownBody: child?.markdown_body,
        })),
        createdAt: comment?.created_at,
        errorMsg: comment?.error_msg,
        htmlBody: comment?.html_body,
        id: comment?.id,
        isPublic: comment?.is_public,
        markdownBody: comment?.markdown_body,
      })
    ),
    commentsCount: body.comments_count,
    createdAt: body.created_at,
    developer: formatUser(body.developer),
    // Make an array out of the values. Not pupulated in an issues array.
    events: Object.entries(
      body.events || {}
    ).map(([_eventId, event]: [string, any]) => formatEvent(event)),
    id: body.id,
    isBlocking: body.is_blocking,
    isPublic: body.is_public,
    lastActivityAt: body.last_activity_at,
    linkedJiraIssueKey: body.linked_jira_issue_key,
    numInterestedUsers: body.num_interested_users,
    priority: body.priority,
    priorityChangedAt: body.priority_changed_at,
    priorityOverride: body.priority_override,
    priorityOverrideChangedAt: body.priority_override_changed_at,
    priorityRank: body.priority_rank,
    selectedApi: body.selected_api,
    status: body.status,
    statusDisplay: body.status_display,
    // Not pupulated in an issues array.
    stream: (body.stream ?? []).map(({ data_type, id }) => ({
      dataType: data_type,
      id,
    })),
    tags: (body.tags ?? []).map(formatTag),
    title: body.title,
    trackedGithubIssueId: body.tracked_github_issue_id,
    trackedGithubRepoSlug: body.tracked_github_repo_slug,
    typeOf: body.type_of,
    typeOfDisplay: body.type_of_display,
    waitingOnThirdPartySince: body.waiting_on_third_party_since,
    workStatus: body.work_status,
    workStatusDisplay: body.work_status_display,
  };
}

function useQueryIntegrationIssue(
  args: Args,
  options?: UseQueryOptions<Issue, Error>
) {
  const { integrationId, issueId } = args;
  const notify = useNotify();

  const queryKey = getQueryKey({ integrationId, issueId });
  const url = new URL(
    `api/platform/cli/apps/${encodeURIComponent(
      integrationId
    )}/issues/${encodeURIComponent(issueId)}`,
    ZAPIER_APP_BASE_URL
  );

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryIntegrationIssue;
export { formatResponseBody, getQueryKey, QUERY_KEY_PREFIX };
