// @flow weak
/*
Dispatch an array of actions.
*/

import _ from 'lodash';

export default store => next => action => {
  if (!_.isArray(action)) {
    return next(action);
  }

  action.forEach(eachAction => store.dispatch(eachAction));

  return undefined;
};
