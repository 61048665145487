import { useEffect, useState } from 'react';

export default function useDebouncedValue<T>(value: T, delay = 1000) {
  const [valueDebounced, setValueDebounced] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setValueDebounced(value);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);

  return [valueDebounced, setValueDebounced] as const;
}
