import { QueryClient } from 'react-query';
import { ROUTE_TO } from 'redux-router-kit';

import { Profile } from '@zapier/shared-entities';
import { fetchJson } from '@zapier/toolbox-browser-fetch';

import * as CliApp from 'app/entities/CliApp';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { getValidAppIntention } from 'app/developer-v3/utils';
import { getQueryKeyOfPartnerDashboard } from 'app/developer-v3/hooks';
import { getQueryKey as getQueryKeyOfAdmins } from 'app/developer-v3/hooks/useQueryAdmins';
import { getQueryKey as getQueryKeyOfCollaborators } from 'app/developer-v3/hooks/useQueryCollaborators';
import { queryOptions as dashboardQueryOptions } from 'app/developer-v3/pages/dashboard/constants';
import { selectAppId } from 'app/developer-v3/store/selectors';
import { PartnerProfile } from 'app/developer-v3/types/partnerProfile';

const intercomAPIBase = 'https://api-iam.intercom.io';
const intercomAppID = 'eg4np976';
const queryClient = new QueryClient();

async function fetchPartnerDashboard(
  serviceId: number
): Promise<{
  appHealthScore: string | undefined;
  appTier: string | undefined;
}> {
  const defaultReturn = { appHealthScore: undefined, appTier: undefined };

  if (!serviceId) {
    console.error('Failed to load Partner Dashboard information for Intercom');
    return defaultReturn;
  }

  try {
    const url = new URL(
      `/api/v3/partner/${serviceId}/dashboard`,
      ZAPIER_APP_BASE_URL
    );
    const data = await queryClient.fetchQuery({
      queryKey: getQueryKeyOfPartnerDashboard({ serviceId }),
      queryFn: () => fetchJson(url.href),
      staleTime: dashboardQueryOptions.staleTime,
    });

    return {
      appHealthScore: data?.health?.current_score,
      appTier: data?.status?.tier,
    };
  } catch (error) {
    console.error(error.message);
    return defaultReturn;
  }
}

function formatPartnerProfileResponseBody(body: any): PartnerProfile {
  const { objects } = body;
  const partnerProfile = objects?.[0];

  return {
    intercomIdentitySecret: partnerProfile?.intercom_identity_verification,
  };
}

async function fetchPartnerProfile(): Promise<{
  intercomIdentitySecret: string | undefined;
}> {
  const defaultReturn = { intercomIdentitySecret: undefined };

  try {
    const url = new URL('/api/platform/cli/profile', ZAPIER_APP_BASE_URL);
    const data = await queryClient.fetchQuery({
      queryFn: async () =>
        formatPartnerProfileResponseBody(await fetchJson(url.href)),
      staleTime: 1800000, // 30 mins
    });

    return {
      intercomIdentitySecret: data?.intercomIdentitySecret,
    };
  } catch (error) {
    console.error(error.message);
    return defaultReturn;
  }
}

async function fetchCollaborators(
  appId: string
): Promise<
  {
    email: string;
    id: string;
    name: string;
    role: string;
    status: 'accepted' | 'pending';
  }[]
> {
  try {
    const url = new URL(
      `/api/platform/cli/apps/${encodeURIComponent(
        appId
      )}/limited_collaborators`,
      ZAPIER_APP_BASE_URL
    );
    const data = await queryClient.fetchQuery({
      queryKey: getQueryKeyOfCollaborators(appId),
      queryFn: () => fetchJson(url.href),
    });

    const collaborators = data?.objects ?? [];

    return collaborators.map((collaborator: any) => ({
      email: collaborator.email,
      id: collaborator.id,
      name: collaborator.name,
      role: collaborator.role,
      status: collaborator.status,
    }));
  } catch (error) {
    console.error(error.message);
    return [];
  }
}

async function fetchAdmins(
  appId: string
): Promise<
  {
    email: string;
    id: string;
    name: string;
    role: string;
    status: 'accepted' | 'pending';
  }[]
> {
  try {
    const url = new URL(
      `/api/platform/cli/apps/${encodeURIComponent(appId)}/collaborators`,
      ZAPIER_APP_BASE_URL
    );
    const data = await queryClient.fetchQuery({
      queryKey: getQueryKeyOfAdmins(appId),
      queryFn: () => fetchJson(url.href),
    });

    const admins = data?.objects ?? [];

    return admins.map((admin: any) => ({
      email: admin.email,
      id: admin.id,
      name: admin.name,
      role: admin.role,
      status: admin.status,
    }));
  } catch (error) {
    console.error(error.message);
    return [];
  }
}

// Intercom should be initialized with the `boot` command and updated with the
// `update` command. This let's us keep track of if we've already booted, so we
// can send the correct command.
let intercomMethod: 'boot' | 'update' = 'boot';

async function updateIntercom(store: any): Promise<void> {
  const state = store.getState();
  const currentProfile = Profile.selectors.all.currentProfile(state);
  // User level
  const userId = Profile.selectors.id(currentProfile);
  const email = Profile.selectors.email(currentProfile);
  const firstName = Profile.selectors.firstName(currentProfile);
  const lastName = Profile.selectors.lastName(currentProfile);
  const role = Profile.selectors.roles(currentProfile)?.[0]?.role;
  const accessLevel = role;
  const platformUserRole = role;
  // Company level
  const appId = selectAppId(state);
  const app = CliApp.selectors.all.entity(appId, state);
  const appIntegrationId = CliApp.selectors.id(app);
  const serviceId = CliApp.selectors.serviceId(app);
  const appName = CliApp.selectors.title(app) || CliApp.selectors.key(app);
  const appStatus = CliApp.selectors.status(app);
  const appIntention = getValidAppIntention(CliApp.selectors.intention(app));
  const { appTier, appHealthScore } = await fetchPartnerDashboard(serviceId);
  const { intercomIdentitySecret } = await fetchPartnerProfile();
  const totalIntegrations =
    CliApp.selectors.all.entities(state)?.length ?? null;

  const appContributorCount = appId
    ? (await fetchAdmins(appId)).length +
      (await fetchCollaborators(appId)).length
    : null;

  // The `ROUTE_TO` action is dispatched before the `window.location` has
  // actually changed. That's a problem because Intercom doesn't take any
  // parameters, it just gets the page from `window.location` when it's called.
  // It doesn't seem that any actions are dispatched to indicate that the
  // `ROUTE_TO` is complete. To get around this, we can use `setTimeout(..., 0)`
  // to put the Intercom call at the end of the JS event queue. This way the
  // `ROUTE_TO` action will have been processed and `window.location` will have
  // been updated by the time Intercom is actually invoked.
  setTimeout(() => {
    window.Intercom(intercomMethod, {
      api_base: intercomAPIBase,
      app_id: intercomAppID,
      user_id: userId,
      email,
      user_hash: intercomIdentitySecret,
      first_name: firstName,
      last_name: lastName,
      access_level: accessLevel,
      platform_user_role: platformUserRole,
      total_integrations: totalIntegrations,
      company: {
        id: appIntegrationId && Number(appIntegrationId),
        name: appName,
        app_integration_id: appIntegrationId && Number(appIntegrationId),
        app_name: appName,
        app_status: appStatus,
        app_tier: appTier,
        app_health_score: appHealthScore,
        app_intent: appIntention,
        app_contributor_count: appContributorCount,
        company_load_type: 'integration',
      },
    });
    intercomMethod = 'update';
  }, 0);
}

const intercomMiddleware = store => next => action => {
  // Intercom is loaded by index.html, so it's not available in tests
  if (!window.Intercom) {
    return next(action);
  }

  // When routing to a new URL
  if (action && action.type === ROUTE_TO) {
    updateIntercom(store);
  }

  // When a profile fetch is completed
  if (
    action &&
    action.type === 'entity/FETCH_COLLECTION_DONE' &&
    action.entityType === 'partnerProfile'
  ) {
    updateIntercom(store);
  }

  // When a CLI app fetch is completed
  if (
    action &&
    action.type === 'entity/FETCH_ENTITY_DONE' &&
    action.entityType === 'cliApp'
  ) {
    updateIntercom(store);
  }

  return next(action);
};

export default intercomMiddleware;
