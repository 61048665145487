import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

function formatDateInUTC(date: Date | string) {
  const dateFormat = 'yyyy-MM-dd kk:mm:ss';
  const timeZone = 'UTC';

  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  const zonedTime = utcToZonedTime(parsedDate, timeZone);

  return format(zonedTime, dateFormat, { timeZone });
}

export { formatDateInUTC };
