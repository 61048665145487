// Returns true for
// - public status
// - private status and public intention
// Returns false for
// - private status and private intention
function isPublicIntentionElementVisible({
  isPrivate,
  isPublicIntention,
}: {
  isPrivate: boolean;
  isPublicIntention: boolean;
}) {
  return !isPrivate || isPublicIntention;
}

export { isPublicIntentionElementVisible };
