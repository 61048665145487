import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

const QUERY_KEY_PREFIX = 'query-integration';
const getQueryKey = () => QUERY_KEY_PREFIX;

export type Integration = {
  // allVersions: string[];
  // allowedSelfSignupDomains?: string[];
  // appCategory: string;
  // appCategory_other: string;
  // coreVersions: string[];
  // date: string;
  // description: string;
  // homepageUrl: string;
  id: number;
  image?: string;
  // TODO: remove empty string and null when the value presents at all times.
  // We'll force integrations to have the value attached when they are being
  // created either on Visual Builder or CLI. In the interim, empty string and
  // null are exceptions for integrations created on the CLI platform without
  // the intention supplied.
  intention: 'private' | 'global' | '' | null;
  // inviteUrl: string;
  isBeta: boolean;
  // isNewAppMigrationEndpoints: boolean;
  key: string;
  // latestVersion: string;
  // latestCoreVersion: string;
  // latestCoreNpmVersion: string;
  public: boolean;
  pending: boolean;
  // publicIsh: boolean;
  // role: string;
  // selfSignupAllowed: boolean;
  serviceId?: number;
  // slug?: string;
  status: 'private' | 'public';
  // null (private) -> submitted (pending) [-> // pushed-back (pushed back)] -> submitted (resubmitted)-> null (approved) // rejected?
  submitStatus: 'submitted' | 'pushed-back' | null;
  title: string;
  // versions: string[];
};

function formatResponseBody(body: any): Integration {
  return {
    id: body.id,
    image: body.image,
    intention: body.intention,
    isBeta: body.is_beta,
    key: body.key,
    public: body.public,
    pending: body.pending,
    serviceId: body.service_id,
    status: body.status,
    submitStatus: body.submit_status,
    title: body.title,
  };
}

function useQueryIntegration(
  args: { integrationId: number },
  options?: UseQueryOptions<Integration, Error, Integration>
) {
  const { integrationId } = args;

  const notify = useNotify();

  const queryKey = getQueryKey();
  const url = new URL(
    `api/platform/cli/apps/${encodeURIComponent(integrationId)}`,
    ZAPIER_APP_BASE_URL
  );

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryIntegration;
export { formatResponseBody, getQueryKey, QUERY_KEY_PREFIX };
