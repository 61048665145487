import { useState, ReactNode } from 'react';

import { ToastNotification } from '@zapier/shared-entities';
import { useRedux } from '@zapier/toolbox-redux-framework';

type NotificationMessage = string | { title: string; body: ReactNode };

type NotificationArgs = [NotificationMessage, ID?, number?];

interface UseNotify {
  success: (...args: NotificationArgs) => void;
  failure: (...args: NotificationArgs) => void;
}

const mapDispatch = dispatch => ({
  success: (...args: NotificationArgs) =>
    dispatch(ToastNotification.actions.createSuccess(...args)),
  failure: (...args: NotificationArgs) =>
    dispatch(ToastNotification.actions.createFailure(...args)),
});

export default function useNotify(): UseNotify {
  // TODO: Fix useRedux
  // Hacky way of memoizing these actions. useRedux does not memoize its results
  // so we lose equality checks downstream. This casues useEffect hooks to over render.
  const actions = useRedux(null, mapDispatch);
  const [notify] = useState(actions);

  return notify;
}
