import { findRoutes } from 'redux-router-kit';

type Route = any;
type Routes = Record<string, Route>;
type RouteKey = string[] | string;

export const getSanitizedRedirect = (untrustedUrl: string, fallback = null) => {
  if (!untrustedUrl && fallback) {
    return fallback;
  }

  return `/redirect/?safe_only=true&next=${escape(untrustedUrl)}`; // backend will return a valid URL of next is empty
};

// Most `/app` routes require authentication and most
// non-`/app` routes do not require authentication.
// Routes that break this convention specify it via `isAuthRequired`
const defaultIsAuthRequired = (route: Route) =>
  (route?.path ?? '').includes('/app');

const hasMatchedRoute = (
  routes: Routes,
  routeKey: RouteKey,
  matchRoute: (route: Route) => boolean
) => {
  const matchedRoutes = findRoutes(routes, routeKey) || [];
  return matchedRoutes.some(matchRoute);
};

export const isAuthRequiredForRoutes = (routes: Routes, routeKey: RouteKey) => {
  const matchRoute = (route: Route) =>
    route.isAuthRequired === undefined
      ? defaultIsAuthRequired(route)
      : route.isAuthRequired;
  return hasMatchedRoute(routes, routeKey, matchRoute);
};

export const shouldScrollToTopForMatchedRoutes = (
  routes: Routes,
  routeKey: string[] | string
) => {
  const matchRoute = (route: Route) => !!route.shouldScrollToTop;
  return hasMatchedRoute(routes, routeKey, matchRoute);
};

export const assignParams = (newParams: Record<string, string>) => ({
  assign: ({ params }) => ({ params: { ...params, ...newParams } }),
});
