import { useMutation, useQueryClient } from 'react-query';

import { patchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import useNotify from './useNotify';
import { getQueryKey } from './useQueryAdmins';

type Args =
  | {
      email: string;
      integrationId: string;
      typeToAdd: 'marketing' | 'technical';
    }
  | {
      email: string;
      integrationId: string;
      typeToRevoke: 'marketing' | 'technical';
    };
type Return = Record<string, any>;

const MUTATION_KEY = 'update-admin-contact-type';

function useUpdateAdminContactType() {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mutationKey = MUTATION_KEY;

  const mutation = useMutation<Return, Error, Args>({
    mutationKey,
    async mutationFn(args) {
      const { email, integrationId } = args;

      return patchJson(
        `${ZAPIER_APP_BASE_URL}/api/platform/cli/apps/${integrationId}/collaborators/${email}`,
        'typeToAdd' in args
          ? { contact_type: args.typeToAdd }
          : { remove_contact_type: args.typeToRevoke }
      );
    },
    onError(_err, { email }) {
      notify.failure(`Failed to update the contact type(s) of ${email}.`);
    },
    onSuccess(_data, { email, integrationId }) {
      queryClient.invalidateQueries(getQueryKey(integrationId));
      notify.success(`The contact type(s) of ${email} has been updated.`);
    },
  });

  const { isLoading } = mutation;

  return { isMutating: isLoading, mutate: mutation.mutateAsync, mutationKey };
}

export default useUpdateAdminContactType;
