import { useCallback, useState } from 'react';

import { useRedux } from '@zapier/toolbox-redux-framework';

import * as WbConversion from 'app/entities/WbConversion/WbConversion';
import * as CliApp from 'app/entities/CliApp';
import {
  ConversionStage,
  MigrationUpdate,
  StepName,
} from 'app/entities/WbConversion/types';
import { currentAppEntity, currentAppId } from 'app/developer-v3/selectors';

const mapState = state => {
  const appId = currentAppId(state);
  const conversionEntity = WbConversion.selectors.all.entity(appId, state);
  const stage = WbConversion.selectors.stage(conversionEntity);
  const appEntity = currentAppEntity(state);
  const wasConvertedFromWB = !!CliApp.selectors.wasConvertedFromWB(appEntity);

  const isProgress = {
    loading: WbConversion.selectors.isLoading(conversionEntity),
    success: WbConversion.selectors.isSuccess(conversionEntity),
    failure: WbConversion.selectors.isFailure(conversionEntity),
  };

  return {
    appId,
    isProgress,
    migration: {
      progress: Math.floor(
        WbConversion.selectors.progress(conversionEntity) * 100
      ),
      currentStep: WbConversion.selectors.currentStep(conversionEntity),
    },
    stage,
    steps: WbConversion.selectors.steps(conversionEntity),
    timestamp: WbConversion.selectors.timestamp(conversionEntity),
    wasConvertedFromWB,
  };
};

const mapDispatch = dispatch => ({
  startMigration(appId: ID) {
    return dispatch(WbConversion.actions.startMigration(appId));
  },
  getMigrationProgress(appId: ID) {
    return dispatch(WbConversion.actions.getMigrationProgress(appId));
  },
  finishTesting(appId: ID) {
    return dispatch(WbConversion.actions.finishTesting(appId));
  },
});

// Manually typing because of useRedux :fist-shake:
// Need to upgrade redux or we'll be doing more of this.
type IUseConversion = {
  appId: number;
  /** Current stage of the migration process */
  stage: ConversionStage;
  /** Selectors for the state of the last progress request */
  isProgress: { loading: boolean; success: boolean; failure: boolean };
  /** Details about the in-progress migration */
  migration: MigrationUpdate;
  /** When stage is aborted, there was an error with migrating.
   * Requires staff intervention, we render an error block
   */
  readonly hasError: boolean;
  /** Whether the partner has marked the integration tested and ready to migrate. */
  readonly isTested: boolean;
  /** Whether the integration is currently migrating users. Based off of stage. */
  readonly isMigrating: boolean;
  /** Whether the integration is migrated. */
  readonly isMigrated: boolean;
  /** Retunrs the total number of zaps converted */
  readonly completedZapsCount: number;
  /** Returns timestamp of the migration */
  readonly migratedDate: number;
  /** This is a converted app that has not finished the conversion/migration process */
  readonly isMigrationPending: boolean;
  /** Marks testing as done and sets conversion stage to `tested` */
  finishTesting(): void;
  /** Triggers a migration the inital response state should be `estimating` */
  migrate(): void;
  /** Fetches the migration status */
  fetchMigrationStatus(): void;
  /** Whether the integration was converted from WB. */
  wasConvertedFromWB: boolean;
};

/**
 * Facilitates the remaining conversion steps of a WB app to a v3 integration
 */
function useConversion(): IUseConversion {
  const {
    appId,
    conversionEntity,
    estimatedPoints,
    finishedPoints,
    isMigrated,
    isMigratingZaps,
    isMigratingZapTemplates,
    isProgress,
    migration,
    migrationProgress,
    shouldGetProgress,
    steps,
    stage,
    timestamp,
    wasConvertedFromWB,
    ...actionsUnmemo
  } = useRedux(mapState, mapDispatch);
  // We need to update to the latest redux. We hit this problem before with useNotify
  // where the actions returned from useRedux aren't memoized causing too many
  // re-renders
  const [actions] = useState(actionsUnmemo);

  return {
    appId,
    stage,
    isProgress,
    migration,
    get hasError() {
      return stage === ConversionStage.aborted;
    },
    get isTested() {
      const { converted, aborted, ...testedStages } = ConversionStage;
      return Object.values(testedStages).includes(stage);
    },
    get isMigrating() {
      const {
        converted,
        tested,
        aborted,
        migrated,
        ...migratingStages
      } = ConversionStage;
      return Object.values(migratingStages).includes(stage);
    },
    get isMigrated() {
      return stage === ConversionStage.migrated;
    },
    get isMigrationPending() {
      return !!stage && !this.isMigrated;
    },

    /** When the stage is migrated, we get the number of completed zaps from the migration step named 'migrate_zaps'*/
    get completedZapsCount(): number {
      return (
        steps?.length &&
        steps.find(el => el.name === StepName.migrateZaps)?.finished_points
      );
    },

    /** Retuns the timestamp (ms) of when the migrated step was last updated */
    get migratedDate(): number {
      return stage === ConversionStage.migrated ? timestamp : null;
    },

    finishTesting() {
      actions.finishTesting(appId);
    },
    migrate() {
      actions.startMigration(appId);
    },
    fetchMigrationStatus: useCallback(
      () => actions.getMigrationProgress(appId),
      [actions, appId]
    ),
    wasConvertedFromWB,
  };
}

export default useConversion;
