import { useCallback } from 'react';
import { useRedux } from '@zapier/toolbox-redux-framework';

import * as VersionLifecycle from 'app/entities/VersionLifecycle';

import {
  LifecycleStage,
  LifecycleChangeType,
  LifecycleJob,
} from 'app/entities/VersionLifecycle/types';

const mapState = (appId: ID) => state => {
  const lifecycleEntity = VersionLifecycle.selectors.all.findByAppId(
    appId,
    state
  );
  const progress = Math.round(
    VersionLifecycle.selectors.progress(lifecycleEntity) * 100
  );
  const stage = VersionLifecycle.selectors.stage(lifecycleEntity);

  return {
    error: VersionLifecycle.selectors.error(lifecycleEntity),
    id: VersionLifecycle.selectors.id(lifecycleEntity),
    isDone: VersionLifecycle.selectors.isDone(lifecycleEntity),
    isPending: VersionLifecycle.selectors.isPending(lifecycleEntity),
    isLoading:
      VersionLifecycle.selectors.isSaving(lifecycleEntity) ||
      VersionLifecycle.selectors.isLoading(lifecycleEntity),
    job: {
      currentStep: VersionLifecycle.selectors.currentStep(lifecycleEntity),
      progress,
      selectedApi: VersionLifecycle.selectors.selectedApi(lifecycleEntity),
      stage,
    },
    lifecycleType: VersionLifecycle.selectors.type(lifecycleEntity),
    stage,
    steps: VersionLifecycle.selectors.steps(lifecycleEntity),
    updatedAt: VersionLifecycle.selectors.updatedAt(lifecycleEntity),
    progress,
  };
};

const mapDispatch = (appId: ID) => dispatch => ({
  loadProgress() {
    return dispatch(VersionLifecycle.actions.getProgress(appId));
  },
});

type UseVersionLifecycle = {
  /** The error messages. */
  error: { messages: string[] };
  /** Does the app have any aborted lifecycle transitions? */
  hasError: boolean;
  /** The job ID of the version lifecycle */
  id: string;
  /** Does the app have any completed lifecycle transitions? */
  isDone: boolean;
  /** Are we waiting for a lifecycle request to finish? */
  isLoading: boolean;
  /** Does the app have any in-progress lifecycle transitions? */
  isPending: boolean;
  /** Details about the in-progress lifecycle job */
  job: LifecycleJob;
  /** Helper to specify completed by type such as isPromoted or isMigrated */
  isDoneByType: (type: LifecycleChangeType) => boolean;
  /** Helper to specify in-progress by type such as isPromoting or isMigrating */
  isPendingByType: (type: LifecycleChangeType) => boolean;
  /**
   * Are we waiting for any lifecycle transition to complete?
   * The criteria here is we have a transition that hasn’t completed.
   * This includes a transition in an error/aborted state.
   * */
  isLifecyclePending: boolean;
  /** The type of lifecycle transition we’re waiting on */
  lifecycleType: LifecycleChangeType;
  /** Loads any in-progress lifecycle updates */
  loadProgress: () => Promise<void>;
  /** The amount of progress completed for the lifecycle update */
  progress: number;
  /** Current stage of the in-progress lifecycle job */
  stage: LifecycleStage;
  steps: VersionLifecycle.JobStep[];
  /** The timestamp of the last recorded update to the job */
  updatedAt: number;
};

export default function useVersionLifecycle(appId: ID): UseVersionLifecycle {
  const { loadProgress, ...mapped } = useRedux(
    mapState(appId),
    mapDispatch(appId)
  );

  return {
    ...mapped,
    loadProgress: useCallback(loadProgress, []),
    get hasError() {
      return mapped.stage === LifecycleStage.errored;
    },
    get isLifecyclePending() {
      return !!mapped.stage && !mapped.isDone;
    },
    get lifecycleType() {
      return mapped.lifecycleType;
    },

    isDoneByType(type) {
      return mapped.isDone && mapped.lifecycleType === type;
    },
    isPendingByType(type) {
      return mapped.isPending && mapped.lifecycleType === type;
    },
  };
}
