// @flow weak
/*
 * Wrap a component along with a media query (or queries). If a media query
 * changes, re-render the component. Pass in an object of strings, and the
 * wrapped component will get a `matchesMediaQuery` prop with an object of
 * booleans.
 */

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import createReactClass from 'create-react-class'; // eslint-disable-line no-restricted-syntax

import hocify from 'app/common/components/hocify';

type MediaQueryLists = {
  [string]: {
    [string]: (fn: any) => {},
  },
};

export const MatchesMediaQuery = createReactClass({
  displayName: 'MatchesMediaQuery',
  mediaQueryLists: (null: ?MediaQueryLists),

  propTypes: {
    Component: PropTypes.func.isRequired,
    mediaQueries: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      matchesMediaQuery: {},
    };
  },

  UNSAFE_componentWillMount() {
    this.configureMediaQueries(this.props.mediaQueries);
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.mediaQueries, nextProps.mediaQueries)) {
      this.configureMediaQueries(nextProps.mediaQueries);
    }
  },

  componentWillUnmount() {
    this.bindMediaQueryListeners(false);
    this.mediaQueryLists = null;
  },

  configureMediaQueries(mediaQueries) {
    this.bindMediaQueryListeners(false);
    this.buildMediaQueryLists(mediaQueries);
    this.bindMediaQueryListeners(true);
    this.setMatchesState();
  },

  buildMediaQueryLists(mediaQueries) {
    this.mediaQueryLists = Object.keys(mediaQueries).reduce(
      (lists, key) => ({
        [key]: window.matchMedia(mediaQueries[key]),
        ...lists,
      }),
      {}
    );
  },

  bindMediaQueryListeners(shouldBind) {
    const { mediaQueryLists } = this;
    const method = shouldBind ? 'addListener' : 'removeListener';
    Object.keys(mediaQueryLists || {}).forEach(key => {
      const mediaQuery = _.get(mediaQueryLists, key);
      const methodFn = _.get(mediaQuery, method);
      if (mediaQuery && methodFn) {
        methodFn.call(mediaQuery, this.onChangeMediaQuery);
      }
    });
  },

  onChangeMediaQuery() {
    this.setMatchesState();
  },

  setMatchesState() {
    const { mediaQueryLists } = this;
    if (!mediaQueryLists) {
      return;
    }

    const matchesMediaQuery = Object.keys(mediaQueryLists).reduce(
      (result, key) => ({
        [key]: mediaQueryLists[key].matches,
        ...result,
      }),
      {}
    );
    this.setState({ matchesMediaQuery });
  },

  render() {
    const { Component, ...props } = this.props;
    const childProps = _.omit(props, 'mediaQueries');
    return <Component {...childProps} {...this.state} />;
  },
});

const matchesMediaQuery = mediaQueries =>
  hocify(MatchesMediaQuery, { mediaQueries });

export default matchesMediaQuery;
