import { onFetchComplete } from '@zapier/toolbox-browser-fetch';
import {
  buildEntityActions,
  buildEntityApi,
  buildEntitySelectors,
} from '@zapier/toolbox-redux-framework';
import { isAPIDown } from 'app/developer-v3/utils';
import { addAppPendingDeletion } from 'app/developer-v3/utils/appsPendingDeletion';
import { identity } from 'lodash';
import { BaseApi } from './types';

export const type = 'legacyApp';

const API_BASE = '/api/developer/v1/apps';

export interface IApi extends BaseApi {
  /** DELETE request with custom middleware logic. */
  delete: (
    appID: ID,
    data: Record<string, any>,
    getState: () => any
  ) => Promise<any>;
  deleteWith: <T>(
    identity: () => T,
    appID: ID,
    data: Record<string, any>,
    getState: () => any
  ) => Promise<any>;
}

export const api = buildEntityApi(
  type,
  path => `${API_BASE}/${path}`,
  (legacyAppApi: IApi) => {
    legacyAppApi.delete = (appId, data, getState) => {
      const state = getState();
      const legacyAppEntity = state.entities.legacyApp.entities[appId].data;

      onFetchComplete(response => {
        if (response.status === 202) {
          addAppPendingDeletion(legacyAppEntity);
        }
      });

      return legacyAppApi
        .deleteWith(identity, appId, data, getState)
        .then(() => onFetchComplete(() => {}));
    };
  }
);

export const selectors = buildEntitySelectors(type, sel => {
  sel.isAPIDown = state =>
    isAPIDown(sel.statusCode(state), sel.all.statusMessages(state));
});

export const actions = buildEntityActions(type, selectors, api);
