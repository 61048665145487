import React from 'react';

import { useCookieConsent } from '@zapier/cookies';
import { useInstallFullStory } from '@zapier/react-fullstory';

function FullStory() {
  useInstallFullStory();

  return null;
}

export default function FullStoryContainer() {
  const cookieCategories = useCookieConsent();

  const shouldRecord = !!cookieCategories?.byName?.analytics;

  return shouldRecord && <FullStory />;
}
