import { IEntity, BaseSelectors, BaseActions } from 'app/entities/types';

export enum ConversionStage {
  /** Integration was successfully converted from WB */
  converted = 'converted',
  /** Completed testing by creating Zaps for each trigger/action. */
  tested = 'tested',
  /** Start of migration process. We first estimate Zaps/Users to migrate */
  migrationEstimating = 'estimating',
  /** Done estimating. Migration will now start automatically. */
  migrationEstimated = 'estimated',
  /** Migration is in progress */
  migrating = 'migrating',
  /** Done migrating. The integration connversion process is now complete.
   * The integration should now be editable */
  migrated = 'migrated',
  /** There was an error with migrating. Requires staff intervention.
   * This state can occur from `estimating` through `migrated` states. */
  aborted = 'aborted',
}

export enum StepName {
  addLegacyTitle = 'add_legacy_title',
  clearInProgress = 'clear_in_progress',
  clearUsers = 'clear_users',
  copyContactInfo = 'copy_contact_info',
  copyStatus = 'copy_status',
  copyThrottleLimit = 'copy_throttle_limit',
  copyUsers = 'copy_users',
  downgrade = 'downgrade',
  migrateAccessControls = 'migrate_access_controls',
  migrateAuths = 'migrate_auths',
  migrateLokiIssues = 'migrate_loki_issues',
  migrateRoverNotes = 'migrate_rover_notes',
  migrateSubscribers = 'migrate_subscribers',
  migrateTemplates = 'migrate_zts',
  migrateZaps = 'migrate_zaps',
  swapServices = 'swap_services',
}

export type MigrationUpdate = {
  progress: number;
  currentStep: {
    name: StepName;
    estimatedPoints: number;
    finishedPoints: number;
  };
};

/** MigrationStep: https://docs.k8s.zapier.com/avro-schemas/#/schema/event.zapier.wb_conversion.stage_done.MigrationStep */
export type Step = {
  /** The name of the migration step */
  name: StepName;
  /** The estimated effort of work to finish the step. he unit of this number is relative but should be consistent with finished_points */
  estimated_points: number;
  /** The effort of work finished so far. Only available when stage is migrated and always 0 when stage is estimated */
  finished_points: number;
  /** The timestamp (ms) when this step started. 0 if unstarted. */
  started_at: number;
  /** The timestamp (ms) when this step last runs. 0 if unstarted. */
  updated_at: number;
  /** The average speed since last update (number of points / min). */
  current_speed: number;
  /** The accumulated average speed (number of points / min). */
  avg_speed: number;
  /** The step status: unstarted, in_progress, failed, success */
  status?: string;
};

export type IApi = {
  /** Empty POST to /wb-migration/start */
  migrate: (id: ID) => Promise<any>;
  /** GET /wb-migration/progress */
  loadProgress: (id: ID) => Promise<any>;
};

type ConversionUser = { customuser_id: ID; email: string };

type WbConversionData = {
  stage: ConversionStage;
  app_wrapper_id: ID;
  primary_wb_app_id: ID;
  title: string;
  app_status: string;
  old_selected_api: string;
  requested_user: ConversionUser;
  timestamp_ms: number;
  git_sha: string;
  cli_app_id: ID;
  new_selected_api: string;
  converted_version_mapping: {
    wb_app_id: ID;
    cli_app_version: string;
  }[];
  tested_user: ConversionUser;
  migration_progress: {
    overall_progress: number;
    current_step: Step;
    steps: Step[];
  };
  rank_in_queue: number | null;
};

export type WbConversionEntity = IEntity<WbConversionData>;

/** Selectors for the WbConversion Entity */
export interface ISelectors extends BaseSelectors<WbConversionEntity> {
  cliAppId: (entity: WbConversionEntity) => ID;
  /** Stage of the migration process, check ConversionStage to see the possible values */
  stage: (entity: WbConversionEntity) => ConversionStage;
  /** Overall progress of the migration process, returns a float */
  progress: (entity: WbConversionEntity) => number;
  /** Returns the object with the information about the current step of the migration process */
  currentStep: (entity: WbConversionEntity) => Step | {};
  /** Returns the estimated points used to calculate the total & remaining zaps to be migrated */
  estimatedPoints: (entity: WbConversionEntity) => number;
  /** Returns the finished points used to calculate the total & updates zaps in the migration process */
  finishedPoints: (entity: WbConversionEntity) => number;
  /** The time when this event was created (Unix UTC milliseconds since epoch) */
  timestamp: (entity: WbConversionEntity) => number;
  /**
   * It's a 0-based integer and it's only available when stage is tested.
   * To show the partner what's their order in the queue:
   * `Migration hasn't started yet. You are {rank_in_queue + 1}th in the queue`
   */
  rankInQueue: (entity: WbConversionEntity) => number;
  /**
   * Should we fetch migration progress?
   * This selectors takes into account:
   *   - Are we currently fetching?
   *   - Do we have a response?
   *   - Is that response a failure?
   */
  requiresMigration: (entity: WbConversionEntity) => boolean;
  /** Returns an array of all the steps */
  steps: (entity: WbConversionEntity) => Array<Step>;
}

export interface IActions extends BaseActions<WbConversionData> {
  /** Triggers the start of a migration. */
  startMigration(id: ID): void;
  /** Fetches the current progress of a migration */
  getMigrationProgress(id: ID): void;
  /** todo */
  finishTesting(id: ID): void;
}
