const minRoute = {
  onEnter({ routeTo, router }) {
    routeTo(`/app/editor/template/${router.next.params.xpid}`, {
      replace: true,
    });
  },
  isAuthRequired: false,
};

const routes = {
  '/app/min/:xpid/start': minRoute,
  '/app/min/:xpid/start-nod': minRoute,
  '/app/min/:xpid/:zapid/:step': minRoute,
};

export default routes;
