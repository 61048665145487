import { useCallback, useState } from 'react';

type Arguments = {
  initialValue?: boolean;
};

/**
 * Hook to toggle a boolean value
 *
 * @param {Boolean} initialValue
 */
export default function useToggle(
  initialValue: Arguments['initialValue'] = false
): [boolean, () => void] {
  const [isToggleOn, setIsToggleOn] = useState(initialValue);

  const toggle = useCallback(() => setIsToggleOn(!isToggleOn), [isToggleOn]);

  return [isToggleOn, toggle];
}
