import { includes, get } from 'lodash';

const VBOnlyRouteKeys = ['triggers', 'authentication', 'actions', 'advanced'];
const AdvancedRouteKeys = ['advanced'];
const AdminOnlyRouteKeys = [
  'actions',
  'advanced',
  'authentication',
  'monitoring',
  'triggers',
];
const PublicIntentionOnlyRouteKeys = ['publishing'];

/**
 * When app is not a VB integration, we need to redirect the user
 * for `triggers`, `authentication`, `actions` and `advanced` routes.
 *
 * Since we don't have access to `isGUIIntegration` in the router we
 * need to check the router manually
 *
 * This utility function is also used to know when to render the
 * CTA that warns the user about a non editable version
 *
 * @param {Router} router
 */
export const isGUIIntegrationOnlyRoute = router =>
  includes(VBOnlyRouteKeys, get(router, ['current', 'routeKey'], [])[5]);

export const isAdvancedIntegrationRoute = router =>
  AdvancedRouteKeys.includes(router?.current?.routeKey?.[5]);

export const isAdminOnlyRoute = router =>
  includes(AdminOnlyRouteKeys, get(router, ['current', 'routeKey'], [])[5]);

export const isPublicIntentionOnlyRoute = router =>
  includes(
    PublicIntentionOnlyRouteKeys,
    get(router, ['current', 'routeKey'], [])[3]
  );
