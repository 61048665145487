export enum ROLES {
  admin = 'collaborator',
  collaborator = 'limited_collaborator',
  invitee = 'invitee',
  subscriber = 'subscriber',
}

export enum MEMBER_CONTACT_TYPES {
  marketing = 'marketing',
  technical = 'technical',
}
