export const TestingStatuses = {
  INITIAL: 'initial',
  FAILURE: 'failure',
  SUCCESS: 'success',
  TESTING: 'testing',
};

export const AuthTypes = {
  PRIVATE: 'private',
  RESTRICTED: 'restricted',
  SHARED: 'shared',
};

export const NEW_AUTH_FORM = 'new-auth-form';

// Stub for auth data when it's not available. This happens
// in shared zaps when an auth is owned by a different user
// from the one who is currently logged in.
export const authStub = Object.freeze({
  id: 'other-users-connected-account',
  is_shared: false,
  selected_api: 'unknown',
  title: 'Other User’s Connected Account',
});

export const IS_STALE_AUTH = 'is_stale';
export const SELECTED_API = 'selected_api';
