import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import { getQueryKey } from './useQueryIssue';

import type { Issue } from './types';

const MUTATION_KEY = 'mutate-issue-comment';

type Args = { body?: string; commentId?: number; isPublic?: boolean };

function useMutationComment(
  { integrationId, issueId }: { integrationId: number; issueId: number },
  options?: UseMutationOptions<void, Error, Args, { issue: Issue }>
) {
  const notify = useNotify();

  const queryClient = useQueryClient();
  const queryKey = getQueryKey({ integrationId, issueId });

  const mutationKey = MUTATION_KEY;
  const mutation = useMutation({
    mutationKey,
    async mutationFn({ body, commentId, isPublic }) {
      const url = commentId
        ? new URL(
            `/api/platform/cli/apps/${encodeURIComponent(
              integrationId
            )}/issues/${encodeURIComponent(
              issueId
            )}/comments/${encodeURIComponent(commentId)}`,
            ZAPIER_APP_BASE_URL
          )
        : new URL(
            `/api/platform/cli/apps/${encodeURIComponent(
              integrationId
            )}/issues/${encodeURIComponent(issueId)}/comments`,
            ZAPIER_APP_BASE_URL
          );

      fetchJson(url.href, {
        body: JSON.stringify({ body, is_public: isPublic }),
        method: commentId ? 'PATCH' : 'POST',
      });
    },
    onError: (error: any, _variables) => {
      notify.failure(error.message, mutationKey);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      notify.success('Successfully updated issue comment.');
    },
    ...options,
  });

  return { ...mutation, mutationKey };
}

export default useMutationComment;
