import { Status } from './constants';

// Derivatives helpers to determine the status of an issue.
export function getStatusHelpers(status: number) {
  return {
    isOpen: status === Status.open,
    isClosed: status === Status.closed,
    isResolved: status === Status.resolved,
  };
}
