// @flow weak
/*
Add a `routeTo` to your action, and this middleware will change the route
after dispatch. If dispatching a promise, it will only dispatch after the
promise successfully completes.
*/

import _ from 'lodash';
import { routeTo } from 'redux-router-kit';

const changeRoute = (dispatch, route) => {
  if (route) {
    route = _.isString(route) ? { path: route } : route;
    if (route.shouldRefresh) {
      window.location.href = route.path;
    } else {
      const { ...options } = route;
      dispatch(routeTo(route.path, options));
    }
  }
};

export default ({ dispatch, getState }) => next => action => {
  if (!action) {
    return next(action);
  }
  if (!action.routeTo) {
    return next(action);
  }

  let route = action.routeTo;

  action = _.extend({}, action);
  delete action.routeTo;

  const dispatchResult = next(action);

  if (_.isObject(dispatchResult) && _.isFunction(dispatchResult.then)) {
    return dispatchResult.then(result => {
      route = _.isFunction(route) ? route(result, getState()) : route;
      if (route) {
        changeRoute(dispatch, route);
      }
      return result;
    });
  }

  changeRoute(dispatch, route);
  return dispatchResult;
};
