// @flow weak
/*
Add a `promise` to your action, and this middleware will work with `types` or
`type` to dispatch before the promise and after it completes, either for
success or error.

If `types` is specified, it should be an array of 3 action types, such as
`[MY_ACTION, MY_ACTION_DONE, MY_ACTION_FAIL]`. Use null for any of the types
to prevent that action from being dispatched. If one `type` is specified, that
type is assumed to be the optimistic action, and `_DONE` and `_FAIL` are
automatically added for success and failure.

The result of the promise is put in a `result` key, or if `resultKey` is
specified, that key will be used.

`onDangerouslyReceiveSuccess` should not be used if you can avoid it!!!
Use routing middleware to support `routeTo`, or check for the correct state in
your component. Or for doing multiple actions, make a composite action
creator.
*/

import _ from 'lodash';
import monotonicTimestamp from 'monotonic-timestamp';

import {
  commonErrors as Errors,
  RouterSelectors,
} from '@zapier/toolbox-redux-framework';
import { urlWithNext } from '@zapier/url-utils';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import z from 'app/common/z';
import { isAuthRequiredForRoutes } from 'app/common/utils/RouterUtils';

const withMonotonicTimestamp = (fn: Function) => {
  return fn(monotonicTimestamp());
};

const defaultShouldRequireLogin = (error, state, routes) => {
  return (
    error &&
    error.status === 401 &&
    isAuthRequiredForRoutes(routes, RouterSelectors.getRouteKey(state))
  );
};

// TODO: get rid of this onDangerouslyReceiveSuccess crap
export default ({ routes, isTest = false }) => ({
  getState,
}) => next => action => {
  if (!action) {
    return next(action);
  }
  if (!action.promise) {
    return next(action);
  }

  const shouldShowLegacyError = action.shouldShowLegacyError;

  if (action.shouldShowLegacyError) {
    action = _.extend({}, action);
    delete action.shouldShowLegacyError;
  }

  const types: Array<string> = [];

  if (!action.types) {
    if (_.isString(action.type)) {
      types.push(action.type);
      types.push(`${action.type}_DONE`);
      types.push(`${action.type}_FAIL`);
    }
  } else if (_.isArray(types)) {
    types.push.apply(types, action.types);
  } else {
    throw new Errors.ActionTypeNotValid({ action });
  }

  return withMonotonicTimestamp(timestampId => {
    const newActionBlacklistedKeys = [
      'formatError',
      'formatSuccess',
      'onDangerouslyReceiveSuccess',
      'promise',
      'resultKey',
      'shouldSilenceErrors',
    ];

    const createAction = (type, data) => ({
      ..._.omit(action, ...newActionBlacklistedKeys),
      ...data,
      ...(isTest ? {} : { timestampId }),
      type,
    });

    const resultKey = action.resultKey || 'result';

    if (types[0]) {
      next(createAction(types[0]));
    }

    const promise = action.promise.then(
      result => {
        let newResult;
        if (types[1]) {
          const formatSuccess =
            action.formatSuccess || ((res, key) => ({ [key]: res }));
          newResult = next(
            createAction(types[1], formatSuccess(result, resultKey))
          );
        }
        if (action.onDangerouslyReceiveSuccess) {
          // Yuck, yuck, yuckety yuck, just a little while longer.
          action.onDangerouslyReceiveSuccess();
        }
        return newResult;
      },
      error => {
        const shouldRequireLogin = defaultShouldRequireLogin;

        // Let's route to the login page if this is an auth error and this page requires login.
        if (shouldRequireLogin(error, getState(), routes)) {
          window.location = urlWithNext(
            `${ZAPIER_APP_BASE_URL}/app/login`,
            `${window.location.host}/${action.meta.url}`
          );
          return Promise.resolve();
        }

        if (types[2]) {
          const formatError = action.formatError || (err => ({ error: err }));
          next(createAction(types[2], formatError(error)));
        }

        if (shouldShowLegacyError) {
          z.app.errorHandler.showErrorMessage(
            error.status,
            error.statusText,
            error.responseText
          );
        }

        if (action.shouldSilenceErrors !== true) {
          throw error;
        }
        return undefined;
      }
    );

    return promise;
  });
};
