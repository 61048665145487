import { get } from 'lodash';

import { singular } from '@zapier/common-utils';

import { buildZapLink, ZapMeta } from 'app/developer-v3/utils';
import { useRedux } from '@zapier/toolbox-redux-framework';
import {
  currentAppTitle,
  currentAppVersion,
  currentSelectedApi,
  currentService,
  currentServiceType,
} from 'app/developer-v3/selectors';

export const DEFAULT_REFERRER = 'platform-visual-builder';

const mapState = state => {
  const serviceType = currentServiceType(state);

  return {
    serviceType,
    service: serviceType ? currentService(serviceType, state) : {},
    selectedApi: currentSelectedApi(state),
    version: currentAppVersion(state),
    title: currentAppTitle(state),
  };
};

function makeCreateZapUrl(metaDefaults) {
  return (overrides?: ZapMeta | {}) => {
    const zapMeta = {
      ...metaDefaults,
      ...overrides,
    };

    return zapMeta.action
      ? buildZapLink(zapMeta, { referrer: DEFAULT_REFERRER })
      : undefined;
  };
}

export default function useZapUrl() {
  const { service, serviceType, selectedApi, version, title } = useRedux(
    mapState
  );

  const zapMeta = {
    action: service.key,
    selectedApi,
    platformSchemaType: singular(serviceType || ''),
    service: {
      version,
      name: title,
    },
    actionLabel: get(service, ['display', 'label']),
  };

  return makeCreateZapUrl(zapMeta);
}
