// @flow weak

import _ from 'lodash';

import { RouterSelectors } from '@zapier/toolbox-redux-framework';

import * as CliApp from 'app/entities/CliApp';
import * as CliAppDefinition from 'app/entities/CliAppDefinition';
import { rootKey } from 'app/developer-v3/constants';

export const selectFormFields = (formType, state) =>
  _.get(state, [rootKey, 'formFields', formType], []);

export const selectAppId = state => {
  const id = RouterSelectors.getParam('id', state);
  return parseInt(id, 10);
};

export const selectAppVersion = state => {
  return RouterSelectors.getParam('version', state);
};
export const selectVersionNumber = state =>
  RouterSelectors.getParam('version', state);

export const selectAppEntity = state => {
  const entityId = selectAppId(state);
  return CliApp.selectors.all.entity(entityId, state);
};

export const selectAppVersionEntity = state =>
  CliAppDefinition.selectors.all.findByAppIdAndVersion(
    selectAppId(state),
    selectVersionNumber(state),
    state
  );

export const selectActiveVersions = state => {
  const app = selectAppEntity(state);
  return CliApp.selectors.activeVersions(app);
};

export default {
  selectActiveVersions,
  selectAppEntity,
  selectAppId,
  selectAppVersion,
  selectFormFields,
};
