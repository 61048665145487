// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import { Profile } from '@zapier/shared-entities';

import ProfileContext, { type ProfileType } from './ProfileContext';

type Props = {
  children: React.Node,
  isProfileLoading: boolean,
  loadProfile: () => void,
  profile: ProfileType,
};

const createLazyProfileContext = ({
  profile,
  isProfileLoading,
  loadProfile,
}) => ({
  // Using a getter here enables lazy loading of the profile
  get profile() {
    if (!profile && !isProfileLoading) {
      loadProfile();
    }
    return profile;
  },

  isProfileLoading,
});

const ProfileProvider = ({ children, ...profileProps }: Props) => (
  <ProfileContext.Provider value={createLazyProfileContext(profileProps)}>
    {children}
  </ProfileContext.Provider>
);

const mapStateToProps = state => ({
  isProfileLoading: Profile.selectors.all.isLoadImpending(state),
  profile: Profile.selectors.all.currentProfile(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadProfile() {
    dispatch(Profile.actions.loadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileProvider);
