import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import useRedirectToAuth from './useRedirectToAuth';
import { getQueryKey } from './useQueryPartnerProfile';

import type { PartnerProfile } from 'app/developer-v3/types/partnerProfile';

const MUTATION_KEY = 'mutate-partner-profile';

function formatToRequestBody(partnerProfile: PartnerProfile) {
  const {
    firstName,
    jobTitle,
    lastName,
    memberships,
    roles,
    tosAgreement,
  } = partnerProfile;

  return {
    first_name: firstName,
    job_title: jobTitle,
    last_name: lastName,
    memberships: memberships.map(membership => ({
      app: {
        id: membership.app.id,
        image: membership.app.image,
        is_beta: membership.app.isBeta,
        is_public: membership.app.isPublic,
        slug: membership.app.slug,
        title: membership.app.title,
        type: membership.app.type,
      },
      opted_in: membership.optedIn,
      type: membership.type,
    })),
    roles,
    tosAgreement,
  };
}

function useMutationPartnerProfile(
  options?: UseMutationOptions<
    void,
    Error,
    PartnerProfile,
    { partnerProfile: PartnerProfile }
  >
) {
  const { redirectToAuth, secret } = useRedirectToAuth();
  const notify = useNotify();

  const queryClient = useQueryClient();
  const queryKey = getQueryKey(secret);

  const mutationKey = MUTATION_KEY;
  const url = new URL('/api/platform/cli/profile', ZAPIER_APP_BASE_URL);
  if (secret) {
    url.search = new URLSearchParams({ secret }).toString();
  }

  const mutation = useMutation({
    mutationKey,
    async mutationFn(partnerProfileNext) {
      fetchJson(url.href, {
        body: JSON.stringify(formatToRequestBody(partnerProfileNext)),
        method: 'POST',
      });
    },
    onMutate: async partnerProfileNext => {
      // Optimistic update.
      await queryClient.cancelQueries(queryKey);
      const partnerProfile = queryClient.getQueryData<PartnerProfile>(queryKey);
      queryClient.setQueryData(queryKey, partnerProfileNext);
      // This builds the context passed over to onError/onSuccess callbacks.
      return { partnerProfile };
    },
    onError: (error: any, _variables, context) => {
      if (error.responseText?.includes?.('Key Not Authorized') && secret) {
        redirectToAuth();
      } else {
        queryClient.setQueryData(queryKey, context.partnerProfile);
        notify.failure(error.message, mutationKey);
      }
    },
    onSuccess: () => notify.success('Successfully updated partner profile.'),
    ...options,
  });

  return { ...mutation, mutationKey };
}

export default useMutationPartnerProfile;
