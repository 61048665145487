import { isEmpty } from 'lodash';

import { RequestMethodTypes } from 'app/developer-v3/types/request';

import type { RequestSchema } from 'app/developer-v3/platformSchema/request';

function cleanRequest(request: RequestSchema = {}) {
  const {
    body,
    headers,
    method,
    params,
    removeMissingValuesFrom,
    url,
    ...rest
  } = request;

  const hasBody = !isEmpty(body);
  const hasHeaders = !isEmpty(headers);
  const hasMethod = !!method;
  const isGet = method === RequestMethodTypes.GET;
  const hasParams = !isEmpty(params);
  const hasRemoveMissingValuesFrom = !isEmpty(removeMissingValuesFrom);
  const hasUrl = !!url;

  return {
    ...(hasBody ? { body } : {}),
    ...(hasHeaders ? { headers } : {}),
    ...(hasMethod && !isGet ? { method } : {}),
    ...(hasParams ? { params } : {}),
    ...(hasRemoveMissingValuesFrom ? { removeMissingValuesFrom } : {}),
    ...(hasUrl ? { url } : {}),
    ...rest,
  };
}

export { cleanRequest };
