// @flow

import _ from 'lodash';

import _str from 'app/common/underscore-string';
import fuzzysearch from 'app/common/fuzzysearch';

import type { Keys } from 'app/common/types';

const PRETTY_TYPE_BY_TYPE = {
  read: 'Trigger',
  write: 'Action',
  search: 'Action',
  search_or_write: 'Action',
  filter: 'Filter',
};

// Use this as a default object for applicable cases
// like for a key in `WeakMap` (for memoization) or
// as a default value that would keep a component pure,
// and so on.
export const emptyObject = Object.freeze({});

const vowels = {
  a: true,
  e: true,
  i: true,
  o: true,
  u: true,
};

const MISSING = undefined;

const getKeys = (keys: ?Keys): string[] => {
  if (keys == null) {
    return [];
  }
  if (!Array.isArray(keys)) {
    return [keys];
  }
  return keys;
};

export const get = (
  thing: Function | ?{} | ?Array<any>,
  keys: Keys,
  defaultValue: mixed
): any => {
  let context = null;
  let keyIndex = 0;

  const keysArray = getKeys(keys);

  while (keyIndex < keysArray.length && thing != null) {
    const key: any = keysArray[keyIndex];
    if (_.isArray(key)) {
      if (typeof thing === 'function') {
        thing = thing.apply(context, key);
        context = null;
      } else {
        thing = undefined;
      }
    } else if (thing != null) {
      context = thing;
      // key can be number or string, cast to silence flow
      thing = thing[key];
    }
    keyIndex++;
  }

  if (thing == null && defaultValue != null) {
    return defaultValue;
  }

  return thing;
};

export const exists = (thing: any, ...rest: Keys[]) => {
  if (rest.length > 0) {
    thing = get(thing, ...rest);
    return exists(thing);
  }

  return typeof thing !== 'undefined' && thing !== null;
};

export const set = (thing: Function | {}, keys: string[], value: any) => {
  // once we turn on flow everywhere we can remove these dynamic type check:
  if (
    thing === null ||
    (typeof thing !== 'object' && typeof thing !== 'function')
  ) {
    throw new Error('Must use set on an object or function.');
  }
  if (keys.length === 0) {
    throw new Error('Must provide keys to set an object.');
  }
  if (typeof keys === 'string') {
    throw new Error('keys must be an array, not a string');
  }

  for (let keyIndex = 0; keyIndex < keys.length - 1; keyIndex++) {
    const key = keys[keyIndex];
    const subThing = thing[key];
    if (
      !exists(subThing) ||
      (typeof subThing !== 'object' && typeof subThing !== 'function')
    ) {
      thing[key] = {};
    }
    thing = thing[key];
  }

  thing[keys[keys.length - 1]] = value;
};

export const deepSearch = <T>(
  query: string,
  value: T,
  depth: number = 0
): ?T => {
  // console.log(depth, value);
  if (Array.isArray(value)) {
    let outValue = value
      .map(v => deepSearch(query, v, depth + 1))
      .filter(v => v !== MISSING);
    if (_.isEmpty(outValue) && depth) {
      outValue = MISSING;
    }
    // console.log('out array:', value, outValue);
    return outValue;
  } else if (_.isObject(value)) {
    let outValue = _.chain(value)
      .toPairs()
      .map(([k, v]) => {
        const kSearch = deepSearch(query, k, depth + 1);
        const vSearch = deepSearch(query, v, depth + 1);
        if (kSearch === MISSING && vSearch === MISSING) {
          return MISSING;
        }
        return [kSearch || k, vSearch || v];
      })
      .filter(v => v !== MISSING)
      .fromPairs()
      .value();
    if (_.isEmpty(outValue) && depth) {
      outValue = MISSING;
    }
    // console.log('out obj:', value, outValue);
    return outValue;
  } else {
    let outValue = value;
    if (!fuzzysearch(query, String(value))) {
      outValue = MISSING;
    }
    // console.log('out single:', value, outValue);
    return outValue;
  }
};

export const superHumanize = (string: string) => {
  // the standard str.humanize kills _id$
  string = _str.underscored(string).replace(/_/g, ' ').replace(/\[\]/g, ' ');
  const out = _str.titleize(_str.capitalize(string));
  const words = _str.words(out);
  const subs = {
    Url: 'URL',
    Uri: 'URI',
    Html: 'HTML',
    Id: 'ID',
    id: 'ID',
  };
  _.each(subs, function (v, k) {
    const index = words.indexOf(k);
    if (index !== -1) {
      words[index] = v;
    }
  });
  return words.join(' ');
};

export const prettyType = (typeOf: string) => {
  return PRETTY_TYPE_BY_TYPE[typeOf];
};

export const prefixWithArticle = (word: string) => {
  if (vowels[word[0].toLowerCase()]) {
    return 'an ' + word;
  } else {
    return 'a ' + word;
  }
};
export const possessive = (string: string) => {
  return string + (string.slice(-1) === 's' ? "'" : "'s");
};

export const isListChild = (field: {}) => {
  return get(field, ['parent', 'parent', 'list'], false);
};

export const firstItemIfArray = (thing: mixed) => {
  if (Array.isArray(thing)) {
    return _.head(thing);
  }
  return thing;
};

export const cleanObjectOfFormaticKeys = (obj: {}) => {
  return _.chain(obj)
    .map((v, k) => (String(k).indexOf('$$__temp__') === 0 ? null : [k, v]))
    .compact()
    .fromPairs()
    .value();
};

export const cleanObjectOfEmptyValues = <O: {}>(obj: O): O => {
  const emptyAndNotNum = (v: any, k: string) => {
    // prune formatic "empty" objects of {$$__temp__: ''}
    if (v && _.isObject(v) && !_.isArray(v) && !_.isEmpty(v)) {
      v = cleanObjectOfFormaticKeys(v);
    }
    // a normal empty object like {} or ''
    if (!_.isNumber(v) && !_.isBoolean(v) && _.isEmpty(v)) {
      return null;
    }
    // an "empty" array like ['']
    if (_.isArray(v) && v.length === 1 && v[0] === '') {
      return null;
    }
    // an "empty" object like {'': ''}
    if (
      _.isObject(v) &&
      _.keys(v).length === 1 &&
      _.keys(v)[0] === '' &&
      v[''] === ''
    ) {
      return null;
    }
    // otherwise return the object
    return [k, v];
  };
  const cleanPairs = _.chain(obj).map(emptyAndNotNum).compact().value();
  const wasAlreadyClean =
    _.isObject(obj) && cleanPairs.length === Object.keys(obj).length;

  // $FlowFixMe I'm not going to fight with flow and lodash
  return wasAlreadyClean ? obj : _.fromPairs(cleanPairs);
};

export const safeExtend = (...args: Array<{}>) => {
  const saveKeys = [];
  const expectedKeyCount = args.reduce((prev, curr) => {
    if (_.isObject(curr)) {
      const keys = Object.keys(curr);
      saveKeys.push(keys);
      return keys.length + prev;
    }
    return prev;
  }, 0);
  const result = _.extend(...args);
  const resultKeyCount = Object.keys(result).length;
  if (resultKeyCount !== expectedKeyCount) {
    const counts = _.countBy(_.flatten(saveKeys), key => key);
    const collisionKeys = Object.keys(counts).filter(key => counts[key] > 1);
    throw new Error(
      'safeExtend expected no key collisions, but these keys collided: ' +
        collisionKeys.join(', ')
    );
  }
  return result;
};

export const flattenObj = (input: {}, splitter: ?string) => {
  let flatObject, k, x;
  if (splitter == null) {
    splitter = '__';
  }
  const output = {};
  for (k in input) {
    if (!input.hasOwnProperty(k)) {
      continue;
    }
    if (typeof input[k] === 'object') {
      flatObject = flattenObj(input[k], splitter);
      for (x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) {
          continue;
        }
        output[k + splitter + x] = flatObject[x];
      }
    } else {
      output[k] = input[k];
    }
  }
  return output;
};

export const unflatten = (input: {}, splitter: ?string) => {
  let k, key, part, parts, t;
  if (splitter == null) {
    splitter = '__';
  }
  const output = {};
  for (k in input) {
    t = output;
    parts = k.split(splitter);
    key = parts.pop();
    while (parts.length) {
      part = parts.shift();
      t = t[part] = t[part] || {};
    }
    t[key] = input[k];
  }
  return output;
};

export const escapeRegExp = function (str: ?string): string {
  if (str == null) {
    return '';
  }
  return String(str).replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
};

export const isPromise = (thing: any) => {
  return _.isObject(thing) && _.isFunction(thing.then);
};

export const isProductionEnv = () => process.env.NODE_ENV === 'production';

export const deepFreeze = (obj: {}) => {
  Object.freeze(obj);

  Object.getOwnPropertyNames(obj).forEach(function (prop) {
    if (
      Object.prototype.hasOwnProperty.call(obj, prop) &&
      obj[prop] !== null &&
      (typeof obj[prop] === 'object' || typeof obj[prop] === 'function') &&
      !Object.isFrozen(obj[prop])
    ) {
      deepFreeze(obj[prop]);
    }
  });

  return obj;
};

export const subscribeAnimationFrame = (onFrame: Function) => {
  let isListening = false;
  const listenToFrame = () => {
    requestAnimationFrame((...args) => {
      if (isListening) {
        onFrame(...args);
        listenToFrame();
      }
    });
  };
  isListening = true;
  listenToFrame();
  return () => {
    isListening = false;
  };
};

// Will return params with curlies replaced by whatever modify callback returns.
// `modify` is passed `id` and `key` as arguemnts.
export const replaceParams = (
  params: any,
  modify: Function,
  options: Object = {}
) => {
  if (_.isString(params)) {
    return params.replace(/{{(([0-9]+)__)?([^}]+)}}/g, function (
      curly,
      prefix,
      id,
      key
    ) {
      return modify(id, key, curly);
    });
  } else if (Array.isArray(params)) {
    return params.map(value => replaceParams(value, modify, options));
  } else if (_.isObject(params)) {
    const newParams = _.fromPairs(
      _.map(params, (value, key) => [
        key,
        replaceParams(value, modify, options),
      ])
    );
    // Filters are special snowflakes. The key property of filter clauses is not
    // a curly, but rather just a key. So we have to handle filter clauses as a
    // special case.
    if (options.isFilter) {
      if (Array.isArray(newParams.filter_criteria)) {
        const filterCriteria = newParams.filter_criteria.map(clause => {
          if (_.isObject(clause) && _.isString(clause.key)) {
            // Only do this if there are no curlies here already.
            if (clause.key.indexOf('{') < 0) {
              const curlyClauseKey = `{{${clause.key}}}`;
              const renderedClauseKey = replaceParams(curlyClauseKey, modify);
              return {
                ...clause,
                key: renderedClauseKey.replace('{{', '').replace('}}', ''),
              };
            }
          }
          return clause;
        });
        return {
          ...newParams,
          filter_criteria: filterCriteria,
        };
      }
    }
    return newParams;
  } else {
    return params;
  }
};

export const clamp = (min: number, max: number, val: number) =>
  Math.max(min, Math.min(val, max));

// Borrowed from MDN
export const ensureArray = <T>(value: T | Array<T>): Array<T> =>
  Array.isArray(value) ? value : [value];

export const flattenReduce = (flatArr: [], nestedArr: []) => {
  return [...flatArr, ...ensureArray(nestedArr)];
};

export const uniqueFilter = (item: any, idx: number, arr: []) => {
  return arr.indexOf(item) === idx;
};

export const arrayToObjectByKey = (key: string, array: ?Array<*>) =>
  (array || []).reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    {}
  );

export const toString = (thing: mixed) => (exists(thing) ? String(thing) : '');

export const toLowerCaseString = (thing: mixed): string =>
  toString(thing).toLowerCase();

export const matchesQuery = (query: string, string: mixed) => {
  if (!exists(string)) {
    return false;
  }
  return toLowerCaseString(string).indexOf(toLowerCaseString(query)) > -1;
};

export const matchesQueryForKeypath = (
  query: string,
  keypath: string | Array<string>,
  obj: {}
) => {
  return matchesQuery(query, _.get(obj, keypath));
};

export const compareStrings = (string1?: string, string2?: string) => {
  const s1 = (string1 || '').toLowerCase();
  const s2 = (string2 || '').toLowerCase();

  if (s1 < s2) {
    return -1;
  }
  return s1 === s2 ? 0 : 1;
};

type SortOrderOrTitleArg = {
  sortOrder?: number,
  title?: string,
};

export const compareBySortOrderOrTitle = (
  f1: SortOrderOrTitleArg,
  f2: SortOrderOrTitleArg
) => {
  if (f1.sortOrder && f2.sortOrder) {
    return f1.sortOrder - f2.sortOrder;
  }

  if (f1.sortOrder || f2.sortOrder) {
    return f1.sortOrder ? -1 : 1;
  }

  return compareStrings(f1.title, f2.title);
};

export const compareVersions = (version1: string, version2: string) => {
  if (version1 === version2) {
    return 0;
  }
  const v1s = version1.split('.').map(part => parseInt(part, 10));
  const v2s = version2.split('.').map(part => parseInt(part, 10));
  for (let i = 0; i < Math.max(v1s.length, v2s.length); i++) {
    const v1i = v1s[i] || 0;
    const v2i = v2s[i] || 0;
    if (v1i !== v2i) {
      return v1i - v2i;
    }
  }
  return v1s.length - v2s.length;
};

export const infinitize = (num: number | string) =>
  num === -1 || num === 'unlimited' ? Infinity : Number(num);

export const uninfinitizeWith = (alt: number, num: number) =>
  num === Infinity ? alt : num;

export const uninfinitize = _.partial(uninfinitizeWith, 'unlimited');

const createLogicalCompositorWith = (method: string) => (
  ...predicates: any
) => (...args: []) => predicates[method](predicate => predicate(...args));

export const andify = createLogicalCompositorWith('every');
export const orify = createLogicalCompositorWith('some');

export const partialWithDefault = (
  fn: Function,
  outerArgs: mixed[] = [],
  defaultValue: any
) => (...innerArgs: mixed[]) => {
  const result = fn(...ensureArray(outerArgs), ...innerArgs);
  return result === undefined || result === null ? defaultValue : result;
};

export const getCookie = (id: string) => {
  let result = null;

  _.forEach(document.cookie.split('; '), cookie => {
    const [name, value] = cookie.split('=');

    if (name === id) {
      result = value;
      return;
    }
  });

  return result;
};

export const setCookie = (
  id: string,
  value: string,
  maxAge: number,
  path: string = '/'
) => {
  const maxAgeValue = maxAge ? `; max-age=${maxAge}` : '';
  document.cookie = `${id}=${value}${maxAgeValue}; path=${path}`;
};

export const createId = () => {
  // we used to use a uuid... but... legacy rules indicate the
  // group and rule ids need to be 64-bit signed integers :'(
  // but, of course, javascript's integers are only 53-bits, so
  // we use MAX_SAFE_INTEGER for a base
  return Math.floor(Math.random() * (Math.pow(2, 53) - 1));
};

export const getInitialsFromString = (str: string): string[] => {
  return str.split(/\s+/).map(s => s[0]);
};

// Borrowed from http://bit.ly/2oKP2ll
export const downloadFile = (filename: string, content: string) => {
  const el = document.createElement('a');
  const file = new File([content], filename, { type: 'application/json' });
  const href = URL.createObjectURL(file);
  el.setAttribute('href', href);
  el.setAttribute('download', filename);
  el.style.display = 'none';
  if (document.body !== null) {
    document.body.appendChild(el);
  }
  el.click();
  if (document.body !== null) {
    document.body.removeChild(el);
  }
};

export const matchTokensAndSpace = /({{|}}|\s)/;

export const hasOpenTag = (stringWithTokens: string = '') => {
  const openTag = '{{';
  const closeTag = '}}';
  const parts = stringWithTokens.split(matchTokensAndSpace);

  return parts.some((part, i, allParts) => {
    const isText = part !== openTag && part !== closeTag;
    const isUnclosed =
      allParts[i - 1] === openTag && allParts[i + 1] !== closeTag;

    return isText && isUnclosed;
  });
};
