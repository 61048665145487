import { useQuery, UseQueryOptions } from 'react-query';

import { getJson } from '@zapier/toolbox-browser-fetch';

const QUERY_KEY_PREFIX = 'query-service-info';
const getQueryKey = (slug: string) => `${QUERY_KEY_PREFIX}-${slug}`;

function useQueryServiceInfo(
  { slug, fields = 'images,name' }: { slug: string; fields?: string },
  options?: UseQueryOptions<{ name: string; images: { url_64x64: string } }>
) {
  const queryKey = getQueryKey(slug);
  const url = `/api/v4/services/${slug}/?fields=${fields}`;

  const { data, error, ...rest } = useQuery(
    queryKey,
    () => getJson(url, { credentials: 'include' }),
    options
  );

  const name = data?.name ?? '';
  const images = data?.images ?? { url_64x64: '' };

  return { ...rest, images, name, error, queryKey };
}

export default useQueryServiceInfo;
export { getQueryKey, QUERY_KEY_PREFIX };
