import { readCookie } from '@zapier/cookies';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

const getCSRFTokenFromCookie = readCookie('csrftoken');

async function getCSRFTokenFromServer() {
  const url = new URL('/api/v3/csrf', ZAPIER_APP_BASE_URL);
  const getCSRFHeader = await fetch(url.href, {
    credentials: 'include',
    method: 'HEAD',
  });
  const response = await getCSRFHeader;
  return response.headers.get('x-upstreamcsrftoken');
}

let CSRFTokenPromise: Promise<string> | null = null;

export const getCSRFToken = async () => {
  if (!CSRFTokenPromise) {
    CSRFTokenPromise = Promise.resolve(
      getCSRFTokenFromCookie || getCSRFTokenFromServer()
    );
  }
  return await CSRFTokenPromise;
};
