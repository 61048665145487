import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import { getQueryKey } from './useQueryIssue';

import type { Issue } from './types';

const MUTATION_KEY = 'mutate-issue-status';

import { Status, WorkStatus, ResolutionStatus } from './constants';

type Args = {
  status?: Status;
  workStatus?: WorkStatus;
  resolutionStatus?: ResolutionStatus;
};

function useMutationIssue(
  { integrationId, issueId }: { integrationId: number; issueId: number },
  options?: UseMutationOptions<void, Error, Args, { issue: Issue }>
) {
  const notify = useNotify();

  const queryClient = useQueryClient();
  const queryKey = getQueryKey({ integrationId, issueId });

  const mutationKey = MUTATION_KEY;
  const url = new URL(
    `/api/platform/cli/apps/${encodeURIComponent(
      integrationId
    )}/issues/${encodeURIComponent(issueId)}`,
    ZAPIER_APP_BASE_URL
  );

  const mutation = useMutation({
    mutationKey,
    async mutationFn({ status, workStatus, resolutionStatus }) {
      // Only allows to switch between open and resolved. It’s the only use
      // case today.
      if (
        status !== undefined &&
        status !== Status.open &&
        status !== Status.resolved
      ) {
        throw new Error(`${status} is not a valid issue status`);
      }

      if (
        workStatus !== undefined &&
        workStatus !== WorkStatus.planned &&
        workStatus !== WorkStatus.inProgress &&
        workStatus !== WorkStatus.notStarted
      ) {
        throw new Error(`${workStatus} is not a valid issue work status`);
      }

      if (
        resolutionStatus !== undefined &&
        !Object.values(ResolutionStatus).includes(resolutionStatus)
      ) {
        throw new Error(`${resolutionStatus} is not a valid issue resolution`);
      }

      const body = JSON.stringify({
        status,
        work_status: workStatus,
        resolution: resolutionStatus,
      });
      fetchJson(url.href, { body, method: 'PATCH' });
    },
    onError: (error: any) => {
      notify.failure(error.message, mutationKey);
    },
    onSuccess: () => {
      notify.success('Successfully updated the issue.');
      queryClient.invalidateQueries(queryKey);
    },
    ...options,
  });

  return { ...mutation, mutationKey };
}

export default useMutationIssue;
