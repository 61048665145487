// !!! IMPORTANT !!!
//
// This file is shared between the zapier/zapier and zapier/next-js repository.
// Any changes made here should be reflected in there as well.
// For more information, see: https://zapier.quip.com/xaKqAOpdhpY2#ZXFACA8Jnp9

import React from 'react';
import _ from 'lodash';

const getAriaChecked = checked =>
  _.isString(checked) ? checked : `${!!checked}`;

const getTabIndexFromProps = props => {
  const { disabled, tabIndex } = props;

  if (disabled) {
    return undefined;
  }

  if (_.isUndefined(tabIndex)) {
    return 0;
  }

  return tabIndex;
};

const propsForRoles = {
  checkbox: props => ({ 'aria-checked': getAriaChecked(props.checked) }),
};

const addPropsForRole = (role, props) =>
  role in propsForRoles
    ? {
        ...props,
        ...propsForRoles[role](props),
      }
    : props;

const AccessibleElement = ({ tag, domRef, ...props }) => {
  if (!props.onClick) {
    return React.createElement(tag, props);
  }

  const tabIndex = getTabIndexFromProps(props);
  const onClick = props.disabled ? null : props.onClick;
  const role = _.isUndefined(props.role) ? 'button' : props.role;

  return React.createElement(
    tag,
    addPropsForRole(role, {
      ...props,
      tabIndex,
      onClick,
      role,
      'aria-disabled': props.disabled,
      ref: domRef,
    })
  );
};

export default AccessibleElement;

export const Anchor = props => {
  if (!props.href && props.onClick) {
    const style =
      props.style && typeof props.style.cursor === 'undefined'
        ? {
            ...props.style,
            cursor: 'pointer',
          }
        : { cursor: 'pointer' };
    return <AccessibleElement tag="a" {...props} style={style} />;
  }
  return <AccessibleElement tag="a" {...props} />;
};
export const Div = props => <AccessibleElement tag="div" {...props} />;
export const Span = props => <AccessibleElement tag="span" {...props} />;
export const Heading1 = props => <AccessibleElement tag="h1" {...props} />;
export const Heading2 = props => <AccessibleElement tag="h2" {...props} />;
export const Heading3 = props => <AccessibleElement tag="h3" {...props} />;
export const Header = props => <AccessibleElement tag="header" {...props} />;
export const ListItem = props => <AccessibleElement tag="li" {...props} />;
