import { capitalize, escape, flow, startCase } from 'lodash';

import { singular } from '@zapier/common-utils';

const createChangelogString = ({
  reasons,
  description,
}: {
  reasons: { [key: string]: boolean };
  description: string;
}): string =>
  Object.keys(reasons)
    .filter((key: string) => reasons[key])
    .map(startCase)
    .concat(description)
    .join(', ');

/**
 * Escapes the provided string
 *
 * @param {string} value
 */
const makeTextHtmlSafe = (value: string): string => escape(String(value));

/**
 * Escapes the given string and for every line break, adds a br tag
 *
 * @param {string} value
 */
const convertPlainTextToHtml = (value: string): string =>
  makeTextHtmlSafe(value).split('\n').join('<br />');

const titleize = flow(singular, capitalize);

const toSentenceCase = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export {
  createChangelogString,
  convertPlainTextToHtml,
  makeTextHtmlSafe,
  titleize,
  toSentenceCase,
};
