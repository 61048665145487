import { useState } from 'react';

import { useClient, useTreatments } from '@splitsoftware/splitio-react';

/**
 * @todo Leverage our own useTreatment rather than the useTreatments hook from SplitIO. useTreatments could always return a 'control' treatment regardlessly when components mount initially. It's confusing when the false returned stands for not only the 'off' treatment but also any treatment other than 'on'.
 **/
export function useFeatureFlag(
  flag: string,
  key?: string,
  attributes?: Record<string, any>
): boolean {
  const splitNames = [flag];
  const obj: SplitIO.TreatmentsWithConfig = useTreatments(
    splitNames,
    attributes,
    key
  );
  return obj[flag].treatment === 'on';
}

/**
 * This hook picks up the state of SplitIO client. The treatment of a SplitIO
 * split is evaluated sanely.
 *
 * @see {@link https://help.split.io/hc/en-us/articles/360020863412-General-SDK-Always-getting-Control-treatments} for further information.
 **/
export function useTreatment({ key, name }: { key: string; name: string }) {
  const client = useClient(key);

  const treatment = client.getTreatment(name);
  const hasTreatment = !!treatment && treatment !== 'control';

  const [isReady, setIsReady] = useState(hasTreatment);
  const [isTimeout, setIsTimeout] = useState(false);

  if (!isReady && client) {
    client
      .on(client.Event.SDK_READY, function () {
        setIsReady(true);
        setIsTimeout(false);
      })
      .on(client.Event.SDK_READY_TIMED_OUT, function () {
        setIsReady(false);
        setIsTimeout(true);
      });
  }

  return {
    isReady,
    isTimeout,
    treatment: hasTreatment ? treatment : undefined,
  };
}

/**
 * Old method of checking feature flags using attributes, without any key - equivalent to
 * useFeatureFlag(flag, undefined, attributes).
 * @deprecated
 **/
export function useDeprecatedFeatureFlag(flag, attributes?): boolean {
  return useFeatureFlag(flag, undefined, attributes);
}

export default useFeatureFlag;
