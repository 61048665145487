import { applyChange, diff } from 'deep-diff';
import { fromPairs, intersection, isEqual } from 'lodash';

const quickCopy = o => JSON.parse(JSON.stringify(o));

// it's not a conflict if none of the paths of the server changes overlap with any of the paths from the client
// that is, they're two distinct sets

// if the change.type is N(ew) or D(elete) and isn't a conflict, can blindly apply it
// if the change is E(dit)
//   if the change.rhs is the same for both then we're good
//   if the rhs are different, then we have an issue

// if the chage.type is A(rray)... it's really tough. maybe just mark all array items as conflicts?
// users could have a list of checkboxes that's a combo of both arrays and they figure it out

const isConflict = (a, b) => {
  let l, r;
  if (a.kind === 'A' && b.kind === 'A') {
    [l, r] = [a.item.rhs, b.item.rhs];
    // maybe need to further compare array item change types?
  } else {
    [l, r] = [a.rhs, b.rhs];
  }
  return !isEqual(l, r);
};

const generateChangesMap = changes =>
  fromPairs(changes.map(c => [c.path.join('.'), c]));

const mergeDefinitions = (clientDef, oldServerDef, serverDef) => {
  const serverChanges = diff(oldServerDef, serverDef) || [];
  const clientChanges = diff(oldServerDef, clientDef) || [];

  const serverChangesMap = generateChangesMap(serverChanges);
  const clientChangesMap = generateChangesMap(clientChanges);

  const conflictsPaths = intersection(
    Object.keys(serverChangesMap),
    Object.keys(clientChangesMap)
  ).filter(p => isConflict(serverChangesMap[p], clientChangesMap[p]));

  if (conflictsPaths.length) {
    return { success: false, paths: conflictsPaths };
  } else {
    // apply each client change to the server app
    const updatedDefinition = quickCopy(serverDef);
    clientChanges.forEach(c => applyChange(updatedDefinition, c));
    return { success: true, mergedDefinition: updatedDefinition };
  }
};

export { mergeDefinitions, quickCopy };
