import PropTypes from 'prop-types';
import createReactClass from 'create-react-class'; // eslint-disable-line no-restricted-syntax
import { connect } from 'react-redux';

import { RouterSelectors } from '@zapier/toolbox-redux-framework';

import TrackActions from 'app/track/TrackActions';
import { getLandingPageName } from 'app/track/Selectors';
import { hasLanded, hasVisited } from 'app/track/utils/TrackUtils';

// This component should only mount in `Root` and looks at `localStorage`
// data to determine whether tracking actions should be dispatched. It's
// a very special case and shouldn't really be repeated elsewhere.

const MaybeTrackLandingOnMount = createReactClass({
  displayName: 'MaybeTrackLandingOnMount',

  propTypes: {
    landingPageName: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    referrer: PropTypes.string.isRequired,
    onTrackLanded: PropTypes.func.isRequired,
    onTrackVisited: PropTypes.func.isRequired,
  },

  maybeTrackVisited() {
    if (!hasVisited()) {
      this.props.onTrackVisited({
        referrer: this.props.referrer,
      });
    }
  },

  maybeTrackLanded() {
    if (!hasLanded()) {
      this.props.onTrackLanded(this.props.landingPageName, {
        path: this.props.path,
        referrer: this.props.referrer,
      });
    }
  },

  componentDidMount() {
    this.maybeTrackVisited();
    this.maybeTrackLanded();
  },

  render() {
    return this.props.children;
  },
});

const mapStateToProps = state => ({
  landingPageName: getLandingPageName(state) || 'other',
  path: RouterSelectors.currentUrl(state),
  referrer: document.referrer,
});

const mapDispatchToProps = dispatch => ({
  onTrackVisited(data) {
    dispatch(TrackActions.trackVisited(data));
  },
  onTrackLanded(landingPageName, data) {
    dispatch(TrackActions.trackLanding(landingPageName, data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaybeTrackLandingOnMount);
