import * as DeveloperActions from 'app/developer-v3/Actions';
import { hasUnsavedChanges } from 'app/developer-v3/selectors';
import { notify } from 'app/developer-v3/store/shared/actions';

import type { Dispatch } from 'app/common/types';

const isLastDescendantNodeClicked = event => {
  const { children } = event.currentTarget;
  const lastChild = children[children.length - 1];

  return lastChild.contains(event.target);
};

const warnIfLeavingUnsavedChanges = ({
  cancelRoute,
  getState,
  dispatch,
}: {
  cancelRoute: () => void;
  getState: () => any;
  dispatch: Dispatch;
}) => {
  const state = getState();
  if (hasUnsavedChanges(state)) {
    cancelRoute();
    const message = `It seems you have made changes that weren't saved yet. Please save them, or try going again where you were going.`;
    dispatch(notify.info(message));
    dispatch(DeveloperActions.setUnsavedChanges(false));
  }
};

export { isLastDescendantNodeClicked, warnIfLeavingUnsavedChanges };
