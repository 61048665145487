import { sortBy } from 'lodash';

import {
  APP_STATUSES,
  APP_STATUSES_NAME,
  APP_STATUS_INVITE_ONLY,
  DEPLOYMENT_STATUSES,
  VersionStatus,
} from 'app/developer-v3/constants';

import type { AppType } from 'app/developer-v3/types';
import type { AppStatusName } from 'app/developer-v3/types/statuses';

const allStatuses = {
  private: APP_STATUSES.private,
  pending: APP_STATUSES.pending,
  beta: APP_STATUSES.beta,
  inviteOnly: APP_STATUS_INVITE_ONLY,
  public: APP_STATUSES.public,
};

const legacyAppStatusNames = [
  allStatuses.private,
  allStatuses.pending,
  allStatuses.inviteOnly,
  allStatuses.public,
].map(s => s.name);

const allStatusNames = Object.keys(allStatuses);

const sortAppsByStatus = (apps: Array<AppType>, isDesc: boolean = true) => {
  const order = isDesc ? [...allStatusNames].reverse() : allStatusNames;
  return sortBy(apps, app => order.indexOf(app.status.name));
};

const getStatus = (status: AppStatusName) => {
  return allStatuses[status];
};

const normalizeStatusName = (
  status?: number | AppStatusName
): AppStatusName => {
  const name =
    typeof status === 'number' ? legacyAppStatusNames[status] : status;

  return name || APP_STATUSES_NAME.pending;
};

const getVersionStatus = (deployment: string, deprecationDate?: string) => {
  return deprecationDate && Date.parse(deprecationDate) < Date.now()
    ? VersionStatus.deprecated
    : deployment === DEPLOYMENT_STATUSES.production
    ? VersionStatus.public
    : VersionStatus.private;
};

export { getStatus, getVersionStatus, normalizeStatusName, sortAppsByStatus };
