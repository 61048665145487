/** @jsx jsx */

import { useEffect, useState } from 'react';

import { getKeyInStorage, setKeyInStorage } from '@zapier/local-storage';

const LOCAL_STORAGE_KEY = 'dismissed-help';

const getIds = () => getKeyInStorage(LOCAL_STORAGE_KEY) || {};
const addId = (id: string) =>
  setKeyInStorage(LOCAL_STORAGE_KEY, { ...getIds(), [id]: true });
const isDismissed = (id: string) => !!getIds()[id];

type Params = {
  persistId: string;
};

/**
 * @param persistId id used to save to localStorage upon dismissal. Should be namespaced by a plugin name.
 */
function useDismissable(params: Params) {
  const { persistId } = params;

  const [isVisible, setIsVisible] = useState(
    !persistId || !isDismissed(persistId)
  );

  const dismiss = () => setIsVisible(false);

  useEffect(() => {
    if (persistId && !isVisible && !isDismissed(persistId)) {
      addId(persistId);
    }
  }, [persistId, isVisible]);

  return { dismiss, isVisible };
}

export default useDismissable;
export { LOCAL_STORAGE_KEY };
