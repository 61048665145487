import { ROUTE_TO } from 'redux-router-kit';
import { Profile } from '@zapier/shared-entities';

const appCuesMiddleware = store => next => action => {
  if (action && action.type === ROUTE_TO) {
    // This `ROUTE_TO` action is dispatched before the `window.location` has actually changed.
    // That's a problem because `Appcues.page()` doesn't take any parameters, it just gets the page from `window.location` when it's called.
    // It doesn't seem that any actions are dispatched to indicate that the `ROUTE_TO` is complete.
    // To get around this, we can use setTimeout(..., 0) to put the `Appcues.page()` call at the end of the javascript event queue.
    // This way, the `ROUTE_TO` action will have been processed and `window.location` will have been updated by the time `Appcues.page()` is actually invoked.
    setTimeout(() => window.Appcues?.page(), 0);
  }

  // When a profile fetch is completed
  if (
    action &&
    action.type === 'entity/FETCH_COLLECTION_DONE' &&
    action.entityType === 'partnerProfile'
  ) {
    const state = store.getState();
    const currentProfile = Profile.selectors.all.currentProfile(state);
    const id = Profile.selectors.id(currentProfile);
    const isStaff = Profile.selectors.isStaff(currentProfile);
    // eslint-disable-next-line no-unused-expressions
    window.Appcues?.identify(id, {
      accountId: id,
      isStaff,
    });
  }

  return next(action);
};

export default appCuesMiddleware;
