// @flow weak

// !!! IMPORTANT !!!
//
// This file is shared between the zapier/zapier and zapier/next-js repository.
// Any changes made here should be reflected in there as well.
// For more information, see: https://zapier.quip.com/xaKqAOpdhpY2#ZXFACA8Jnp9
import React from 'react';

const partiallyApplyComponent = (Component, partialProps) => {
  if (!(partialProps && Component)) {
    throw new Error(
      'partiallyApplyComponent requires `partialProps` and `Component` arguments.'
    );
  }
  const PartiallyApplied = props => <Component {...partialProps} {...props} />;
  PartiallyApplied.displayName = `PartiallyApplied${
    Component.displayName || Component.name
  }`;
  return PartiallyApplied;
};

export default partiallyApplyComponent;
