// @flow

import { ROUTE_TO_NEXT } from 'redux-router-kit';

import { shouldScrollToTopForMatchedRoutes } from 'app/common/utils/RouterUtils';

import type { Next } from 'app/common/types';

const createScrollToTopMiddleware = (config: Object) => {
  // `let` since `setRoutes` might change it and destructuring
  // `config` causing flow issues.
  let routes = config.routes;
  const isBrowser = !!document;

  const middleware = () => (next: Next) => (action: Object) => {
    if (!action || action.type !== ROUTE_TO_NEXT || !isBrowser) {
      return next(action);
    }

    const { routeKey } = action.meta;

    if (
      document.documentElement &&
      shouldScrollToTopForMatchedRoutes(routes, routeKey)
    ) {
      document.documentElement.scrollTop = 0;
    }

    return next(action);
  };

  middleware.setRoutes = newRoutes => {
    routes = newRoutes;
  };

  return middleware;
};

export default createScrollToTopMiddleware;
