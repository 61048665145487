import { noop } from 'lodash';
import React, { ReactNode, useCallback, useContext } from 'react';

import {
  COMMON_SECTION_IDS,
  MODES,
  TABS,
  VB_GUIDE_LOCAL_STORAGE_KEY,
} from './constants';

import type { PropertiesType, SubsectionId } from './types';

type SidebarRightContext = {
  activeSubsection: SubsectionId;
  mode: MODES;
  tabActive: TABS;
  update: (properties: Partial<PropertiesType>) => void;
  sectionContext: Record<string, any>;
};

const Context = React.createContext<SidebarRightContext>({
  activeSubsection: COMMON_SECTION_IDS.NONE,
  mode: MODES.DEFAULT,
  tabActive: TABS.NONE,
  update: noop,
  sectionContext: {},
});

const useSidebarRight = () => useContext(Context);

type Props = {
  activeSubsection?: SubsectionId;
  children: ReactNode;
  mode?: MODES;
  sectionContext: Record<string, any>;
  tabActive: TABS;
  update: (properties: PropertiesType) => void;
};

const Provider = (props: Props) => {
  const {
    children,
    activeSubsection,
    mode,
    tabActive,
    sectionContext,
    update,
  } = props;

  const updateProperties = useCallback(
    properties => {
      update(properties);
      localStorage.setItem(VB_GUIDE_LOCAL_STORAGE_KEY, properties.tabActive);
    },
    [update]
  );

  return (
    children && (
      <Context.Provider
        value={{
          mode,
          tabActive,
          update: updateProperties,
          activeSubsection,
          sectionContext,
        }}
      >
        {React.Children.only(children)}
      </Context.Provider>
    )
  );
};

export default Provider;
export { useSidebarRight };
