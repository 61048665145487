import {
  camelCase,
  flatMap,
  identity,
  includes,
  last,
  map,
  mapKeys,
  partial,
  snakeCase,
} from 'lodash';

import {
  EntityActions,
  buildEntityActions,
  buildEntityApi,
  buildEntitySelectors,
} from '@zapier/toolbox-redux-framework';
import {
  onFetchComplete,
  postJson,
  putJson,
} from '@zapier/toolbox-browser-fetch';

import { APP_STATUSES } from 'app/developer-v3/constants';
import { addAppPendingDeletion } from 'app/developer-v3/utils/appsPendingDeletion';
import { isAPIDown } from 'app/developer-v3/utils';
import { partialWithDefault } from 'app/common/CommonUtils';

import type { IActions, IApi, ISelectors } from './types';

export const type = 'cliApp';

export const READY_TO_ACTIVATE_MESSAGE =
  "You can't promote until we've approved your integration";

const API_BASE = '/api/platform/cli/apps';

export const api: IApi = buildEntityApi(type, API_BASE, (cliAppApi: IApi) => {
  cliAppApi.updateApp = (app, id) => {
    return putJson(cliAppApi.modelEndpoint(`${id}`, app));
  };

  cliAppApi.promote = (appId, appVersion, changelog) => {
    return putJson(
      `${API_BASE}/${appId}/versions/${appVersion}/promote/production`,
      { changelog, is_ui: true }
    );
  };

  cliAppApi.publish = (appId, data) => {
    return postJson(
      `${API_BASE}/${appId}/versions/${data.version}/activate`,
      mapKeys(data, (_value, key) => snakeCase(key))
    );
  };

  cliAppApi.delete = (appId, data, getState) => {
    const state = getState();
    const cliAppEntity = state.entities.cliApp.entities[appId].data;
    onFetchComplete(response => {
      if (response.status === 202) {
        addAppPendingDeletion(cliAppEntity);
      }
    });

    return cliAppApi
      .deleteWith(identity, appId, data, getState)
      .then(() => onFetchComplete(() => {}));
  };
});

export const selectors: ISelectors = buildEntitySelectors(
  type,
  (sel: ISelectors) => {
    sel.activationUrl = partial(sel.dataItem, ['']);
    sel.id = partial(sel.dataItem, 'id');
    sel.key = partial(sel.dataItem, 'key');
    sel.homepageUrl = partial(sel.dataItem, 'homepage_url');
    sel.slug = partial(sel.dataItem, 'slug');
    sel.title = partial(sel.dataItem, 'title');
    sel.description = partial(sel.dataItem, 'description');
    sel.imageUrl = partial(sel.dataItem, 'image');
    sel.inviteUrl = partial(sel.dataItem, 'invite_url');
    sel.wasConvertedFromWB = partial(sel.dataItem, 'was_converted_from_wb');
    sel.latestVersion = partial(sel.dataItem, 'latest_version');
    sel.activeVersions = partialWithDefault(sel.dataItem, ['versions'], []);
    sel.status = entity =>
      camelCase(sel.dataItem('status', entity)) || undefined;

    const isStatus = (expected, entity) => sel.status(entity) === expected;
    sel.isPrivate = partial(isStatus, APP_STATUSES.private.name);
    sel.isPending = partial(isStatus, APP_STATUSES.pending.name);
    sel.isBeta = partial(isStatus, APP_STATUSES.beta.name);
    sel.isPublic = partial(isStatus, APP_STATUSES.public.name);
    sel.isPublished = entity => sel.isBeta(entity) || sel.isPublic(entity);

    sel.submitStatus = partial(sel.dataItem, 'submit_status');
    sel.isReadyToActivate = entity => {
      const [message = ''] = sel.statusMessages(entity) || [];
      return typeof message === 'object'
        ? includes(
            map(flatMap(message.violations, 'results'), 'message'),
            READY_TO_ACTIVATE_MESSAGE
          )
        : message.includes(READY_TO_ACTIVATE_MESSAGE);
    };
    sel.serviceId = partial(sel.dataItem, 'service_id');
    // confusingly, 'versions' from the server is only active versions
    // can't change that easily now, but we can be more explicit about what we expect here
    sel.versions = partialWithDefault(sel.dataItem, ['all_versions'], []);
    sel.lastVersion = entity => {
      return last(sel.versions(entity));
    };
    sel.isAPIDown = state =>
      isAPIDown(sel.statusCode(state), sel.all.statusMessages(state));
    sel.intention = partial(sel.dataItem, 'intention');
  }
);

export const actions: IActions = buildEntityActions(
  type,
  selectors,
  api,
  (appActions: IActions) => {
    appActions.promoteApp = (appId, appVersion, changelog) =>
      EntityActions.saveEntity(
        type,
        () => {},
        () => api.promote(appId, appVersion, changelog),
        {},
        appId
      );

    appActions.publish = (appId, data, { onPublish }) => {
      return EntityActions.saveEntity(
        type,
        () => {},
        async () => {
          try {
            const response = await api.publish(appId, data);
            onPublish();
            return response;
          } catch (error) {
            return error;
          }
        },
        {},
        appId
      );
    };

    appActions.resetStatus = appId =>
      EntityActions.resetEntityStatus(type, appId);
  }
);
