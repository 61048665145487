import type { SemVer } from 'app/developer-v3/types/version';
// The editor and platform differ on how they refer to these names
// In platform we have `triggers` and `creates`. The editor calls these `reads` and `writes`.
// The platformSchema type key is called an action in the editor.
// For example, if 'new_issue' is the trigger key in platform (triggers.new_issue.key),
// it is called the `action` in the editor (node.action: 'new_issue') even if it's a trigger.
export enum typeMap {
  trigger = 'read',
  triggers = 'read',
  create = 'write',
  creates = 'write',
  search = 'search',
  searches = 'search',
}

/** Kind of a mishmash of platform and editor types. Trying to straddle both worlds.  */
export type ZapMeta = {
  /** Referring to a node's service in editor.  */
  service: {
    /** The implementation version  */
    version: SemVer;
    /** The integration title */
    name: string;
  };
  /** The platformSchemaType key (`triggers[triggerName].key`) */
  action: string;
  /** The selected_api aka serviceKey. */
  selectedApi: string;
  /** The strange un-nameable thing that is a trigger, create, search etc. in the platform schema */
  platformSchemaType: typeMap;
  /** The label for the above. In platform it would be trigger.display.label */
  actionLabel: string;
};

export type OptionsType = {
  /** Used by team-editor to track usage of the auto-create link */
  referrer: string;
};
