import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import {
  Integration,
  formatResponseBody as formatResponseBodyQueryIntegration,
} from './useQueryIntegration';

const QUERY_KEY_PREFIX = 'query-integrations';
const getQueryKey = () => QUERY_KEY_PREFIX;

function formatResponseBody(body: any): Integration[] {
  const { objects: integrations } = body;
  return integrations.map((integration: any) =>
    formatResponseBodyQueryIntegration(integration)
  );
}

function useQueryIntegrations(options?: UseQueryOptions<Integration[], Error>) {
  const notify = useNotify();

  const queryKey = getQueryKey();
  const url = new URL(`api/platform/cli/apps`, ZAPIER_APP_BASE_URL);
  url.search = new URLSearchParams({ full: 'true' }).toString();

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryIntegrations;
export { getQueryKey, QUERY_KEY_PREFIX };
