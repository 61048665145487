/** Loki sends status in two ways:
 *    1. As a number (status: 1)
 *    2. As a string (statusDisplay: 'Open')
 *
 * Since statusDisplay is more likely to change than status,
 * we'll use the number to derive Loki issue status.
 */
export enum IssueStatus {
  /** `Open` Loki issue */
  open = 1,
  /** `Closed` Loki issue */
  closed = 2,
  /** `Close Pending` Loki issue */
  resolved = 3,
}
