import {
  buildEntityActions,
  buildEntityApi,
  buildEntitySelectors,
} from '@zapier/toolbox-redux-framework';

import { selectAppIdOrWb } from 'app/developer-v3/selectors';

export const type = 'appAdmin';

export const api = buildEntityApi(type, (path, getState) => {
  const state = getState();
  const { appId } = selectAppIdOrWb(state);

  return `/api/platform/cli/apps/${appId}/collaborators/${path}`;
});

export const selectors = buildEntitySelectors(type, () => {});

export const actions = buildEntityActions(type, selectors, api);
