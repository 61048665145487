// @flow

import * as React from 'react';

import AccountProvider from 'app/common/components/contexts/AccountProvider';
import ProfileProvider from 'app/common/components/contexts/ProfileProvider';

type Props = {
  children: React.Node,
};

const AccountAndProfileProvider = (props: Props) => (
  <AccountProvider>
    <ProfileProvider>{props.children}</ProfileProvider>
  </AccountProvider>
);

export default AccountAndProfileProvider;
