import { cleanRequest } from './cleanRequest';

import type { RequestSchema } from 'app/developer-v3/platformSchema/request';
import type { AuthenticationSchema } from 'app/developer-v3/platformSchema/authentication';

function cleanOauth1(oauth1Config: AuthenticationSchema['oauth1Config'] = {}) {
  return {
    ...oauth1Config,
    authorizeUrl: cleanRequest(oauth1Config.authorizeUrl),
    getAccessToken: cleanRequest(oauth1Config.getAccessToken),
    getRequestToken: cleanRequest(oauth1Config.getRequestToken),
  };
}

function cleanOauth2(oauth2Config: AuthenticationSchema['oauth2Config'] = {}) {
  return {
    ...oauth2Config,
    authorizeUrl: cleanRequest(oauth2Config.authorizeUrl),
    getAccessToken: cleanRequest(oauth2Config.getAccessToken),
    refreshAccessToken: cleanRequest(oauth2Config.refreshAccessToken),
  };
}

function cleanSession(
  sessionConfig: AuthenticationSchema['sessionConfig'] = {}
) {
  return {
    ...sessionConfig,
    perform: cleanRequest(sessionConfig.perform),
  };
}

function cleanAuthTest(test: RequestSchema = {}) {
  return cleanRequest(test);
}

function cleanAuth(auth: AuthenticationSchema = {}) {
  const {
    basicConfig,
    customConfig,
    digestConfig,
    oauth1Config,
    oauth2Config,
    sessionConfig,
    test,
    ...rest
  } = auth;

  const isOauth1 = !!oauth1Config;
  const oauth1ConfigClean = isOauth1 ? cleanOauth1(oauth1Config) : {};

  const isOauth2 = !!oauth2Config;
  const oauth2ConfigClean = isOauth2 ? cleanOauth2(oauth2Config) : {};

  const isSession = !!sessionConfig;
  const sessionConfigClean = isSession ? cleanSession(sessionConfig) : {};

  const hasTest = !!test;
  const testClean = hasTest ? cleanAuthTest(test) : {};

  return {
    ...rest,
    ...(isOauth1 ? { oauth1Config: oauth1ConfigClean } : {}),
    ...(isOauth2 ? { oauth2Config: oauth2ConfigClean } : {}),
    ...(isSession ? { sessionConfig: sessionConfigClean } : {}),
    ...(hasTest ? { test: testClean } : {}),
  };
}

function cleanAuthConfig(authConfig: AuthenticationSchema = {}) {
  const { oauth1Config, oauth2Config, sessionConfig } = authConfig;

  const isOauth1 = !!oauth1Config;
  const oauth1ConfigClean = isOauth1 ? cleanOauth1(oauth1Config) : {};

  const isOauth2 = !!oauth2Config;
  const oauth2ConfigClean = isOauth2 ? cleanOauth2(oauth2Config) : {};

  const isSession = !!sessionConfig;
  const sessionConfigClean = isSession ? cleanSession(sessionConfig) : {};

  return {
    ...(isOauth1 ? oauth1ConfigClean : {}),
    ...(isOauth2 ? oauth2ConfigClean : {}),
    ...(isSession ? sessionConfigClean : {}),
  };
}

export {
  cleanAuth,
  cleanAuthConfig,
  cleanOauth1,
  cleanOauth2,
  cleanSession,
  cleanAuthTest,
};
