import { BundleInfo, BundleMeta } from 'app/developer-v3/types/bundle';

export const BUNDLE_INFO: BundleInfo = [
  {
    label: 'authData',
    help: '',
    data: [{ label: 'api_key', help: '' }],
  },
  {
    label: 'inputData',
    help: '',
    data: [],
  },
  {
    label: 'inputRawData',
    help: '',
    data: [],
  },
  {
    label: 'meta',
    help: '',
    data: [],
  },
  {
    label: 'rawRequest',
    help: '',
    data: [],
  },
  {
    label: 'cleanedRequest',
    help: '',
    data: [],
  },
];
export const BASE_BUNDLE_META: BundleMeta = [
  {
    key: 'isLoadingSample',
    help: 'If true, this run was initiated manually via the Zap Editor',
  },
  {
    key: 'isFillingDynamicDropdown',
    help:
      'If true, this poll is being used to populate a dynamic dropdown. You only need to return the fields you specified (such as id and name), though returning everything is fine too',
  },
  {
    key: 'isPopulatingDedupe',
    help:
      'If true, the results of this poll will be used to initialize the deduplication list rather than trigger a zap. You should grab as many items as possible. See also: deduplication',
  },
  {
    key: 'limit',
    help:
      'The number of items you should fetch. -1 indicates there’s no limit. Build this into your calls insofar as you are able',
  },
  {
    key: 'page',
    help:
      'Used in paging to uniquely identify which page of results should be returned',
  },
  {
    key: 'isTestingAuth',
    help:
      '(legacy property) If true, the poll was triggered by a user testing their account (via clicking "test" or during setup). We use this data to populate the auth label, but it’s mostly used to verify we made a successful authenticated request',
  },
];
