import _ from 'lodash';
import { $set, update } from 'qim';

import { createReducer } from '@zapier/toolbox-redux-framework';

import ActionTypes from 'app/service/ActionTypes';

const initialState = {
  categories: [],
  selectedServices: [],
};

const handlers = {
  [ActionTypes.LOAD_USER_SERVICES_DONE](state, { result }) {
    const selectedKeys = _.map(state.selectedServices || [], 'key');
    const servicesToAdd = result.filter(service => {
      return selectedKeys.indexOf(service.key) === -1;
    });
    return update(
      ['selectedServices', $set(state.selectedServices.concat(servicesToAdd))],
      state
    );
  },

  // We can drop canceled out services right away, but to add we need the full data and
  // are this required to do more processing in the _DONE reducer
  [ActionTypes.TOGGLE_USER_SERVICE](state, { service, isSelecting }) {
    const selectedKeys = _.map(state.selectedServices, 'key');
    const keyIndex = selectedKeys.indexOf(service.key);
    if (isSelecting) {
      // User is selecting this app, so add if not already on the list
      if (keyIndex === -1) {
        state = update(
          ['selectedServices', $set([service, ...state.selectedServices])],
          state
        );
      }
    } else {
      // User is removing this app, so remove if on the list
      if (keyIndex !== -1) {
        const oldSelectedServices = [...state.selectedServices];
        const selectedServices = oldSelectedServices
          .slice(0, keyIndex)
          .concat(oldSelectedServices.slice(keyIndex + 1));
        state = update(['selectedServices', $set(selectedServices)], state);
      }
    }
    return state;
  },

  [ActionTypes.TOGGLE_USER_SERVICE_DONE](
    state,
    { service, isSelecting, result }
  ) {
    if (isSelecting) {
      const selectedKeys = _.map(state.selectedServices, 'key');
      const keyIndex = selectedKeys.indexOf(service.key);

      // If the user hastily double-clicked and it's already gone, don't re-add
      // in the callback reducer
      if (keyIndex !== -1) {
        const selectedServices = state.selectedServices
          .slice(0, keyIndex)
          .concat([result])
          .concat(state.selectedServices.slice(keyIndex + 1));
        state = update(['selectedServices', $set(selectedServices)], state);
      }
    }
    return state;
  },
};

export default createReducer(handlers, initialState);
