import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { INVITE_STATUSES, ROLES } from 'app/developer-v3/constants';

import useNotify from './useNotify';

import type { Member } from 'app/developer-v3/types/member';

const QUERY_KEY_PREFIX = 'query-subscribers';
const getQueryKey = (integrationId: string) =>
  `${QUERY_KEY_PREFIX}-${integrationId}`;

function formatResponseBody(body: any): Member[] {
  const { objects } = body;
  const members = objects ?? [];

  return members.map((member: any) => ({
    email: member.email ?? '',
    gravatar: member.gravatar ?? '',
    id: member.id ?? '',
    name: member.name ?? '',
    status: member.status ?? INVITE_STATUSES.waiting,
    role: member.role ?? ROLES.subscriber,
  }));
}

function useQuerySubscribers(
  integrationId: string,
  options?: UseQueryOptions<Member[], Error, Member[]>
) {
  const notify = useNotify();

  const queryKey = getQueryKey(integrationId);
  const url = new URL(
    `/api/platform/v3/integrations/${encodeURIComponent(
      integrationId
    )}/subscribers`,
    ZAPIER_APP_BASE_URL
  );

  const { data, error, ...rest } = useQuery({
    initialData: [],
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    ...options,
  });

  if (error) {
    notify.failure('Failed to fetch subscribers.');
  }

  return { ...rest, subscribers: data, error, queryKey };
}

export default useQuerySubscribers;
export { getQueryKey, QUERY_KEY_PREFIX };
