import { useMutation, UseMutationOptions } from 'react-query';

import { patchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import useNotify from './useNotify';

type Args = {
  causationId?: string;
  correlationId?: string;
  id: number;
};
type Return = Record<string, any>;

const MUTATION_KEY = 'regenerate-oauth-application-client-secret';

function formatResponseBody(body: any): { clientSecret: string } {
  return { clientSecret: body.client_secret };
}

function useRegenerateOAuthAppClientSecret(
  options?: UseMutationOptions<Record<string, any>, Error, Args>
) {
  const notify = useNotify();
  const mutationKey = MUTATION_KEY;

  const mutation = useMutation<Return, Error, Args>({
    mutationKey,
    async mutationFn({ causationId, correlationId, id }) {
      const url = new URL(
        `/api/v4/oauth/applications/${encodeURIComponent(
          id
        )}/regenerate_client_secret`,
        ZAPIER_APP_BASE_URL
      );

      const body: { causation_id?: string; correlation_id?: string } = {};
      if (causationId) {
        body.causation_id = causationId;
      }
      if (correlationId) {
        body.correlation_id = correlationId;
      }

      const response = await patchJson(url.href, {
        body: JSON.stringify(body),
      });

      return formatResponseBody(response);
    },
    onError: error => {
      console.error(error);
      notify.failure('Failed to generate client secret.');
    },
    onSuccess() {
      notify.success('Client secret generated.');
    },
    ...options,
  });

  return {
    isMutating: mutation.isLoading,
    mutate: mutation.mutateAsync,
    mutationKey,
  };
}

export default useRegenerateOAuthAppClientSecret;
