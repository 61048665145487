import { useRedux } from '@zapier/toolbox-redux-framework';

import * as CliAppDefinition from 'app/entities/CliAppDefinition';
import {
  currentAppDefinitionEntity,
  currentAppId,
  isNotAdmin as isNotAdminSelector,
} from 'app/developer-v3/selectors';

import useConversion from './useConversion';
import useVersionLifecycle from './useVersionLifecycle';

/**
 * @typedef {number} Reason
 * @enum {Reason}
 */
export enum Reason {
  NONE,
  PENDING_WB_MIGRATION,
  PENDING_VERSION_LIFECYCLE,
  PRODUCTION,
  USER_COUNT_LIMIT_EXCEEDED,
  NOT_ADMIN,
}

const mapState = state => {
  const appDefinitionEntity = currentAppDefinitionEntity(state);
  const appId = currentAppId(state);
  const isProduction = CliAppDefinition.selectors.isProduction(
    appDefinitionEntity
  );
  const isUserCountLimitExceeded = CliAppDefinition.selectors.doesUserCountExceedLimit(
    appDefinitionEntity
  );

  return {
    appId,
    isNotAdmin: isNotAdminSelector(state),
    isProduction,
    isUserCountLimitExceeded,
  };
};

/**
 * @return {Reason} The main reason for the current version to be read-only.
 */
function useCurrentVersionReadOnlyReason() {
  const {
    appId,
    isNotAdmin,
    isProduction,
    isUserCountLimitExceeded,
  } = useRedux(mapState);
  const versionLifecycle = useVersionLifecycle(appId);
  const wbConversion = useConversion();

  // The statement below is a priority conditional with the highest to the
  // lowest:
  //   Not admin > WB migration > version lifecycle > production > user count exceeded.
  //
  // For instance, when a public version with exceeded user count is pending for
  // a version lifecycle operation, we indicate in the UI that the version is
  // read-only because of versioning. When the operation is done, that version
  // is still read-only but the reason becomes production.
  //
  // Edit with care since the order matters.
  return isNotAdmin
    ? Reason.NOT_ADMIN
    : wbConversion.isMigrationPending
    ? Reason.PENDING_WB_MIGRATION
    : versionLifecycle.isLifecyclePending
    ? Reason.PENDING_VERSION_LIFECYCLE
    : isProduction
    ? Reason.PRODUCTION
    : isUserCountLimitExceeded
    ? Reason.USER_COUNT_LIMIT_EXCEEDED
    : Reason.NONE;
}

export default useCurrentVersionReadOnlyReason;
