import { v4 as uuidv4 } from 'uuid';

import { readCookie } from '@zapier/cookies';

import { trackEvent } from 'app/track/trackingFunctions';

import { EVENT_TYPE, SYSTEM_NAME } from './constants';
import { emit } from './utils';

const TYPE = EVENT_TYPE.PAVE_VIEW;

type PageViewEvent = {
  identity_id: string;
  page_path: string;
  system_name: typeof SYSTEM_NAME;
  url_address: string;
};

function emitPageViewEvent(eventProperties: {
  pagePath: string;
  urlAddress: string;
  userId: number;
}) {
  // TODO: Remove this /v3 pageview event.
  trackEvent('navigate_develop', {
    system_name: 'developer_platform',
    redirected_flag: false,
    identity_id: readCookie('zapidentity'),
    event_id: uuidv4(),
    page_path: eventProperties.pagePath,
    path: eventProperties.pagePath,
    release_id: process.env.COMMIT_HASH,
    timestamp_ms: Date.now(),
    user_agent: navigator.userAgent,
    user_id: String(eventProperties.userId),
    url_address: eventProperties.urlAddress,
    referer: document.referrer,
    learn_more_flag: false,
  });

  const properties: PageViewEvent = {
    identity_id: readCookie('zapidentity'),
    page_path: eventProperties.pagePath,
    system_name: SYSTEM_NAME,
    url_address: eventProperties.urlAddress,
  };

  emit(properties, TYPE);

  return properties;
}

export default emitPageViewEvent;
