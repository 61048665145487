import _ from 'lodash';
import { ROUTE_TO } from 'redux-router-kit';

import { isSubUrlOf, normalizePathname } from '@zapier/url-utils';

// Routes that add this is acceptable on
const acceptableUrlPredicates = [
  currentUrl => isSubUrlOf(currentUrl, '/blog/'),
  currentUrl => {
    const normalizedPathname = normalizePathname(currentUrl);
    if (normalizedPathname === '/apps/integrations/') {
      return false;
    }
    return isSubUrlOf(currentUrl, '/apps/');
  },
];

const getAddThisShareButtons = () => document.getElementById('at4-share');

// This middleware is a hack to hide the `addthis` social sharing buttons.
// Whenever a client navigates away from a page with social sharing enabled,
// this middleware will hide the buttons.
const clearAddThisMiddleware = () => next => action => {
  // Only perform this when the client is planning route
  if (!action) {
    return next(action);
  }

  if (action.type !== ROUTE_TO) {
    return next(action);
  }

  const nextUrl = _.get(action, ['payload', 'href']);

  const retainAddThis = acceptableUrlPredicates.some(predicate =>
    predicate(nextUrl)
  );

  if (!retainAddThis) {
    const target = getAddThisShareButtons();

    if (target) {
      target.style.display = 'none';
      target.style.visibility = 'hidden';
    }
  }

  return next(action);
};

export default clearAddThisMiddleware;
