import ActionTypes from 'app/developer-v3/ActionTypes';
import asyncRoutes from 'app/developer-v3/asyncRoutes';
import reducer from 'app/developer-v3/reducer';

export default {
  actionTypes: ActionTypes,
  routes: asyncRoutes,
  reducers: {
    developerV3: reducer,
  },
};
