import { createContext } from 'react';

import type { PartnerProfile } from 'app/developer-v3/types/partnerProfile';

type Context = {
  isLoading: boolean;
  partnerProfile: PartnerProfile;
  updatePartnerProfile: (partnerProfileNext: PartnerProfile) => Promise<void>;
};

const defaultValues: Context = {
  isLoading: false,
  partnerProfile: {
    customuser: NaN,
    email: '',
    firstName: '',
    jobTitle: '',
    lastName: '',
    memberships: [],
    roles: [],
    tosAgreement: true,
  },
  async updatePartnerProfile() {},
};

const PartnerProfileContext = createContext<Context>(defaultValues);

export default PartnerProfileContext;
