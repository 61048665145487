import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import type { Usage } from 'app/developer-v3/types/partnerDashboard';

type Args = {
  period: 'month' | 'quarter';
  serviceId: number;
};

const QUERY_KEY_PREFIX = 'query-partner-dashboard-usage';
const getQueryKey = (args: Args) =>
  `${QUERY_KEY_PREFIX}-${args.serviceId}-${args.period}`;

function useQueryPartnerDashboardUsage(
  args: Args,
  options?: UseQueryOptions<Usage, Error, Usage>
) {
  const { period, serviceId } = args;

  const notify = useNotify();
  const queryKey = getQueryKey(args);
  const url = new URL(
    `/api/v3/partner/${encodeURIComponent(
      serviceId
    )}/dashboard/usage/${period}`,
    ZAPIER_APP_BASE_URL
  );

  const query = useQuery({
    queryKey,
    queryFn: () => fetchJson(url.href),
    onError: error => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryPartnerDashboardUsage;
export { getQueryKey, QUERY_KEY_PREFIX };
