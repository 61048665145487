import React, { ComponentProps } from 'react';

import { Link as DSLink } from '@zapier/design-system';
import { useLink } from '@zapier/common-hooks';

import BlockLink from './BlockLink';
import { isClientRoute } from './utils';

type Props = ComponentProps<typeof BlockLink | typeof DSLink> & {
  anchor?: string;
};

const defaultProps = {
  anchor: undefined,
};

function Link(props: Props) {
  const {
    anchor = defaultProps.anchor,
    children,
    href,
    onClick,
    target,
  } = props;

  const { routeTo } = useLink();

  const isBlockLink = typeof children !== 'string';
  const LinkComponent = isBlockLink ? BlockLink : DSLink;

  const hrefComplete = href ? `${href}${anchor || ''}` : undefined;

  return (
    <LinkComponent
      {...props}
      href={hrefComplete}
      onClick={event => {
        // eslint-disable-next-line no-unused-expressions
        onClick?.(event);

        if (isClientRoute(href)) {
          if (target) {
            if (target !== '_blank' && isBlockLink) {
              // The child component BlockLink renders an <a> element for a
              // block link meant to open a client route in a new tab. We skip
              // that scenario since the <a> element per se will handle
              // opening the tab.
              const url = new URL(hrefComplete, window.location.origin).href;
              window.open(url, target).focus();
            }
          } else {
            routeTo(hrefComplete);
            // Stop browser from handling routing since routeTo is taking over
            event.preventDefault();
          }
        }
      }}
    >
      {children}
    </LinkComponent>
  );
}

Link.defaultProps = defaultProps;

export default Link;
