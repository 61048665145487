// @flow weak

import PropTypes from 'prop-types';
import React from 'react';
import bemify from 'bemify-js';
import { pure } from 'recompose';

import ErrorSummary from 'app/common/components/ErrorSummary';
import 'app/common/components/ErrorList.scss';

const ErrorList = props => {
  const bem = bemify('error-list');

  return (
    <div className={bem()}>
      {props.errors.map((error, i) => (
        <ErrorSummary
          className={bem('__item')}
          error={error}
          key={i}
          {...props}
        />
      ))}
    </div>
  );
};

ErrorList.propTypes = {
  errors: PropTypes.array.isRequired,
};

const enhanceErrorList = pure;

export default enhanceErrorList(ErrorList);
