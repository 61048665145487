import { v4 as uuidv4 } from 'uuid';

import { readCookie } from '@zapier/cookies';

import { EVENT_TYPE, SYSTEM_NAME } from './constants';
import { emit } from './utils';

import type { EventAction } from './types';

const TYPE = EVENT_TYPE.USER_INTERACTION;

type UserInteractionEvent = {
  absolute_url: string;
  destination_url_address?: string;
  event_action: EventAction;
  event_id: string;
  identity_id: string;
  interaction_goal?: string;
  interaction_name?: string;
  interaction_raw_text?: string;
  option_selected: string;
  release_id: string;
  system_name: typeof SYSTEM_NAME;
  timestamp_ms: number;
  url_address?: string;
};

function emitUserInteractionEvent(eventProperties: {
  absoluteUrl?: string;
  destinationUrlAddress?: string;
  eventAction: EventAction;
  eventId?: string;
  interactionGoal?: string;
  interactionName?: string;
  interactionRawText?: string;
  optionSelected?: string;
  urlAddress?: string;
}) {
  const properties: UserInteractionEvent = {
    absolute_url: eventProperties.absoluteUrl ?? location.href,
    event_action: eventProperties.eventAction,
    event_id: eventProperties.eventId ?? uuidv4(),
    identity_id: readCookie('zapidentity'),
    option_selected: eventProperties.optionSelected ?? 'not_applicable',
    release_id: process.env.COMMIT_HASH,
    system_name: SYSTEM_NAME,
    timestamp_ms: Date.now(),
  };
  if (eventProperties.destinationUrlAddress) {
    properties.destination_url_address = eventProperties.destinationUrlAddress;
  }
  if (eventProperties.interactionGoal) {
    properties.interaction_goal = eventProperties.interactionGoal;
  }
  if (eventProperties.interactionName) {
    properties.interaction_name = eventProperties.interactionName;
  }
  if (eventProperties.interactionRawText) {
    properties.interaction_raw_text = eventProperties.interactionRawText;
  }
  if (eventProperties.urlAddress) {
    properties.url_address = eventProperties.urlAddress;
  }

  emit(properties, TYPE);

  return properties;
}

export default emitUserInteractionEvent;
