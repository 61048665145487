import _ from 'lodash';

import { stripTrailingSlash } from '@zapier/url-utils';
import {
  buildEntityActions,
  buildEntityApi,
  buildEntitySelectors,
} from '@zapier/toolbox-redux-framework';

import { partialWithDefault } from 'app/common/CommonUtils';
import {
  cleanDefinition as definitionClean,
  currentAppDefinitionEntityId,
  currentAppId,
  currentAppVersion,
} from 'app/developer-v3/selectors';
import { USER_LIMIT_COUNT } from 'app/developer-v3/constants';
import { cleanDefinition, isAPIDown } from 'app/developer-v3/utils';

import { ISelectors, IActions, CliAppDefinitionData } from './types';

export const type = 'cliAppDefinition';

export function from(overrides: Partial<CliAppDefinitionData>) {
  return new CliAppDefinitionData(overrides);
}

export const api = buildEntityApi(type, (path, getState) => {
  const state = getState();
  const appId = currentAppId(state);

  const versionNumber = currentAppVersion(state);
  const isCollection = path === '';
  const platformVersion = _.get(definitionClean, ['platformVersion']);
  const finalPathSegment = isCollection
    ? ''
    : platformVersion
    ? `${versionNumber}?schema_version=${platformVersion}`
    : `${versionNumber}`;

  // TODO: In the previous commit we were appending `path`, which contained the
  // CliAppDefinition `id`, to the end of the URl path below, but that was
  // breaking CliAppDefinition loading and saving. The current solution either
  // appends '' or a version number, e.g., "1.0.0", which fixes loading and
  // saving, but is inconsistent with adjustments we would like to make to the
  // backend API. In the future, the API will accept the CliAppDefintion `id`
  // as the final path segment, i.e., we can revert the commit which introduced
  // this comment.
  return stripTrailingSlash(
    `/api/platform/cli/apps/${appId}/versions/${finalPathSegment}`
  );
});

export const selectors: ISelectors = buildEntitySelectors(
  type,
  (sel: ISelectors) => {
    sel.definition = partialWithDefault(sel.dataItem, 'definition', {});
    sel.definitionOverride = partialWithDefault(
      sel.dataItem,
      'definition_override',
      {}
    );
    sel.definitionOverrideClean = entity =>
      cleanDefinition(sel.definitionOverride(entity));
    sel.etag = _.partial(sel.dataItem, 'etag');
    sel.appId = _.partial(sel.dataItem, 'app_id');
    sel.version = _.partial(sel.dataItem, 'version');
    sel.selectedApi = _.partial(sel.dataItem, 'selected_api');
    sel.deployment = _.partial(sel.dataItem, 'deployment');
    sel.deprecationDate = _.partial(sel.dataItem, 'deprecation_date');
    sel.userCount = _.partial(sel.dataItem, 'user_count');
    sel.userCountUpdatedAt = _.partial(sel.dataItem, 'user_count_updated_at');
    sel.changelog = _.partial(sel.dataItem, 'changelog');
    sel.createdAt = _.partial(sel.dataItem, 'date');
    sel.updatedAt = _.partial(sel.dataItem, 'last_changed');
    sel.authorEmail = _.partial(sel.dataItem, ['author', 'email']);
    sel.oauthRedirectUri = _.partial(sel.dataItem, 'oauth_redirect_uri');
    sel.platformVersion = _.partial(sel.dataItem, 'platform_version');
    sel.isGUIIntegration = _.partial(sel.dataItem, 'is_ui');
    sel.auth = _.partial(sel.dataItem, [
      'definition_override',
      'authentication',
    ]);
    sel.authFields = _.partial(sel.dataItem, [
      'definition_override',
      'authentication',
      'fields',
    ]);
    sel.authType = _.partial(sel.dataItem, [
      'definition_override',
      'authentication',
      'type',
    ]);
    sel.isOauth2 = partialWithDefault(
      sel.matchesSelector,
      [sel.authType, 'oauth2'],
      false
    );
    sel.submitStatus = _.partial(sel.dataItem, 'submit_status');

    sel.doesUserCountExceedLimit = entity =>
      sel.userCount(entity) >= USER_LIMIT_COUNT;

    sel.isProduction = entity => sel.deployment(entity) === 'production';

    sel.isReadOnly = entity =>
      sel.isProduction(entity) || sel.doesUserCountExceedLimit(entity);

    const hasAppIdAndVersion = (appId, version, entity) => {
      return (
        sel.appId(entity) === Number(appId) && sel.version(entity) === version
      );
    };
    sel.all.findByAppIdAndVersion = _.partial(
      sel.all.findBy,
      hasAppIdAndVersion
    );

    sel.isAPIDown = state =>
      isAPIDown(sel.statusCode(state), sel.all.statusMessages(state));
  }
);

export const actions: IActions = buildEntityActions(
  type,
  selectors,
  api,
  (cliAppDefinitionActions: IActions) => {
    cliAppDefinitionActions.updateAppDefinition = (
      definition_override,
      etag,
      messages = {}
    ) => {
      return async (dispatch, getState) => {
        const state = getState();
        const entityId = currentAppDefinitionEntityId(state);

        dispatch(
          cliAppDefinitionActions.updateEntityData(
            {
              definition_override,
              ...(etag && { etag }),
            },
            entityId
          )
        );

        return await dispatch(
          cliAppDefinitionActions.saveEntity(messages, entityId)
        );
      };
    };
  }
);
