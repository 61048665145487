import { createSelector } from 'reselect';

import { Account, Profile } from '@zapier/shared-entities';

export const isProfileAdminOfAccount = (profileEntity, accountEntity) =>
  !!profileEntity &&
  !!accountEntity &&
  Account.selectors.ownerId(accountEntity) ===
    Profile.selectors.id(profileEntity);

export const isCurrentProfileAdminOfCurrentAccount = state =>
  isProfileAdminOfAccount(
    Profile.selectors.all.currentProfile(state),
    Account.selectors.all.currentAccount(state)
  );

export const haveAccountsAndProfilesLoaded = state =>
  Account.selectors.all.hasLoaded(state) &&
  Profile.selectors.all.hasLoaded(state);

const selectCurrentAccount = state =>
  Account.selectors.all.currentAccount(state);
const selectCurrentProfile = state =>
  Profile.selectors.all.currentProfile(state);

// Don't use this for new code! This is purely for legacy purposes
// because the `/me` endpoint combined account and profile data which
// are now split. However updating all components to use the split
// data is super time consuming, so we'll need to do it as we refactor
// components in the future and use this as a band-aid for now.
export const legacyAccountAndProfileCombo = createSelector(
  selectCurrentAccount,
  selectCurrentProfile,
  haveAccountsAndProfilesLoaded,
  (currentAccount, currentProfile, isLoaded) => {
    return {
      // Note that `Account` _intentionally_ precedes `Profile` since
      // typically the `id` refers to the `Profile` `id`. There's no
      // longer a 1:1 match.
      ...Account.selectors.data(currentAccount),
      ...Profile.selectors.data(currentProfile),
      // This is to match how the original data status worked. It was just
      // a boolean indicating whether the data had loaded; whether it was
      // successful or not was implied by whether any data existed.
      isLoaded,
    };
  }
);

export const haveAccountsAndProfilesSuccessfullyLoaded = createSelector(
  Account.selectors.all.hasLoaded,
  Profile.selectors.all.hasLoaded,
  (accountHasLoaded, profileHasLoaded) => accountHasLoaded && profileHasLoaded
);
