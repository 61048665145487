// @flow

import { frame } from 'app/perf-utils/rules/Constants';

import type {
  PerformanceLintRule,
  PerformanceMeasurementItem,
  PerformanceStats,
} from 'app/perf-utils/types';

const threshold = {
  failure: 1 * frame,
  warning: 1,
};

const rule: PerformanceLintRule = {
  id: 'slow-should-component-update',

  description: `
    Custom implementations of shouldComponentUpdate exist to improve React performance.
    If an implementation is too slow, then any performance improvement during rendering may be lost.
    Greater than ${threshold.warning} ms is a warning.
    Greater than ${threshold.failure} ms is a failure.`,

  check(item: PerformanceMeasurementItem, stats: PerformanceStats) {
    if (item.phase !== 'shouldComponentUpdate') {
      return 'success';
    }

    if (stats.max > threshold.failure) {
      return 'failure';
    }
    if (stats.max > threshold.warning) {
      return 'warning';
    }
    return 'success';
  },
};

export default rule;
