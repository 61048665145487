/** @jsx jsx */

import { Fragment } from 'react';

import { BrandButton, Colors } from '@zapier/design-system';
import { css, jsx } from '@emotion/core';

import NavLinkItem from './NavLinkItem';
import { MEDIA_MIN_DESKTOP, MEDIA_MIN_TABLET } from './constants';

const Styles = {
  list: css`
    margin: 0px;
    padding: 0px;
  `,
  root: css`
    border-top: 1px solid ${Colors.neutral400};
    box-shadow: rgba(0, 0, 0, 0.2) 0px 30px 30px 0px;
    justify-content: space-between;
    margin: 0px;
    ${[MEDIA_MIN_DESKTOP]} {
      background-color: initial;
      border: initial;
      box-shadow: initial;
      display: flex;
      flex: 1;
    }
  `,
  signUpWrapper: css`
    display: flex;
    padding: 15px 0px 15px 40px;
    ${[MEDIA_MIN_TABLET]} {
      display: none;
    }
  `,
};

type NavLink = {
  href: string;
  isVisible: boolean;
  label: string;
};

type Props = {
  currentUrl: string;
  isAuthed: boolean;
  isLightTheme: boolean;
  linksLeft: NavLink[];
  linksRight: NavLink[];
  onClickLink?: (link: NavLink) => void;
  urlSignUp: string;
};

const defaultProps = {
  linksLeft: undefined,
  onClickLink: () => undefined,
};

function NavLinks({
  currentUrl,
  isAuthed,
  isLightTheme,
  linksLeft,
  linksRight,
  onClickLink = defaultProps.onClickLink,
  urlSignUp,
}: Props) {
  return (
    <Fragment>
      {!isAuthed && (
        <div css={Styles.signUpWrapper}>
          <BrandButton href={urlSignUp}>Sign up</BrandButton>
        </div>
      )}
      <nav css={Styles.root}>
        <ul css={Styles.list}>
          {linksLeft.map(link => (
            <NavLinkItem
              currentUrl={currentUrl}
              isLightTheme={isLightTheme}
              link={link}
              onClick={() => onClickLink(link)}
            />
          ))}
        </ul>
        <ul css={Styles.list}>
          {linksRight.map(link => (
            <NavLinkItem
              currentUrl={currentUrl}
              isLightTheme={isLightTheme}
              link={link}
              onClick={() => onClickLink(link)}
            />
          ))}
        </ul>
      </nav>
    </Fragment>
  );
}

NavLinks.defaultProps = defaultProps;

export default NavLinks;
