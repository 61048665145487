import { isProduction } from '@zapier/common-utils';
import { emit as emitAvroEvent } from '@zapier/events-toolkit-browser';

import { EVENT_TYPE } from './constants';

function emit(properties: Record<string, any>, type: EVENT_TYPE) {
  if (isProduction()) {
    emitAvroEvent(properties, type);
  } else {
    if (!isProduction()) {
      console.info(`🧭 Would have tracked /v4/event/ "${type}"`, properties);
    }
  }
}

export { emit };
