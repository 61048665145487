import _ from 'lodash';

import { hasKeyInStorage, setKeyInStorage } from '@zapier/local-storage';

export const hasVisited = _.partial(hasKeyInStorage, 'zapier-visit');
export const setVisited = _.partial(setKeyInStorage, 'zapier-visit', true);

export const hasLanded = _.partial(hasKeyInStorage, 'zapier-landing');
export const setLanded = _.partial(setKeyInStorage, 'zapier-landing', true);

export const createLandingPageRoute = (landingPageName, route = {}) => ({
  ...route,
  assign: (...args) => ({
    ...(route.assign ? route.assign(...args) : {}),
    landingPageName,
  }),
});
