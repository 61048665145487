import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

export const BASE_APP_URL = '';

export const PLATFORM_MARKETING_BASE_URL = `${ZAPIER_APP_BASE_URL}/platform`;
export const LEGACY_BUILDER_BASE_URL = `${ZAPIER_APP_BASE_URL}/developer/builder`; // legacy web-builder

// API URLs
export const ZAPIER_APP_API_PATH = '/api/platform/cli/apps';
export const ZAPIER_APP_V1_API_PATH = '/api/developer/v1';

// Documentation URLs
export const BASE_DOC_URL_VB = 'https://platform.zapier.com';
export const DEVELOPER_CLI_GUIDE_URL = '/cli-guide';
export const NEW_EXPERTS_BASE_URL = 'https://zapier.com';
export const PARTNER_API_ACCESS_TYPEFORM =
  'https://zapier.typeform.com/to/atnWuF';
export const REBRANDING_GUIDE_URL = 'https://integration-branding.zapier.app/';
export const NEW_WORKFLOW_API_DOCS_URL = 'https://docs.api.zapier.com/';

// Parnter Contact Form path
export const PARTNER_CONTACT_FORM_PATH = '/contact';

export const PLACEHOLDER_SRC_OLD =
  'https://cdn.zapier.com/zapier/images/frontend/placeholder/128x128.grey.png';
