export const highlightActionApiKeys = {
  sample: 'sample',
  outputFields: 'outputFields',
  perform: 'perform',
  test: 'test',
  url: 'url',
};

export const highlightAuthFieldKey = {
  connectionLabel: 'connectionLabel',
  test: 'test',
};

export const highlightTriggerApiKeys = {
  performGet: 'performGet',
  performSubscribe: 'performSubscribe',
  performUnsubscribe: 'performUnsubscribe',
  performList: 'performList',
  perform: 'perform',
  test: 'test',
  sample: 'sample',
  outputFields: 'outputFields',
};
