import LazyLoadComponent from 'app/developer-v3/components/LazyLoadComponent';

// Lazily load this module since it is imported but not rendered on the
// Developer Platform dashboard page.
const LazyLoadSidebarRightContainer = LazyLoadComponent({
  loader: () =>
    import(
      /* webpackChunkName: 'app-developer-v3-components-SidebarRight-Container' */
      './Container'
    ),
});

export default LazyLoadSidebarRightContainer;
export {
  MODES,
  TABS,
  GUIDE_SECTION_IDS,
  VERSIONING_SECTION_IDS,
} from './constants';
export { default as Provider, useSidebarRight } from './Provider';
export type { PropertiesType, SubsectionId } from './types';
