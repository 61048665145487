import { UseQueryOptions, useQueries } from 'react-query';

import { useNotify } from 'app/developer-v3/hooks';

import { Implementation } from './useQueryImplementation';
import { Integration } from './useQueryIntegration';
import useQueryIntegrations from './useQueryIntegrations';
import {
  getQueryKey as getQueryKeyQueryImplementations,
  getQueryFn as getQueryFnQueryImplementations,
  getQueryOptions as getQueryOptionsQueryImplementations,
} from './useQueryImplementations';

export type IntegrationsImplementations = (Integration & {
  implementations: Implementation[];
})[];

const QUERY_KEY_PREFIX = 'query-integrations-implementations';
const getQueryKey = () => QUERY_KEY_PREFIX;

function useQueryIntegrationsImplementations(
  options?: UseQueryOptions<Integration[], Error>
) {
  const notify = useNotify();

  const queryIntegrations = useQueryIntegrations({
    staleTime: 600000,
    ...options,
  });
  const {
    isLoading: isLoadingIntegrations,
    data: integrations = [],
  } = queryIntegrations;
  const integrationIds = integrations.map(({ id }) => id);

  const queries = integrationIds.map(integrationId => {
    return {
      queryKey: getQueryKeyQueryImplementations(integrationId),
      queryFn: getQueryFnQueryImplementations(integrationId),
      ...getQueryOptionsQueryImplementations({
        options: {
          enabled: !isLoadingIntegrations,
          staleTime: 600000,
        },
        notify,
        queryKey: getQueryKeyQueryImplementations(integrationId),
      }),
    };
  });
  const queriesImplementations = useQueries(queries);
  const isLoadingImplementations = queriesImplementations.some(
    ({ isLoading }) => isLoading
  );

  const integrationsImplementations: IntegrationsImplementations = integrations.map(
    (integration, indexIntegration) => ({
      ...integration,
      implementations:
        (queriesImplementations[indexIntegration].data as
          | Implementation[]
          | undefined) ?? [],
    })
  );

  return {
    isLoading: isLoadingIntegrations || isLoadingImplementations,
    integrationsImplementations,
    queryKey: getQueryKey(),
  };
}

export default useQueryIntegrationsImplementations;
export { getQueryKey, QUERY_KEY_PREFIX };
