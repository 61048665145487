const routes = {
  '/cli-guide': {
    fetch: () =>
      import(
        /* webpackChunkName: 'cli-guide-route' */ 'app/cli-guide/routes/asyncRoutes'
      ).then(module => module.default),
  },
};

export default routes;
