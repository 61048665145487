import React, { ReactNode } from 'react';

import { Text } from '@zapier/design-system';

type Props = {
  children: ReactNode;
};

function Description({ children }: Props) {
  return (
    <Text color="gray60" margin="10px 0px 25px" tag="p" type="paragraph3">
      {children}
    </Text>
  );
}

export default Description;
