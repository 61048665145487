// import 'app/common/publicPath';
import 'app/common/patch';

import 'app/app.scss';

import { datadogRum } from '@datadog/browser-rum';
import { configureTracking } from '@zapier/toolbox-tracking';
import { configureErrorTracking } from '@zapier/toolbox-redux-framework';
import { isProduction } from '@zapier/common-utils';

import buildPlugins from 'app/common/buildPlugins';

import z from 'app/common/z';

import createApp from 'app/common/createApp';
import { readCookie } from '@zapier/cookies';

configureErrorTracking({
  // DSN for `dev-platform` project:
  // https://sentry.io/organizations/zapier-1/issues/?project=5255207
  sentryDSN:
    'https://a8b154a2a3b04a08a4d8beb55752d93e@o23399.ingest.sentry.io/5255207',
});

configureTracking({
  service: 'develop',
  release: process.env.COMMIT_HASH,
});

const sharedDataDogConfig = {
  applicationId: '2e0c75c1-554d-4626-a5ad-13a527159e93',
  clientToken: 'pub83cd96ebb878633ac6b42ac0f8da8779',
  site: 'datadoghq.com',
  version: process.env.COMMIT_HASH,
  trackResources: true,
  trackLongTasks: true,
  sessionReplaySampleRate: 0,
};

// both staging and production environments use NODE_ENV=production, so we check
// for a staging hostname first to determine if we should use the staging config
if (location.hostname.includes('zapier-staging.com')) {
  datadogRum.init({
    ...sharedDataDogConfig,
    env: 'staging',
    sessionSampleRate: 100,
    defaultPrivacyLevel: 'allow',
  });
} else if (isProduction()) {
  datadogRum.init({
    ...sharedDataDogConfig,
    env: 'production',
    sessionSampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const splitioKey =
  location.hostname === 'developer.zapier.com'
    ? 'mctu4q1tvfa8hgn8kgt5doe0ev6h68th135'
    : 'tra7b4qvk62o373ivf9gc24547fp5cqdv6u0';

const splitConfig = {
  core: {
    authorizationKey: splitioKey,
    key: readCookie('zapidentity'),
  },
};

const app = createApp({
  plugins: buildPlugins(),
  initialReduxState: {},
  splitConfig,
});

z.app.dangerousGlobalStore = app.store;

// Append the commit version, provided by webpack
Object.assign(window, { Z_COMMIT_HASH: process.env.COMMIT_HASH });

app.run();
