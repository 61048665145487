// @flow weak

import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';
import _ from 'lodash';
import createReactClass from 'create-react-class'; // eslint-disable-line no-restricted-syntax
import bemify, { toModifiers } from 'bemify-js';

import { isPermanentError } from '@zapier/toolbox-redux-framework';

import ErrorList from 'app/common/components/ErrorList';
import Icon from 'app/common/components/Icon';
import KindPropType from 'app/common/KindPropType';
import maybeRender from 'app/common/components/maybeRender';
import { getRef, setRef } from 'app/common/utils/ReactUtils';
import 'app/common/components/ErrorBar.scss';

const bem = bemify('error-bar');

const ErrorBar = createReactClass({
  displayName: 'ErrorBar',
  mixins: [PureRenderMixin],

  propTypes: {
    errors: PropTypes.arrayOf(PropTypes.object).isRequired,
    shouldScrollIntoView: PropTypes.bool,
    clearErrors: PropTypes.func.isRequired,
    clearErrorsScroll: PropTypes.func,
    kind: KindPropType,
    className: PropTypes.string,
    children: PropTypes.node,
  },

  getDefaultProps() {
    return {
      kind: 'standalone',
      shouldScrollIntoView: false,
    };
  },

  componentDidMount() {
    this.scrollIntoView();
  },

  componentDidUpdate() {
    this.scrollIntoView();
  },

  scrollIntoView() {
    const container = getRef(this, 'container');

    if (this.props.shouldScrollIntoView && container) {
      container.scrollIntoView();
      if (this.props.clearErrorsScroll) {
        _.defer(this.props.clearErrorsScroll);
      }
    }
  },

  onClose(event) {
    if (event) {
      event.stopPropagation();
    }

    this.props.clearErrors();
  },

  renderCloseIcon() {
    const { errors = [] } = this.props;
    return errors.every(isPermanentError) ? null : (
      <Icon className={bem('__close')} onClick={this.onClose} type="delete" />
    );
  },

  render() {
    const { props } = this;
    const className = bem(toModifiers(props.kind), props.className);

    return (
      <div className={className} ref={setRef(this, 'container')}>
        <div className={bem('__bar')}>
          <ErrorList errors={props.errors} />
          {this.renderCloseIcon()}
          {this.props.children && (
            <div className={bem('__message')}>{this.props.children}</div>
          )}
        </div>
      </div>
    );
  },
});

const shouldDisplay = props => props.errors.length > 0;

export default maybeRender(shouldDisplay)(ErrorBar);
