import { $set, update } from 'qim';

import { createReducer } from '@zapier/toolbox-redux-framework';

import ActionTypes from 'app/refresh/ActionTypes';

const askDelayMS = 24 * 60 * 60 * 1000; // 24 hours

const initialState = {
  showRefreshAfter: Number.MAX_SAFE_INTEGER,
  currentResourceVersion: null,
};

const handlers = {
  [ActionTypes.UPDATE_RESOURCE_VERSION](state, { newResourceVersion }) {
    if (state.currentResourceVersion === null) {
      // the first time, capture the starting version
      return update(
        ['currentResourceVersion', $set(newResourceVersion)],
        state
      );
    } else if (
      state.showRefreshAfter === Number.MAX_SAFE_INTEGER &&
      newResourceVersion !== state.currentResourceVersion
    ) {
      // if we're seeing a legitimate new version from the server
      const showTime = Date.now() + askDelayMS;
      return update(
        [
          ['showRefreshAfter', $set(showTime)],
          ['currentResourceVersion', $set(newResourceVersion)],
        ],
        state
      );
    } else {
      return update(
        ['currentResourceVersion', $set(newResourceVersion)],
        state
      );
    }
  },
};

export default createReducer(handlers, initialState);
