// @flow weak

import React from 'react';
import createReactClass from 'create-react-class'; // eslint-disable-line no-restricted-syntax

const Noop = createReactClass({
  displayName: 'Noop',
  render() {
    return null;
  },
});

const maybeRender = predicate => (Component, FallbackComponent) => {
  FallbackComponent = FallbackComponent || Noop;

  const MaybeRender = props =>
    predicate(props) ? (
      <Component {...props} />
    ) : (
      <FallbackComponent {...props} />
    );
  MaybeRender.displayName = `maybeRender(${
    (Component && Component.displayName) || 'UnnamedComponent'
  })`;
  return MaybeRender;
};

export default maybeRender;
