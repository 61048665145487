// @flow
/** @jsx jsx */

import { Component } from 'react';

import { Colors, Zindexes } from '@zapier/theme';
import { css, jsx } from '@emotion/core';

import ReportBody from 'app/perf-utils/reporter/DocumentReporter/ReportBody';
import ReportHeader from 'app/perf-utils/reporter/DocumentReporter/ReportHeader';

import type {
  PerformanceLintReport,
  PerformanceLintReporter,
  PerformanceLintSeverity,
} from 'app/perf-utils/types';
import type { PerformanceLinterState } from 'app/perf-utils/state';

type Props = {
  registerReporter: (reporter: PerformanceLintReporter) => void,
  linterState: PerformanceLinterState,
};

type State = {
  openedSeverity?: PerformanceLintSeverity,
  report?: PerformanceLintReport,
};

const offset = 20;

const containerStyle = css({
  position: 'fixed',
  maxWidth: '90%',
  left: offset,
  bottom: offset,
  zIndex: Zindexes.perfReport,
  boxShadow: '0 2px 3px 1px rgba(0, 0, 0, 0.2)',
  backgroundColor: Colors.white,
  borderRadius: 3,
  transition: `width 0.5s cubic-bezier(0.65, 0.05, 0.36, 1)`,
});

const disabledStyle = css({
  display: 'none',
});

export default class extends Component<Props, State> {
  state = {
    openedSeverity: undefined,
    report: undefined,
  };

  constructor(props: Props) {
    super(props);
    props.registerReporter(this.onReceiveReport);
  }

  onReceiveReport = (report: PerformanceLintReport) => {
    this.setState({ report });
  };

  onOpenSeverity = (severity: PerformanceLintSeverity) => {
    this.setState({
      openedSeverity: severity,
    });
  };

  onClose = () => {
    this.setState({ openedSeverity: undefined });
  };

  onDisable = () => {
    this.props.linterState.disable();
    this.forceUpdate();
  };

  render() {
    const { isEnabled } = this.props.linterState;
    return isEnabled ? this.renderEnabled() : this.renderDisabled();
  }

  renderEnabled() {
    const { openedSeverity, report } = this.state;

    const isOpened = Boolean(openedSeverity);

    return (
      <div css={containerStyle}>
        <ReportHeader
          isOpened={isOpened}
          onClose={this.onClose}
          onDisable={this.onDisable}
          onOpenSeverity={this.onOpenSeverity}
          openedSeverity={openedSeverity}
          report={report}
        />

        <ReportBody
          isOpened={isOpened}
          openedSeverity={openedSeverity}
          report={report}
        />
      </div>
    );
  }

  renderDisabled() {
    return <div css={disabledStyle} />;
  }
}
