// @flow
/** @jsx jsx */

import { Colors } from '@zapier/theme';
import { Icon } from '@zapier/common-components';
import { createKeyHandler } from '@zapier/common-utils';
import { css, jsx } from '@emotion/core';

import SeverityButton from 'app/perf-utils/reporter/DocumentReporter/SeverityButton';

import type {
  PerformanceLintReport,
  PerformanceLintSeverity,
} from 'app/perf-utils/types';

type Props = {
  isOpened: boolean,
  openedSeverity?: PerformanceLintSeverity,
  report?: PerformanceLintReport,
  onOpenSeverity: (severity: PerformanceLintSeverity) => void,
  onClose: () => void,
  onDisable: () => void,
};

const headerStyle = (isOpened: boolean) =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: isOpened ? Colors.adamantium : 'transparent',
    opacity: isOpened ? 1.0 : 0.5,
    ':hover': {
      opacity: 1.0,
    },
  });

const dismissButtonStyle = css({
  display: 'inline-block',
  marginLeft: 'auto',
  padding: 3,
  cursor: 'pointer',
  color: Colors.steel,
  ':hover': {
    color: Colors.batmobile,
  },
});

const ReportHeader = ({
  isOpened,
  openedSeverity,
  report,
  onOpenSeverity,
  onClose,
}: Props) => (
  <div css={headerStyle(isOpened)}>
    <Icon name="platform-clock" />
    <SeverityButton
      isOpened={isOpened}
      isSelected={openedSeverity === 'critical'}
      onSelect={onOpenSeverity}
      report={report}
      severity="critical"
    />
    <SeverityButton
      isOpened={isOpened}
      isSelected={openedSeverity === 'failure'}
      onSelect={onOpenSeverity}
      report={report}
      severity="failure"
    />
    <SeverityButton
      isOpened={isOpened}
      isSelected={openedSeverity === 'warning'}
      onSelect={onOpenSeverity}
      report={report}
      severity="warning"
    />
    {isOpened && (
      <div
        css={dismissButtonStyle}
        onClick={onClose}
        onKeyDown={createKeyHandler(['enter'], onClose)}
        role="button"
        tabIndex="0"
        title="Turn the Performance Nag off"
      >
        <Icon name="delete" size={12} />
      </div>
    )}
  </div>
);

export default ReportHeader;
