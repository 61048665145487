// @flow weak

import { EntityActionTypes } from '@zapier/toolbox-redux-framework';

import { ToastNotification } from '@zapier/shared-entities';
import { ensureArray } from 'app/common/CommonUtils';

const notificationActionsConfig = {
  [EntityActionTypes.FETCH_ENTITY_DONE]: {
    type: 'success',
  },
  [EntityActionTypes.FETCH_ENTITY_FAIL]: {
    type: 'error',
  },
};

const hasNotificationEnabled = action => action.shouldNotify === true;

const isNotifiableAction = action =>
  action &&
  action.type in notificationActionsConfig &&
  hasNotificationEnabled(action);

const entityNotificationMiddleware = store => next => action => {
  if (!isNotifiableAction(action)) {
    return next(action);
  }

  const config = notificationActionsConfig[action.type];
  const message = ensureArray(action.messages)[0];
  const { notificationDuration, status } = action;

  if (message) {
    store.dispatch(
      ToastNotification.actions.create(
        config.type,
        message,
        `notification-for-${action.entityType}-${action.id}`,
        status === 'success' ? undefined : notificationDuration
      )
    );
  }

  return next(action);
};

export default entityNotificationMiddleware;
