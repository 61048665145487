import { useQuery, UseQueryOptions } from 'react-query';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import useNotify from './useNotify';

const QUERY_KEY_PREFIX = 'query-self-signup-domains';
const getQueryKey = (integrationId: string) =>
  `${QUERY_KEY_PREFIX}-${integrationId}`;

export type SelfSignUpDomains = {
  selfSignupDomains: string[];
  selfSignupAllowed: boolean;
};

function useQuerySelfSignupDomains(
  integrationId: string,
  options?: UseQueryOptions<{
    allowed_self_signup_domains: string[];
    self_signup_allowed: boolean;
  }>
) {
  const notify = useNotify();

  const queryKey = getQueryKey(integrationId);
  const url = `${ZAPIER_APP_BASE_URL}/api/platform/cli/apps/${integrationId}/self_signup/allowed_domains`;

  const { data, error, ...rest } = useQuery(
    queryKey,
    () => fetch(url, { credentials: 'include' }).then(res => res.json()),
    {
      onError(_err) {
        notify.failure('Failed to fetch allowed email domains.');
      },
      ...options,
    }
  );

  const domains = data?.allowed_self_signup_domains ?? [];
  const isAllowed = data?.self_signup_allowed ?? false;

  return { ...rest, domains, isAllowed, error, queryKey };
}

export default useQuerySelfSignupDomains;
export { getQueryKey, QUERY_KEY_PREFIX };
