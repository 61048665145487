import { useQueryImplementationCheck } from 'app/developer-v3/hooks';
import {
  MODES as SIDEBAR_RIGHT_MODES,
  TABS as SIDEBAR_RIGHT_TABS,
  useSidebarRight,
} from 'app/developer-v3/components/SidebarRight';
import type { SemVer } from 'app/developer-v3/types/version';

type Args = {
  id: string | number;
  onError: () => void;
  onSuccess: () => void;
  version: SemVer;
};

export default function useSidebarRightToPublish({
  id,
  onError,
  onSuccess,
  version,
}: Args) {
  const { update: updateSidebarRight } = useSidebarRight();

  const { isLoading, refetch } = useQueryImplementationCheck(
    { integrationId: Number(id), mode: 'activate', version },
    {
      enabled: false,
      onError: () => onError(),
      onSettled: data => {
        if (data.hasFailures) {
          updateSidebarRight({
            mode: SIDEBAR_RIGHT_MODES.PUBLISHING,
            tabActive: SIDEBAR_RIGHT_TABS.VALIDATE,
          });
        } else {
          onSuccess();
        }
      },
    }
  );

  return { isValidating: isLoading, validate: refetch };
}
