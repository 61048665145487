// @flow

import state from 'app/perf-utils/state';

const STYLE_BOLD = 'font-weight: bold';
const STYLE_CODE = 'font-family: monospace; padding: 5px;';

// Quick hack to allow developers to enable/disable as needed
/* eslint-disable no-console */
const usage = () => {
  console.group(`🏇 Performance Nag`);
  console.info(
    `Currently %c${state.isEnabled ? 'enabled' : 'disabled'}`,
    STYLE_BOLD
  );
  console.info('You can enable/disable the Performance Nag using');
  console.info('%cperfLinter.enable()', STYLE_CODE);
  console.info('and');
  console.info('%cperfLinter.disable()', STYLE_CODE);
  console.info(
    'Enabled/disabled state is persisted across page refreshes with local storage.'
  );
  console.groupEnd();

  global.perfLinter = {
    enable() {
      state.enable();
    },
    disable() {
      state.disable();
    },
  };
};

export const installPerformanceMonitor = (React: {}) => {
  const {
    createPerformanceObserver,
  } = require('app/perf-utils/acquisition/performanceObserver');

  const {
    createNotifier,
  } = require('app/perf-utils/acquisition/whyDidYouUpdate');

  const { whyDidYouUpdate } = require('why-did-you-update');

  createPerformanceObserver(state);

  whyDidYouUpdate(React, {
    notifier: createNotifier(state),
  });

  usage();
};

export const reset = () => {
  state.reset();
};
