// @flow

import AvoidableRenders from 'app/perf-utils/data/AvoidableRenders';
import Measurements from 'app/perf-utils/data/Measurements';

export type PerformanceLinterState = {
  avoidableRenders: AvoidableRenders,
  measurements: Measurements,
  reset: () => void,
  enable: () => void,
  disable: () => void,
  isEnabled: boolean,
};

const restore = <T>(key: string, defaultValue: T): T => {
  const rawItem: string = localStorage.getItem(`perf-lint.${key}`) || 'null';
  try {
    return rawItem !== undefined ? JSON.parse(rawItem) : defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

const persist = <T>(key: string, value: T) => {
  localStorage.setItem(`perf-lint.${key}`, JSON.stringify(value));
};

const avoidableRenders = new AvoidableRenders();
const measurements = new Measurements({ threshold: 'lenient' });

let _isEnabled: boolean = restore('enabled', false);

const state: PerformanceLinterState = {
  measurements,

  avoidableRenders,

  reset() {
    avoidableRenders.reset();
    measurements.reset();
  },

  enable() {
    _isEnabled = true;
    persist('enabled', true);
  },

  disable() {
    _isEnabled = false;
    persist('enabled', false);
  },

  get isEnabled() {
    return _isEnabled;
  },
};

export default state;
