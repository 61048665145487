import _ from 'lodash';

import { isProduction } from '@zapier/common-utils';
import { emit } from '@zapier/events-toolkit-browser';

import trackingFunctions, { trackEvent } from 'app/track/trackingFunctions';

type PopupSurveyEvent = 'shown' | 'completed' | 'dismissed';

const Data = {
  trackPageView(path: string, url: string) {
    const eventType = 'web_analytics.tracking.page_view.PageViewEvent';
    const eventData = {
      page_path: path,
      system_name: 'Platform Visual Builder',
      url_address: url,
    };
    if (isProduction()) {
      emit(eventData, eventType);
    } else {
      console.info(
        `🧭 Would have tracked /v4/event/ "${eventType}"`,
        eventData
      );
    }
  },
  recordUserPopupSurvey(
    response: string | null | undefined,
    event: PopupSurveyEvent
  ) {
    trackEvent(`popup-survey:${event}`, {
      ...(response ? { response } : {}),
      path: _.get(document, ['location', 'pathname']),
    });
  },
  ...trackingFunctions,
};

export default Data;
