import { ACTION_INPUT_TYPES } from './action';

export const FIELD_KEYS = {
  altersDynamicFields: 'altersDynamicFields',
  canPaginate: 'canPaginate',
  choices: 'choices',
  computed: 'computed',
  default: 'default',
  dynamic: 'dynamic',
  event: 'event',
  helpText: 'helpText',
  hookType: 'hookType',
  inputFieldsUrl: 'inputFieldsUrl',
  inputFormat: 'inputFormat',
  key: 'key',
  label: 'label',
  list: 'list',
  options: 'options',
  outputFieldsUrl: 'outputFieldsUrl',
  perform: 'perform',
  performList: 'performList',
  performSubscribe: 'performSubscribe',
  performUnsubscribe: 'performUnsubscribe',
  placeholder: 'placeholder',
  resourceUrl: 'resourceUrl',
  required: 'required',
  source: 'source',
  type: 'type',
  url: 'url',
};
export const BASIC_FIELD_TYPE_LABELS = {
  string: 'String',
  text: 'Text',
  integer: 'Integer',
  number: 'Number',
  boolean: 'Boolean',
  datetime: 'DateTime',
  password: 'Password',
};
export const INPUT_FIELD_TYPE_LABELS = {
  ...BASIC_FIELD_TYPE_LABELS,
  dict: 'Dictionary',
  copy:
    'Copy (Shows the Help Text field copy in a display-only formatted note, with no input field.)',
};
export const FIELD_TYPES = Object.keys(BASIC_FIELD_TYPE_LABELS);
export const INPUT_FIELDS_SELECTOR = ['operation', 'inputFields'];
export const NEW_SERVICE_FIELD = '__new__';
export const BASIC_AUTH_FIELDS = [
  { key: 'username', help: '' },
  { key: 'password', help: '' },
];
export const OAUTH2_DATA = [
  { key: 'access_token' },
  { key: 'state' },
  { key: 'redirect_uri' },
  { key: 'code' },
];
const DEFAULT_INPUT_FUNCTION = `// Configure a request to an endpoint of your api that
// returns custom field meta data for the authenticated
// user.  Don't forget to configure authentication!

const options = {
  url: 'https://api.example.com/custom_field_meta_data',
  method: 'GET',
  headers: {
    'Accept': 'application/json'
  },
  params: {

  }
}

return z.request(options)
  .then((response) => {
    const results = response.json;

    // modify your api response to return an array of Field objects
    // see https://zapier.github.io/zapier-platform-schema/build/schema.html#fieldschema
    // for schema definition.

    return results;
  });
`;
export const PLACEHOLDERS_FIELDS = {
  [ACTION_INPUT_TYPES.INPUT]: {
    create: () => ({
      key: NEW_SERVICE_FIELD,
      label: '',
      type: 'string',
    }),
  },
  [ACTION_INPUT_TYPES.DYNAMIC]: {
    create: () => ({
      source: DEFAULT_INPUT_FUNCTION,
    }),
  },
  [ACTION_INPUT_TYPES.GROUP]: {
    create: () => ({
      key: NEW_SERVICE_FIELD,
      children: [],
    }),
  },
};

export const visibilityValues = {
  SHOWN: 'shown',
  HIDDEN: 'hidden',
};

const VISIBILITY_CHOICES = [
  { value: visibilityValues.SHOWN, label: 'Shown' },
  { value: visibilityValues.HIDDEN, label: 'Hidden' },
].map(({ value, label }) => ({
  value,
  label,
  key: value,
}));

export const VISIBILITY_CHOICES_FIELD = {
  type: 'radio-array',
  key: 'visibility',
  label: 'Visibility in Editor',
  choices: VISIBILITY_CHOICES,
  hasSearch: false,
  required: true,
  default: visibilityValues.SHOWN,
};
