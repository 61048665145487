import { useQuery } from 'react-query';
import useNotify from './useNotify';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

const QUERY_KEY_PREFIX = 'integration-triggers-actions-daily';
const getQueryKey = ({ integrationId, methodType }) =>
  `${QUERY_KEY_PREFIX}-${integrationId}-${methodType}`;

export type IntegrationTriggersActionsDaily = {
  outgest_id: string;
  outgest_run_date_time: string;
  app_integration_id: number;
  app_name: string;
  date: string; // ISO string
  is_trigger: boolean;
  method_name: string;
  rank_created_nodes_by_method_type: number;
  rank_group_by_method_type: string;
  count_created_nodes: number;
  count_activated_nodes: number;
  percentage_activated_daily: number;
  avg_percentage_activated_last_7_days: number;
};

export type IntegrationMethodGroup = {
  is_trigger: boolean;
  method_name: string;
  rank_created_nodes_by_method_type: number;
  rank_group_by_method_type: string;
  daily_data: IntegrationTriggersActionsDaily[];
};

export type IntegrationTriggersActionsDailyByMethod = {
  triggers: IntegrationMethodGroup[];
  actions: IntegrationMethodGroup[];
};

export type MethodType = 'triggers' | 'actions';

export const groupDataByMethod = (
  integrationTriggersActionsDaily: IntegrationTriggersActionsDaily[]
): IntegrationTriggersActionsDailyByMethod => {
  const resultsGroupedByMethodName = {
    trigger: {} as { [key: string]: IntegrationMethodGroup },
    action: {} as { [key: string]: IntegrationMethodGroup },
  };
  for (const result of integrationTriggersActionsDaily) {
    const resultMethodType = result.is_trigger ? 'trigger' : 'action';
    if (!resultsGroupedByMethodName[resultMethodType][result.method_name]) {
      resultsGroupedByMethodName[resultMethodType][result.method_name] = {
        is_trigger: result.is_trigger,
        method_name: result.method_name,
        rank_created_nodes_by_method_type:
          result.rank_created_nodes_by_method_type,
        rank_group_by_method_type: result.rank_group_by_method_type,
        daily_data: [],
      };
    }
    resultsGroupedByMethodName[resultMethodType][
      result.method_name
    ].daily_data.push(result);
  }

  const groupedData: IntegrationTriggersActionsDailyByMethod = {
    triggers: [],
    actions: [],
  };
  for (const methodGroup of Object.values(resultsGroupedByMethodName.trigger)) {
    groupedData.triggers.push({
      ...methodGroup,
      daily_data: methodGroup.daily_data.sort(
        // sort in descending order (most recent dates first)
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      ),
    });
  }
  for (const methodGroup of Object.values(resultsGroupedByMethodName.action)) {
    groupedData.actions.push({
      ...methodGroup,
      daily_data: methodGroup.daily_data.sort(
        // sort in descending order (most recent dates first)
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      ),
    });
  }
  // Sort triggers & actions alphabetically by method_name.
  // This Collator helps us sort more intelligently with text and numbers.
  // For example, it will sort "Trigger 9" before "Trigger 10"
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  groupedData.triggers.sort((a, b) =>
    collator.compare(a.method_name, b.method_name)
  );
  groupedData.actions.sort((a, b) =>
    collator.compare(a.method_name, b.method_name)
  );

  return groupedData;
};

type Result = { integration_activity: IntegrationTriggersActionsDaily[] };

function useQueryInsightsIntegrationTriggersActionsDaily(
  integrationId: string,
  methodType: MethodType
) {
  const notify = useNotify();
  const queryKey = getQueryKey({ integrationId, methodType });

  let fetchUrl = `${ZAPIER_APP_BASE_URL}/developer/insights/v1/${integrationId}/triggers-actions-daily`;
  if (methodType) {
    let methodTypeParam = null;
    if (methodType === 'actions') {
      methodTypeParam = 'action';
    } else if (methodType === 'triggers') {
      methodTypeParam = 'trigger';
    }
    fetchUrl = `${fetchUrl}?method_type=${methodTypeParam}`;
  }
  const { data, error, ...rest } = useQuery(
    queryKey,
    (): Promise<IntegrationTriggersActionsDailyByMethod> =>
      fetch(fetchUrl, {
        credentials: 'include',
      })
        .then(res => res.json())
        .then((json: Result) =>
          groupDataByMethod(json.integration_activity ?? [])
        )
  );

  if (error) {
    console.error(error);
    notify.failure('Failed to fetch insights activity daily data.');
  }

  return { data, error, ...rest };
}

export default useQueryInsightsIntegrationTriggersActionsDaily;
