// @flow

import { chain, every, isEmpty } from 'lodash';

import { getComponentNameFromWhyDidYouUpdate } from 'app/perf-utils/parsing';

import type {
  WhyDidYouUpdateDiff,
  WhyDidYouUpdateDiffs,
} from 'app/perf-utils/types';

const wasAvoidable = (diff: WhyDidYouUpdateDiff) =>
  diff.type === 'same' || diff.type === 'equal';

export default class AvoidableRenders {
  queue: WhyDidYouUpdateDiffs[] = [];

  isEmpty(): boolean {
    return isEmpty(this.queue);
  }

  record(diffs: WhyDidYouUpdateDiffs) {
    this.queue.push(diffs);
  }

  getQueue(): WhyDidYouUpdateDiffs[] {
    return this.queue;
  }

  getCompletelyAvoidable(): Array<string> {
    return chain(this.getQueue())
      .filter(diffs => !isEmpty(diffs) && every(diffs, wasAvoidable))
      .map(diffs => getComponentNameFromWhyDidYouUpdate(diffs[0].name))
      .sort()
      .uniq()
      .value();
  }

  reset() {
    this.queue = [];
  }
}
