// @flow
/** @jsx jsx */

import { Component } from 'react';
import { chain, first } from 'lodash';

import { SimpleTable, Text } from '@zapier/common-components';
import { css, jsx } from '@emotion/core';

import { formatNumber } from 'app/perf-utils/data/Formatting';

import type {
  PerformanceLint,
  PerformanceLintReport,
  PerformanceLintRule,
  PerformanceLintSeverity,
} from 'app/perf-utils/types';

type Props = {
  isOpened: boolean,
  openedSeverity?: PerformanceLintSeverity,
  report?: PerformanceLintReport,
};

const transition = {
  duration: {
    opening: '0.4s',
    closing: '0.2s',
  },
};

const reportStyle = (isOpened: boolean) =>
  css({
    maxHeight: isOpened ? 400 : 0,
    transition: `max-height ${
      isOpened ? transition.duration.opening : transition.duration.closing
    } cubic-bezier(0.65, 0.05, 0.36, 1)`,
    overflow: isOpened ? 'auto' : 'hidden',
  });

const descriptionStyle = css({
  margin: '30px 15px 0 15px',
});

const tableFontSize = '1.3rem';

const tableStyle = css({
  maxWidth: '100%',
  overflow: 'auto',
  table: {
    thead: {
      th: {
        fontSize: tableFontSize,
      },
    },
    tbody: {
      tr: {
        fontSize: tableFontSize,
      },
    },
  },
});

const NAME_KEY = 'Name';
const PHASE_KEY = 'Phase';
const MIN_KEY = 'Min (ms)';
const MAX_KEY = 'Max (ms)';
const AVG_KEY = 'Avg (ms)';
const COUNT_KEY = 'Count';

const getPerformanceLintRuleId = (lint: PerformanceLint) => lint.rule.id;

export default class extends Component<Props> {
  renderReport() {
    const { report, openedSeverity } = this.props;
    const violations: ?Array<PerformanceLint> =
      report && openedSeverity && report[openedSeverity];
    if (violations) {
      const header = [
        NAME_KEY,
        PHASE_KEY,
        MAX_KEY,
        AVG_KEY,
        MIN_KEY,
        COUNT_KEY,
      ];
      return chain(violations)
        .groupBy(getPerformanceLintRuleId)
        .map((violationsOfRule: Array<PerformanceLint>) => {
          const rule: PerformanceLintRule = first(violationsOfRule).rule;

          const data = chain(violationsOfRule)
            .sortBy('stats.max', 'stats.avg')
            .reverse()
            .map(violation => [
              violation.item.name,
              violation.item.phase,
              formatNumber(violation.stats.max),
              formatNumber(violation.stats.avg),
              formatNumber(violation.stats.min),
              violation.stats.count,
            ])
            .value();

          return (
            <div key={rule.id}>
              <div css={descriptionStyle}>
                <Text type="subsection-head">{rule.id}</Text>
                <br />
                <Text type="fine">{rule.description}</Text>
              </div>
              <div css={tableStyle}>
                <SimpleTable data={[header, ...data]} />
              </div>
            </div>
          );
        })
        .values()
        .value();
    }

    return <div />;
  }

  render() {
    return (
      <div css={reportStyle(this.props.isOpened)}>{this.renderReport()}</div>
    );
  }
}
