import { getKeyInStorage, setKeyInStorage } from '@zapier/local-storage';

function clearDismissables(endsWith) {
  const LOCAL_STORAGE_KEY = 'dismissed-help';
  const ids = getKeyInStorage(LOCAL_STORAGE_KEY);
  const idsNext = {};

  for (const idSlug in ids) {
    if (!idSlug.endsWith(endsWith)) {
      idsNext[idSlug] = ids[idSlug];
    }
  }

  if (idsNext !== ids) {
    setKeyInStorage(LOCAL_STORAGE_KEY, { ...idsNext });
  }
}

export default clearDismissables;
