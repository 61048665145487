import React, { Suspense, lazy, ReactNode, ComponentType } from 'react';

// HOC to lazy-load a component when it mounts.
// Usage:
//
// From the consumer component, do:
// ```
// const LoadableOnboardingModal = LazyLoadComponent({
//   loader: () =>
//     import(/* webpackChunkName: 'app-onboarding-components-OnboardingModal' */ 'app/onboarding/components/OnboardingModal'),
// });
// ```
//
// Then, use it like any other component:
// ```
// <LoadableOnboardingModal /> // <-- will automatically fetch the async chunk when it's about to mount
// <LoadableOnboardingModal foo="foo" bar={bar} /> // <-- Any props passed are passed down to the inner component as you would expect
// ```
//
// For the example above, webpack would create a new async chunk with the contents of `app/onboarding/components/OnboardingModal`.
//
// Please note:
// To make sure we don't have any collisions between wepback chunk names, use the path to your module with / replaced with -:
// app/onboarding/components/OnboardingModal -> app-onboarding-components-OnboardingModal

type Props = {
  loader: () => Promise<{ default: ComponentType<any> }>;
  renderLoader?: () => ReactNode;
};

const LazyLoadComponent = ({ loader, renderLoader = () => null }: Props) => {
  const LazyComponent = lazy(loader);
  const loading = renderLoader();

  return (props: any) => (
    <Suspense fallback={loading}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default LazyLoadComponent;
