import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import {
  formatResponseBody as formateImplementationResponseBody,
  Implementation,
} from './useQueryImplementation';

const QUERY_KEY_PREFIX = 'query-implementations';
const getQueryKey = (integrationId: number) =>
  `${QUERY_KEY_PREFIX}-${integrationId}`;

function formatResponseBody(body: any): Implementation[] {
  const { objects: implementations } = body;
  return implementations.map(formateImplementationResponseBody);
}

// This uses the factory pattern since we need the query function in
// `useQueryIntegrationsImplementations` to run multiple queries with
// `useQueries`, which requires to build the queries inline.
function getQueryFn(integrationId: number) {
  const url = new URL(
    `api/platform/cli/apps/${encodeURIComponent(integrationId)}/versions`,
    ZAPIER_APP_BASE_URL
  );

  return async () => formatResponseBody(await fetchJson(url.href));
}

// This uses the factory pattern to get query options since we need them in
// `useQueryIntegrationsImplementations` to run multiple queries with
// `useQueries`, which requires to build the queries inline.
function getQueryOptions({
  options,
  notify,
  queryKey,
}: {
  options?: UseQueryOptions<Implementation[], Error>;
  notify: ReturnType<typeof useNotify>;
  queryKey: string;
}) {
  return {
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  };
}

function useQueryImplementations(
  args: { integrationId: number },
  options?: UseQueryOptions<Implementation[], Error>
) {
  const { integrationId } = args;
  const notify = useNotify();

  const queryKey = getQueryKey(integrationId);

  const query = useQuery({
    queryKey,
    queryFn: getQueryFn(integrationId),
    ...getQueryOptions({ notify, options, queryKey }),
  });

  return { ...query, queryKey };
}

export default useQueryImplementations;
export { getQueryFn, getQueryKey, getQueryOptions, QUERY_KEY_PREFIX };
