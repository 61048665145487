import { createLogger as createLoggerMiddleware } from 'redux-logger';

import { level } from 'app/common/log';

// We're making our own logger so we can intercept the calls to do logging and thin out logging in production.
// Maybe we should consider just forking redux-logger to avoid this.
const smartLogger = {
  group(...args) {
    if (level.verbose) {
      console.group(...args); // eslint-disable-line no-console
    }
  },
  groupCollapsed(...args) {
    if (level.verbose) {
      console.groupCollapsed(...args); // eslint-disable-line no-console
    }
  },
  groupEnd() {
    if (level.verbose) {
      console.groupEnd(); // eslint-disable-line no-console
    }
  },
  // This is bound pretty tightly to redux-logger.
  log(...args) {
    if (level.verbose) {
      console.log(...args); // eslint-disable-line no-console
    }
  },
};

const loggerMiddleware = createLoggerMiddleware({
  collapsed: true,
  stateTransformer: state => (level.verbose ? state : null),
  titleFormatter: (action, timestamp) => {
    const { type, entityType } = action;
    const entitySuffix = entityType ? `(${entityType} entity)` : '';
    return `${timestamp} ${type} ${entitySuffix}`.trim();
  },
  logger: smartLogger,
});

export default loggerMiddleware;
