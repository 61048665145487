import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

const QUERY_KEY_PREFIX = 'query-service-implementation-meta';
const getQueryKey = (selectedApi: string) =>
  `${QUERY_KEY_PREFIX}-${selectedApi}`;

type ServiceImplementationMeta = {
  banner: string;
  id: string;
  isBeta: boolean;
  isDeprecated: boolean;
  isHidden: boolean;
  isInvite: boolean;
  isPremium: boolean;
  name: string;
  selectedApi: string;
};

function formatResponseBody(body: any): ServiceImplementationMeta {
  const implementationMeta = body?.results?.[0];

  return implementationMeta
    ? {
        banner: implementationMeta.banner,
        id: implementationMeta.id,
        isBeta: implementationMeta.is_beta,
        isDeprecated: implementationMeta.is_deprecated,
        isHidden: implementationMeta.is_hidden,
        isInvite: implementationMeta.is_invite,
        isPremium: implementationMeta.is_premium,
        name: implementationMeta.name,
        selectedApi: implementationMeta.selected_api,
      }
    : undefined;
}

function useQueryServiceImplementationMeta(
  {
    selectedApi,
    status,
  }: { selectedApi: string; status: 'public' | 'private' },
  options?: UseQueryOptions<
    ServiceImplementationMeta,
    Error,
    ServiceImplementationMeta
  >
) {
  const notify = useNotify();
  const queryKey = getQueryKey(selectedApi);
  const url = new URL(
    `/api/v4/implementations-meta/${encodeURIComponent(status)}`,
    ZAPIER_APP_BASE_URL
  );
  const searchParams = new URLSearchParams({
    format: 'json',
    selected_apis: selectedApi,
  }).toString();
  url.search = searchParams;

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryServiceImplementationMeta;
export { getQueryKey, QUERY_KEY_PREFIX };
