import { useMutation, useQueryClient } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ROLES } from 'app/developer-v3/constants';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import useNotify from './useNotify';
import { getQueryKey } from './useQueryAdmins';

type Args = { email: string; integrationId: string; message: string };
type Return = Record<string, any>;

const MUTATION_KEY = 'add-admin';

function useAddAdmin() {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mutationKey = MUTATION_KEY;

  const mutation = useMutation<Return, Error, Args>({
    mutationKey,
    async mutationFn({ email, integrationId, message }) {
      const url = new URL(
        `/api/platform/cli/apps/${encodeURIComponent(
          integrationId
        )}/collaborators`,
        ZAPIER_APP_BASE_URL
      );
      return await fetchJson(url.href, {
        body: JSON.stringify({
          email,
          message,
          role: ROLES.admin,
          status: 'waiting',
        }),
        method: 'POST',
      });
    },
    onError(_err, { email }) {
      notify.failure(`Failed to add ${email} as an admin.`);
    },
    onSuccess(_data, { email, integrationId }) {
      queryClient.invalidateQueries(getQueryKey(integrationId));
      notify.success(`Invitation email sent to ${email}.`);
    },
  });

  const { isLoading } = mutation;

  return { isMutating: isLoading, mutate: mutation.mutateAsync, mutationKey };
}

export default useAddAdmin;
