/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Icon, InteractiveTag, TooltipWrapper } from '@zapier/design-system';
import {
  FormatItems,
  TriggerActionAndOthersDropdown,
} from './TriggerActionAndOthersDropdown';

const styleRoot = css`
  margin-left: 36px;
`;

const stylesTagsWrapper = css`
  display: flex;
  flex-direction: column;

  button[class*='InteractiveTag'][class][class][class][class][class] {
    width: max-content;
    max-width: 383px; /* 383px is the max width of the dropdown */
    margin-bottom: 10px;
  }
`;

const stylesText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface DropdownAndTagsContainerProps<T extends FormatItems> {
  label: string;
  onSelect: (item: T) => void;
  selectedItems: T[];
  onRemoveTag: (value: T['value']) => void;
}

export const DropdownAndTagsContainer = <T extends FormatItems>({
  label,
  onSelect,
  selectedItems,
  onRemoveTag,
}: DropdownAndTagsContainerProps<T>) => {
  return (
    <div css={styleRoot}>
      <TriggerActionAndOthersDropdown
        label={label}
        onSelect={onSelect}
        selectedItems={selectedItems}
      />
      <div css={stylesTagsWrapper}>
        {selectedItems.map(item => {
          const showTooltip = item.label.length > 40; // average length before truncation

          // TODO: Update here when X button and tooltip are added to InteractiveTag
          // https://zapierorg.atlassian.net/browse/DS-1145
          return (
            <TooltipWrapper
              allowMultilineTooltip={true}
              content={showTooltip ? item.label : undefined}
              isBlock={true}
              key={item.value}
            >
              {() => (
                <InteractiveTag
                  color="primary"
                  key={item.value}
                  onClick={() => onRemoveTag(item.value)}
                >
                  <Icon name="formX" size="xsmall" />
                  <span css={stylesText}>{item.label}</span>
                </InteractiveTag>
              )}
            </TooltipWrapper>
          );
        })}
      </div>
    </div>
  );
};
