import { compact } from 'lodash';

import {
  entityPlugin as Entity,
  errorPlugin as Error,
} from '@zapier/toolbox-redux-framework';

import Auth from 'app/auth'; // we need this for authenticaton editor
import CliGuide from 'app/cli-guide';
import DeveloperV3 from 'app/developer-v3'; // we need this one
import Min from 'app/min'; // needed for editor - keep
import Refresh from 'app/refresh'; // needed in fetchMiddleware
import Service from 'app/service'; // needed
import Track from 'app/track';
import ZapierAuth from 'app/zapier-auth';
import ZapTemplates from 'app/zap-templates';
import { safeExtend } from 'app/common/CommonUtils';

const appPlugins = [
  Auth,
  CliGuide,
  ZapTemplates,
  Track,
  Service,
  Error,
  Min,
  Entity,
  Refresh,
  ZapierAuth,
  DeveloperV3,
];

export default function () {
  return {
    all: appPlugins,

    get routes() {
      // Grab all the route objects.
      const routeArgs = compact(appPlugins.map(plugin => plugin.routes));

      // Make sure they're all unique.
      const safeExtendRouteArgs = [{}].concat(routeArgs);

      return safeExtend(...safeExtendRouteArgs);
    },

    get reducers() {
      return appPlugins.map(plugin => plugin.reducers);
    },

    get actionsTypes() {
      return appPlugins.map(plugin => plugin.actionTypes);
    },
  };
}
