// @flow

import { connect } from 'react-redux';

import { Account, Profile } from '@zapier/shared-entities';

import DowngradedModal from 'app/team/components/DowngradedModal';

import { type Dispatch } from 'app/common/types';

const mapStateToProps = state => {
  const currentAccount = Account.selectors.all.currentAccount(state);
  return {
    ownerName:
      Account.selectors.ownerName(currentAccount) ||
      Account.selectors.ownerEmail(currentAccount),
    planName: Account.selectors.planName(currentAccount),
    profileId: Profile.selectors.all.currentProfileId(state),
    trackingKey: Profile.selectors.all.currentAccountDowngradedTrackingKey(
      state
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose(trackingKey, profileId) {
    dispatch(Profile.actions.acknowledgeHelp(trackingKey, profileId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DowngradedModal);
