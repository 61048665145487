// flow @weak

import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';
import bemify from 'bemify-js';
import createReactClass from 'create-react-class'; // eslint-disable-line no-restricted-syntax

import {
  commonErrors as Errors,
  selectErrorMessages,
} from '@zapier/toolbox-redux-framework';

import ErrorDetail from 'app/common/components/ErrorDetail';
import Icon from 'app/common/components/Icon';
import Link from 'app/common/components/Link';
import 'app/common/components/ErrorSummary.scss';

const ErrorSummary = createReactClass({
  displayName: 'ErrorSummary',
  mixins: [PureRenderMixin],

  propTypes: {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  },

  getInitialState() {
    return {
      isOpenDetail: false,
    };
  },

  errorType() {
    if (this.props.error.name) {
      return Errors[this.props.error.name];
    }
    return undefined;
  },

  onToggleDetail() {
    this.setState({
      isOpenDetail: !this.state.isOpenDetail,
    });
  },

  render() {
    let error = this.props.error;
    let ErrorType = this.errorType();
    if (!ErrorType) {
      error = new Errors.UnknownError(error);
      ErrorType = Errors.UnknownError;
    }

    const summary = ErrorType.summary(error);
    const errors = ErrorType.errors(error);

    const bem = bemify('error-summary');

    return (
      <div className={`${this.props.className} ${bem()}`}>
        <Icon className={bem('__icon')} type="error" />

        <div className={bem('__error')}>
          <div className={bem('__summary')}>{summary}</div>
          {errors.length === 0 ? null : (
            <ul className={bem('__list')}>
              {selectErrorMessages(errors).map((message, i) => (
                <li key={i}>{message}</li>
              ))}
            </ul>
          )}
          {!ErrorType.hasDetail(error) ? null : (
            <div>
              {this.state.isOpenDetail ? (
                <ErrorDetail error={error} />
              ) : (
                <Link className="noselect" onClick={this.onToggleDetail}>
                  Show Details
                </Link>
              )}
            </div>
          )}
          {!ErrorType.hasHelpLink(error) ? null : (
            <div className={bem('__help')}>
              There are a few things that could be going on,{' '}
              <Link
                color="secondary"
                href={ErrorType.helpLink(error)}
                target="_blank"
              >
                learn more about them here
              </Link>
              .
            </div>
          )}
        </div>
      </div>
    );
  },
});

export default ErrorSummary;
