// @flow weak

import { CANCEL_ROUTE, ROUTE_TO_NEXT } from 'redux-router-kit';

import { RouterSelectors } from '@zapier/toolbox-redux-framework';

import ActionTypes from 'app/perf-utils/ActionTypes';
import { trackTimeToMount } from 'app/perf-utils/PerfActions';

import type { TimeToMountData } from 'app/perf-utils/TimeToMount';

type Timer = {
  start: number,
};

let timer: ?Timer;

const handlers = {
  [ROUTE_TO_NEXT]: () => {
    // Start the timer
    timer = {
      start: performance.now(),
    };
  },

  [CANCEL_ROUTE]: () => {
    timer = undefined;
  },

  [ActionTypes.RECORD_TIME_TO_MOUNT]: (store, action) => {
    const href = RouterSelectors.getCurrentPathname(store.getState());
    if (timer) {
      const time = performance.now() - timer.start;
      console.info(
        `⏱ Recorded a time to mount of ${time.toFixed(2)} ms for ${href}`,
        action.trackingData
      );
      store.dispatch(
        trackTimeToMount({
          href,
          source: 'client-routing',
          timeToMount: time,
          timeToMountType: action.timeToMountType,
          ...action.trackingData,
        })
      );
    } else {
      console.warn(`Unable to find a client-side routing timer for ${href}`);
    }
  },
};

export default store => next => action => {
  const handler = handlers[action.type];
  if (handler) {
    handler(store, action);
  }
  return next(action);
};

export const recordTimeToMount = ({
  timeToMountType,
  trackingData,
}: TimeToMountData) => ({
  type: ActionTypes.RECORD_TIME_TO_MOUNT,
  timeToMountType,
  trackingData,
});
