import { RequestMethodTypes } from 'app/developer-v3/types/request';

export type RequestOptions = {
  params?: boolean;
  body?: boolean;
};

export type RequestSchema = {
  url?: string;
  method?: RequestMethodTypes;
  params?: Record<string, any>;
  headers?: Record<string, any>;
  removeMissingValuesFrom?: RequestOptions;
  body?: Record<string, any>;
  source?: string;
  require?: string;
};
