// @flow weak

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import { MatchesMediaQuery } from 'app/common/components/matchesMediaQuery';

const kindQuery = Component => {
  const KindInjecter = props => {
    const passthroughProps = _.omit(
      props,
      'kindQueries',
      'mediaQueries',
      'matchesMediaQuery'
    );
    return (
      <Component
        {...passthroughProps}
        kind={[props.kind, props.matchesMediaQuery]}
      />
    );
  };

  const MediaQueryInverter = props => {
    const queryByKind = _.invert(props.kindQueries);
    const passthroughProps = _.omit(props, 'kindQueries');

    return (
      <MatchesMediaQuery
        Component={KindInjecter}
        mediaQueries={queryByKind}
        {...passthroughProps}
      />
    );
  };

  MediaQueryInverter.propTypes = {
    // Key is media query, value is string `kind`.
    // Multiple kinds can also be passed space-separated e.g.: `kind1 kind2 kind3`
    kindQueries: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string]).isRequired
    ),
  };

  MediaQueryInverter.defaultProps = {
    kindQueries: {},
  };

  return MediaQueryInverter;
};

export default kindQuery;
