// @flow weak

// !!! IMPORTANT !!!
//
// This file is shared between the zapier/zapier and zapier/next-js repository.
// Any changes made here should be reflected in there as well.
// For more information, see: https://zapier.quip.com/xaKqAOpdhpY2#ZXFACA8Jnp9
import React from 'react';
import bemify, { toModifiers } from 'bemify-js';
import { compose, defaultProps, pure } from 'recompose';

import './Icon.scss';

import type { HOC } from 'recompose';

import type { KindType } from 'app/common/types';

// $FlowFixMe
const requireIcons = require.context('./icons', false, /.svg/);

const icons = requireIcons.keys();

const iconsMap = icons.reduce((acc, filepath) => {
  const iconName = filepath.replace('./', '').replace('.svg', '');
  const iconModule = requireIcons(filepath);
  acc[iconName] = iconModule.default || iconModule;
  return acc;
}, {});

type IconProps = {
  type: string,
  kind?: KindType,
  className?: string,
  size?: number,
};

const Icon = componentProps => {
  const { kind, type, size, ...props } = componentProps;
  const bem = bemify('svg-icon');
  const hasIcon = !!iconsMap[type];
  const className = bem(
    // TODO: Remove `type` and only have `kind` once we
    // fully switch to BEM. Combining them now makes it
    // really difficult for `icon-${type}` below because
    // `kind` can have multiple values so `icon-${kind}`
    // wouldn't always work properly.
    ...toModifiers(kind, hasIcon ? type : 'does-not-exist'),
    props.className,
    {
      '--clickable': !!props.onClick,
    }
  );
  const IconSvg = iconsMap[type];
  const svg = IconSvg ? (
    <IconSvg />
  ) : (
    <svg viewBox="0 0 10 10">
      <rect height="10" width="10" x="0" y="0" />
    </svg>
  );
  // Default size is handled via CSS so it's easier for consuming components to override via classes.
  const style = size ? { width: `${size}px`, height: `${size}px` } : null;

  return (
    <span {...props} className={className} style={style}>
      {svg}
    </span>
  );
};

const defaultPropsValue = {
  size: null,
};

const enhanceIcon: HOC<*, IconProps> = compose(
  pure,
  defaultProps(defaultPropsValue)
);

export default enhanceIcon(Icon);
