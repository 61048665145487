import React from 'react';
import bemify from 'bemify-js';

import 'app/common/components/LoadingNextRouteIndicator.scss';

const bem = bemify('loading-next-route-indicator');

const LoadingNextRouteIndicator = () => <div className={bem()} />;

export default LoadingNextRouteIndicator;
