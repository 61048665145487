export const REQUEST_METHODS = [
  { value: 'GET', label: 'GET' },
  { value: 'PUT', label: 'PUT' },
  { value: 'POST', label: 'POST' },
  { value: 'PATCH', label: 'PATCH' },
  { value: 'DELETE', label: 'DELETE' },
  { value: 'HEAD', label: 'HEAD' },
];
export const WRITE_METHODS = ['POST', 'PATCH', 'PUT'];
export const API_CONFIGURATION_DEFAULT_HEADERS = ['Accept', 'Content-Type'];
