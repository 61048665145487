// @flow

import { chain, get, isEmpty } from 'lodash';

import { calculatePerformanceStats } from 'app/perf-utils/data/PerformanceStats';

import type {
  ComponentLifecyclePhase,
  PerformanceMeasurementItem,
  PerformanceMeasurementSet,
  WarningByComponentName,
} from 'app/perf-utils/types';

type Threshold = 'lenient' | 'strict';
type Thresholds = { [Threshold]: number };

type MeasurementProps = {
  threshold: Threshold,
};

type MeasurementsByComponentName = { [string]: number[] };
type MeasurementsByPhaseByComponentName = {
  [ComponentLifecyclePhase]: MeasurementsByComponentName,
};

type WarningsByPhase = { [ComponentLifecyclePhase]: WarningByComponentName[] };

const thresholds: Thresholds = {
  lenient: 66,
  strict: 33,
};

export default class Measurements {
  warningThreshold: number;

  measurements: MeasurementsByPhaseByComponentName = {};

  warnings: WarningByComponentName[] = [];

  warningsByPhase: WarningsByPhase = {};

  constructor(props: MeasurementProps) {
    this.warningThreshold = thresholds[props.threshold] || thresholds.lenient;
  }

  record(
    componentName: string,
    duration: number,
    phase: ComponentLifecyclePhase
  ) {
    this.measurements[phase] = this.measurements[phase] || {};
    this.measurements[phase][componentName] =
      this.measurements[phase][componentName] || [];
    this.measurements[phase][componentName].push(duration);

    if (duration > this.warningThreshold) {
      const warning = { name: componentName, duration, phase };
      this.warnings.push(warning);
      this.warningsByPhase[phase] = this.warningsByPhase[phase] || [];
      this.warningsByPhase[phase].push(warning);
    }
  }

  getMeasurements(): MeasurementsByPhaseByComponentName {
    return this.measurements;
  }

  getMeasurementsForPhase(
    phase: ComponentLifecyclePhase
  ): MeasurementsByComponentName {
    return get(this.measurements, [phase], {});
  }

  getMeasurementsForPhaseAndComponent(
    phase: ComponentLifecyclePhase = 'update',
    componentName: string
  ): number[] {
    return get(this.measurements, [phase, componentName], []);
  }

  hasMeasurements(): boolean {
    return !isEmpty(this.measurements);
  }

  hasMeasurementsForPhase(phase: ComponentLifecyclePhase): boolean {
    return !isEmpty(this.measurements[phase]);
  }

  getWarnings(): WarningByComponentName[] {
    return this.warnings;
  }

  getWarningsForPhase(
    phase: ComponentLifecyclePhase
  ): WarningByComponentName[] {
    return this.warningsByPhase[phase] || [];
  }

  hasWarnings(): boolean {
    return !isEmpty(this.warnings);
  }

  hasWarningsForPhase(phase: ComponentLifecyclePhase): boolean {
    return !isEmpty(this.warningsByPhase[phase]);
  }

  getMeasurementSets(): Array<PerformanceMeasurementSet> {
    return chain(this.measurements)
      .map((forPhase, phase: ComponentLifecyclePhase) => {
        return chain(forPhase)
          .map((forComponent, name): PerformanceMeasurementSet => {
            const item: PerformanceMeasurementItem = { name, phase };
            const stats = calculatePerformanceStats(forComponent);

            return {
              item,
              stats,
            };
          })
          .value();
      })
      .flatten()
      .value();
  }

  reset() {
    this.measurements = {};
    this.warnings = [];
    this.warningsByPhase = {};
  }
}
