import { createPrefixedActions } from '@zapier/toolbox-redux-framework';

export default createPrefixedActions(
  'service',
  `
    LOAD_USER_SERVICES
    LOAD_USER_SERVICES_DONE

    TOGGLE_USER_SERVICE
    TOGGLE_USER_SERVICE_DONE
  `
);
