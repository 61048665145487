import { connect } from 'react-redux';

import { Account } from '@zapier/shared-entities';
import BrowserTabFocus from 'app/common/components/BrowserTabFocus';

const mapStateToProps = state => ({
  currentAccountId: Account.selectors.all.currentAccountId(state),
});

const mapDispatchToProps = dispatch => ({
  onChangeFocus(isFocused, props) {
    // Whenever the browser tab becomes focused we want to update
    // `localStorage` with the current account id in the store state.
    // This is because users can have different accounts active in
    // different tabs and we need to know what the current one is
    // for things like creating an auth since that opens in a new window.
    // Another example is if a user opens a new tab, it should open
    // for whichever account was selected in their previous tab.
    if (isFocused && props.currentAccountId) {
      dispatch(Account.actions.storeCurrentAccountId(props.currentAccountId));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BrowserTabFocus);
