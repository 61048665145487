import { isPlainObject, fromPairs } from 'lodash';

const defaultOptions = {
  url: 'https://example.com',
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  params: 'bundle.inputData',
  body: {},
  removeMissingValuesFrom: {
    body: false,
    params: false,
  },
};

// this is less supported than I thought it would be
const count = (str: string, substr: string) =>
  (str.match(new RegExp(substr, 'g')) || []).length;

// returns spaces to indent <depth> times
const leftPad = (depth: number) => ' '.repeat(depth * 2);

/**
 * The FE passes us a value that could be one of the following:
 * * a variable wrapped in curlies (`"{{a}}"`)
 * * a plain string (`"blah"`)
 * We need to correctly stick it in template code, which might be in a string already
 * @param {string} input the value to unwrap
 * @param {object} opts options, see below
 * @param {boolean} opts.isInteriorString optional, true if the result is already in a string and doesn't need wrapping `"`
 */
const stringToMaybeVar = (
  input: string,
  opts: { isInteriorString?: boolean } = {}
  // interiorString: boolean = false
) => {
  const wrapped = input.replace(/{{/g, '${').replace(/}}/g, '}');
  if (opts.isInteriorString) {
    // a variable in a string - '${process.env.VAR}'
    return wrapped;
  }
  if (
    wrapped.startsWith('${') &&
    wrapped.endsWith('}') &&
    count(wrapped, '{') === 1
  ) {
    // a variable - 'process.env.VAR'
    return wrapped.slice(2, wrapped.length - 1);
  }
  if (wrapped.includes('${')) {
    // a string with variables in it - `https://${subdomain}.site.com`
    return `\`${wrapped}\``;
  }
  // just a string - "https://site.com"
  return `'${wrapped}'`;
};

// custom JSON.stringify
const stringify = (obj: any, depth: number = 1) => {
  if (!isPlainObject(obj)) {
    return obj;
  }

  const keys = Object.keys(obj);
  const commmaSeparatedValues = keys.map(key => {
    const value = obj[key];
    return `${leftPad(depth + 1)}'${key}': ${
      typeof value === 'string' ? stringToMaybeVar(value) : value
    }`;
  });
  const depthPadded = `{
${commmaSeparatedValues.join(',\n')}
${leftPad(depth)}}
`;

  return depthPadded.trim();
};

const objectToOptions = (userOptions?: any) => {
  const options = Object.assign({}, defaultOptions, userOptions);
  return `
const options = {
  url: ${stringToMaybeVar(options.url)},
  method: '${options.method}',
  headers: ${stringify(options.headers)},
  params: ${stringify(options.params)}${
    options.method !== 'GET' ? `,\n  body: ${stringify(options.body)}` : ''
  },
  removeMissingValuesFrom: ${stringify(options.removeMissingValuesFrom)},
}
`.trim();
};

const requestHandler = (returnKeys: string[] = [], message?: string) => `
return z.request(options)
  .then((response) => {
    const results = response.json;

    // ${
      message ||
      'You can do any parsing you need for results here before returning them'
    }

    return ${
      returnKeys.length
        ? stringify(
            fromPairs(returnKeys.map(rk => [rk, `{{results.${rk}}}`])),
            2
          )
        : 'results'
    };
  });
`;

const requestCode = (
  options?: any,
  returnKeys: string[] = [],
  message?: string
) => {
  return `
    ${objectToOptions(options)}\n${requestHandler(returnKeys, message)}
  `.trim();
};

const urlCode = (url: string, options: any = {}) =>
  `
const url = \`${url || 'https://example.com/oauth/authorize'}?${Object.keys(
    options || {}
  )
    .map(k =>
      k.includes('uri')
        ? `${k}=\${encodeURIComponent(${stringToMaybeVar(options[k])})}`
        : `${k}=${stringToMaybeVar(options[k], { isInteriorString: true })}`
    )
    .join('&')}\`;

return url;
`.trim();

export {
  count,
  objectToOptions,
  requestCode,
  stringify,
  stringToMaybeVar,
  urlCode,
};
