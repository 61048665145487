import { isEmpty } from 'lodash';

import { cleanAuth } from './cleanAuth';
import { cleanActions } from './cleanActions';
import { cleanTriggers } from './cleanTriggers';

import type { AppDefinitionSchema } from 'app/developer-v3/platformSchema/app-definition';

function cleanDefinition(definition: AppDefinitionSchema) {
  const { authentication, creates, searches, triggers, ...rest } = definition;

  const hasAuth = !isEmpty(authentication);
  const hasCreates = !isEmpty(creates);
  const hasSearches = !isEmpty(searches);
  const hasTriggers = !isEmpty(triggers);

  return {
    ...rest,
    ...(hasAuth ? { authentication: cleanAuth(authentication) } : {}),
    ...(hasCreates ? { creates: cleanActions(creates) } : {}),
    ...(hasSearches ? { searches: cleanActions(searches) } : {}),
    ...(hasTriggers ? { triggers: cleanTriggers(triggers) } : {}),
  };
}

export { cleanDefinition };
