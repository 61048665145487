// @flow weak

import { connect } from 'react-redux';
import { cancelRoute, routeTo } from 'redux-router-kit';

import { routeBackTo } from 'app/common/actions';

const connectRouteBackTo = connect(null, dispatch => ({
  cancelRoute: (...args) => dispatch(cancelRoute(...args)),
  routeTo: (...args) => dispatch(routeTo(...args)),
  routeBackTo: url => dispatch(routeBackTo(url)),
}));

export default connectRouteBackTo;
