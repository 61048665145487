/*
 * Generate a middleware that is connects HTTP response handling to the
 * store. Current use is to check for new builds on the backend
 * as indicated by a build timestamp in the response header.
 */

import { onFetchComplete } from '@zapier/toolbox-browser-fetch';

import { refreshFetchResponseHandler } from 'app/refresh/refreshFetchResponseHandler';

let notifier = () => {};

onFetchComplete(response => notifier(response));

const fetchMiddleware = store => {
  notifier = response => {
    refreshFetchResponseHandler(store.dispatch, response);
  };
  return next => action => next(action);
};

export default fetchMiddleware;
