import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

const QUERY_KEY_PREFIX = 'query-platform-versions';
const getQueryKey = () => QUERY_KEY_PREFIX;

export type PlatformVersion = {
  date: Date;
  version: string;
};

function formatResponseBody(body: any): PlatformVersion[] {
  const { objects: platformVersions } = body;

  return platformVersions.map(({ date, version }) => ({
    date: new Date(date),
    version,
  }));
}

function useQueryPlatformVersions(
  options?: UseQueryOptions<PlatformVersion[], Error, PlatformVersion[]>
) {
  const notify = useNotify();
  const queryKey = getQueryKey();
  const url = new URL(
    '/api/platform/cli/platform-versions',
    ZAPIER_APP_BASE_URL
  );

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: error => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryPlatformVersions;
export { getQueryKey, QUERY_KEY_PREFIX };
