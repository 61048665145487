import { addMinutes } from 'date-fns';

export const APPS_PENDING_DELETION_KEY = 'apps-pending-deletion';

const isLegacyAppEntity = app => app.hasOwnProperty('version');

export const getAppsPendingDeletion = (): Record<string, any> => {
  return JSON.parse(localStorage.getItem(APPS_PENDING_DELETION_KEY)) || {};
};
export const addAppPendingDeletion = (app: { id: string }) => {
  const apps = getAppsPendingDeletion();

  localStorage.setItem(
    APPS_PENDING_DELETION_KEY,
    JSON.stringify({
      ...apps,
      [app.id]: {
        ...app,
        pendingDeletionExpirationAt: addMinutes(Date.now(), 20).getTime(),
      },
    })
  );
};

const refreshStore = (): void => {
  const appsPendingDeletion = getAppsPendingDeletion();

  if (!appsPendingDeletion) {
    return;
  }

  // Returns apps that have not expired yet
  const apps = Object.values(appsPendingDeletion).filter(
    app =>
      app.pendingDeletionExpirationAt &&
      app.pendingDeletionExpirationAt > Date.now()
  );

  const value = {};
  for (const app of apps) {
    value[app.id] = app;
  }
  localStorage.setItem(APPS_PENDING_DELETION_KEY, JSON.stringify(value));
};

// getCategorizedAppsPendingDeletion returns non-expired apps and legacy apps.
export const getCategorizedAppEntitiesPendingDeletion = () => {
  refreshStore();
  const apps = getAppsPendingDeletion();
  return [
    Object.values(apps).filter(app => !isLegacyAppEntity(app)),
    Object.values(apps).filter(app => isLegacyAppEntity(app)),
  ];
};
