import { connect } from 'react-redux';

import { ToastNotification } from '@zapier/shared-entities';

import PageNotification from './PageNotification';

const mapStateToProps = state => {
  const notifications = ToastNotification.selectors.all
    .entities(state)
    .map(notification => ({
      id: ToastNotification.selectors.id(notification),
      message: ToastNotification.selectors.message(notification),
      type: ToastNotification.selectors.type(notification),
      dismissAfter: ToastNotification.selectors.dismissAfter(notification),
    }));

  return {
    notifications,
  };
};

const mapDispatchToProps = dispatch => ({
  onDismiss: id => {
    // If no id is provided, we reset the whole collection.
    dispatch(
      id
        ? ToastNotification.actions.deleteCollectionEntities(id)
        : ToastNotification.actions.resetCollection()
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageNotification);
