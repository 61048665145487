// @flow weak

import partiallyApplyComponent from 'app/common/components/partiallyApplyComponent';

const hocify = (Component, partialProps) => ComponentProp => {
  return partiallyApplyComponent(Component, {
    ...(partialProps || {}),
    Component: ComponentProp,
  });
};

export default hocify;
