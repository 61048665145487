// @flow
/** @jsx jsx */

import { size } from 'lodash';

import { Button, Icon } from '@zapier/common-components';
import { css, jsx } from '@emotion/core';

import {
  severityColors,
  severityIcons,
  severityLabels,
} from 'app/perf-utils/Messages';

import type {
  PerformanceLintReport,
  PerformanceLintSeverity,
} from 'app/perf-utils/types';

type SeverityButtonProps = {
  report?: PerformanceLintReport,
  severity: PerformanceLintSeverity,
  isOpened: boolean,
  isSelected: boolean,
  onSelect: (severity: PerformanceLintSeverity) => void,
};

const severityButtonContainerStyle = css({
  display: 'inline-block',
  marginLeft: 10,
});

type ShouldShowButtonProps = {
  report?: PerformanceLintReport,
  severity: PerformanceLintSeverity,
};

const getReportSize = ({ report, severity }: ShouldShowButtonProps) =>
  report ? size(report[severity]) : 0;

const SeverityButton = ({
  report,
  severity,
  isOpened,
  isSelected,
  onSelect,
}: SeverityButtonProps) =>
  getReportSize({ report, severity }) > 0 ? (
    <div css={severityButtonContainerStyle}>
      <Button
        backgroundType={!isSelected ? 'chameleon' : undefined}
        onClick={() => onSelect(severity)}
        size="small"
      >
        <Icon
          color={severityColors[severity]}
          name={severityIcons[severity]}
          size={12}
        />
        &nbsp;
        {isOpened && <strong>{severityLabels[severity]}</strong>}
        &nbsp;
        <strong>{getReportSize({ report, severity })}</strong>
      </Button>
    </div>
  ) : null;

export default SeverityButton;
