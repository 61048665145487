// @flow weak
/*
Sometimes, you want to use a thunk so you can get (dispatch, getState), but
you also want the dispatch of that action to return a promise. This middleware
lets you do both.

Return an object like:

{
  thunkPromise: thunk,
  ...other props...
}

`thunk` should be a function. It can return an action to be dispatched or a
promise. Dispatch will return a promise that resolves when the promise or
dispatching the new action resolves.
*/

import _ from 'lodash';

import { exists } from 'app/common/CommonUtils';

export default ({ dispatch, getState }) => next => action => {
  if (!action) {
    return next(action);
  }
  if (!action.thunkPromise || !_.isFunction(action.thunkPromise)) {
    return next(action);
  }
  return new Promise((resolve, reject) => {
    const newActionOrPromise = action.thunkPromise(dispatch, getState);
    if (
      _.isObject(newActionOrPromise) &&
      _.isFunction(newActionOrPromise.then)
    ) {
      return newActionOrPromise.then(resolve).catch(reject);
    }
    if (!exists(newActionOrPromise)) {
      return resolve();
    }
    const promise = dispatch(newActionOrPromise);
    if (_.isObject(promise) && _.isFunction(promise.then)) {
      return promise.then(resolve).catch(reject);
    } else {
      return resolve();
    }
  });
};
