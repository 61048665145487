// @flow

import React, { useContext } from 'react';

export type ProfileType = {} | null;
export type ProfileContextType = {
  isProfileLoading: boolean,
  profile: ProfileType,
};

const DEFAULT_VALUE: ProfileType = {
  isProfileLoading: false,
  profile: {},
};

const ProfileContext = React.createContext(DEFAULT_VALUE);

export default ProfileContext;

export const useProfile = () => useContext(ProfileContext);
