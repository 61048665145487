import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import type { PartnerDashboard } from 'app/developer-v3/types/partnerDashboard';

type Args = { serviceId: number };
const QUERY_KEY_PREFIX = 'query-partner-dashboard';
const getQueryKey = (args: Args) => `${QUERY_KEY_PREFIX}-${args.serviceId}`;

function formatResponseBody(body: any): PartnerDashboard {
  const { health, usage, status } = body;

  return {
    health: {
      currentScore: health?.current_score,
      hasCompleted: health?.has_completed,
      statistics: {
        bugs: health?.statistics?.bugs,
        featureRequests: health?.statistics?.feature_requests,
        issues: health?.statistics?.issues,
        percentResponded: health?.statistics?.percent_responded,
      },
      tier: health?.tier, // { minimum, percent, upgrade }
    },
    status: {
      deadline: status?.deadline,
      tier: status?.tier,
    },
    usage: {
      currentActive: usage?.current_active,
      hasCompleted: usage?.has_completed,
      tier: usage?.tier, // { minimum, percent, upgrade }
    },
  };
}

function useQueryPartnerDashboard(
  args: Args,
  options?: UseQueryOptions<PartnerDashboard, Error, PartnerDashboard>
) {
  const { serviceId } = args;

  const notify = useNotify();
  const queryKey = getQueryKey(args);
  const url = new URL(
    `/api/v3/partner/${encodeURIComponent(serviceId)}/dashboard`,
    ZAPIER_APP_BASE_URL
  );

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: error => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryPartnerDashboard;
export { getQueryKey, QUERY_KEY_PREFIX };
