import React from 'react';

import Link from 'app/common/components/Link';

export const AUTH_SCHEMES = [
  {
    value: 'custom',
    label: 'API Key',
    helpText: (
      <span>
        Use API Key authentication type if you simply need to collect some
        information from your users and then include that information, as it was
        entered by the user, when you make an API request.{' '}
        <Link
          href="https://platform.zapier.com/build/apikeyauth"
          target="_blank"
        >
          Learn more
        </Link>
        .
      </span>
    ),
  },
  {
    value: 'oauth2',
    label: 'OAuth v2',
    helpText: (
      <span>
        Use the OAuth 2 authentication type if your API supports{' '}
        <Link
          href="https://tools.ietf.org/html/rfc6749#section-1.3.1"
          target="_blank"
        >
          OAuth 2 "Authorization Code" grant
        </Link>
        . When setting up a Zap, your user's browser will be redirected to your
        site where you can authenticate them. Your OAuth implementation will
        then return an access token that your Zapier integration will use to
        authorize requests to your API. If your API uses one of the other OAuth
        2 grant types, Session auth or API Key authentication will be a better
        fit.{' '}
        <Link href="https://platform.zapier.com/build/oauth" target="_blank">
          Learn more
        </Link>
        .
      </span>
    ),
  },
  {
    value: 'session',
    label: 'Session Auth',
    helpText: (
      <span>
        Use the session authentication type if you need to collect some
        information from your users, for example a user name and password, and
        then make a request to your API to exchange that information for a token
        or session key, which you will use to authorize subsequent API requests.
        The Zapier platform will handle refreshing this token automatically each
        time it expires.{' '}
        <Link
          href="https://platform.zapier.com/build/sessionauth"
          target="_blank"
        >
          Learn more
        </Link>
        .
      </span>
    ),
  },
  {
    value: 'basic',
    label: 'Basic Auth',
    helpText: (
      <span>
        Use the basic authentication type if your API relies on the{' '}
        <Link href="https://tools.ietf.org/html/rfc7617" target="_blank">
          HTTP “Basic” Authentication standard
        </Link>
        . When your user sets up a new Zap, Zapier will prompt them for a
        username and password, then automatically add the appropriate encoded
        authorization headers to your API requests for you.{' '}
        <Link
          href="https://platform.zapier.com/build/basicauth"
          target="_blank"
        >
          Learn more
        </Link>
        .
      </span>
    ),
  },
  {
    value: 'digest',
    label: 'Digest Auth',
    helpText: (
      <span>
        Use the digest authentication type if your API uses the{' '}
        <Link href="https://tools.ietf.org/html/rfc2617#page-6" target="_blank">
          HTTP Digest Access Authentication standard
        </Link>{' '}
        to authenticate requests. When your user sets up a new Zap, Zapier will
        prompt them for a username and password, handle the cryptographic
        interaction with your server, and automatically add the appropriate
        encoded authorization headers to your API requests for you.{' '}
        <Link
          href="https://platform.zapier.com/build/digestauth"
          target="_blank"
        >
          Learn more
        </Link>
        .
      </span>
    ),
  },
];
