// @flow

import type {
  ComponentLifecycleMeasure,
  PerformanceMeasure,
} from 'app/perf-utils/types';

export const getComponentNameFromPerformanceMeasure = (
  measure: PerformanceMeasure
): ?string => {
  const match = /⚛ (.*) \[update\]/g.exec(measure.name);
  if (match) {
    return match[1];
  }
  return null;
};

export const toComponentLifecycleMeasure = (
  measure: PerformanceMeasure
): ComponentLifecycleMeasure => {
  const name = measure.name;

  // Parse all the measurements of the form Component [operation]
  // Examples
  // ⚛ TrackTime [update]
  // ⚛ Connect(RouterHistoryContainer) [update]
  // ⚛ TrackTime [mount]
  // ⚛ Connect(RouterHistoryContainer) [mount]
  const matchPhase = /⚛ (.*) \[(.*)\]/g.exec(name);
  if (matchPhase) {
    return {
      name: matchPhase[1],
      // $FlowFixMe: https://cdn.zapier.com/storage/photos/771f74797952a5b5d6193cb9f950b3ca.png
      phase: matchPhase[2],
    };
  }

  // Parse all the measurements of the form Component.methodName
  // Examples
  // ⚛ ServicesSearch.shouldComponentUpdate
  // ⚛ pure(defaultProps(Icon)).shouldComponentUpdate
  const matchMethod = /⚛ (.*)\.(.*)/g.exec(name);
  if (matchMethod) {
    return {
      name: matchMethod[1],
      // $FlowFixMe: https://cdn.zapier.com/storage/photos/d8a0294682ea1fc80887e492c41cb118.png
      phase: matchMethod[2],
    };
  }

  // Parse everything else React-related (probably all React internals)
  if (name.startsWith('⚛')) {
    return {
      name,
      phase: 'react',
    };
  }

  return {
    name,
    phase: 'other',
  };
};

export const getComponentNameFromWhyDidYouUpdate = (name: string): string =>
  name.substring(0, name.lastIndexOf('.'));
