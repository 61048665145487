export const VB_GUIDE_LOCAL_STORAGE_KEY = 'vb-guide-open-tab-id';

export enum MODES {
  DEFAULT = 'default',
  PROMOTING = 'promoting',
  PUBLISHING = 'publishing',
}

export enum TABS {
  NONE = '',
  GUIDE = 'guide',
  VALIDATE = 'validate',
  SETTINGS = 'settings',
  CONVERSION = 'conversion',
  VERSIONING = 'versioning',
}

export enum COMMON_SECTION_IDS {
  NONE = '',
}

export enum GUIDE_SECTION_IDS {
  ZAPIER_PLATFORM = 'zapierPlatform',
  BUILD = 'build',
  TOUR = 'tour',
  GET_STARTED = 'getStarted',
  VERSIONS = 'versions',
  HELP = 'help',
}

export enum VERSIONING_SECTION_IDS {
  MIGRATE = 'migrate',
  PROMOTE = 'promote',
}

export const checkMap = {
  read: 'Trigger',
  write: 'Action',
  search: 'Search',
  auth: 'Auth',
  meta: 'Meta',
};

export const iconsMap = {
  errors: {
    name: 'formXCircle' as const,
    color: 'lava' as const,
  },
  activationTasks: {
    name: 'alertCircleFill' as const,
    color: 'warning' as const,
  },
  warnings: {
    name: 'alertInfoFill' as const,
    color: 'warning' as const,
  },
};

export const interactionGoals = {
  [TABS.GUIDE]: 'open the developer platform guide',
  [TABS.VALIDATE]: 'run a validation check for an integration',
  validated: 'view validation check details',
  [TABS.SETTINGS]: 'open the settings',
};
