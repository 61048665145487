// @flow

import { Component } from 'react';

import { createKeyHandler } from '@zapier/common-utils';

class MaybeShowFocusOutline extends Component<{}> {
  componentDidMount() {
    window.addEventListener(
      'keydown',
      createKeyHandler('tab', this.handleFirstTab)
    );
  }

  handleMouseDownOnce = () => {
    if (document.body) {
      document.body.classList.remove('user-is-tabbing');
    }

    window.removeEventListener('mousedown', this.handleMouseDownOnce);
    window.addEventListener(
      'keydown',
      createKeyHandler('tab', this.handleFirstTab)
    );
  };

  handleFirstTab = () => {
    if (document.body) {
      document.body.classList.add('user-is-tabbing');
    }

    window.removeEventListener(
      'keydown',
      createKeyHandler('tab', this.handleFirstTab)
    );
    window.addEventListener('mousedown', this.handleMouseDownOnce);
  };

  componentWillUnmount() {
    window.removeEventListener(
      'keydown',
      createKeyHandler('tab', this.handleFirstTab)
    );
    window.removeEventListener('mousedown', this.handleMouseDownOnce);
  }

  render() {
    return null;
  }
}

export default MaybeShowFocusOutline;
