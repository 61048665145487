import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import useNotify from './useNotify';

const QUERY_KEY_PREFIX = 'query-zapier-session';
const getQueryKey = () => QUERY_KEY_PREFIX;

type Session = {
  currentAccountId: number;
  email: string;
  emailHash: string;
  fullName: string;
  id: number;
  identity: number;
  isDeveloper: boolean;
  isStaff: boolean;
  firstName: string;
  lastName: string;
  username: string;
};

function formatResponseBody(body: any): Session {
  return {
    currentAccountId: body?.current_account_id ?? NaN,
    email: body?.email ?? '',
    emailHash: body?.email_hash ?? '',
    firstName: body?.first_name ?? '',
    fullName: `${body?.first_name} ${body?.last_name}`.trim(),
    id: body?.id ?? NaN,
    identity: body?.identity ?? '',
    isDeveloper: body?.is_developer ?? false,
    isStaff: body?.is_staff ?? false,
    lastName: body?.last_name ?? '',
    username: body?.username ?? '',
  };
}

function useQueryZapierSession(
  options?: UseQueryOptions<Session, Error, Session>
) {
  const notify = useNotify();
  const queryKey = getQueryKey();

  const query = useQuery({
    queryKey,
    queryFn: async () =>
      formatResponseBody(
        await fetchJson(`${ZAPIER_APP_BASE_URL}/api/v4/session`)
      ),
    onError: async error => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryZapierSession;
export { QUERY_KEY_PREFIX, getQueryKey };
