/** @jsx isolateComponent */

import { isolateComponent } from '@zapier/common-utils';

import { THEME } from './constants';

type Props = {
  /** Whether to inverse the logo's colors. */
  theme?: 'dark' | 'light';
  /** SVG Width. */
  width?: number;
};

const defaultProps = {
  theme: 'light' as const,
  width: 120,
};

function LogoZapierPlatform(props: Props) {
  const { theme = defaultProps.theme, width = defaultProps.width } = props;

  const themeInverted = theme === 'dark' ? 'light' : 'dark';

  return (
    <svg
      fill="none"
      viewBox="0 0 192 33"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7302 21.1057L21.7172 21.0644L29.9463 13.5309V9.90106H16.7441V13.5309H24.6298L24.6428 13.5657L16.4941 21.1057V24.7356H30.0789V21.1057H21.7302Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M38.185 9.55548C36.6621 9.55548 35.421 9.78515 34.4617 10.2445C33.5681 10.6448 32.8154 11.3044 32.3012 12.1377C31.8093 12.9764 31.5004 13.9097 31.3948 14.8763L35.4007 15.4393C35.5456 14.5858 35.8354 13.9808 36.2701 13.6244C36.7642 13.2515 37.3732 13.0632 37.9915 13.0919C38.8045 13.0919 39.3942 13.3092 39.7608 13.7439C40.1238 14.1786 40.3086 14.7807 40.3086 15.5741V15.9696H36.457C34.4965 15.9696 33.088 16.4044 32.2316 17.2738C31.3752 18.1432 30.9478 19.2908 30.9492 20.7167C30.9492 22.1773 31.3767 23.2641 32.2316 23.977C33.0866 24.69 34.1617 25.0442 35.4572 25.0399C37.0642 25.0399 38.3002 24.4871 39.1653 23.3815C39.6762 22.702 40.041 21.9243 40.2368 21.0971H40.3825L40.9345 24.7269H44.5644V15.5349C44.5644 13.6352 44.0637 12.1638 43.0625 11.1204C42.0612 10.0771 40.4354 9.55548 38.185 9.55548ZM39.4543 20.8862C38.8784 21.3666 38.1241 21.6057 37.1852 21.6057C36.4114 21.6057 35.8738 21.4702 35.5724 21.1992C35.4255 21.071 35.3087 20.9121 35.2302 20.7336C35.1517 20.5552 35.1134 20.3617 35.1181 20.1668C35.1096 19.983 35.1385 19.7994 35.2031 19.6271C35.2678 19.4549 35.3668 19.2976 35.4941 19.1648C35.6319 19.0356 35.7944 18.9357 35.9719 18.871C36.1493 18.8064 36.338 18.7784 36.5266 18.7887H40.3151V19.0387C40.3228 19.392 40.2491 19.7424 40.0999 20.0627C39.9507 20.3831 39.7298 20.6648 39.4543 20.8862Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M66.513 9.90106H62.2246V24.7378H66.513V9.90106Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M91.8319 9.90106C90.6206 9.90106 89.6656 10.3285 88.9672 11.1835C88.4658 11.7978 88.0847 12.7057 87.8239 13.9069H87.6978L87.1371 9.90106H83.4746V24.7356H87.7631V17.1281C87.7631 16.1689 87.9877 15.4335 88.4369 14.922C88.8861 14.4105 89.7149 14.1547 90.9234 14.1547H92.5514V9.90106H91.8319Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M79.2191 10.3859C78.2178 9.79035 76.955 9.49331 75.4306 9.49476C73.031 9.49476 71.1531 10.178 69.7968 11.5444C68.4405 12.9109 67.7623 14.7946 67.7623 17.1957C67.7278 18.6473 68.0447 20.0857 68.6861 21.3884C69.2732 22.5493 70.1875 23.5128 71.3161 24.1597C72.4521 24.8161 73.8034 25.1451 75.3698 25.1465C76.7246 25.1465 77.8563 24.9379 78.7649 24.5206C79.619 24.1458 80.3628 23.5583 80.9254 22.8143C81.461 22.0839 81.8588 21.2619 82.0991 20.3886L78.404 19.354C78.2486 19.9219 77.9491 20.44 77.5346 20.8581C77.1173 21.2754 76.4181 21.4841 75.4371 21.4841C74.2707 21.4841 73.4012 21.1501 72.8289 20.4821C72.4137 19.9995 72.1529 19.3105 72.0377 18.4172H82.1556C82.1969 17.9999 82.2186 17.6499 82.2186 17.3674V16.4436C82.2431 15.2026 81.9868 13.9722 81.4687 12.8442C80.9839 11.8167 80.1997 10.9598 79.2191 10.3859ZM75.3067 13.0311C77.0456 13.0311 78.0316 13.8868 78.2649 15.5981H72.1138C72.2114 15.0271 72.448 14.4889 72.8028 14.031C73.3665 13.363 74.2011 13.0297 75.3067 13.0311Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M64.4173 3.92162C64.0947 3.91064 63.7732 3.96464 63.4719 4.08039C63.1705 4.19614 62.8956 4.37128 62.6633 4.59541C62.4342 4.82169 62.2547 5.09312 62.1361 5.39245C62.0175 5.69179 61.9623 6.0125 61.9742 6.33426C61.9627 6.6581 62.018 6.98086 62.1367 7.28238C62.2554 7.5839 62.435 7.85772 62.6642 8.08675C62.8935 8.31579 63.1674 8.49512 63.4691 8.61356C63.7707 8.732 64.0935 8.78703 64.4173 8.77517C64.7391 8.78734 65.0599 8.73235 65.3593 8.61372C65.6587 8.49509 65.93 8.31542 66.1562 8.08614C66.3805 7.85451 66.5556 7.5799 66.671 7.27883C66.7864 6.97777 66.8397 6.65647 66.8278 6.33426C66.8426 6.01396 66.7905 5.69408 66.6747 5.39506C66.559 5.09603 66.3822 4.82444 66.1556 4.5976C65.9289 4.37077 65.6575 4.19369 65.3586 4.07767C65.0596 3.96165 64.7376 3.90707 64.4173 3.92162Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M55.5553 9.49475C54.0946 9.49475 52.9209 9.95336 52.0341 10.8706C51.3777 11.5502 50.8959 12.5645 50.5887 13.9136H50.4517L49.891 9.90772H46.2285V29.0002H50.5169V21.1363H50.6735C50.8043 21.6607 50.9878 22.1704 51.2212 22.6578C51.584 23.4517 52.1789 24.1171 52.9274 24.5662C53.7036 24.9897 54.5779 25.2005 55.4618 25.177C57.2571 25.177 58.6243 24.4778 59.5633 23.0795C60.5023 21.6812 60.9718 19.73 60.9718 17.2261C60.9718 14.8076 60.5175 12.9145 59.6089 11.5466C58.7004 10.1787 57.3492 9.49475 55.5553 9.49475ZM55.99 20.2452C55.49 20.9697 54.7076 21.3319 53.6425 21.3319C53.2034 21.3522 52.7657 21.2676 52.3658 21.085C51.9659 20.9024 51.6152 20.6272 51.3429 20.2821C50.7879 19.5851 50.5112 18.6309 50.5126 17.4195V17.137C50.5126 15.9067 50.7894 14.9649 51.3429 14.3113C51.8964 13.6578 52.663 13.3347 53.6425 13.3419C54.7264 13.3419 55.5139 13.6861 56.0052 14.3744C56.4964 15.0627 56.742 16.0227 56.742 17.2543C56.7449 18.528 56.4957 19.525 55.9943 20.2452H55.99Z"
        fill={THEME[themeInverted].primary}
      />
      <path d="M14.8367 21.1057H0V24.7399H14.8367V21.1057Z" fill="#FF4F00" />
      <path
        d="M107.021 23.379C104.926 23.379 103.58 21.8578 103.58 19.6633V18.7655C103.58 16.4712 104.926 15.0248 107.021 15.0248C109.241 15.0248 110.263 16.3714 110.263 19.1894C110.263 21.9825 109.216 23.379 107.021 23.379ZM112.408 19.1894C112.408 15.2492 110.812 13.0547 107.894 13.0547C105.65 13.0547 104.328 14.4263 103.754 16.4213H103.58V13.3539H101.41V28.8155H103.58V21.8578H103.729C104.303 23.8528 105.724 25.2993 107.994 25.2993C110.737 25.2993 112.408 23.1546 112.408 19.1894Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M117.026 8.98978H114.856V25H117.026V8.98978Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M123.853 23.5037C122.132 23.5037 121.558 22.6808 121.558 21.7581C121.558 20.6109 122.431 20.0124 123.952 20.0124H127.369V20.6608C127.369 22.3067 125.997 23.5037 123.853 23.5037ZM127.469 25H129.538V17.5435C129.538 14.6507 128.117 13.0796 124.875 13.0796C122.082 13.0796 120.411 14.4512 119.937 16.6956L121.982 17.1944C122.356 15.6482 123.229 14.8752 124.8 14.8752C126.696 14.8752 127.369 15.8228 127.369 17.5685V18.5161H123.628C120.785 18.5161 119.488 19.9875 119.488 22.0074C119.488 24.2519 120.91 25.2494 123.104 25.2494C125.174 25.2494 126.671 24.1521 127.244 22.2319H127.469V25Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M133.208 21.5336C133.208 23.7032 134.281 25.2993 136.725 25.2993C137.797 25.2993 138.421 25.1496 139.219 24.9002L138.944 22.98C138.321 23.1546 137.797 23.2793 137.174 23.2793C135.877 23.2793 135.378 22.6059 135.378 21.2842V15.1495H139.094V13.3539H135.378V10.0871H133.208V13.3539H131.438V15.1495H133.208V21.5336Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M144.47 12.6557C144.47 11.1345 145.068 10.3364 146.49 10.3364C146.988 10.3364 147.462 10.4113 148.011 10.4861L148.111 8.86509C147.637 8.76534 147.038 8.66559 146.165 8.66559C143.572 8.66559 142.3 10.2367 142.3 12.6058V13.3539H140.205V15.1495H142.3V25H144.47V15.1495H148.011V13.3539H144.47V12.6557Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M154.84 25.2993C158.531 25.2993 160.675 22.9301 160.675 19.1396C160.675 15.324 158.356 13.0547 154.84 13.0547C151.299 13.0547 149.004 15.324 149.004 19.1396C149.004 22.9301 151.149 25.2993 154.84 25.2993ZM154.84 14.925C157.234 14.925 158.506 16.4961 158.506 19.1146C158.506 21.7331 157.284 23.404 154.815 23.404C152.346 23.404 151.174 21.7331 151.174 19.1146C151.174 16.4961 152.446 14.925 154.84 14.925Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M165.17 25V19.2393C165.17 16.6208 166.192 15.6233 168.711 15.6233H169.31V13.2292H168.861C167.065 13.2292 165.868 14.127 165.27 16.6707L165.095 16.6458V13.3539H163V25H165.17Z"
        fill={THEME[themeInverted].primary}
      />
      <path
        d="M173.426 18.3415C173.426 16.3465 174.822 15.0248 176.892 15.0248C178.713 15.0248 179.411 16.2218 179.411 17.8178V25H181.58V18.3415C181.58 16.3465 182.927 15.0248 185.047 15.0248C186.867 15.0248 187.566 16.2218 187.566 17.8178V25H189.735V17.2941C189.735 14.7505 188.513 13.0547 185.92 13.0547C183.6 13.0547 182.229 14.4013 181.68 16.546H181.456C181.231 14.4263 180.109 13.0547 177.815 13.0547C175.745 13.0547 174.249 14.2766 173.6 16.3714H173.376V13.3539H171.256V25H173.426V18.3415Z"
        fill={THEME[themeInverted].primary}
      />
    </svg>
  );
}

export default LogoZapierPlatform;
