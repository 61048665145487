import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import useRedirectToAuth from './useRedirectToAuth';

import type { PartnerProfile } from 'app/developer-v3/types/partnerProfile';

const QUERY_KEY_PREFIX = 'query-partner-profile';
const getQueryKey = (secret?: string) => `${QUERY_KEY_PREFIX}-${secret}`;

function formatResponseBody(body: any): PartnerProfile {
  const { objects } = body;
  const partnerProfile = objects?.[0];

  return {
    customuser: partnerProfile?.customuser || '',
    email: partnerProfile?.email || '',
    firstName: partnerProfile?.first_name || '',
    jobTitle: partnerProfile?.job_title || '',
    lastName: partnerProfile?.last_name || '',
    memberships: (partnerProfile?.memberships || []).map((membership: any) => ({
      app: {
        id: membership.app.id,
        image: membership.app.image,
        isBeta: membership.app.is_beta,
        isPublic: membership.app.is_public,
        slug: membership.app.slug || '',
        title: membership.app.title,
        type: membership.app.type,
      },
      optedIn: membership.opted_in,
      type: membership.type,
    })),
    roles: partnerProfile?.roles || [],
  };
}

function useQueryPartnerProfile(
  options?: UseQueryOptions<PartnerProfile, Error, PartnerProfile>
) {
  const { redirectToAuth, secret } = useRedirectToAuth();
  const notify = useNotify();

  const queryKey = getQueryKey(secret);
  const url = new URL('/api/platform/cli/profile', ZAPIER_APP_BASE_URL);
  if (secret) {
    url.search = new URLSearchParams({ secret }).toString();
  }

  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => {
      if (error.responseText?.includes?.('Key Not Authorized') && secret) {
        redirectToAuth();
      } else {
        notify.failure(error.message, queryKey);
      }
    },
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryPartnerProfile;
export { getQueryKey, QUERY_KEY_PREFIX };
