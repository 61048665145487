import { useRedux } from '@zapier/toolbox-redux-framework';

import * as VersionLifecycle from 'app/entities/VersionLifecycle';

import useVersionLifecycle from './useVersionLifecycle';

const mapDispatch = dispatch => ({
  migrateVersion(
    appId: ID,
    fromVersion: string,
    toVersion: string,
    options: { email: string } | { percent: number }
  ) {
    return dispatch(
      VersionLifecycle.actions.migrate(appId, fromVersion, toVersion, options)
    );
  },
});

type BaseTypes = ReturnType<typeof useVersionLifecycle>;

type UseVersionMigration = {
  error: { messages: string[] };
  hasError: boolean;
  id: BaseTypes['id'];
  /** Are we waiting for a migration request to finish? */
  isLoading: boolean;
  /** Are we done migrating any versions of this integration? */
  isMigrated: boolean;
  /** Are we currently migrating any versions of this integration? */
  isMigrating: boolean;
  /** Is this a migration that hasn’t completed (in-progress)? */
  isPending: boolean;
  job: BaseTypes['job'];
  migrate(
    fromVersion: string,
    toVersion: string,
    options: { user: string } | { percent: number }
  ): Promise<void>;
  migratedDate: number;
  progress: BaseTypes['progress'];
  loadProgress: BaseTypes['loadProgress'];
  steps: BaseTypes['steps'];
  versionFrom: string;
  versionTo: string;
};

export default function useVersionMigration(appId: ID): UseVersionMigration {
  const lifecycle = useVersionLifecycle(appId);
  const { migrateVersion } = useRedux(null, mapDispatch);

  return {
    get error() {
      return lifecycle.error;
    },

    get hasError() {
      return lifecycle.hasError;
    },

    get isLoading() {
      return lifecycle.isLoading;
    },

    get isMigrating() {
      return lifecycle.isPendingByType(VersionLifecycle.ChangeType.migrate);
    },

    get isMigrated() {
      return lifecycle.isDoneByType(VersionLifecycle.ChangeType.migrate);
    },

    get progress() {
      return lifecycle.progress;
    },

    get job() {
      return lifecycle.job;
    },

    get isPending() {
      return lifecycle.isPendingByType(VersionLifecycle.ChangeType.migrate);
    },

    migrate(fromVersion, toVersion, options) {
      return migrateVersion(appId, fromVersion, toVersion, options);
    },

    get migratedDate() {
      return lifecycle.isDoneByType(VersionLifecycle.ChangeType.migrate)
        ? lifecycle.updatedAt
        : null;
    },

    get steps() {
      return lifecycle.steps;
    },

    id: lifecycle.id,

    loadProgress: lifecycle.loadProgress,

    versionFrom: lifecycle.job.selectedApi.from,

    versionTo: lifecycle.job.selectedApi.to,
  };
}
