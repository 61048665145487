import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

import type { CustomAction } from 'app/developer-v3/types/customAction';

const QUERY_KEY_PREFIX = 'query-external-actions';
const getQueryKey = (selectedApi: string) =>
  `${QUERY_KEY_PREFIX}-${selectedApi}`;

function formatResponseBody(body: any): CustomAction[] {
  return (body ?? []).map(action => ({
    accountId: action.account_id,
    customuserId: action.customuser_id,
    description: action.description,
    extensionType: action.extension_type,
    id: action.id,
    isPublic: action.visibility === 'P' && action.status === 'P',
    name: action.name,
    selectedApi: action.selected_api,
    status: action.status,
    visibility: action.visibility,
  }));
}

function useQueryCustomActions(
  selectedApi: string,
  options?: UseQueryOptions<CustomAction[], Error, CustomAction[]>
) {
  const queryKey = getQueryKey(selectedApi);
  const notify = useNotify();

  const url = new URL(
    `api/v4/appextensions/proxy/actions?selected_api=${selectedApi}`,
    ZAPIER_APP_BASE_URL
  );

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      return formatResponseBody(await fetchJson(url.href));
    },
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  const { data, ...rest } = query;

  return { ...rest, customActions: data, queryKey };
}

export default useQueryCustomActions;
export { getQueryKey, QUERY_KEY_PREFIX };
