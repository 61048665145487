// @flow weak

import _ from 'lodash';

export default store => next => action => {
  if (!(action && action.willPromise)) {
    return next(action);
  }

  const { createPromise, args = [] } = action.willPromise;

  const preppedArgs = args.map(arg =>
    _.isFunction(arg) ? arg(store.getState()) : arg
  );

  const transformedAction = {
    ..._.omit(action, 'willPromise'),
    promise: createPromise(...preppedArgs),
  };

  return next(transformedAction);
};

export const willPromisify = (createPromise, args, action = {}) => ({
  ...action,
  willPromise: {
    createPromise,
    args,
  },
});
