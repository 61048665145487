import { useMutation, useQueryClient } from 'react-query';

import { postJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import useNotify from '../useNotify';
import { getQueryKey } from './useQueryCustomActions';

import type { CustomAction } from 'app/developer-v3/types/customAction';

type Args = { customActionId: number };
type Return = CustomAction;

const MUTATION_KEY = 'publish-custom-admin';

function formatResponseBody(body: any): CustomAction {
  const action = body ?? {};

  return {
    accountId: action.account_id,
    customuserId: action.customuser_id,
    description: action.description,
    extensionType: action.extension_type,
    id: action.id,
    isPublic: action.visibility === 'P' && action.status === 'P',
    name: action.name,
    selectedApi: action.selected_api,
    status: action.status,
    visibility: action.visibility,
  };
}

function useMakeCustomActionPublic() {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const mutationKey = MUTATION_KEY;

  const mutation = useMutation<Return, Error, Args>({
    mutationKey,
    async mutationFn({ customActionId }) {
      const url = new URL(
        `api/v4/appextensions/proxy/actions/${encodeURIComponent(
          customActionId
        )}/make_public`,
        ZAPIER_APP_BASE_URL
      );

      return formatResponseBody(
        await postJson(url.href, { body: JSON.stringify({}) })
      );
    },
    onError(error) {
      notify.failure('Failed to make Custom Action public');
      console.error(JSON.stringify(error));
    },
    onSuccess(data) {
      queryClient.invalidateQueries(getQueryKey(data.selectedApi));
      notify.success('Successfully made Custom Action public.');
    },
  });

  return {
    isMutating: mutation.isLoading,
    mutateAsync: mutation.mutateAsync,
    mutationKey,
  };
}

export default useMakeCustomActionPublic;
