// @flow

import { combineReducers } from 'redux';

import { deepFreeze, isProductionEnv } from 'app/common/CommonUtils';

import type { ReducersMap } from 'app/common/types';

const freezeState = isProductionEnv() ? state => state : deepFreeze;

export default (reducers: ReducersMap) => {
  const reducer = combineReducers(reducers);
  return (...args: []) => {
    const state = reducer(...args);
    return freezeState(state);
  };
};
