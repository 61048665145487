// @flow

import * as React from 'react';

import { StaticAssetProvider } from '@zapier/common-components';

type Props = {
  children: React.Node,
};

export default (props: Props) => (
  <StaticAssetProvider
    {...props}
    staticAssetPath={process.env.MEDIA_URL || ''}
  />
);
