const routes = {
  '/zap-templates': {
    fetch: () =>
      import(
        /* webpackChunkName: 'zap-templates-route' */
        'app/zap-templates/routes/asyncRoutes'
      ).then(module => module.default),
  },
};

export default routes;
