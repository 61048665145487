/** @jsx jsx */

import { ComponentProps } from 'react';

import { Link as DSLink } from '@zapier/design-system';
import { css, jsx } from '@emotion/core';

import { isClientRoute } from './utils';

const Styles = {
  root: css`
    cursor: pointer !important;
    &,
    &:focus,
    &:hover {
      text-decoration: none !important;
    }
  `,
};

type Props = ComponentProps<typeof DSLink>;

function BlockLink(props: Props) {
  const { children, className, href, onClick, target, ...restProps } = props;

  // We need a straight <a> element in the case of a block link opening a new
  // tab, or browsers show a popup blocker in the address bar.
  const LinkComponent =
    isClientRoute(href) && target === '_blank' ? 'a' : DSLink;

  return (
    <LinkComponent
      {...restProps}
      css={Styles.root}
      className={className} // eslint-disable-line react/jsx-sort-props
      href={href}
      onClick={onClick}
      target={target}
    >
      {children}
    </LinkComponent>
  );
}

export default BlockLink;
