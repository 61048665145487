// @flow weak
/*
Validate every action to follow certain constraints:
- Actions must be objects.
- Actions must have a type field that is a string.
*/

import _ from 'lodash';

import { commonErrors as Errors } from '@zapier/toolbox-redux-framework';

export default (/*store*/) => next => action => {
  if (!_.isObject(action)) {
    throw new Errors.ActionNotObjectError({ action });
  }

  if (!_.isString(action.type)) {
    throw new Errors.ActionTypeNotValid({ action });
  }

  return next(action);
};
