export function emitUserEventOptionClicked(
  emit: (eventProperties: {
    eventAction: 'click';
    interactionGoal: string;
    interactionName: 'profile_menu_option_clicked';
    interactionRawText: string;
    optionSelected: string;
  }) => void,
  interactionGoal: string,
  interactionRawText: string,
  optionSelected: string
) {
  emit({
    eventAction: 'click',
    interactionGoal,
    interactionName: 'profile_menu_option_clicked',
    interactionRawText,
    optionSelected,
  });
}
