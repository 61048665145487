// @flow weak

import React from 'react';
import bemify from 'bemify-js';

import { StaticImage } from '@zapier/common-components';
import { buildCommonComponent } from '@zapier/legacy-components';

import Icon from 'app/common/components/Icon';
import 'app/common/components/InfoBlock.scss';

const block = 'info-block';
const bem = bemify(block);

const buildInfoBlockComponent = buildCommonComponent(block);

export const InfoBlock = buildInfoBlockComponent();
export const InfoBlockContent = buildInfoBlockComponent('__content');
export const InfoBlockInner = buildInfoBlockComponent('__inner');
export const InfoBlockDescription = buildInfoBlockComponent('__description');
export const InfoBlockFooter = buildInfoBlockComponent('__footer', 'footer');
export const InfoBlockAddOnFooter = buildInfoBlockComponent(
  '__add-on-footer',
  'footer'
);
export const InfoBlockIconWrap = buildInfoBlockComponent('__icon-wrap');
export const InfoBlockPreTitle = buildInfoBlockComponent('__pre-title', 'p');
export const InfoBlockTitle = buildInfoBlockComponent('__title', 'h1');

export const InfoBlockIcon = props => {
  return <Icon {...props} className={bem('__icon', props.className)} />;
};

export const InfoBlockStaticImage = props => {
  return <StaticImage {...props} className={bem('__image', props.className)} />;
};

export const InfoBlockIcons = props => {
  return props.icons.map(icon => (
    <InfoBlockIcon className={props.className} type={icon} />
  ));
};

export default InfoBlock;
