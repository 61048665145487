import { useRedux } from '@zapier/toolbox-redux-framework';

import * as CliApp from 'app/entities/CliApp';
import { currentAppEntity } from 'app/developer-v3/selectors';
import {
  isPublicIntentionElementVisible,
  parseBackendAppToAppFormType,
} from 'app/developer-v3/utils';

const mapState = state => {
  const appEntity = currentAppEntity(state);

  const { intention = 'global' } = appEntity.data
    ? parseBackendAppToAppFormType(appEntity.data)
    : {};

  return {
    isPrivate: CliApp.selectors.isPrivate(appEntity),
    isPublicIntention: intention === 'global',
  };
};

function usePrivatePlatform() {
  const { isPrivate, isPublicIntention } = useRedux(mapState);

  return {
    isPublicIntention,
    isPublicIntentionElementVisible: isPublicIntentionElementVisible({
      isPrivate,
      isPublicIntention,
    }),
  };
}

export default usePrivatePlatform;
