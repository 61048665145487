import type { KeyValue } from 'app/developer-v3/components/KeyValueEditor';

const convertObjToKvps = (
  params: Record<string, string> = {}
): Array<KeyValue> =>
  Object.entries(params).map(([key, value]) => ({ key, value }));

const convertKvpsToObj = (items: Array<KeyValue>): Record<string, string> =>
  items.reduce((params, item) => ({ ...params, [item.key]: item.value }), {});

export { convertKvpsToObj, convertObjToKvps };
