// @flow weak

import { ROUTE_TO_NEXT } from 'redux-router-kit';

import { getJson } from '@zapier/toolbox-browser-fetch';
import { urlWithNext } from '@zapier/url-utils';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import { isAuthRequiredForRoutes } from 'app/common/utils/RouterUtils';
import clearDismissables from 'app/developer-v3/utils/clearDismissables';

// This class takes care of fetching and caching the user session.
// This is a rudimentary cache to prevent fetching the session on every route action that happens.
class SessionRepository {
  expires: ?number;
  session: ?{};

  // The session endpoint doesn't give us a way to calculate when the session will expire,
  // nor can we access the session cookie since it came from zapier.com, so we use a rudimentary
  // cache expiration mechanism.
  // This should be okay because when the session expires and a random request later 401's, the
  // code in `autoPromiseMiddleware` should take care of redirecting the user back to zapier.com/app/login.
  static TTL = 1000 * 60 * 60; // 1 hour

  constructor() {
    this.expires = null;
    this.session = null;
  }

  async get() {
    // If there is a cached session and if the cache has not expired
    if (this.session && this.expires && this.expires > Date.now()) {
      return this.session;
    }

    this.expires = Date.now() + SessionRepository.TTL;
    this.session = await getJson('/api/v4/session/');
    return this.session;
  }
}

const createAuthenticationMiddleware = ({ routes }) => {
  const session = new SessionRepository();

  const middleware = () => next => async action => {
    if (!action || action.type !== ROUTE_TO_NEXT) {
      return next(action);
    }

    const { routeKey } = action.meta;
    const isAuthRequired = isAuthRequiredForRoutes(routes, routeKey);
    if (!isAuthRequired) {
      return next(action);
    }

    const { is_logged_in } = await session.get();

    if (is_logged_in) {
      return next(action);
    }

    clearDismissables('-missing-required-settings');
    window.location = urlWithNext(
      `${ZAPIER_APP_BASE_URL}/platform/login`,
      `${window.location.origin}${action.meta.url}` // action.meta.url will always starts with a slash
    );
    return Promise.resolve();
  };

  middleware.setRoutes = newRoutes => {
    routes = newRoutes;
  };

  return middleware;
};

export default createAuthenticationMiddleware;
