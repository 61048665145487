import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import useNotify from './useNotify';

const QUERY_KEY_PREFIX = 'query-support-eligibility';
const getQueryKey = () => QUERY_KEY_PREFIX;

type SupportEligibility = {
  isOnboardingChatEligible: boolean;
  isEmailSupportEligible: boolean;
  isPremierSupportEligible: boolean;
};

function formatResponseBody(body: any): SupportEligibility {
  return {
    isOnboardingChatEligible: body?.is_onboarding_chat_eligible ?? false,
    isEmailSupportEligible: body?.is_email_support_eligible ?? true,
    isPremierSupportEligible: body?.is_premier_support_eligible ?? true,
  };
}

function useQuerySupportEligibility(
  options?: UseQueryOptions<SupportEligibility, Error, SupportEligibility>
) {
  const notify = useNotify();
  const queryKey = getQueryKey();

  const query = useQuery({
    queryKey,
    queryFn: async () =>
      formatResponseBody(
        await fetchJson(`${ZAPIER_APP_BASE_URL}/api/v4/support-eligibility/`)
      ),
    onError: async error => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQuerySupportEligibility;
export { QUERY_KEY_PREFIX, getQueryKey };
