import { useQuery, UseQueryOptions } from 'react-query';

import { fetchJson } from '@zapier/toolbox-browser-fetch';

import { ZAPIER_APP_BASE_URL } from 'app/common/constants';
import { useNotify } from 'app/developer-v3/hooks';

const QUERY_KEY_PREFIX = 'query-service-implementation';
const getQueryKey = (selectedApi: string) =>
  `${QUERY_KEY_PREFIX}-${selectedApi}`;

type ServiceImplementation = {
  // auth_type: string; // TODO: refine this type. 'oauth' and ?
  // isBeta: boolean;
  // isDeprecated: boolean;
  // isHidden: boolean;
  // isInvite: boolean;
  // isPremium: boolean;
  name: string;
  // primaryColor: string;
  selectedApi: string;
};

function formatResponseBody(body: any): ServiceImplementation {
  const implementation = body?.results?.[0];

  return implementation
    ? {
        // auth_type: implementation.auth_type,
        // isBeta: implementation.is_beta,
        // isDeprecated: implementation.is_deprecated,
        // isHidden: implementation.is_hidden,
        // isInvite: implementation.is_invite_only,
        // isPremium: implementation.is_premium,
        name: implementation.name,
        // primaryColor: implementation.primary_color,
        selectedApi: implementation.selected_api,
      }
    : undefined;
}

function useQueryServiceImplementation(
  { selectedApi }: { selectedApi: string },
  options?: UseQueryOptions<ServiceImplementation, Error, ServiceImplementation>
) {
  const notify = useNotify();
  const queryKey = getQueryKey(selectedApi);
  const url = new URL('/api/v4/implementations', ZAPIER_APP_BASE_URL);
  url.search = new URLSearchParams({ selected_apis: selectedApi }).toString();
  const query = useQuery({
    queryKey,
    queryFn: async () => formatResponseBody(await fetchJson(url.href)),
    onError: async (error: any) => notify.failure(error.message, queryKey),
    ...options,
  });

  return { ...query, queryKey };
}

export default useQueryServiceImplementation;
export { getQueryKey, QUERY_KEY_PREFIX };
