import { isEmpty } from 'lodash';

import type { AppDefinitionSchema } from 'app/developer-v3/platformSchema/app-definition';
import type { ZapData } from 'app/entities/Zap/types';
import { CliAppDefinitionData } from 'app/entities/CliAppDefinition/types';

type ZapDetail = {
  hasTask: boolean;
  hasZap: boolean;
  key: string;
  name: string;
  type: 'triggers' | 'creates' | 'searches';
};

function findZapsOfImplementation(
  implementation: CliAppDefinitionData,
  zaps: Array<ZapData>
) {
  const selectedApi = implementation.selected_api;
  const definition = implementation.definition_override || {};
  const services = ['triggers', 'creates', 'searches']
    .map(type => Object.values(definition[type] ?? {}))
    .flat();
  const serviceKeys = services.map(({ key }) => key);
  return zaps.filter(
    ({ nodes }) =>
      !isEmpty(nodes) &&
      nodes.some(
        node =>
          node.selected_api === selectedApi && serviceKeys.includes(node.action)
      )
  );
}

/*
 * For each trigger/create/search, find the zaps  that has a node using the
 * trigger/create/search.
 */
function matchZaps(
  selectedApi: string,
  definition: AppDefinitionSchema,
  zaps: Array<ZapData>,
  types: Array<'triggers' | 'creates' | 'searches'>
) {
  return types.flatMap(type => {
    const services = definition[type]; // Triggers, creates, or searches.

    if (!services) {
      return [];
    }

    return Object.entries(services).map(([serviceKey, service]) => {
      const matchedZaps = zaps.filter(
        ({ nodes }) =>
          !isEmpty(nodes) &&
          nodes.some(
            node =>
              node.selected_api === selectedApi && node.action === serviceKey
          )
      );

      return {
        key: serviceKey,
        name: service.display.label,
        type,
        zapIds: matchedZaps.map(matchedZap => matchedZap.id),
      };
    });
  });
}

/**
 * Gives a summary of an implementation's Zap/Task data
 * (ie. Show whether each trigger/action have a corresponding Zap and/or Task).
 * If @zaps and @tasks aren't included, we still get the trigger/action detail summary
 */
function parseZapDetails(
  appVersion: CliAppDefinitionData,
  zaps: ZapData[] = [],
  zapIdsOfTasks: ID[] = []
): { triggers: ZapDetail[]; actions: ZapDetail[] } {
  const selectedApi = appVersion.selected_api;
  const definition = appVersion.definition_override || {};

  const zapTriggers = matchZaps(selectedApi, definition, zaps, ['triggers']);
  const zapActions = matchZaps(selectedApi, definition, zaps, [
    'creates',
    'searches',
  ]);

  const appendDetails = ({ key, name, type, zapIds }) => {
    const hasZap = zapIds.length > 0;
    return {
      hasZap,
      hasTask: hasZap && zapIdsOfTasks.some(id => zapIds.includes(id)),
      key,
      name,
      type,
    };
  };

  return {
    triggers: zapTriggers.map(appendDetails),
    actions: zapActions.map(appendDetails),
  };
}

export { findZapsOfImplementation, parseZapDetails };
