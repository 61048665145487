import { $merge, $none, $set, update } from 'qim';
import { curry, defaults, flow, partial, reduce } from 'lodash';

import { createReducer } from '@zapier/toolbox-redux-framework';

import ActionTypes from 'app/auth/ActionTypes';
import { TestingStatuses } from 'app/auth/utils/constants';
import { get } from 'app/common/CommonUtils';

const initialState = {
  auths: undefined,
  authStates: undefined,
};

const defaultAuthState = id => ({
  id,
  isDisconnecting: false,
  isReconnecting: false,
  // This really only works one time for the destination auths modal. We'd need
  // to reset this to make it more universally useful and resilient to state
  // changes.
  isReconnected: false,
  isRenaming: false,
  isShowingTestingError: false,
  numZaps: 0,
  testingStatus: TestingStatuses.INITIAL,
  testingError: null,
  isLoading: false,
});

const updateAuthItem = curry((keypath, data, id, state) => {
  return update([keypath, id, $merge(data)], state);
});

const deleteAuthItem = curry((keypath, id, state) => {
  const newState = update([keypath, id, $none], state);
  newState[keypath] = newState[keypath] || {};
  return newState;
});

const updateAuthState = updateAuthItem('authStates');
const deleteAuthState = deleteAuthItem('authStates');
const updateAuthData = updateAuthItem('auths');
const deleteAuthData = deleteAuthItem('auths');

const addAuth = (auth, state) => {
  const authState = defaults(
    {},
    get(state, ['authStates', auth.id]),
    defaultAuthState(auth.id)
  );
  return flow(
    updateAuthData(auth, auth.id),
    updateAuthState(authState, auth.id)
  )(state);
};

const deleteAuth = (auth, state) => {
  return flow(deleteAuthState(auth.id), deleteAuthData(auth.id))(state);
};

const resetAuths = (auths, state) => {
  const newState = update(
    [
      ['auths', $set({})],
      ['isLoading', $set(false)],
    ],
    state
  );
  return auths && auths.length
    ? auths.reduce((accumState, auth) => {
        return addAuth(auth, accumState);
      }, newState)
    : newState;
};

const handlers = {
  [ActionTypes.LOAD_AUTHS](state) {
    return update(['isLoading', $set(true)], state);
  },

  // For swapping auth records against a node
  [ActionTypes.UPDATE_AUTHS](state, { ops }) {
    return ops.reduce((newState, op) => {
      const auth = op.data;
      if (op.type === 'add') {
        return addAuth(auth, newState);
      } else if (op.type === 'remove') {
        return deleteAuth(auth, newState);
      } else if (op.type === 'reset') {
        return resetAuths(op.data, newState);
      }
      return newState;
    }, state);
  },

  // For PUTs to individual auth records. Optimistically updates.
  [ActionTypes.UPDATE_AUTH](state, { id, newData }) {
    return updateAuthData(newData, id, state);
  },

  // We optimistically updated above, so here we'll reset.
  // Sorry about the error!
  [ActionTypes.SAVE_AUTH_FAIL](state, { authData }) {
    return updateAuthData(authData, authData.id, state);
  },

  [ActionTypes.DELETE_AUTH_DONE](state, { id }) {
    return deleteAuth({ id }, state);
  },

  [ActionTypes.UPDATE_AUTH_STATE](state, { id, newState }) {
    return updateAuthState(newState, id, state);
  },

  [ActionTypes.RESET_AUTH_STATES](state) {
    return Object.keys(state.authStates || {}).reduce((newState, id) => {
      return updateAuthState(defaultAuthState(id), id, newState);
    }, state);
  },

  [ActionTypes.TEST_AUTH](state, { id }) {
    return updateAuthState(
      {
        testingStatus: TestingStatuses.TESTING,
        testingError: null,
      },
      id,
      state
    );
  },

  [ActionTypes.TEST_AUTH_DONE](state, { id }) {
    return updateAuthState(
      {
        testingStatus: TestingStatuses.SUCCESS,
        testingError: null,
      },
      id,
      state
    );
  },

  [ActionTypes.TEST_AUTH_FAIL](state, { id, err }) {
    return updateAuthState(
      {
        testingStatus: TestingStatuses.FAILURE,
        testingError: err.toString(),
      },
      id,
      state
    );
  },

  [ActionTypes.LOAD_AUTH_COUNTS_DONE](state, { result }) {
    return reduce(
      result,
      (newState, { id, zap_count }) =>
        updateAuthState({ numZaps: zap_count }, id, newState),
      state
    );
  },

  [ActionTypes.RECONNECT_AUTH](state, { id }) {
    return updateAuthState(
      {
        isReconnecting: true,
        isReconnected: false,
      },
      id,
      state
    );
  },

  [ActionTypes.RECONNECT_AUTH_DONE](state, { result }) {
    return flow(
      partial(updateAuthData, result, result.id),
      partial(
        updateAuthState,
        {
          isReconnecting: false,
          isReconnected: true,
          testingStatus: TestingStatuses.INITIAL,
          testingError: null,
        },
        result.id
      )
    )(state);
  },

  [ActionTypes.RECONNECT_AUTH_FAIL](state) {
    // TODO error ux?
    // We just toss close the popup and toss the store when this happens.
    // Not gonna log the result, just in case it's huge, contains secrets
    // or something.
    console.warn('Failed to reconnect auth');
    return state;
  },
};

export default createReducer(handlers, initialState);
